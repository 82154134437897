<div class="popup-global-layout width-650 p-4 p-md-5">
    <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="cancel()">close</mat-icon></div>
    <div class="global-title">{{ 'customizedClaims.terms.title' | translate }}</div>
    <div class="global-green-bar"></div>
    <div class="global-text">
        <div class="mb-3">{{ 'customizedClaims.terms.integral' | translate }}</div>
        <div>
            {{ 'customizedClaims.terms.award' | translate }}
            {{ 'customizedClaims.terms.confidentiality' | translate }}
        </div>
        <div>
            {{ 'customizedClaims.terms.contact' | translate }}
        </div>
    </div>
    <div class="global-button-format">
        <button type="button" class="upsc-white-button2" (click)="cancel()"><mat-icon class="icon-key">keyboard_arrow_left</mat-icon>Decline</button>
        <button type="button" class="upsc-green-popup-button" (click)="confirm()">Accept<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
    </div>
  </div>