import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslationLoaderResolver } from 'src/app/resolvers/translation-loader.resolver';
import { HappyReturnsLayoutComponent } from 'src/app/shared/components/happy-returns-layout/happy-returns-layout.component';
import { HappyReturnsComponent } from './happy-returns/happy-returns.component';
import { HappyReturnsLandingComponent } from './happy-returns-landing/happy-returns-landing.component';
import { HrFileClaimComponent } from './hr-file-claim/hr-file-claim.component';
import { HrClaimsComponent } from './hr-claims/hr-claims.component';
import { HrClaimListComponent } from './hr-claim-list/hr-claim-list.component';
import { HrMessagesComponent } from './hr-messages/hr-messages.component';
import { HrFaqComponent } from './hr-faq/hr-faq.component';
import { HrClaimDetailComponent } from './hr-claim-detail/hr-claim-detail.component';
import { HrNewClaimComponent } from './hr-new-claim/hr-new-claim.component';
import { HRAuthGuardService } from 'src/app/core/services/hr-auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: HappyReturnsLayoutComponent,
    resolve: { mode: TranslationLoaderResolver },
    children: [
      {
        path: '',
        component: HappyReturnsComponent,
        children: [
          {
            path: '', component: HappyReturnsLandingComponent,
          },
          {
            path: 'claims', component: HrClaimsComponent,
            canActivate: [HRAuthGuardService],
            children: [
              {
                path: '',
                component: HrClaimListComponent
              },
              {
                path: 'message-list',
                component: HrMessagesComponent
              },
              {
                path: 'help',
                component: HrFaqComponent,
              },
              {
                path: 'new',
                component: HrNewClaimComponent,
              }
            ],
          },
          {
            path: 'claims/file-claim', 
            component: HrFileClaimComponent,
            canActivate: [HRAuthGuardService]
             //resolve: { prefetch: ClaimEditResolver },
          },
          {
            path: 'claims/detail',
            component: HrClaimDetailComponent,
            //resolve: { policyDetails: HrPolicyResolver }
          },
          {
            path: 'claims/MultiClaim',
            component: HrNewClaimComponent,
            canActivate: [HRAuthGuardService]
          },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HappyReturnsRoutingModule { }
