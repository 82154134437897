<div class="claim-section">

  <div #parent (mousedown)="startDragging($event, false)" (mouseup)="stopDragging($event, false)"
    (mousemove)="moveEvent($event)" class="isop-navigation d-flex align-items-center justify-content-between">
    <ul class="navigation-items">
      <li class="navigation-item">
        <a href="#" routerLink="/happy-returns/claims" [class.active]="tabData.claims"
          (click)="setHighlghter('claims');">{{ 'claims.claimMenus.allClaims' |
          translate }}</a>
      </li>
      <li class="navigation-item">
        <a href="#" routerLink="/happy-returns/claims/new" [class.active]="tabData.fileClaim"
          (click)="setHighlghter('fileClaim');">{{ 'claims.claimMenus.fileNewClaim' | translate }}</a>
      </li>
      <li class="navigation-item" [ngClass]="{'message-bubble': showStats && (userPermission.isFileClaim)}">
        <a href="#" routerLink="/happy-returns/claims/message-list/" [class.active]="tabData.messages"
          (click)="setHighlghter('messages');">{{ 'claims.claimMenus.Messages' | translate }}</a>
        <div *ngIf="showStats && (userPermission.isFileClaim)" (click)="gotoMessages($event)" class="message-badge"
          ngbTooltip="You have {{claimStats?.claimsWithNewMessagesCnt}} new messages!" placement="right"
          tooltipClass="ngb-tooltip-class">
          {{ claimStats?.claimsWithNewMessagesCnt }}
        </div>
      </li>
      <li class="navigation-item">
        <a href="#" routerLink="/happy-returns/claims/help" [class.active]="tabData.faq"
          (click)="setHighlghter('faq');">{{ 'claims.claimMenus.claimsHelp' | translate }}</a>
      </li>
    </ul>
  </div>
  <router-outlet></router-outlet>
</div>