import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment'

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const b2cPolicies = {
     names: {
        signIn: environment.azureB2C.signinPolicy,
        // signIn: "b2c_1a_csaa_local_signin",
     },
     authorities: {
         signUpSignIn: {
            authority: `https://upsb2c.b2clogin.com/upsb2c.onmicrosoft.com/${environment.azureB2C.signinPolicy}`,
         },
     },
     authorityDomain: "upsb2c.b2clogin.com"
 };


export const msalConfig: Configuration = {
     auth: {
         clientId: environment.azureB2C.clientID,
         authority: b2cPolicies.authorities.signUpSignIn.authority,
         knownAuthorities: [b2cPolicies.authorityDomain]
     },
     cache: {
         cacheLocation: BrowserCacheLocation.LocalStorage,
         storeAuthStateInCookie: isIE,
     },
     system: {
         loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
                console.log(message);
             },
             logLevel: LogLevel.Verbose,
             piiLoggingEnabled: true
         }
     }
 }

export const protectedResources = {
  todoListApi: {
    endpoint: "http://localhost:5000/api/todolist",
    scopes: ['https://upsB2C.onmicrosoft.com/bb3ce5fd-bc0d-40c1-b21e-fe1cf4b3f429/InsureShield_api']
  },
  webAPI: {
    endpoint: "*",
    // scopes: ["https://upsB2C.onmicrosoft.com/bb3ce5fd-bc0d-40c1-b21e-fe1cf4b3f429/InsureShield_api"]
    scopes: []
  }
}

export const loginRequest = {
  redirectUri: `${environment.baseUrl.web}auth`,
  scopes: [environment.azureB2C.clientID]
  // scopes: ["https://upsB2C.onmicrosoft.com/bb3ce5fd-bc0d-40c1-b21e-fe1cf4b3f429/InsureShield_api/"]
};
