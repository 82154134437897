import en from '../assets/i18n/en.json';    //The "en" translations are basically useless in this file. We end up using translateService on these consts inside of the component files.

export const States = [
    { id: 1, state: 'AA'},  //USA
    { id: 2, state: 'AB'},  //CA
    { id: 3, state: 'AE'},  //USA
    { id: 4, state: 'AK'},
    { id: 5, state: 'AL'},
    { id: 6, state: 'AP'},  //USA
    { id: 7, state: 'AR'},
    { id: 8, state: 'AZ'},
    { id: 9, state: 'BC'},  //CA
    { id: 10, state: 'CA'},
    { id: 11, state: 'CO'},
    { id: 12, state: 'CT'},
    { id: 13, state: 'DC'},
    { id: 14, state: 'DE'},
    { id: 15, state: 'FL'},
    { id: 16, state: 'GA'},
    { id: 17, state: 'HI'},
    { id: 18, state: 'IA'},
    { id: 19, state: 'ID'},
    { id: 20, state: 'IL'},
    { id: 21, state: 'IN'},
    { id: 22, state: 'KS'},
    { id: 23, state: 'KY'},
    { id: 24, state: 'LA'},
    { id: 25, state: 'MA'},
    { id: 26, state: 'MB'},  //CA
    { id: 27, state: 'MD'},
    { id: 28, state: 'ME'},
    { id: 29, state: 'MI'},
    { id: 30, state: 'MN'},
    { id: 31, state: 'MO'},
    { id: 32, state: 'MS'},
    { id: 33, state: 'MT'},
    { id: 34, state: 'NB'},  //CA
    { id: 35, state: 'NC'},
    { id: 36, state: 'ND'},
    { id: 37, state: 'NE'},
    { id: 38, state: 'NH'},
    { id: 39, state: 'NJ'},
    { id: 40, state: 'NL'},  //CA
    { id: 41, state: 'NM'},
    { id: 42, state: 'NS'},  //CA
    { id: 43, state: 'NT'},  //CA
    { id: 44, state: 'NU'},  //CA
    { id: 45, state: 'NV'},
    { id: 46, state: 'NY'},
    { id: 47, state: 'OH'},
    { id: 48, state: 'OK'},
    { id: 49, state: 'ON'},  //CA
    { id: 50, state: 'OR'},
    { id: 51, state: 'PA'},
    { id: 52, state: 'PE'},  //CA
    { id: 53, state: 'PR'},
    { id: 54, state: 'QC'},  //CA
    { id: 55, state: 'RI'},
    { id: 56, state: 'SC'},
    { id: 57, state: 'SD'},
    { id: 58, state: 'SK'},  //CA
    { id: 59, state: 'TN'},
    { id: 60, state: 'TX'},
    { id: 61, state: 'UT'},
    { id: 62, state: 'VA'},
    { id: 63, state: 'VT'},
    { id: 64, state: 'WA'},
    { id: 65, state: 'WI'},
    { id: 66, state: 'WV'},
    { id: 67, state: 'WY'},
    { id: 68, state: 'YT'}  //CA
]

export const usStates = [
    { id: 1, state: 'AA'},  //USA
    { id: 2, state: 'AE'},  //USA
    { id: 3, state: 'AK'},
    { id: 4, state: 'AL'},
    { id: 5, state: 'AP'},  //USA
    { id: 6, state: 'AR'},
    { id: 7, state: 'AZ'},
    { id: 8, state: 'CA'},
    { id: 9, state: 'CO'},
    { id: 10, state: 'CT'},
    { id: 11, state: 'DC'},
    { id: 12, state: 'DE'},
    { id: 13, state: 'FL'},
    { id: 14, state: 'GA'},
    { id: 15, state: 'HI'},
    { id: 16, state: 'IA'},
    { id: 17, state: 'ID'},
    { id: 18, state: 'IL'},
    { id: 19, state: 'IN'},
    { id: 20, state: 'KS'},
    { id: 21, state: 'KY'},
    { id: 22, state: 'LA'},
    { id: 23, state: 'MA'},
    { id: 24, state: 'MD'},
    { id: 25, state: 'ME'},
    { id: 26, state: 'MI'},
    { id: 27, state: 'MN'},
    { id: 28, state: 'MO'},
    { id: 29, state: 'MS'},
    { id: 30, state: 'MT'},
    { id: 31, state: 'NC'},
    { id: 32, state: 'ND'},
    { id: 33, state: 'NE'},
    { id: 34, state: 'NH'},
    { id: 35, state: 'NJ'},
    { id: 36, state: 'NM'},
    { id: 37, state: 'NV'},
    { id: 38, state: 'NY'},
    { id: 39, state: 'OH'},
    { id: 40, state: 'OK'},
    { id: 41, state: 'OR'},
    { id: 42, state: 'PA'},
    { id: 43, state: 'PR'},
    { id: 44, state: 'RI'},
    { id: 45, state: 'SC'},
    { id: 46, state: 'SD'},
    { id: 47, state: 'TN'},
    { id: 48, state: 'TX'},
    { id: 49, state: 'UT'},
    { id: 50, state: 'VA'},
    { id: 51, state: 'VT'},
    { id: 52, state: 'WA'},
    { id: 53, state: 'WI'},
    { id: 54, state: 'WV'},
    { id: 55, state: 'WY'},
]

export const caStates = [
    { id: 1, state: 'AB'},  //CA    
    { id: 2, state: 'BC'},  //CA    
    { id: 3, state: 'MB'},  //CA
    { id: 4, state: 'NB'},  //CA   
    { id: 5, state: 'NL'},  //CA
    { id: 6, state: 'NS'},  //CA
    { id: 7, state: 'NT'},  //CA
    { id: 8, state: 'NU'},  //CA
    { id: 9, state: 'ON'},  //CA
    { id: 10, state: 'PE'},  //CA
    { id: 11, state: 'QC'},  //CA
    { id: 12, state: 'SK'},  //CA
    { id: 13, state: 'YT'}   //CA
]

export const statusMapping = {
    'denied_inherent_vice_Ext': en.metadata.claimStatusMapping.denied_inherent_vice_Ext,
    'denied_firearms_Ext': en.metadata.claimStatusMapping.denied_firearms_Ext,
    'denied_alcohol_Ext': en.metadata.claimStatusMapping.denied_alcohol_Ext,
    'denied_asd_pickup_Ext': en.metadata.claimStatusMapping.denied_asd_pickup_Ext,
    'denied_credit_card_Ext': en.metadata.claimStatusMapping.denied_credit_card_Ext,
    'denied_gift_card_certificate_checks_Ext': en.metadata.claimStatusMapping.denied_gift_card_certificate_checks_Ext,
    'denied_improper_pack_Ext': en.metadata.claimStatusMapping.denied_improper_pack_Ext,
    'denied_improper_packaging_Ext': en.metadata.claimStatusMapping.denied_improper_packaging_Ext,
    'denied_late_report_Ext': en.metadata.claimStatusMapping.denied_late_report_Ext,
    'denied_no_damage_Ext': en.metadata.claimStatusMapping.denied_no_damage_Ext,
    'denied_no_damage_intransit_Ext': en.metadata.claimStatusMapping.denied_no_damage_intransit_Ext,
    'denied_no_declared_value_of_101_Ext': en.metadata.claimStatusMapping.denied_no_declared_value_of_101_Ext,
    'denied_no_premium_Ext': en.metadata.claimStatusMapping.denied_no_premium_Ext,
    'denied_no_record_of_pickup_Ext': en.metadata.claimStatusMapping.denied_no_record_of_pickup_Ext,
    'denied_no_salvage_Ext': en.metadata.claimStatusMapping.denied_no_salvage_Ext,
    'denied_no_signature_letter_Ext': en.metadata.claimStatusMapping.denied_no_signature_letter_Ext,
    'denied_on_time_delivery_Ext': en.metadata.claimStatusMapping.denied_on_time_delivery_Ext,
    'denied_original_packing_Ext': en.metadata.claimStatusMapping.denied_original_packing_Ext,
    'denied_perishables_Ext': en.metadata.claimStatusMapping.denied_perishables_Ext,
    'denied_phonecard_gift_card_certificate_checks_Ext': en.metadata.claimStatusMapping.denied_phonecard_gift_card_certificate_checks_Ext,
    'denied_pp_approved_commodity_Ext': en.metadata.claimStatusMapping.denied_pp_approved_commodity_Ext,
    'denied_pp_asd_pickup_Ext': en.metadata.claimStatusMapping.denied_pp_asd_pickup_Ext,
    'denied_pp_customs_seizure_Ext': en.metadata.claimStatusMapping.denied_pp_customs_seizure_Ext,
    'denied_pp_duration_Ext': en.metadata.claimStatusMapping.denied_pp_duration_Ext,
    'denied_pp_exception_to_carrier_Ext': en.metadata.claimStatusMapping.denied_pp_exception_to_carrier_Ext,
    'denied_pp_false_information_Ext': en.metadata.claimStatusMapping.denied_pp_false_information_Ext,
    'denied_pp_fraud_Ext': en.metadata.claimStatusMapping.denied_pp_fraud_Ext,
    'denied_pp_good_and_valid_delivery_Ext': en.metadata.claimStatusMapping.denied_pp_good_and_valid_delivery_Ext,
    'denied_pp_high_value_receipt_Ext': en.metadata.claimStatusMapping.denied_pp_high_value_receipt_Ext,
    'denied_pp_inadequate_pack_Ext': en.metadata.claimStatusMapping.denied_pp_inadequate_pack_Ext,
    'denied_pp_late_report_Ext': en.metadata.claimStatusMapping.denied_pp_late_report_Ext,
    'denied_pp_ncti_Ext': en.metadata.claimStatusMapping.denied_pp_ncti_Ext,
    'denied_pp_no_evidence_of_tampering_Ext': en.metadata.claimStatusMapping.denied_pp_no_evidence_of_tampering_Ext,
    'denied_pp_no_physical_scan_Ext': en.metadata.claimStatusMapping.denied_pp_no_physical_scan_Ext,
    'denied_pp_no_signature_Ext': en.metadata.claimStatusMapping.denied_pp_no_signature_Ext,
    'denied_pp_out_of_liability_Ext': en.metadata.claimStatusMapping.denied_pp_out_of_liability_Ext,
    'denied_pp_reveal_contents_Ext': en.metadata.claimStatusMapping.denied_pp_reveal_contents_Ext,
    'denied_prohibited_from_shipping_Ext': en.metadata.claimStatusMapping.denied_prohibited_from_shipping_Ext,
    'denied_prohibited_from_shipping_aouv_Ext': en.metadata.claimStatusMapping.denied_prohibited_from_shipping_aouv_Ext,
    'denied_unusual_value_claim_Ext': en.metadata.claimStatusMapping.denied_unusual_value_claim_Ext,
    'denied_concealed_dmg_Ext': en.metadata.claimStatusMapping.denied_concealed_dmg_Ext,
    'denied_delay_Ext': en.metadata.claimStatusMapping.denied_delay_Ext,
    'denied_goods_insured_Ext': en.metadata.claimStatusMapping.denied_goods_insured_Ext,
    'denied_infidelity_Ext': en.metadata.claimStatusMapping.denied_infidelity_Ext,
    'denied_pkg_warranty_Ext': en.metadata.claimStatusMapping.denied_pkg_warranty_Ext,
    'denied_temperature_Ext': en.metadata.claimStatusMapping.denied_temperature_Ext,
    'denied_used_goods_Ext': en.metadata.claimStatusMapping.denied_used_goods_Ext,
    'denied_no_insured_value_of_101_ups_Ext': en.metadata.claimStatusMapping.denied_no_insured_value_of_101_ups_Ext,
    'tc_denied_Ext': en.metadata.claimStatusMapping.tc_denied_Ext,
    'tc_denied_late_report_Ext': en.metadata.claimStatusMapping.tc_denied_late_report_Ext,
    'tc_denied_no_insured_value_Ext': en.metadata.claimStatusMapping.tc_denied_no_insured_value_Ext,
    'outside_market_carrier_handling_Ext': en.metadata.claimStatusMapping.outside_market_carrier_handling_Ext,
    'denied_eur_exceeded_storage_period_Ext': en.metadata.claimStatusMapping.denied_eur_exceeded_storage_period_Ext,
    'denied_eur_excluded_goods_Ext': en.metadata.claimStatusMapping.denied_eur_excluded_goods_Ext,
    'denied_Ext': en.metadata.claimStatusMapping.denied_Ext,
    'other_Ext': en.metadata.claimStatusMapping.other_Ext,
    'fraud': en.metadata.claimStatusMapping.fraud,
    'duplicate': en.metadata.claimStatusMapping.duplicate,
    'mistake': en.metadata.claimStatusMapping.mistake,
    'incomplete_documentation_Ext': en.metadata.claimStatusMapping.incomplete_documentation_Ext,
    'withdrawn_Ext': en.metadata.claimStatusMapping.withdrawn_Ext,
    'paymentscomplete': en.metadata.claimStatusMapping.paymentscomplete,
    'claim_received': en.metadata.claimStatusMapping.claim_received,
    'paid': en.metadata.claimStatusMapping.paid,
    'closed': en.metadata.claimStatusMapping.closed,
    'submitted_for_payment': en.metadata.claimStatusMapping.submitted_for_payment,
    'incomplete_submission': en.metadata.claimStatusMapping.incomplete_submission,
    'investigation_Pending': en.metadata.claimStatusMapping.investigation_Pending,
    'not_applicable': en.metadata.claimStatusMapping.not_applicable,
    'file_date': en.metadata.claimStatusMapping.file_date,
    'check_ach': en.metadata.claimStatusMapping.check_ach,
    'check_edd': en.metadata.claimStatusMapping.check_edd,
    'search_in_progress': en.metadata.claimStatusMapping.search_in_progress
}

export const countries = {
    "AD": en.metadata.country.AD,
    "AE": en.metadata.country.AE,
    "AF": en.metadata.country.AF,
    "AG": en.metadata.country.AG,
    "AI": en.metadata.country.AI,
    "AL": en.metadata.country.AL,
    "AM": en.metadata.country.AM,
    "AN": en.metadata.country.AN,
    "AO": en.metadata.country.AO,
    "AQ": en.metadata.country.AQ,
    "AR": en.metadata.country.AR,
    "AS": en.metadata.country.AS,
    "AT": en.metadata.country.AT,
    "AU": en.metadata.country.AU,
    "AW": en.metadata.country.AW,
    "AZ": en.metadata.country.AZ,
    "BA": en.metadata.country.BA,
    "BB": en.metadata.country.BB,
    "BD": en.metadata.country.BD,
    "BE": en.metadata.country.BE,
    "BF": en.metadata.country.BF,
    "BG": en.metadata.country.BG,
    "BH": en.metadata.country.BH,
    "BI": en.metadata.country.BI,
    "BJ": en.metadata.country.BJ,
    "BL": en.metadata.country.BL,
    "BM": en.metadata.country.BM,
    "BN": en.metadata.country.BN,
    "BO": en.metadata.country.BO,
    "BR": en.metadata.country.BR,
    "BS": en.metadata.country.BS,
    "BT": en.metadata.country.BT,
    "BV": en.metadata.country.BV,
    "BW": en.metadata.country.BW,
    "BY": en.metadata.country.BY,
    "BZ": en.metadata.country.BZ,
    "CA": en.metadata.country.CA,
    "CC": en.metadata.country.CC,
    "CD": en.metadata.country.CD,
    "CF": en.metadata.country.CF,
    "CG": en.metadata.country.CG,
    "CH": en.metadata.country.CH,
    "CI": en.metadata.country.CI,
    "CK": en.metadata.country.CK,
    "CL": en.metadata.country.CL,
    "CM": en.metadata.country.CM,
    "CN": en.metadata.country.CN,
    "CO": en.metadata.country.CO,
    "CR": en.metadata.country.CR,
    "CU": en.metadata.country.CU,
    "CV": en.metadata.country.CV,
    "CX": en.metadata.country.CX,
    "CY": en.metadata.country.CY,
    "CZ": en.metadata.country.CZ,
    "DE": en.metadata.country.DE,
    "DJ": en.metadata.country.DJ,
    "DK": en.metadata.country.DK,
    "DM": en.metadata.country.DM,
    "DO": en.metadata.country.DO,
    "DZ": en.metadata.country.DZ,
    "EC": en.metadata.country.EC,
    "EE": en.metadata.country.EE,
    "EG": en.metadata.country.EG,
    "EH": en.metadata.country.EH,
    "ER": en.metadata.country.ER,
    "ES": en.metadata.country.ES,
    "ET": en.metadata.country.ET,
    "FI": en.metadata.country.FI,
    "FJ": en.metadata.country.FJ,
    "FK": en.metadata.country.FK,
    "FM": en.metadata.country.FM,
    "FO": en.metadata.country.FO,
    "FR": en.metadata.country.FR,
    "GA": en.metadata.country.GA,
    "GB": en.metadata.country.GB,
    "GD": en.metadata.country.GD,
    "GE": en.metadata.country.GE,
    "GF": en.metadata.country.GF,
    "GH": en.metadata.country.GH,
    "GI": en.metadata.country.GI,
    "GL": en.metadata.country.GL,
    "GM": en.metadata.country.GM,
    "GN": en.metadata.country.GN,
    "GP": en.metadata.country.GP,
    "GQ": en.metadata.country.GQ,
    "GR": en.metadata.country.GR,
    "GS": en.metadata.country.GS,
    "GT": en.metadata.country.GT,
    "GU": en.metadata.country.GU,
    "GW": en.metadata.country.GW,
    "GY": en.metadata.country.GY,
    "HK": en.metadata.country.HK,
    "HM": en.metadata.country.HM,
    "HN": en.metadata.country.HN,
    "HR": en.metadata.country.HR,
    "HT": en.metadata.country.HT,
    "HU": en.metadata.country.HU,
    "ID": en.metadata.country.ID,
    "IE": en.metadata.country.IE,
    "IL": en.metadata.country.IL,
    "IN": en.metadata.country.IN,
    "IO": en.metadata.country.IO,
    "IQ": en.metadata.country.IQ,
    "IR": en.metadata.country.IR,
    "IS": en.metadata.country.IS,
    "IT": en.metadata.country.IT,
    "JM": en.metadata.country.JM,
    "JO": en.metadata.country.JO,
    "JP": en.metadata.country.JP,
    "KE": en.metadata.country.KE,
    "KG": en.metadata.country.KG,
    "KH": en.metadata.country.KH,
    "KI": en.metadata.country.KI,
    "KM": en.metadata.country.KM,
    "KN": en.metadata.country.KN,
    "KP": en.metadata.country.KP,
    "KR": en.metadata.country.KR,
    "KW": en.metadata.country.KW,
    "KY": en.metadata.country.KY,
    "KZ": en.metadata.country.KZ,
    "LA": en.metadata.country.LA,
    "LB": en.metadata.country.LB,
    "LC": en.metadata.country.LC,
    "LI": en.metadata.country.LI,
    "LK": en.metadata.country.LK,
    "LR": en.metadata.country.LR,
    "LS": en.metadata.country.LS,
    "LT": en.metadata.country.LT,
    "LU": en.metadata.country.LU,
    "LV": en.metadata.country.LV,
    "LY": en.metadata.country.LY,
    "MA": en.metadata.country.MA,
    "MC": en.metadata.country.MC,
    "MD": en.metadata.country.MD,
    "ME": en.metadata.country.ME,
    "MF": en.metadata.country.MF,
    "MG": en.metadata.country.MG,
    "MH": en.metadata.country.MH,
    "MK": en.metadata.country.MK,
    "ML": en.metadata.country.ML,
    "MM": en.metadata.country.MM,
    "MN": en.metadata.country.MN,
    "MO": en.metadata.country.MO,
    "MP": en.metadata.country.MP,
    "MQ": en.metadata.country.MQ,
    "MR": en.metadata.country.MR,
    "MS": en.metadata.country.MS,
    "MT": en.metadata.country.MT,
    "MU": en.metadata.country.MU,
    "MV": en.metadata.country.MV,
    "MW": en.metadata.country.MW,
    "MX": en.metadata.country.MX,
    "MY": en.metadata.country.MY,
    "MZ": en.metadata.country.MZ,
    "NA": en.metadata.country.NA,
    "NC": en.metadata.country.NC,
    "NE": en.metadata.country.NE,
    "NF": en.metadata.country.NF,
    "NG": en.metadata.country.NG,
    "NI": en.metadata.country.NI,
    "NL": en.metadata.country.NL,
    "NO": en.metadata.country.NO,
    "NP": en.metadata.country.NP,
    "NR": en.metadata.country.NR,
    "NU": en.metadata.country.NU,
    "NZ": en.metadata.country.NZ,
    "OM": en.metadata.country.OM,
    "PA": en.metadata.country.PA,
    "PE": en.metadata.country.PE,
    "PF": en.metadata.country.PF,
    "PG": en.metadata.country.PG,
    "PH": en.metadata.country.PH,
    "PK": en.metadata.country.PK,
    "PL": en.metadata.country.PL,
    "PM": en.metadata.country.PM,
    "PN": en.metadata.country.PN,
    "PR": en.metadata.country.PR,
    "PS": en.metadata.country.PS,
    "PT": en.metadata.country.PT,
    "PW": en.metadata.country.PW,
    "PY": en.metadata.country.PY,
    "QA": en.metadata.country.QA,
    "RE": en.metadata.country.RE,
    "RO": en.metadata.country.RO,
    "RS": en.metadata.country.RS,
    "RU": en.metadata.country.RU,
    "RW": en.metadata.country.RW,
    "SA": en.metadata.country.SA,
    "SB": en.metadata.country.SB,
    "SC": en.metadata.country.SC,
    "SD": en.metadata.country.SD,
    "SE": en.metadata.country.SE,
    "SG": en.metadata.country.SG,
    "SH": en.metadata.country.SH,
    "SI": en.metadata.country.SI,
    "SJ": en.metadata.country.SJ,
    "SK": en.metadata.country.SK,
    "SL": en.metadata.country.SL,
    "SM": en.metadata.country.SM,
    "SN": en.metadata.country.SN,
    "SO": en.metadata.country.SO,
    "SR": en.metadata.country.SR,
    "ST": en.metadata.country.ST,
    "SV": en.metadata.country.SV,
    "SY": en.metadata.country.SY,
    "SZ": en.metadata.country.SZ,
    "TC": en.metadata.country.TC,
    "TD": en.metadata.country.TD,
    "TF": en.metadata.country.TF,
    "TG": en.metadata.country.TG,
    "TH": en.metadata.country.TH,
    "TJ": en.metadata.country.TJ,
    "TK": en.metadata.country.TK,
    "TL": en.metadata.country.TL,
    "TM": en.metadata.country.TM,
    "TN": en.metadata.country.TN,
    "TO": en.metadata.country.TO,
    "TR": en.metadata.country.TR,
    "TT": en.metadata.country.TT,
    "TV": en.metadata.country.TV,
    "TW": en.metadata.country.TW,
    "TZ": en.metadata.country.TZ,
    "UA": en.metadata.country.UA,
    "UG": en.metadata.country.UG,
    "UM": en.metadata.country.UM,
    "US": en.metadata.country.US,
    "UY": en.metadata.country.UY,
    "UZ": en.metadata.country.UZ,
    "VA": en.metadata.country.VA,
    "VC": en.metadata.country.VC,
    "VE": en.metadata.country.VE,
    "VG": en.metadata.country.VG,
    "VI": en.metadata.country.VI,
    "VN": en.metadata.country.VN,
    "VU": en.metadata.country.VU,
    "WF": en.metadata.country.WF,
    "WS": en.metadata.country.WS,
    "YE": en.metadata.country.YE,
    "YT": en.metadata.country.YT,
    "ZA": en.metadata.country.ZA,
    "ZM": en.metadata.country.ZM,
    "ZW": en.metadata.country.ZW
}

export const carriers = {
    "AIRLINES (DE )": en.metadata.transportationid_Ext['AIRLINES (DE )'],
    "A Duie Pyle": en.metadata.transportationid_Ext['A Duie Pyle'],
    "AAA Cooper": en.metadata.transportationid_Ext['AAA Cooper'],
    "ABF FREIGHT SYSTEM": en.metadata.transportationid_Ext['ABF FREIGHT SYSTEM'],
    "AIT Worldwide": en.metadata.transportationid_Ext['AIT Worldwide'],
    "APC Postal Logistics": en.metadata.transportationid_Ext['APC Postal Logistics'],
    "APC Postal Logistics DE": en.metadata.transportationid_Ext['APC Postal Logistics DE'],
    "APC Postal Logistics UK": en.metadata.transportationid_Ext['APC Postal Logistics UK'],
    "ARAMEX": en.metadata.transportationid_Ext.ARAMEX,
    "ASAP FREIGHT SERVICES": en.metadata.transportationid_Ext['ASAP FREIGHT SERVICES'],
    "Arrow Express": en.metadata.transportationid_Ext['Arrow Express'],
    "Averitt Express, Inc": en.metadata.transportationid_Ext['Averitt Express, Inc'],
    "BRT S.p.A.": en.metadata.transportationid_Ext['BRT S.p.A.'],
    "CCT CANADA": en.metadata.transportationid_Ext['CCT CANADA'],
    "CEVA LOGISTICS": en.metadata.transportationid_Ext['CEVA LOGISTICS'],
    "CROWLEY LOGISTICS": en.metadata.transportationid_Ext['CROWLEY LOGISTICS'],
    "Central Freight Lines": en.metadata.transportationid_Ext['Central Freight Lines'],
    "Central Transport": en.metadata.transportationid_Ext['Central Transport'],
    "Con-Way Freight": en.metadata.transportationid_Ext['Con-Way Freight'],
    "Custom Companies, Inc.": en.metadata.transportationid_Ext['Custom Companies, Inc.'],
    "DAYLIGHT TRANSPORT": en.metadata.transportationid_Ext['DAYLIGHT TRANSPORT'],
    "DAYTON FREIGHT": en.metadata.transportationid_Ext['DAYTON FREIGHT'],
    "DHL": en.metadata.transportationid_Ext.DHL,
    "DHL FR (Deutsche Post AG)": en.metadata.transportationid_Ext['DHL FR (Deutsche Post AG)'],
    "DHL IT (Deutsche Post AG)": en.metadata.transportationid_Ext['DHL IT (Deutsche Post AG)'],
    "DICOM EXPRESS": en.metadata.transportationid_Ext['DICOM EXPRESS'],
    "DLS WORLDWIDE": en.metadata.transportationid_Ext['DLS WORLDWIDE'],
    "DPD Deutschland GmbH": en.metadata.transportationid_Ext['DPD Deutschland GmbH'],
    "DPDgroup UK Ltd": en.metadata.transportationid_Ext['DPDgroup UK Ltd'],
    "DX DE": en.metadata.transportationid_Ext['DX DE'],
    "DX IT": en.metadata.transportationid_Ext['DX IT'],
    "DX UK": en.metadata.transportationid_Ext['DX UK'],
    "EASTERN CONNECTION": en.metadata.transportationid_Ext['EASTERN CONNECTION'],
    "ESTES": en.metadata.transportationid_Ext.ESTES,
    "EXPEDITORS": en.metadata.transportationid_Ext.EXPEDITORS,
    "Estes Forwarding Worldwide": en.metadata.transportationid_Ext['Estes Forwarding Worldwide'],
    "FORWARD AIR": en.metadata.transportationid_Ext['FORWARD AIR'],
    "FREIGHT EXPEDITERS": en.metadata.transportationid_Ext['FREIGHT EXPEDITERS'],
    "FRONTLINE FREIGHT": en.metadata.transportationid_Ext['FRONTLINE FREIGHT'],
    "FWDN": en.metadata.transportationid_Ext.FWDN,
    "FedEx": en.metadata.transportationid_Ext.FedEx,
    "FedEx Express Deutschland GmbH (TNT DE)": en.metadata.transportationid_Ext['FedEx Express Deutschland GmbH (TNT DE)'],
    "FedEx Fr": en.metadata.transportationid_Ext['FedEx Fr'],
    "FedEx Freight": en.metadata.transportationid_Ext['FedEx Freight'],
    "FedEx IT": en.metadata.transportationid_Ext['FedEx IT'],
    "Fedex Smart Post": en.metadata.transportationid_Ext['Fedex Smart Post'],
    "FragilePak": en.metadata.transportationid_Ext['FragilePak'],
    "G4S -UK": en.metadata.transportationid_Ext['G4S -UK'],
    "G4S -FR": en.metadata.transportationid_Ext['G4S -FR'],
    "G4S -DE": en.metadata.transportationid_Ext['G4S -DE'],
    "GLOBALTRANZ": en.metadata.transportationid_Ext.GLOBALTRANZ,
    "GLOVALINK": en.metadata.transportationid_Ext.GLOVALINK,
    "GLS (General Logistics Systems\nGermany GmbH & Co. OHG)": en.metadata.transportationid_Ext['GLS (General Logistics Systems\nGermany GmbH & Co. OHG)'],
    "GLS DENMARK": en.metadata.transportationid_Ext['GLS DENMARK'],
    "GLS FR": en.metadata.transportationid_Ext['GLS FR'],
    "GLS IT": en.metadata.transportationid_Ext['GLS IT'],
    "General Logistics Systems B.V. (GLS UK)": en.metadata.transportationid_Ext['General Logistics Systems B.V. (GLS UK)'],
    "HERCULES": en.metadata.transportationid_Ext.HERCULES,
    "HOLLAND": en.metadata.transportationid_Ext.HOLLAND,
    "JAS Forwarding": en.metadata.transportationid_Ext['JAS Forwarding'],
    "JP EXPRESS": en.metadata.transportationid_Ext['JP EXPRESS'],
    "KUEHNE & NAGEL TRANSWORLD SHIPPING": en.metadata.transportationid_Ext['KUEHNE & NAGEL TRANSWORLD SHIPPING'],
    "La Poste (FR)": en.metadata.transportationid_Ext['La Poste (FR)'],
    "MIDWEST MOTOR EXPRESS": en.metadata.transportationid_Ext['MIDWEST MOTOR EXPRESS'],
    "Manna": en.metadata.transportationid_Ext.Manna,
    "Mondial Relay (FR)": en.metadata.transportationid_Ext['Mondial Relay (FR)'],
    "Mondial Relay IT": en.metadata.transportationid_Ext['Mondial Relay IT'],
    "NEW PENN FREIGHT": en.metadata.transportationid_Ext['NEW PENN FREIGHT'],
    "New England Motor Freight": en.metadata.transportationid_Ext['New England Motor Freight'],
    "ONTRAC": en.metadata.transportationid_Ext.ONTRAC,
    "Old Dominion Freight": en.metadata.transportationid_Ext['Old Dominion Freight'],
    "Other": en.metadata.transportationid_Ext.Other,
    "PILOT AIR FREIGHT": en.metadata.transportationid_Ext['PILOT AIR FREIGHT'],
    "PILOT FREIGHT SERVICES": en.metadata.transportationid_Ext['PILOT FREIGHT SERVICES'],
    "PITTOHIO": en.metadata.transportationid_Ext.PITTOHIO,
    "PLYCON": en.metadata.transportationid_Ext.PLYCON,
    "PUROLATOR FREIGHT": en.metadata.transportationid_Ext['PUROLATOR FREIGHT'],
    "Panama Transfer": en.metadata.transportationid_Ext['Panama Transfer'],
    "Poste Italiane (IT)": en.metadata.transportationid_Ext['Poste Italiane (IT)'],
    "R+L Carriers": en.metadata.transportationid_Ext['R+L Carriers'],
    "RADIANT GLOBAL LOGISTICS": en.metadata.transportationid_Ext['RADIANT GLOBAL LOGISTICS'],
    "REDDAWAY": en.metadata.transportationid_Ext.REDDAWAY,
    "Roadrunner": en.metadata.transportationid_Ext.Roadrunner,
    "SAIA": en.metadata.transportationid_Ext.SAIA,
    "SMSA Express Transportation Company Ltd": en.metadata.transportationid_Ext['SMSA Express Transportation Company Ltd'],
    "SOUTHWESTERN MOTOR TRANSPORT": en.metadata.transportationid_Ext['SOUTHWESTERN MOTOR TRANSPORT'],
    "SPEEDEE": en.metadata.transportationid_Ext.SPEEDEE,
    "STD LOGISTICS LTD": en.metadata.transportationid_Ext['STD LOGISTICS LTD'],
    "STI": en.metadata.transportationid_Ext.STI,
    "STREAM LINKS EXPRESS, INC.": en.metadata.transportationid_Ext['STREAM LINKS EXPRESS, INC.'],
    "STREAMLINE": en.metadata.transportationid_Ext.STREAMLINE,
    "Southeastern Freight Lines": en.metadata.transportationid_Ext['Southeastern Freight Lines'],
    "TForce Freight": en.metadata.transportationid_Ext['TForce Freight'],
    "TNT": en.metadata.transportationid_Ext.TNT,
    "TNT FR": en.metadata.transportationid_Ext['TNT FR'],
    "TNT Holdings B.V. (TNT UK)": en.metadata.transportationid_Ext['TNT Holdings B.V. (TNT UK)'],
    "TNT IT": en.metadata.transportationid_Ext['TNT IT'],
    "UPS": en.metadata.transportationid_Ext.UPS,
    "UPS Freight": en.metadata.transportationid_Ext['UPS Freight'],
    "UPS Limited ": en.metadata.transportationid_Ext['UPS Limited '],
    "UPS Mail Innovation": en.metadata.transportationid_Ext['UPS Mail Innovation'],
    "UPS SCS": en.metadata.transportationid_Ext['UPS SCS'],
    "UPS SUREPOST": en.metadata.transportationid_Ext['UPS SUREPOST'],
    "USF HOLLAND": en.metadata.transportationid_Ext['USF HOLLAND'],
    "USF REDDAWAY": en.metadata.transportationid_Ext['USF REDDAWAY'],
    "USPS": en.metadata.transportationid_Ext.USPS,
    "WARD TRANSPORT": en.metadata.transportationid_Ext['WARD TRANSPORT'],
    "WATKINS SHEPARD": en.metadata.transportationid_Ext['WATKINS SHEPARD'],
    "XPO GLOBAL LOGISTICS": en.metadata.transportationid_Ext['XPO GLOBAL LOGISTICS'],
    "XPO LAST MILE": en.metadata.transportationid_Ext['XPO LAST MILE'],
    "XPRESS GLOBAL": en.metadata.transportationid_Ext['XPRESS GLOBAL'],
    "YRC": en.metadata.transportationid_Ext.YRC,
    "You Shop We Ship Denmark ApS": en.metadata.transportationid_Ext['You Shop We Ship Denmark ApS']
}

export const productCategory = {
    "agricultural_machinery_parts": en.metadata.productCategory.agricultural_machinery_parts,
    "apparel": en.metadata.productCategory.apparel,
    "appliances_small_toaster_blender": en.metadata.productCategory.appliances_small_toaster_blender,
    "art_originals": en.metadata.productCategory.art_originals,
    "audio_equipment": en.metadata.productCategory.audio_equipment,
    "auto_machinery": en.metadata.productCategory.auto_machinery,
    "bags_paint": en.metadata.productCategory.bags_paint,
    "baskets_wickerware": en.metadata.productCategory.baskets_wickerware,
    "batteries_commercial": en.metadata.productCategory.batteries_commercial,
    "bedding_blankets": en.metadata.productCategory.bedding_blankets,
    "beverages_alcoholic": en.metadata.productCategory.beverages_alcoholic,
    "beverages_non_alcoholic": en.metadata.productCategory.beverages_non_alcoholic,
    "bicycles": en.metadata.productCategory.bicycles,
    "boats": en.metadata.productCategory.boats,
    "cables_audio": en.metadata.productCategory.cables_audio,
    "cell_phones": en.metadata.productCategory.cell_phones,
    "ceramic_porcelain": en.metadata.productCategory.ceramic_porcelain,
    "chemicals_fertilizers": en.metadata.productCategory.chemicals_fertilizers,
    "clocks_watches": en.metadata.productCategory.clocks_watches,
    "collectible_antiques": en.metadata.productCategory.collectible_antiques,
    "commercial_electronics": en.metadata.productCategory.commercial_electronics,
    "computer_chips": en.metadata.productCategory.computer_chips,
    "computer_servers": en.metadata.productCategory.computer_servers,
    "computers_personal": en.metadata.productCategory.computers_personal,
    "construction_equipment": en.metadata.productCategory.construction_equipment,
    "consumer_electronics": en.metadata.productCategory.consumer_electronics,
    "cooking_utensils": en.metadata.productCategory.cooking_utensils,
    "cosmetics_hair": en.metadata.productCategory.cosmetics_hair,
    "dental_equipment": en.metadata.productCategory.dental_equipment,
    "electronic_components": en.metadata.productCategory.electronic_components,
    "electronic_measuring": en.metadata.productCategory.electronic_measuring,
    "fertilizers_fodder": en.metadata.productCategory.fertilizers_fodder,
    "food_nonperishable": en.metadata.productCategory.food_nonperishable,
    "footwear": en.metadata.productCategory.footwear,
    "furniture_displays": en.metadata.productCategory.furniture_displays,
    "furniture_displays_shelving": en.metadata.productCategory.furniture_displays_shelving,
    "gifts_novelties": en.metadata.productCategory.gifts_novelties,
    "guns_ammunition": en.metadata.productCategory.guns_ammunition,
    "hardware_tools": en.metadata.productCategory.hardware_tools,
    "hats_caps": en.metadata.productCategory.hats_caps,
    "heating_ventilation": en.metadata.productCategory.heating_ventilation,
    "home_textiles": en.metadata.productCategory.home_textiles,
    "household_items": en.metadata.productCategory.household_items,
    "ink_office": en.metadata.productCategory.ink_office,
    "jewelry_costume": en.metadata.productCategory.jewelry_costume,
    "jewelry_designer": en.metadata.productCategory.jewelry_designer,
    "lawn_garden": en.metadata.productCategory.lawn_garden,
    "leather_harness": en.metadata.productCategory.leather_harness,
    "lighting_equipment": en.metadata.productCategory.lighting_equipment,
    "luggage_bags": en.metadata.productCategory.luggage_bags,
    "lumber_plywood": en.metadata.productCategory.lumber_plywood,
    "machinery_parts": en.metadata.productCategory.machinery_parts,
    "medical_equipment_electrical": en.metadata.productCategory.medical_equipment_electrical,
    "medical_equipment_nonelectrical": en.metadata.productCategory.medical_equipment_nonelectrical,
    "metal_aluminum": en.metadata.productCategory.metal_aluminum,
    "miscellaneous_other": en.metadata.productCategory.miscellaneous_other,
    "miscellaneous_personal": en.metadata.productCategory.miscellaneous_personal,
    "motorcycles_boxed": en.metadata.productCategory.motorcycles_boxed,
    "musical_instruments": en.metadata.productCategory.musical_instruments,
    "office_equipment": en.metadata.productCategory.office_equipment,
    "packaging_material": en.metadata.productCategory.packaging_material,
    "paper_products": en.metadata.productCategory.paper_products,
    "perfume": en.metadata.productCategory.perfume,
    "perishables_fruits": en.metadata.productCategory.perishables_fruits,
    "pharmaceuticals": en.metadata.productCategory.pharmaceuticals,
    "pipes_cast": en.metadata.productCategory.pipes_cast,
    "printed_matter": en.metadata.productCategory.printed_matter,
    "rope_cordage": en.metadata.productCategory.rope_cordage,
    "rubber_rings": en.metadata.productCategory.rubber_rings,
    "rugs_carpet": en.metadata.productCategory.rugs_carpet,
    "sewing_machines": en.metadata.productCategory.sewing_machines,
    "silverware": en.metadata.productCategory.silverware,
    "sporting_goods": en.metadata.productCategory.sporting_goods,
    "stone_products": en.metadata.productCategory.stone_products,
    "surgical_dressings": en.metadata.productCategory.surgical_dressings,
    "tea_spices": en.metadata.productCategory.tea_spices,
    "textiles_silk": en.metadata.productCategory.textiles_silk,
    "tires": en.metadata.productCategory.tires,
    "tobacco_products": en.metadata.productCategory.tobacco_products,
    "toys_games": en.metadata.productCategory.toys_games,
    "wall_paper": en.metadata.productCategory.wall_paper
}

export const limitedCarriers = {
    "DHL": en.metadata.transportationid_Ext.DHL,
    "FedEx": en.metadata.transportationid_Ext.FedEx,
    "UPS": en.metadata.transportationid_Ext.UPS,
    "UPS Mail Innovation": en.metadata.transportationid_Ext['UPS Mail Innovation'],
    "UPS SUREPOST": en.metadata.transportationid_Ext['UPS SUREPOST'],
    "USPS": en.metadata.transportationid_Ext.USPS
}