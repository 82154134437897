import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { InsightsContainerRoutingModule } from './insights-container-routing.module';
import { InsightsContainerComponent } from './insights-container.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DdVisibilityModule } from '../dd-visibility/dd-visibility.module';


@NgModule({
  declarations: [
    InsightsContainerComponent
  ],
  imports: [
    CommonModule,
    InsightsContainerRoutingModule,
    SharedModule,
    DdVisibilityModule,
    TranslateModule.forChild()
  ]
})
export class InsightsContainerModule { }
