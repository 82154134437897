<form [formGroup]="formGroup" (ngSubmit)="onSubmit($event, formGroup.value)" class="form-content">
  <div class="inside-form" [ngClass]="{'no-pad': currentStep == 0, 'yes-pad': currentStep > 0}">
    <div class="breadCrums" *ngIf="currentStep > (!hvcmFlow ? 0 : 1) && currentStep < 6">
      <span class="crum-title-pointer" (click)="openBreadCrumbsSaveConfirmation(currentStep)">{{
        'claims.claimMenus.fileNewClaim' | translate }}</span>/ {{ currentStepText }}
    </div>
    <div class="stepper-header" *ngIf="currentStep > 0 && currentStep < 6">{{ currentStepText }}</div>
    <div class="stepper-header" *ngIf="currentStep == 6">{{ 'claims.stepper.header1' | translate }}</div>
    <upsc-stepper4 *ngIf="currentStep > (!hvcmFlow ? 0 : 1) && currentStep < 7" [steps]="steps"
      [currentStep]="currentStep"></upsc-stepper4>
    <div class="stepper-description" *ngIf="currentStep === 2">{{ 'claims.stepper.desc1' | translate }}</div>
    <div class="stepper-description" *ngIf="currentStep === 3 || currentStep === 4">{{ 'claims.stepper.desc2' |
      translate }}</div>
    <div class="stepper-description" *ngIf="currentStep === 5">{{ 'claims.stepper.desc3' | translate }}</div>
    <div class="stepper-description success" *ngIf="currentStep === 6"></div>
    <div class="req" *ngIf="currentStep === 2 || currentStep === 3"><span class="red">*</span>
      {{ 'claims.edit.start.tracking.form.trackingNumber.errors.requiredFields' | translate }}</div>
    <div class="outside-sections">
      <div class="claim-sections">
        <upsc-notification-box class="error-box" *ngIf="errorNotification" [isDanger]="true">
          <div class="icon">
            <mat-icon>cancel_outline</mat-icon>
          </div>
          <div class="errors" *ngIf="currentStep==2">
            <div *ngIf="startFormGroup.get('trackingNumber').errors?.required">
              - {{ 'claims.edit.start.tracking.form.trackingNumber.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('trackingNumber').errors?.pattern">
              - {{ 'claims.edit.start.tracking.form.trackingNumber.errors.patPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('trackingNumber').errors?.inuse">
              - <span>{{ 'claims.edit.start.tracking.form.trackingNumber.errors.inPrefix' | translate }}</span><span>{{
                'claims.edit.start.tracking.form.trackingNumber.errors.inuse2' | translate }}</span>
            </div>
            <div *ngIf="startFormGroup.get('carrier').errors?.required">
              - {{ 'claims.edit.start.tracking.form.carrier.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('carrier').errors?.invalid">
              - {{ 'claims.edit.start.tracking.form.carrier.errors.invPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('role').errors?.required">
              - {{ 'claims.edit.start.role.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('sender').get('company').errors?.required">
              - {{ 'claims.edit.start.shippingInfo.form.senderInformation.company.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('sender').get('address').errors?.required">
              - {{ 'claims.edit.start.shippingInfo.form.senderInformation.address.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('sender').get('city').errors?.required">
              - {{ 'claims.edit.start.shippingInfo.form.senderInformation.city.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('sender').get('zipCode').errors?.required">
              - {{ 'claims.edit.start.shippingInfo.form.senderInformation.senderzipcode.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('sender').get('city').errors?.pattern">
              - {{ 'claims.edit.start.shippingInfo.form.senderInformation.city.errors.patPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('sender').get('state').errors?.required">
              - {{ 'claims.edit.start.shippingInfo.form.senderInformation.state.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('sender').get('country').errors?.required">
              - {{ 'claims.edit.start.shippingInfo.form.senderInformation.country.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('sender').get('country').errors?.mismatch">
              - {{ 'claims.edit.start.shippingInfo.form.senderInformation.country.errors.misPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('recipient').get('company').errors?.required">
              - {{ 'claims.edit.start.shippingInfo.form.recipientInformation.company.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('recipient').get('address').errors?.required">
              - {{ 'claims.edit.start.shippingInfo.form.recipientInformation.address.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('recipient').get('city').errors?.required">
              - {{ 'claims.edit.start.shippingInfo.form.recipientInformation.city.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('recipient').get('zipCode').errors?.required">
              - {{ 'claims.edit.start.shippingInfo.form.recipientInformation.recipientzipcode.errors.reqPrefix' |
              translate }}
            </div>
            <div *ngIf="startFormGroup.get('recipient').get('city').errors?.pattern">
              - {{ 'claims.edit.start.shippingInfo.form.recipientInformation.city.errors.patPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('recipient').get('state').errors?.required">
              - {{ 'claims.edit.start.shippingInfo.form.recipientInformation.state.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('recipient').get('country').errors?.required">
              - {{ 'claims.edit.start.shippingInfo.form.recipientInformation.country.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('recipient').get('country').errors?.mismatch">
              - {{ 'claims.edit.start.shippingInfo.form.recipientInformation.country.errors.misPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('shipDate').errors?.required">
              - {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="startFormGroup.get('shipDate').errors?.invalid">
              - {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.invPrefix' | translate }}
            </div>
          </div>
          <div class="errors" *ngIf="currentStep==3">
            <div *ngIf="whatHappenedFormGroup.get('reason').errors?.required">
              - {{ 'claims.edit.whatHappened.reason.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('damageDescription').errors?.required">
              - {{ 'claims.edit.whatHappened.reason.dmgReqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('missingDescription').errors?.required">
              - {{ 'claims.edit.whatHappened.reason.msngReqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('category').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.search.form.category.errors.reqPrefix' | translate }}
            </div>
            <div
              *ngIf="(whatHappenedFormGroup.get('category').errors?.invalid || whatHappenedFormGroup.get('category').errors?.requireMatch) && !whatHappenedFormGroup.get('category').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.search.form.category.errors.valPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('quantity').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.search.form.quantity.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('quantity').errors?.pattern">
              - {{ 'claims.edit.whatHappened.merchandise.search.form.quantity.errors.patPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('description').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.description.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('description').errors?.maxlength">
              - {{ 'claims.edit.whatHappened.merchandise.description.limPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('damageDate').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.description.damage.day.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('damageDate').errors?.matDatepickerMin">
              - {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.min' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('damageDate').errors?.matDatepickerMax">
              - {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.max' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('damageRepairable').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.description.damage.repair.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('damageLocations').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.description.damage.where.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('damageOther').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.description.damage.where.otherPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('missingDate').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.description.damage.day.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('missingDate').errors?.matDatepickerMin">
              - {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.min' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('missingDate').errors?.matDatepickerMax">
              - {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.max' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('missingLocations').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.description.damage.where.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('missingOther').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.description.missing.otherPrefix' | translate }}
            </div>
            <div
              *ngIf="whatHappenedFormGroup.get('lateReship').errors?.required && whatHappenedFormGroup.controls['lateReship']?.touched">
              - {{ 'claims.edit.whatHappened.merchandise.description.late.how.reship.reqPrefix' | translate }}
            </div>
            <div
              *ngIf="whatHappenedFormGroup.get('lateRefund').errors?.required && whatHappenedFormGroup.controls['lateRefund']?.touched">
              - {{ 'claims.edit.whatHappened.merchandise.description.late.how.refund.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('lateCare').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.description.late.how.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('lossReship').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.description.late.how.reship.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('lossRefund').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.description.late.how.refund.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('lossCare').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.description.late.how.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('return').errors?.required">
              - {{ 'claims.edit.whatHappened.merchandise.description.return.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('originalTrackingNumber').errors">
              - {{ 'claims.edit.whatHappened.merchandise.description.return.otn.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('currency').errors?.required">
              - {{ 'claims.edit.whatHappened.value.form.currency.errors.required' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('merchandise').errors?.required">
              - {{ 'claims.edit.whatHappened.value.form.merchandise.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('merchandise').errors?.pattern">
              - {{ 'claims.edit.whatHappened.value.form.merchandise.errors.patPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('shipping').errors?.required">
              - {{ 'claims.edit.whatHappened.value.form.shipping.errors.reqPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('shipping').errors?.pattern">
              - {{ 'claims.edit.whatHappened.value.form.shipping.errors.patPrefix' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('amount').errors?.required">
              - {{ 'claims.edit.whatHappened.value.form.amount.errors.reqPrefix' | translate }}
            </div>
          </div>
          <div class="errors" *ngIf="currentStep==4">
            <div *ngIf="contactSelectionForm.get('choice').errors?.required">
              - {{ 'claims.edit.contactInfo.contactInformation.contactForm.choice.errors.required' | translate }}
            </div>
            <div *ngIf="custom && !contactInfoFormGroup.valid">
              <div *ngIf="contactInfoFormGroup.get('contact').get('firstName').errors?.required">
                - {{ 'claims.edit.contactInfo.contactInformation.contactForm.firstName.errors.reqPrefix' | translate }}
              </div>
              <div *ngIf="contactInfoFormGroup.get('contact').get('lastName').errors?.required">
                - {{ 'claims.edit.contactInfo.contactInformation.contactForm.lastName.errors.reqPrefix' | translate }}
              </div>
              <div *ngIf="countryCode.errors?.required">
                - {{ 'claims.edit.contactInfo.contactInformation.contactForm.countryCode.errors.reqPrefix' | translate
                }}
              </div>
              <div *ngIf="countryCode.errors?.pattern">
                - {{ 'claims.edit.contactInfo.contactInformation.contactForm.countryCode.errors.invPrefix' | translate
                }}
              </div>
              <div *ngIf="contactInfoFormGroup.get('contact').get('phoneNumber').errors">
                - {{ 'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.errors.invPrefix' | translate
                }}
              </div>
              <div *ngIf="contactInfoFormGroup.get('contact').get('emailAddress').errors?.required">
                - {{ 'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.reqPrefix' | translate
                }}
              </div>
              <div *ngIf="contactEmail.errors?.invalid || contactEmail.errors?.pattern || contactEmail.errors?.email">
                - {{ 'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.valPrefix' | translate
                }}
              </div>
            </div>
          </div>
          <div class="errors" *ngIf="currentStep==5">
            <div *ngIf="submitClaimFormGroup.get('checkboxFormControl').errors?.required">
              - {{ 'claims.edit.review.reviewAndSubmit.reqPrefix' | translate }}
            </div>
          </div>
        </upsc-notification-box>


        <div>
          <div class="claim-section file-a-claim-welcome" *ngIf="currentStep === 0">
            <ng-container *ngTemplateOutlet="welcomeTemplate"></ng-container>
          </div>
          <div class="claim-section select-a-payee" *ngIf="currentStep === 1 && !isWalletUser">
            <ng-container *ngTemplateOutlet="selectPayeeTemplate"></ng-container>
          </div>
          <div class="claim-section select-a-payee" *ngIf="currentStep === 1 && isUsaUser && isWalletUser">
            <upsc-wallet-recipient (cpRecipient)="cpRecipient($event)"></upsc-wallet-recipient>
          </div>
          <div class="claim-section start-new-claim" *ngIf="currentStep === 2">
            <ng-container *ngTemplateOutlet="startTemplate"></ng-container>
          </div>
          <div class="claim-section what-happened" *ngIf="currentStep === 3">
            <ng-container *ngTemplateOutlet="whatHappenedTemplate"></ng-container>
          </div>
          <div class="claim-section contact-information" *ngIf="currentStep === 4">
            <ng-container *ngTemplateOutlet="contactInfoTemplate"></ng-container>
          </div>
          <div class="claim-section submit-claim" *ngIf="currentStep === 5">
            <ng-container *ngTemplateOutlet="submitClaimTemplate"></ng-container>
          </div>
          <div class="claim-section success-claim" *ngIf="currentStep === 6">
            <ng-container *ngTemplateOutlet="successClaimTemplate"></ng-container>
          </div>
        </div>
      </div>
    </div>



  </div>
</form>


<ng-template #welcomeTemplate>
  <div>
    <span class="user-guide d-flex justify-content-end"><a *ngIf="(isUsaUser)" href="https://bit.ly/isonlguide" target="_blank"
      rel="noopener noreferrer">
      {{ 'app.header.accountitems.userguide' | translate }}<mat-icon class="icon-key">open_in_new</mat-icon>
    </a></span>
    <div class="file-a-claim-title" *ngIf="!hvcmFlow">{{ 'claims.edit.navi_isop.title' | translate }} </div>
    <div class="file-a-claim-title" *ngIf="hvcmFlow">{{ 'claims.edit.navi_isop.titleHVCM' | translate }}</div>
    <div class="file-a-claim-text" *ngIf="!hvcmFlow && !isWalletUser">{{ 'claims.edit.navi_isop.text' | translate }}</div>
    <div class="file-a-claim-text" *ngIf="hvcmFlow && !isWalletUser">{{ 'claims.edit.navi_isop.textHVCM' | translate }}</div>
    <div class="file-a-claim-text" *ngIf="!hvcmFlow && isUsaUser && isWalletUser">{{ 'claims.edit.navi_isop.textDW' | translate }}</div>
    <div *ngIf="!isMobile" class="desktop-row-format">
      <!-- Normal flow desktop (is not wallet user and not HVCM) -->
      <div class="first-three-items" *ngIf="!hvcmFlow && !isWalletUser">
        <div class="item">
          <div class="green-number">1.</div>
          <div class="icon">
            <img src="../../../assets/images/add_card.png">
            <div>{{ 'claims.edit.navi_isop.one' | translate }}</div>
          </div>
        </div>
        <div class="gray-divider"></div>
        <div class="item">
          <div class="green-number">2.</div>
          <div class="icon">
            <img src="../../../assets/images/package.png">
            <div>{{ 'claims.edit.navi_isop.two' | translate }}</div>
          </div>
        </div>
        <div class="gray-divider"></div>
        <div class="item">
          <div class="green-number">3.</div>
          <div class="icon">
            <img src="../../../assets/images/psychology_alt.png">
            <div>{{ 'claims.edit.navi_isop.three' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="last-two-items" *ngIf="!hvcmFlow && !isWalletUser">
        <div class="item max-width-300">
          <div class="green-number spacing-4">4.</div>
          <div class="icon">
            <img src="../../../assets/images/contact_page.png">
            <div>{{ 'claims.edit.navi_isop.four' | translate }}</div>
          </div>
        </div>
        <div class="gray-divider"></div>
        <div class="item">
          <div class="green-number spacing-5">5.</div>
          <div class="icon">
            <img src="../../../assets/images/submit_claim.png">
            <div>{{ 'claims.edit.navi_isop.five' | translate }}</div>
          </div>
        </div>
      </div>
      <!-- Normal flow desktop for HVCM (no differentiation between wallet/no wallet) -->
      <div class="first-three-items" *ngIf="hvcmFlow">
        <div class="item-hvcm">
          <div class="green-number">1.</div>
          <div class="icon">
            <img src="../../../assets/images/add_card.png">
            <div class="text-top">{{ 'claims.edit.navi_isop.oneHVCM' | translate }}</div>
          </div>
        </div>
        <div class="gray-divider"></div>
        <div class="item-hvcm">
          <div class="green-number">2.</div>
          <div class="icon">
            <img src="../../../assets/images/complete-form.png">
            <div class="text-top">{{ 'claims.edit.navi_isop.twoHVCM1' | translate }}<a class="upsc-link"
                href="{{ MultiClaimDownloadForm }}">{{ 'claims.edit.navi_isop.twoHVCM2' | translate }}</a></div>
          </div>
        </div>
        <div class="gray-divider"></div>
        <div class="item-hvcm">
          <div class="green-number spacing-3">3.</div>
          <div class="icon">
            <img src="../../../assets/images/task.png">
            <div class="text-top">{{ 'claims.edit.navi_isop.threeHVCM' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="last-two-items" *ngIf="hvcmFlow">
        <div class="item-hvcm spacing-four">
          <div class="green-number spacing-4">4.</div>
          <div class="icon">
            <img src="../../../assets/images/folder_upload.png">
            <div class="text-4">{{ 'claims.edit.navi_isop.fourHVCM' | translate }}</div>
          </div>
        </div>
        <div class="gray-divider"></div>
        <div class="item-hvcm">
          <div class="green-number">5.</div>
          <div class="icon">
            <img src="../../../assets/images/submit_claim.png">
            <div class="text-5">{{ 'claims.edit.navi_isop.fiveHVCM' | translate }}</div>
          </div>
        </div>
      </div>
      <!-- Digital Wallet Flow Desktop (isWalletUser and not HVCM) -->
      <div class="d-flex flex-column" *ngIf="isUsaUser && isWalletUser">
        <div class="first-three-items" *ngIf="!hvcmFlow && isUsaUser">
          <div class="item-first-three">
            <div class="green-number">1.</div>
            <div class="icon-new">
              <img src="../../../assets/images/add_card.png">
              <div>{{ 'claims.edit.navi_isop.oneWallet' | translate }}</div>
              <div class="bold italicized">UPS<sup>®</sup> Wallet</div>
            </div>
          </div>
          <div class="gray-divider"></div>
          <div class="item-first-three">
            <div class="green-number">2.</div>
            <div class="icon-new">
              <img src="../../../assets/images/package.png">
              <div>{{ 'claims.edit.navi_isop.two' | translate }}</div>
              <div class="bold italicized">&nbsp;</div>
            </div>
          </div>
          <div class="gray-divider"></div>
          <div class="item-first-three">
            <div class="green-number">3.</div>
            <div class="icon-new">
              <img src="../../../assets/images/psychology_alt.png">
              <div>{{ 'claims.edit.navi_isop.three' | translate }}</div>
              <div class="bold italicized">&nbsp;</div>
            </div>
          </div>
        </div>
        <div class="last-two-items" *ngIf="!hvcmFlow && isUsaUser">
          <div class="item-last-two">
            <div class="green-number spacing-4">4.</div>
            <div class="icon-new">
              <img src="../../../assets/images/contact_page.png">
              <div>{{ 'claims.edit.navi_isop.four_wallet_1' | translate }}</div>
              <div>{{ 'claims.edit.navi_isop.four_wallet_2' | translate }}</div>
            </div>
          </div>
          <div class="gray-divider"></div>
          <div class="item-last-two">
            <div class="green-number spacing-5">5.</div>
            <div class="icon-new">
              <img src="../../../assets/images/submit_claim.png">
              <div>{{ 'claims.edit.navi_isop.five' | translate }}</div>
              <div class="bold italicized">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="first-three-items" *ngIf="hvcmFlow">
        <div class="item-hvcm">
          <div class="green-number">1.</div>
          <div class="icon">
            <img src="../../../assets/images/add_card.png">
            <div class="text-top">{{ 'claims.edit.navi_isop.oneHVCM' | translate }}</div>
          </div>
        </div>
        <div class="gray-divider"></div>
        <div class="item-hvcm">
          <div class="green-number">2.</div>
          <div class="icon">
            <img src="../../../assets/images/complete-form.png">
            <div class="text-top">{{ 'claims.edit.navi_isop.twoHVCM1' | translate }}<a class="upsc-link"
                href="{{ MultiClaimDownloadForm }}">{{ 'claims.edit.navi_isop.twoHVCM2' | translate }}</a></div>
          </div>
        </div>
        <div class="gray-divider"></div>
        <div class="item-hvcm">
          <div class="green-number spacing-3">3.</div>
          <div class="icon">
            <img src="../../../assets/images/task.png">
            <div class="text-top">{{ 'claims.edit.navi_isop.threeHVCM' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="last-two-items" *ngIf="hvcmFlow">
        <div class="item-hvcm spacing-four">
          <div class="green-number spacing-4">4.</div>
          <div class="icon">
            <img src="../../../assets/images/folder_upload.png">
            <div class="text-4">{{ 'claims.edit.navi_isop.fourHVCM' | translate }}</div>
          </div>
        </div>
        <div class="gray-divider"></div>
        <div class="item-hvcm">
          <div class="green-number">5.</div>
          <div class="icon">
            <img src="../../../assets/images/submit_claim.png">
            <div class="text-5">{{ 'claims.edit.navi_isop.fiveHVCM' | translate }}</div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- Normal mobile flow (is not wallet user + not HVCM) -->
    <div *ngIf="isMobile && !hvcmFlow && !isWalletUser" class="mobile-column-format">
      <div class="item">
        <div class="green-number">1.</div>
        <div class="icon">
          <img src="../../../assets/images/add_card.png">
          <div>{{ 'claims.edit.navi_isop.one' | translate }}</div>
        </div>
      </div>
      <div class="item">
        <div class="green-number">2.</div>
        <div class="icon">
          <img src="../../../assets/images/package.png">
          <div>{{ 'claims.edit.navi_isop.two' | translate }}</div>
        </div>
      </div>
      <div class="item">
        <div class="green-number">3.</div>
        <div class="icon">
          <img src="../../../assets/images/psychology_alt.png">
          <div>{{ 'claims.edit.navi_isop.three' | translate }}</div>
        </div>
      </div>
      <div class="item">
        <div class="green-number">4.</div>
        <div class="icon">
          <img src="../../../assets/images/contact_page.png">
          <div>{{ 'claims.edit.navi_isop.four' | translate }}</div>
        </div>
      </div>
      <div class="item">
        <div class="green-number">5.</div>
        <div class="icon">
          <img src="../../../assets/images/submit_claim.png">
          <div>{{ 'claims.edit.navi_isop.five' | translate }}</div>
        </div>
      </div>
    </div>
    <!-- Digital Wallet Flow (isWalletUser + not HVCM) -->
    <div *ngIf="isMobile && !hvcmFlow && isUsaUser && isWalletUser" class="mobile-column-format">
      <div class="item">
        <div class="green-number">1.</div>
        <div class="icon">
          <img src="../../../assets/images/add_card.png">
          <div>{{ 'claims.edit.navi_isop.oneWallet' | translate }}</div>
          <div class="bold italicized">UPS<sup>®</sup> Wallet</div>
        </div>
      </div>
      <div class="item">
        <div class="green-number">2.</div>
        <div class="icon">
          <img src="../../../assets/images/package.png">
          <div>{{ 'claims.edit.navi_isop.two' | translate }}</div>
        </div>
      </div>
      <div class="item">
        <div class="green-number">3.</div>
        <div class="icon">
          <img src="../../../assets/images/psychology_alt.png">
          <div>{{ 'claims.edit.navi_isop.three' | translate }}</div>
        </div>
      </div>
      <div class="item">
        <div class="green-number">4.</div>
        <div class="icon">
          <img src="../../../assets/images/contact_page.png">
          <div>{{ 'claims.edit.navi_isop.four' | translate }}</div>
        </div>
      </div>
      <div class="item">
        <div class="green-number">5.</div>
        <div class="icon">
          <img src="../../../assets/images/submit_claim.png">
          <div>{{ 'claims.edit.navi_isop.five' | translate }}</div>
        </div>
      </div>
    </div>
    <!-- Mobile HVCM flow (no differentiation between wallet/no wallet rn) -->
    <div *ngIf="isMobile && hvcmFlow" class="mobile-column-format">
      <div class="item">
        <div class="green-number">1.</div>
        <div class="icon">
          <img src="../../../assets/images/add_card.png">
          <div>{{ 'claims.edit.navi_isop.oneHVCM' | translate }}</div>
        </div>
      </div>
      <div class="item">
        <div class="green-number">2.</div>
        <div class="icon">
          <img src="../../../assets/images/complete-form.png">
          <div>{{ 'claims.edit.navi_isop.twoHVCM1' | translate }}<a class="upsc-link"
              href="{{ MultiClaimDownloadForm }}">{{ 'claims.edit.navi_isop.twoHVCM2' | translate }}</a></div>
        </div>
      </div>
      <div class="item">
        <div class="green-number">3.</div>
        <div class="icon">
          <img src="../../../assets/images/task.png">
          <div>{{ 'claims.edit.navi_isop.threeHVCM' | translate }}</div>
        </div>
      </div>
      <div class="item">
        <div class="green-number">4.</div>
        <div class="icon">
          <img src="../../../assets/images/folder_upload.png">
          <div>{{ 'claims.edit.navi_isop.fourHVCM' | translate }}</div>
        </div>
      </div>
      <div class="item">
        <div class="green-number">5.</div>
        <div class="icon">
          <img src="../../../assets/images/submit_claim.png">
          <div>{{ 'claims.edit.navi_isop.fiveHVCM' | translate }}</div>
        </div>
      </div>
    </div>
    <!-- 3 possible scenarios where we show a claims payout notice
          - Wallet, No T&Cs, check fee: Alert them they will be charged a check fee if they proceed with DPAT
          - Wallet, No T&Cs, no check fee: Alert them that to use ACH payment methods, they must enroll in wallet
          - No wallet, check fee: Alert them they will be charged a check fee if they select a check payee -->
    <div class="notification-box" *ngIf="!hvcmFlow && showClaimsPayoutNotice && isUsaUser">
      <upsc-notification-box [isMessage]="true" class="box-format-5">
        <div class="box-format d-flex flex-column" style="align-items: flex-start;" *ngIf="walletNoTCsWithFee">
          <div class="disclaimer d-flex flex-md-row">
            <img src="../../../../../assets/images/info1.png">
            <div class="text">{{ 'claims.edit.navi_isop.notice' | translate }}</div>
          </div>
          <div class="spacing" style="margin-top: 10px;"></div>
          <div class="text">
            {{ 'claims.edit.navi_isop.textOne' | translate }}{{ checkFeeAmount | currency: 'USD' }}
            {{ 'claims.edit.navi_isop.textTwo' | translate }}<sup>®</sup>{{ 'claims.edit.navi_isop.textThree' | translate }}
            <div style="margin-top: 10px;"><span class="link" (click)="navigateToFAQ()">{{ 'claims.edit.navi_isop.textFour' |
              translate }}</span>
              {{ 'claims.edit.navi_isop.textFive' | translate }}<sup>®</sup>{{ 'claims.edit.navi_isop.textSix' | translate }}</div>
          </div>
        </div>
        <div class="box-format d-flex flex-column" style="align-items: flex-start;" *ngIf="walletNoTCsNoFee">
          <div class="disclaimer d-flex flex-md-row">
            <img src="../../../../../assets/images/info1.png">
            <div class="text">{{ 'claims.edit.navi_isop.notice' | translate }}</div>
          </div>
          <div class="spacing" style="margin-top: 10px;"></div>
          <div class="text">{{ 'checkFee.noTCsNoFeeText1' | translate }}<sup>®</sup>{{ 'checkFee.noTCsNoFeeText2' | translate }}
            <div style="margin-top: 10px;"><span class="link" (click)="navigateToFAQ()">{{ 'claims.edit.navi_isop.textFour' |
              translate }}</span>
              {{ 'claims.edit.navi_isop.textFive' | translate }}<sup>®</sup>{{ 'claims.edit.navi_isop.textSix' | translate }}</div>
          </div>
        </div>
        <div class="box-format d-flex flex-column" style="align-items: flex-start;" *ngIf="noWalletWithFee">
          <div class="disclaimer d-flex flex-md-row">
            <img src="../../../../../assets/images/info1.png">
            <div class="text">{{ 'claims.edit.navi_isop.notice' | translate }}</div>
          </div>
          <div class="spacing" style="margin-top: 10px;"></div>
          <div class="text">
            {{ 'checkFee.noWalletWithFeeText1' | translate }}
            {{ checkFeeAmount | currency:'USD' }}
            {{ 'checkFee.noWalletWithFeeText2' | translate }}
          </div>
        </div>
      </upsc-notification-box>
    </div>
    <div class="notification-box">
      <upsc-notification-box [isMessage]="true" class="box-format-5">
        <div class="box-format d-flex flex-column" style="align-items: flex-start;">
          <div class="disclaimer d-flex flex-md-row">
            <img src="../../../../../assets/images/info1.png">
            <div class="text">{{ 'claims.edit.navi_isop.disclaimer' | translate }}</div>
          </div>
          <div class="spacing" style="margin-top: 10px;"></div>
          <div class="text" *ngIf="!hvcmFlow">{{ 'claims.edit.navi_isop.message' | translate }}</div>
          <div class="text" *ngIf="hvcmFlow">{{ 'claims.edit.navi_isop.messageHVCM' | translate }}</div>
        </div>
      </upsc-notification-box>
    </div>
    <div class="get-started">
      <button class="upsc-button" (click)="getStarted(); tagging('start')" href="#">{{ 'claims.edit.navi_isop.started' |
        translate }}<mat-icon>keyboard_arrow_right</mat-icon></button>
      <button *ngIf="isUsaUser && !hvcmFlow && !isGuestUser" [disabled]="whereILeftButton" class="upsc-white-button"
        routerLink="/claims/draft-list">{{ 'claims.edit.navi_isop.resume' | translate
        }}<mat-icon>keyboard_arrow_right</mat-icon></button>
      <button *ngIf="hvcmFlow" class="upsc-white-button" (click)="downloadTemplate()">{{
        'claims.edit.navi_isop.download' | translate }}<mat-icon>keyboard_arrow_right</mat-icon></button>
    </div>
    <div class="error2 mt-2" *ngIf="systemError">
      {{ 'common.systemError' | translate }}
    </div>
  </div>
</ng-template>

<ng-template #selectPayeeTemplate>
  <div class="payee-wrapper">
    <div class="title mb-2">{{ 'claims.edit.step1' | translate }}</div>
    <div class="subtitle">{{ 'claims.edit.subtitleStep1' | translate }}</div>
    <hr class="divider">
    <div class="table" *ngIf="isPageLoad===true">
      <upsc-payee-table (selectionChange)="payeeChanged($event)" [payeeIdSelected]="claim.payeeId"></upsc-payee-table>
    </div>
    <div *ngIf="scriptError" class="response-error">Invalid Input</div>
    <div class="step-controls">
      <a class="reset-step1" (click)="openCancelDialog($event)">
        {{ 'claims.edit.cancel' | translate }}
      </a>
      <button class="upsc-button continue" (click)="hvcmFlow ? openHVCM() : nextStep($event)" href="#"
        [disabled]="!payeeSelectionValid && !mobilePayeeSelectionValid">{{ 'claims.edit.continue' | translate
        }}</button>
    </div>
  </div>
</ng-template>

<ng-template #startTemplate>
  <form [formGroup]="startFormGroup">
    <div class="white-on-gray">
      <div class="header">
        <div class="text">{{ 'claims.edit.step2a' | translate }}</div>
        <mat-icon ngbTooltip="{{'claims.edit.start.tracking.titleTooltip' | translate}}" [autoClose]="false"
          tooltipClass="ngb-tooltip-class">info_outline</mat-icon>
      </div>
      <div class="section">
        <div class="subsection left">
          <mat-form-field>
            <input matInput required formControlName="carrier"
              placeholder="{{ 'claims.edit.start.tracking.form.carrier.placeholder' | translate }}"
              [matAutocomplete]="auto" [(ngModel)]="transportation_carrier_Ext">
            <mat-icon class="green-icon" matSuffix *ngIf="!startFormGroup.get('carrier').errors">done</mat-icon>
            <mat-autocomplete #auto autoActiveFirstOption>
              <mat-option value="UPS">{{ 'claims.edit.start.tracking.form.carrier.errors.ups' | translate }}
              </mat-option>
              <mat-option *ngFor="let key of Object.keys(carrierList) | carrierSearch : queryCarrier" [value]="key">
                {{carrierList[key]}}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="startFormGroup.get('carrier').errors">
              <div *ngIf="startFormGroup.get('carrier').errors['required']">
                {{ 'claims.edit.start.tracking.form.carrier.errors.required' | translate }}
              </div>
              <div *ngIf="startFormGroup.get('carrier').errors['invalid']">
                {{ 'claims.edit.start.tracking.form.carrier.errors.invalid' | translate }}
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="subsection">
          <mat-form-field [floatLabel]="auto">
            <input matInput required formControlName="trackingNumber"
              [(ngModel)]="claim.shippingDetailsDTO.trackingNumber"
              placeholder="{{ 'claims.edit.start.tracking.form.trackingNumber.label' | translate }}"
              (blur)="checkForDuplicateTrackingNumbers()">
            <mat-icon class="green-icon" matSuffix *ngIf="!startFormGroup.get('trackingNumber').errors">done</mat-icon>
            <mat-error *ngIf="startFormGroup.get('trackingNumber').errors">
              <div *ngIf="startFormGroup.get('trackingNumber').errors['required']">
                {{ 'claims.edit.start.tracking.form.trackingNumber.errors.required' | translate }}
              </div>
              <div *ngIf="startFormGroup.get('trackingNumber').errors['pattern']">
                {{ 'claims.edit.start.tracking.form.trackingNumber.errors.pattern' | translate }}
              </div>
              <div *ngIf="startFormGroup.get('trackingNumber').errors['inuse']">
                <span>{{ 'claims.edit.start.tracking.form.trackingNumber.errors.inuse' | translate }}</span>
                <span>{{ 'claims.edit.start.tracking.form.trackingNumber.errors.inuse2' | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="section mb-3 mb-lg-4">
        <div class="subsection">
          <div class="title">{{ 'claims.edit.start.role.title' | translate }}*</div>
          <mat-radio-group class="radio-group" formControlName="role" [(ngModel)]="shipmentRole">
            <mat-radio-button *ngFor="let role of roles" [value]="role.id">{{ role.text }}</mat-radio-button>
          </mat-radio-group>
          <mat-error class="standalone"
            *ngIf="startFormGroup.get('role').errors && startFormGroup.controls['role']?.touched">
            {{ 'claims.edit.start.role.errors.required' | translate }}
          </mat-error>
        </div>
      </div>
      <div class="header">
        <div class="text">{{ 'claims.edit.start.shippingInfo.address' | translate }}</div>
      </div>
      <div class="section mb-3 mb-lg-4">
        <div class="subsection left">
          <div class="title d-flex align-items-center">
            <div class="text">{{ 'claims.edit.start.shippingInfo.form.senderInformation.title' | translate }}*</div>
          </div>
          <form [formGroup]="startFormGroup.get('sender')" name="sender-address">
            <div>
              <!-- <div class="text" *ngIf="isCanadaUser">{{
                'claims.edit.start.shippingInfo.form.senderInformation.company.label' | translate }}<span
                  class="red">*</span>
              </div> -->
              <mat-form-field class="" tabindex="16">
                <input matInput required formControlName="company"
                  [(ngModel)]="claim.shippingDetailsDTO.senderDetails.companyName"
                  placeholder="{{ 'claims.edit.start.shippingInfo.form.senderInformation.company.label' | translate }}">
                <mat-error *ngIf="startFormGroup.get('sender').get('company').errors">
                  <div *ngIf="startFormGroup.get('sender').get('company').errors['required']">
                    {{ 'claims.edit.start.shippingInfo.form.senderInformation.company.errors.required' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="" tabindex="17" *ngIf="isUkUser || isItalyUser || isGermanyUser || isFranceUser">
                <input matInput required formControlName="address"
                  placeholder="{{ 'claims.edit.start.shippingInfo.form.senderInformation.address.label' | translate }}">
                <mat-error *ngIf="startFormGroup.get('sender').get('address').errors">
                  <div *ngIf="startFormGroup.get('sender').get('address').errors['required']">
                    {{ 'claims.edit.start.shippingInfo.form.senderInformation.address.errors.required' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
              <div class="subsection half">
                <div class="subsubsection left">
                  <!-- <div class="text" *ngIf="isCanadaUser">{{
                    'claims.edit.start.shippingInfo.form.senderInformation.city.label' | translate }}<span
                      class="red">*</span>
                  </div> -->
                  <mat-form-field class="" tabindex="17">
                    <input matInput required formControlName="city"
                      [(ngModel)]="claim.shippingDetailsDTO.senderDetails.city"
                      placeholder="{{ 'claims.edit.start.shippingInfo.form.senderInformation.city.label' | translate }}">
                    <mat-error *ngIf="startFormGroup.get('sender').get('city').errors">
                      <div *ngIf="startFormGroup.get('sender').get('city').errors['required']">
                        {{ 'claims.edit.start.shippingInfo.form.senderInformation.city.errors.required' | translate }}
                      </div>
                      <div *ngIf="startFormGroup.get('sender').get('city').errors['pattern']">
                        {{ 'claims.edit.start.shippingInfo.form.senderInformation.city.errors.pattern' | translate }}
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="subsubsection">
                  <!-- <div class="text" *ngIf="isCanadaUser">{{
                    'claims.edit.start.shippingInfo.form.senderInformation.state.label' | translate }}<span
                      class="red">*</span>
                  </div> -->
                  <mat-form-field class="" tabindex="18" *ngIf="isCanadaUser || isUsaUser">
                    <mat-select name="state" formControlName="state" [(ngModel)]="stateCodeSender"
                      (selectionChange)="senderAutoCountry()" (click)="onClick($event)"
                      placeholder="{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.label' | translate }}">
                      <mat-option>{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.none' | translate }}
                      </mat-option>
                      <mat-option *ngFor="let state of stateList" [value]="state.id">{{state.state}}</mat-option>

                    </mat-select>
                    <mat-error *ngIf="startFormGroup.get('sender').get('state').errors">
                      <div *ngIf="startFormGroup.get('sender').get('state').errors['required']">
                        {{ 'claims.edit.start.shippingInfo.form.senderInformation.state.errors.required' | translate }}
                      </div>
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="" tabindex="18"
                    *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser">
                    <input matInput required formControlName="zipCode" minlength="2"
                      placeholder="{{ 'claims.edit.start.shippingInfo.form.senderInformation.senderzipcode.label' | translate }}">
                    <mat-error *ngIf="startFormGroup.get('sender').get('zipCode').errors">
                      <div *ngIf="startFormGroup.get('sender').get('zipCode').errors['required']">
                        {{ 'claims.edit.start.shippingInfo.form.senderInformation.senderzipcode.errors.required' |
                        translate }}
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <!-- <div class="text" *ngIf="isCanadaUser">{{
                'claims.edit.start.shippingInfo.form.senderInformation.country.label' | translate }}<span
                  class="red">*</span>
              </div> -->
              <mat-form-field tabindex="19">
                <mat-select required disableRipple formControlName="country" [(ngModel)]="countryCodeSender"
                  placeholder="{{ 'claims.edit.start.shippingInfo.form.senderInformation.country.label' | translate }}">
                  <mat-option class="highlighted" value="US">{{
                    'claims.edit.start.shippingInfo.form.senderInformation.state.us' | translate }}</mat-option>
                  <mat-option class="highlighted" value="CA">{{
                    'claims.edit.start.shippingInfo.form.senderInformation.state.canada' | translate }}</mat-option>
                  <mat-option *ngFor="let country of countryList" [value]="country.key">{{country.value}}</mat-option>
                </mat-select>
                <mat-error *ngIf="startFormGroup.get('sender').get('country').errors">
                  <div *ngIf="startFormGroup.get('sender').get('country').errors['required']">
                    {{ 'claims.edit.start.shippingInfo.form.senderInformation.country.errors.required' | translate }}
                  </div>
                  <div *ngIf="startFormGroup.get('sender').get('country').errors['mismatch']">
                    {{ 'claims.edit.start.shippingInfo.form.senderInformation.country.errors.mismatch' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </form>
        </div>
        <div class="subsection">
          <div class="title d-flex align-items-center">
            <div class="text">{{ 'claims.edit.start.shippingInfo.form.recipientInformation.title' | translate }}*</div>
          </div>
          <form [formGroup]="startFormGroup.get('recipient')" name="recipient-address">
            <div>
              <!-- <div class="text" *ngIf="isCanadaUser">{{
                'claims.edit.start.shippingInfo.form.recipientInformation.company.label' | translate }}<span
                  class="red">*</span>
              </div> -->
              <mat-form-field tabindex="20">
                <input matInput required formControlName="company"
                  [(ngModel)]="claim.shippingDetailsDTO.receiverDetails.companyName"
                  placeholder="{{ 'claims.edit.start.shippingInfo.form.recipientInformation.company.label' | translate }}">
                <mat-error *ngIf="startFormGroup.get('recipient').get('company').errors">
                  <div *ngIf="startFormGroup.get('recipient').get('company').errors['required']">
                    {{ 'claims.edit.start.shippingInfo.form.recipientInformation.company.errors.required' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
              <mat-form-field tabindex="21" *ngIf="isUkUser || isItalyUser || isGermanyUser || isFranceUser">
                <input matInput required formControlName="address"
                  placeholder="{{ 'claims.edit.start.shippingInfo.form.recipientInformation.address.label' | translate }}">
                <mat-error *ngIf="startFormGroup.get('recipient').get('address').errors">
                  <div *ngIf="startFormGroup.get('recipient').get('address').errors['required']">
                    {{ 'claims.edit.start.shippingInfo.form.recipientInformation.address.errors.required' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
              <div class="subsection half">
                <div class="subsubsection left">
                  <!-- <div class="text" *ngIf="isCanadaUser">{{
                    'claims.edit.start.shippingInfo.form.recipientInformation.city.label' | translate }}<span
                      class="red">*</span>
                  </div> -->
                  <mat-form-field tabindex="21">
                    <input matInput required formControlName="city"
                      [(ngModel)]="claim.shippingDetailsDTO.receiverDetails.city"
                      placeholder="{{ 'claims.edit.start.shippingInfo.form.recipientInformation.city.label' | translate }}">
                    <mat-error *ngIf="startFormGroup.get('recipient').get('city').errors">
                      <div *ngIf="startFormGroup.get('recipient').get('city').errors['required']">
                        {{ 'claims.edit.start.shippingInfo.form.recipientInformation.city.errors.required' | translate
                        }}
                      </div>
                      <div *ngIf="startFormGroup.get('recipient').get('city').errors['pattern']">
                        {{ 'claims.edit.start.shippingInfo.form.recipientInformation.city.errors.pattern' | translate }}
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="subsubsection">
                  <!-- <div class="text" *ngIf="isCanadaUser">{{
                    'claims.edit.start.shippingInfo.form.recipientInformation.state.label' | translate }}<span
                      class="red">*</span>
                  </div> -->
                  <mat-form-field tabindex="22" *ngIf="isCanadaUser || isUsaUser">
                    <mat-select name="state" [(ngModel)]="stateCodeReceiver" formControlName="state"
                      (selectionChange)="recipientAutoCountry()" (click)="onClick($event)"
                      placeholder="{{ 'claims.edit.start.shippingInfo.form.recipientInformation.state.label' | translate }}">
                      <mat-option>{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.none' | translate }}
                      </mat-option>
                      <mat-option *ngFor="let state of stateList" [value]="state.id">{{state.state}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="startFormGroup.get('recipient').get('state').errors">
                      <div *ngIf="startFormGroup.get('recipient').get('state').errors['required']">
                        {{ 'claims.edit.start.shippingInfo.form.recipientInformation.state.errors.required' | translate
                        }}
                      </div>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field tabindex="22" *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser">
                    <input matInput required formControlName="zipCode" minlength="2"
                      placeholder="{{ 'claims.edit.start.shippingInfo.form.recipientInformation.recipientzipcode.label' | translate }}">
                    <mat-error *ngIf="startFormGroup.get('recipient').get('zipCode').errors">
                      <div *ngIf="startFormGroup.get('recipient').get('zipCode').errors['required']">
                        {{ 'claims.edit.start.shippingInfo.form.recipientInformation.recipientzipcode.errors.required' |
                        translate }}
                      </div>
                    </mat-error>
                  </mat-form-field>

                </div>
              </div>
              <!-- <div class="text" *ngIf="isCanadaUser">{{
                'claims.edit.start.shippingInfo.form.recipientInformation.country.label' | translate }}<span
                  class="red">*</span>
              </div> -->
              <mat-form-field tabindex="23">
                <mat-select required formControlName="country" [(ngModel)]="countryCodeReceiver"
                  placeholder="{{ 'claims.edit.start.shippingInfo.form.recipientInformation.country.label' | translate }}">
                  <mat-option class="highlighted" value="US">{{
                    'claims.edit.start.shippingInfo.form.senderInformation.state.us' | translate }}</mat-option>
                  <mat-option class="highlighted" value="CA">{{
                    'claims.edit.start.shippingInfo.form.senderInformation.state.canada' | translate }}</mat-option>
                  <mat-option *ngFor="let country of countryList" [value]="country.key">{{country.value}}</mat-option>
                </mat-select>
                <mat-error *ngIf="startFormGroup.get('recipient').get('country').errors">
                  <div *ngIf="startFormGroup.get('recipient').get('country').errors['required']">
                    {{ 'claims.edit.start.shippingInfo.form.recipientInformation.country.errors.required' | translate }}
                  </div>
                  <div *ngIf="startFormGroup.get('recipient').get('country').errors['mismatch']">
                    {{ 'claims.edit.start.shippingInfo.form.recipientInformation.country.errors.mismatch' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </form>
        </div>
      </div>
      <div class="header">
        <div class="text">{{ 'claims.edit.start.shippingInfo.title' | translate }}</div>
      </div>
      <div class="section">
        <div class="subsection left">
          <!-- <div class="title" *ngIf="!isCanadaUser">
            <div class="text">{{ 'claims.edit.start.shippingInfo.title2' | translate }}*</div>
          </div> -->
          <div class="subsection half">
            <div class="subsubsection left">
              <!-- <div class="title" *ngIf="isCanadaUser">
                <div class="text">{{ 'claims.edit.start.shippingInfo.title2' | translate }}*
                </div>
              </div> -->
              <mat-form-field tabindex="24" (click)="picker.open()">
                <input matInput [matDatepicker]="picker" [max]="maxDate" required readonly formControlName="shipDate"
                  [(ngModel)]="claim.shippingDetailsDTO.shipDate"
                  placeholder="{{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <svg matDatepickerToggleIcon id="baseline-date_range-24px" xmlns="http://www.w3.org/2000/svg"
                    width="31.711" height="31.711" viewBox="0 0 31.711 31.711">
                    <path id="Path_219" data-name="Path 219"
                      d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                      transform="translate(0.964 0.643)" fill="#737373" />
                    <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z" fill="none" />
                  </svg>
                </mat-datepicker-toggle>
                <mat-datepicker [touchUi]="isMobile" #picker></mat-datepicker>
                <mat-error *ngIf="startFormGroup.get('shipDate').errors">
                  <div *ngIf="startFormGroup.get('shipDate').errors['required']">
                    {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.required' | translate
                    }}
                  </div>
                  <div *ngIf="startFormGroup.get('shipDate').errors['invalid']">
                    {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.invalid' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="subsubsection">
              <!-- <div class="title" *ngIf="isCanadaUser">{{
                'claims.edit.start.shippingInfo.form.shipmentDetails.form.trackingNumber.label' | translate }}*</div> -->
              <mat-form-field>
                <input matInput readonly [value]="startFormGroup.get('trackingNumber').value"
                  placeholder="{{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.trackingNumber.label' | translate }}">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="subsection">
          <!-- <div class="title">
            <div class="text">{{ 'claims.edit.start.shippingInfo.form.referenceDetails.optional' | translate }}</div>
          </div> -->
          <mat-form-field tabindex="25">
            <input matInput #message formControlName="referenceNumber" maxlength="24" [(ngModel)]="referenceNumber"
              placeholder="{{ 'claims.edit.start.shippingInfo.form.referenceDetails.form.referenceNumber.label' | translate }}">
            <mat-hint align="end">
              {{ 'claims.edit.start.shippingInfo.form.referenceDetails.form.referenceNumber.hintEnd' | translate }}{{24
              - message.value.length}}
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="scriptError" class="response-error">Invalid Input</div>
    <div class="step-controls-isop">
      <div class="d-flex continue-save">
        <button class="upsc-button format" *ngIf="!continuing" [disabled]="currentlyCheckingDupeTrackingNumbers"
          (click)="createClaim($event)">{{ 'claims.edit.continue' | translate
          }}<mat-icon>keyboard_arrow_right</mat-icon></button>
        <button class="next-step-isop loading d-flex align-items-center justify-content-between" *ngIf="continuing">
          <div>{{ 'claims.edit.continue' | translate }}</div>
          <div class="spinner-border" role="status">
            <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
          </div>
        </button>
        <button class="upsc-white-button" *ngIf="(persistClaimNumber != 'tempClaimNumber') && isUsaUser && !isGuestUser"
          (click)="openDraftConfirmation()">{{ 'claims.edit.navi.save' | translate
          }}<mat-icon>keyboard_arrow_right</mat-icon></button>
      </div>
      <div class="d-flex align-items-center ml-auto">
        <a class="link" (click)="step1GoBack()">{{ 'claims.edit.back' | translate }}</a>
        <div class="vertical-border"></div>
        <a class="link" (click)="openCancelDialog($event)">{{ 'claims.edit.cancel' | translate }}</a>
      </div>
    </div>
    <div class="red error" *ngIf="systemError">
      {{ 'common.systemError' | translate }}
    </div>
  </form>

</ng-template>

<ng-template #whatHappenedTemplate>
  <form [formGroup]="whatHappenedFormGroup">
    <div class="white-on-gray">
      <div class="header">
        <div class="text" *ngIf="isUsaUser">{{ 'claims.edit.step3' | translate }}</div>
        <div class="text" *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser">{{
          'claims.edit.step3Title' | translate }}</div>
        <mat-icon *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser"
          ngbTooltip="{{'claims.edit.whatHappened.reason.titleTooltip' | translate}}"
          tooltipClass="ngb-tooltip-class">info_outline</mat-icon>
      </div>
      <div class="section mb-3 mb-lg-5">
        <div class="subsection">
          <div class="title">{{ 'claims.edit.whatHappened.reason.title' | translate }}*</div>
          <mat-radio-group class="radio-group" formControlName="reason" [(ngModel)]="claimReason">
            <mat-radio-button class="loss" (click)="toggleFlow('loss')" value="0">
              {{ 'claims.edit.whatHappened.reason.loss' | translate }}
            </mat-radio-button>
            <mat-radio-button *ngIf="!isDDUser" class="damage" (click)="toggleFlow('damage')" value="1">
              {{ 'claims.edit.whatHappened.reason.damage' | translate }}
            </mat-radio-button>
            <div class="damage-description" *ngIf="damage">
              <!-- <div class="description-label">{{ 'claims.edit.whatHappened.reason.damageDescription' | translate }}</div> -->
              <mat-form-field class="fill-width">
                <textarea #dmgdescr matInput maxlength="800" formControlName="damageDescription"
                  placeholder="{{ 'claims.edit.whatHappened.reason.damageDescription' | translate }}"
                  [(ngModel)]="damageDraftDescription"></textarea>
                <mat-hint align="end">
                  {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{800 -
                  dmgdescr.value.length}}
                </mat-hint>
                <mat-error *ngIf="whatHappenedFormGroup.get('damageDescription').errors">
                  <div *ngIf="whatHappenedFormGroup.get('damageDescription').errors['required']">
                    {{ 'claims.edit.whatHappened.reason.damageDescriptionRequired' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <mat-radio-button *ngIf="!isDDUser" class="missing" (click)="toggleFlow('missing')" value="2">
              {{ 'claims.edit.whatHappened.reason.missing' | translate }}
            </mat-radio-button>
            <div class="missing-description" *ngIf="missing">
              <!-- <div class="description-label">{{ 'claims.edit.whatHappened.reason.missingDescription' | translate }}
              </div> -->
              <mat-form-field class="fill-width">
                <textarea #missingdescr matInput maxlength="800" formControlName="missingDescription"
                  placeholder="{{ 'claims.edit.whatHappened.reason.missingDescription' | translate }}"
                  [(ngModel)]="missingDraftDescription"></textarea>
                <mat-hint align="end">
                  {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{800 -
                  missingdescr.value.length}}
                </mat-hint>
                <mat-error *ngIf="whatHappenedFormGroup.get('missingDescription').errors">
                  <div *ngIf="whatHappenedFormGroup.get('missingDescription').errors['required']">
                    {{ 'claims.edit.whatHappened.reason.missingDescriptionRequired' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <mat-radio-button class="late" *ngIf="showLate && isUsaUser && !isDDUser" (click)="toggleFlow('late')"
              value="3">
              {{ 'claims.edit.whatHappened.reason.late' | translate }}
            </mat-radio-button>
            <mat-radio-button class="late" *ngIf="isCanadaUser && istnt && !isDDUser" (click)="toggleFlow('late')"
              value="3">
              {{ 'claims.edit.whatHappened.reason.late' | translate }}
            </mat-radio-button>
          </mat-radio-group>
          <mat-error class="standalone"
            *ngIf="whatHappenedFormGroup.get('reason').errors && whatHappenedFormGroup.controls['reason']?.touched">
            {{ 'claims.edit.whatHappened.reason.errors.required' | translate }}
          </mat-error>
          <div class="retain d-flex align-items-center justify-content-center mt-3 p-3" *ngIf="damage || missing">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="28.423" height="27.506" viewBox="0 0 28.423 27.506">
                <g id="Group_448" data-name="Group 448" transform="translate(-1 -2)">
                  <g id="Group_447" data-name="Group 447" transform="translate(1 2)">
                    <g id="Group_442" data-name="Group 442" transform="translate(0 0)">
                      <path id="Path_5183" data-name="Path 5183"
                        d="M31.774,36.013a.459.459,0,0,1-.458-.458L31.308,19.75a.459.459,0,0,1,.293-.428l13.611-5.276a.458.458,0,0,1,.624.427V30.3a.458.458,0,0,1-.293.428l-13.6,5.257A.46.46,0,0,1,31.774,36.013Zm.451-15.95.007,14.823,12.687-4.9V15.142Z"
                        transform="translate(-17.414 -8.507)" fill="#e5ad15" />
                      <path id="Path_5184" data-name="Path 5184"
                        d="M14.268,16.2a.466.466,0,0,1-.161-.029l-2.76-1.033a.458.458,0,0,1-.3-.43V11.732a.457.457,0,0,1,.285-.424L24.794,5.8a.458.458,0,0,1,.344,0L27.8,6.863a.458.458,0,0,1,0,.85L14.727,13.073v2.665a.458.458,0,0,1-.458.458Zm-2.3-1.809,1.843.689V12.765a.458.458,0,0,1,.285-.424L26.408,7.294,24.97,6.719l-13,5.321Z"
                        transform="translate(-6.443 -4.04)" fill="#e5ad15" />
                      <path id="Path_5185" data-name="Path 5185"
                        d="M6.074,10.148a.458.458,0,0,1-.164-.03L1.295,8.35A.459.459,0,0,1,1.289,7.5L15.046,2.032a.456.456,0,0,1,.34,0l4.352,1.743a.458.458,0,0,1-.165.884L6.248,10.113A.455.455,0,0,1,6.074,10.148ZM2.72,7.914,6.068,9.2,18.3,4.189,15.214,2.952Z"
                        transform="translate(-1 -2)" fill="#e5ad15" />
                      <path id="Path_5186" data-name="Path 5186"
                        d="M24.056,17a.458.458,0,0,1-.164-.03l-6.527-2.5a.459.459,0,0,1-.295-.428.483.483,0,0,1,.285-.442L30.714,8.123a.457.457,0,0,1,.344,0l6.78,2.716a.458.458,0,0,1-.005.853L24.222,16.967A.466.466,0,0,1,24.056,17Zm-5.306-2.981,5.3,2.031,12.362-4.791L30.889,9.043Z"
                        transform="translate(-9.704 -5.298)" fill="#e5ad15" />
                      <path id="Path_5187" data-name="Path 5187"
                        d="M15.369,35.976a.454.454,0,0,1-.162-.03L1.312,30.689a.458.458,0,0,1-.3-.429V14.4a.459.459,0,0,1,.622-.428l4.576,1.742a.483.483,0,0,1,.312.427.452.452,0,0,1-.266.436.46.46,0,0,1-.378-.01l-3.948-1.5V29.943l12.978,4.91L14.9,20.026l-6.2-2.4a.457.457,0,0,1-.043-.86.478.478,0,0,1,.345-.006l6.52,2.52a.458.458,0,0,1,.293.427l.006,15.806a.458.458,0,0,1-.458.458Z"
                        transform="translate(-1.009 -8.47)" fill="#e5ad15" />
                    </g>
                    <g id="Group_446" data-name="Group 446" transform="translate(2.956 13.906)">
                      <path id="Path_5192" data-name="Path 5192"
                        d="M15.646,36.179a.459.459,0,0,1-.162-.03L7.745,33.221a.458.458,0,1,1,.324-.857l7.74,2.928a.458.458,0,0,1-.162.887Z"
                        transform="translate(-7.448 -32.334)" fill="#e5ad15" />
                      <path id="Path_5194" data-name="Path 5194"
                        d="M7.907,36.179a.459.459,0,0,0,.162-.03l7.739-2.928a.458.458,0,1,0-.324-.857l-7.74,2.928a.458.458,0,0,0,.162.887Z"
                        transform="translate(6.763 -32.334)" fill="#e5ad15" />
                      <path id="Path_5193" data-name="Path 5193"
                        d="M15.646,43.005a.459.459,0,0,1-.162-.03L7.745,40.047a.458.458,0,1,1,.324-.857l7.74,2.928a.458.458,0,0,1-.162.887Z"
                        transform="translate(-7.448 -36.031)" fill="#e5ad15" />
                      <path id="Path_5195" data-name="Path 5195"
                        d="M7.907,43.005a.459.459,0,0,0,.162-.03l7.739-2.928a.458.458,0,1,0-.324-.857l-7.74,2.928a.458.458,0,0,0,.162.887Z"
                        transform="translate(6.763 -36.031)" fill="#e5ad15" />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div class="text ml-3">
              {{ 'claims.edit.retain' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="header">
        <div class="text">{{ 'claims.edit.whatHappened.merchandise.title' | translate }}</div>
        <mat-icon *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser"
          ngbTooltip="{{'claims.edit.whatHappened.merchandise.title' | translate}}" tooltipClass="ngb-tooltip-class">
          info_outline</mat-icon>
      </div>
      <div class="section">
        <div class="subsection">
          <div class="subtitle">{{ 'claims.edit.whatHappened.merchandise.search.title' | translate }}*</div>
          <div class="d-flex flex-column flex-lg-row">
            <mat-form-field class="width-70 mr-lg-3">
              <input type="text"
                placeholder="{{ 'claims.edit.whatHappened.merchandise.search.form.category.label' | translate }}"
                aria-label="category" matInput formControlName="category" [matAutocomplete]="autoCategory">
              <mat-icon matSuffix class="green-icon"
                *ngIf="!whatHappenedFormGroup.get('category').errors">done</mat-icon>
              <mat-icon matSuffix>search</mat-icon>
              <mat-autocomplete #autoCategory="matAutocomplete" [displayWith]="displayCategories">
                <mat-option *ngFor="let option of filteredCategories | async" [value]="option">
                  {{ option.value }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="whatHappenedFormGroup.get('category').errors">
                <div *ngIf="whatHappenedFormGroup.get('category').errors['required']">
                  {{ 'claims.edit.whatHappened.merchandise.search.form.category.errors.required' | translate }}
                </div>
                <div
                  *ngIf="(whatHappenedFormGroup.get('category').errors['invalid'] || whatHappenedFormGroup.get('category').errors['requireMatch']) && !whatHappenedFormGroup.get('category').errors['required']">
                  {{ 'claims.edit.whatHappened.merchandise.search.form.category.errors.valid' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="width-30">
              <input matInput required formControlName="quantity" [(ngModel)]="quantity"
                placeholder="{{ 'claims.edit.whatHappened.merchandise.search.form.quantity.label' | translate }}">
              <mat-error *ngIf="whatHappenedFormGroup.get('quantity').errors">
                <div *ngIf="whatHappenedFormGroup.get('quantity').errors['required']">
                  {{ 'claims.edit.whatHappened.merchandise.search.form.quantity.errors.required' | translate }}
                </div>
                <div *ngIf="whatHappenedFormGroup.get('quantity').errors['pattern']">
                  {{ 'claims.edit.whatHappened.merchandise.search.form.quantity.errors.pattern' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="section mb-3 mb-lg-5">
        <div class="subsection">
          <div class="subtitle squeeze">{{ 'claims.edit.whatHappened.merchandise.description.title' | translate }}*
          </div>
          <div class="subtitle">{{ 'claims.edit.whatHappened.merchandise.description.subtitle' | translate }}</div>
          <mat-form-field class="full-width">
            <textarea matInput #descr maxlength="250" formControlName="description"
              [(ngModel)]="claim.shippingDetailsDTO.merchandiseDescription"></textarea>
            <mat-hint align="end">
              {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{250 - descr.value.length}}
            </mat-hint>
            <mat-error *ngIf="whatHappenedFormGroup.get('description').errors">
              <div *ngIf="whatHappenedFormGroup.get('description').errors['required']">
                {{ 'claims.edit.whatHappened.merchandise.description.required' | translate }}
              </div>
              <div *ngIf="whatHappenedFormGroup.get('description').errors['maxlength']">
                {{ 'claims.edit.whatHappened.merchandise.description.limit' | translate }}
              </div>
            </mat-error>
          </mat-form-field>
          <div class="merchandise-damage" *ngIf="damage">
            <div class="panel-section-title">
              {{ 'claims.edit.whatHappened.merchandise.description.damage.day.title' | translate }}*
            </div>
            <div class="mat-form-fields">
              <mat-form-field class="width-30" (click)="damagePicker.open()">
                <input matInput [matDatepicker]="damagePicker" [max]="maxDate" [(ngModel)]="damageDraftDate"
                  [min]="startFormGroup.get('shipDate').value" required readonly formControlName="damageDate"
                  placeholder="{{ 'claims.edit.whatHappened.merchandise.description.damage.day.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="damagePicker">
                  <svg matDatepickerToggleIcon id="baseline-date_range-24px" xmlns="http://www.w3.org/2000/svg"
                    width="31.711" height="31.711" viewBox="0 0 31.711 31.711">
                    <path id="Path_219" data-name="Path 219"
                      d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                      transform="translate(0.964 0.643)" fill="#737373" />
                    <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z" fill="none" />
                  </svg>
                </mat-datepicker-toggle>
                <mat-datepicker [touchUi]="isMobile" #damagePicker></mat-datepicker>
                <mat-error *ngIf="whatHappenedFormGroup.get('damageDate').errors">
                  <div *ngIf="whatHappenedFormGroup.get('damageDate').errors['required']">
                    {{ 'claims.edit.whatHappened.merchandise.description.damage.day.required' | translate }}
                  </div>
                  <div *ngIf="whatHappenedFormGroup.get('damageDate').errors['matDatepickerMin']">
                    {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.min' | translate }}
                  </div>
                  <div *ngIf="whatHappenedFormGroup.get('damageDate').errors['matDatepickerMax']">
                    {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.max' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="panel-section-title damage-repair">
              {{ 'claims.edit.whatHappened.merchandise.description.damage.repair.title' | translate }}*
            </div>
            <div class="mat-form-fields">
              <mat-radio-group class="radio-group" formControlName="damageRepairable" [(ngModel)]="repairable">
                <mat-radio-button *ngFor="let repairable of repairables" value="{{repairable.id}}">{{ repairable.text }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <mat-error class="standalone"
              *ngIf="whatHappenedFormGroup.get('damageRepairable').errors && whatHappenedFormGroup.controls['damageRepairable']?.touched">
              {{ 'claims.edit.whatHappened.merchandise.description.damage.repair.errors.required' | translate }}
            </mat-error>
            <div class="panel-section-title damage-locations">
              {{ 'claims.edit.whatHappened.merchandise.description.damage.where.title' | translate }}*
            </div>
            <div class="mat-form-fields mb-3">
              <mat-radio-group class="radio-group" formControlName="damageLocations" [(ngModel)]="packageLocation">
                <mat-radio-button *ngFor="let locations of damagedLocations" value="{{locations.id}}"
                  (click)="toggleOther(false)">{{
                  locations.text }}</mat-radio-button>
                <mat-radio-button value="3" (click)="toggleOther(true)">{{
                  'claims.edit.whatHappened.merchandise.description.damage.where.other' |
                  translate }}</mat-radio-button>
              </mat-radio-group>
            </div>
            <mat-error class="standalone"
              *ngIf="whatHappenedFormGroup.get('damageLocations').errors && whatHappenedFormGroup.controls['damageLocations']?.touched">
              {{ 'claims.edit.whatHappened.merchandise.description.damage.where.errors.required' | translate }}
            </mat-error>
            <div class="mat-form-fields" *ngIf="other">
              <mat-form-field class="medium-full other-response">
                <textarea #dmgother maxlength="35" matInput placeholder="" formControlName="damageOther"
                  [(ngModel)]="packageLocationDetails"></textarea>
                <mat-hint align="end">
                  {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{35 -
                  dmgother.value.length}}
                </mat-hint>
                <mat-error *ngIf="whatHappenedFormGroup.get('damageOther').errors">
                  <div *ngIf="whatHappenedFormGroup.get('damageOther').errors['required']">
                    {{ 'claims.edit.whatHappened.merchandise.description.damage.where.otherRequired' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="merchandise-missing" *ngIf="missing">
            <div class="panel-section-title">
              {{ 'claims.edit.whatHappened.merchandise.description.missing.day.title' | translate }}*
            </div>
            <div class="mat-form-fields">
              <mat-form-field class="width-30" (click)="missingPicker.open()">
                <input matInput [matDatepicker]="missingPicker" [max]="maxDate" [(ngModel)]="missingDraftDate"
                  [min]="startFormGroup.get('shipDate').value" required readonly formControlName="missingDate"
                  class="date-icon"
                  placeholder="{{ 'claims.edit.whatHappened.merchandise.description.missing.day.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="missingPicker">
                  <svg matDatepickerToggleIcon id="baseline-date_range-24px" xmlns="http://www.w3.org/2000/svg"
                    width="31.711" height="31.711" viewBox="0 0 31.711 31.711">
                    <path id="Path_219" data-name="Path 219"
                      d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                      transform="translate(0.964 0.643)" fill="#737373" />
                    <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z" fill="none" />
                  </svg>
                </mat-datepicker-toggle>
                <mat-datepicker [touchUi]="isMobile" #missingPicker></mat-datepicker>
                <mat-error *ngIf="whatHappenedFormGroup.get('missingDate').errors">
                  <div *ngIf="whatHappenedFormGroup.get('missingDate').errors['required']">
                    {{ 'claims.edit.whatHappened.merchandise.description.damage.day.required' | translate }}
                  </div>
                  <div *ngIf="whatHappenedFormGroup.get('missingDate').errors['matDatepickerMin']">
                    {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.min' | translate }}
                  </div>
                  <div *ngIf="whatHappenedFormGroup.get('missingDate').errors['matDatepickerMax']">
                    {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.max' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="panel-section-title missing-locations">
              {{ 'claims.edit.whatHappened.merchandise.description.damage.where.title' | translate }}*
            </div>
            <div class="mat-form-fields">
              <mat-radio-group class="radio-group" formControlName="missingLocations" [(ngModel)]="packageLocation">
                <mat-radio-button *ngFor="let locations of damagedLocations" value="{{locations.id}}"
                  (click)="toggleOther(false)">{{
                  locations.text
                  }}</mat-radio-button>
                <mat-radio-button #other value="3" (click)="toggleOther(true)">{{
                  'claims.edit.whatHappened.merchandise.description.damage.where.other' |
                  translate }}</mat-radio-button>
              </mat-radio-group>
            </div>
            <mat-error class="standalone"
              *ngIf="whatHappenedFormGroup.get('missingLocations').errors && whatHappenedFormGroup.controls['missingLocations']?.touched">
              {{ 'claims.edit.whatHappened.merchandise.description.damage.where.errors.required' | translate }}
            </mat-error>
            <div class="mat-form-fields" *ngIf="other.checked">
              <mat-form-field class="medium-full other-response">
                <textarea #missingother maxlength="35" matInput placeholder="" formControlName="missingOther"
                  [(ngModel)]="packageLocationDetails"></textarea>
                <mat-hint align="end">
                  {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{35 -
                  missingother.value.length}}
                </mat-hint>
                <mat-error *ngIf="whatHappenedFormGroup.get('missingOther').errors">
                  <div *ngIf="whatHappenedFormGroup.get('missingOther').errors['required']">
                    {{ 'claims.edit.whatHappened.merchandise.description.missing.otherRequired' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="merchandise-late" *ngIf="late">
            <div class="panel-section-title late-care">{{
              'claims.edit.whatHappened.merchandise.description.late.how.label' | translate }}*
            </div>
            <div class="mat-form-fields mb-3">
              <mat-radio-group class="radio-group" formControlName="lateCare" [(ngModel)]="lateCareData">
                <mat-radio-button value="0" #reship>
                  {{'claims.edit.whatHappened.merchandise.description.late.how.reship.label'
                  | translate }}</mat-radio-button>
                <div class="reship-wrapper" *ngIf="reship.checked">
                  <div class="mat-form-fields">
                    <mat-form-field class="medium-full reship-response">
                      <textarea matInput maxlength="45" #latereship
                        placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.reship.placeholder' | translate }}"
                        formControlName="lateReship" [(ngModel)]="lateReshipDescription"></textarea>
                      <mat-hint align="end">{{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{
                        45 - latereship.value.length }}</mat-hint>
                      <mat-error *ngIf="whatHappenedFormGroup.get('lateReship').errors">
                        <div *ngIf="whatHappenedFormGroup.get('lateReship').errors['required']">
                          {{ 'claims.edit.whatHappened.merchandise.description.late.how.reship.required' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <mat-radio-button value="1" #refund>
                  {{'claims.edit.whatHappened.merchandise.description.late.how.refund.label'
                  | translate }} <mat-icon
                    ngbTooltip="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.tooltip' | translate }}"
                    tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                </mat-radio-button>
                <div class="refund-wrapper" *ngIf="refund.checked">
                  <div class="mat-form-fields">
                    <mat-form-field class="medium-full refund-response">
                      <textarea matInput maxlength="45" #laterefund
                        placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.placeholder' | translate }}"
                        formControlName="lateRefund" [(ngModel)]="lateRefundDescription"></textarea>
                      <mat-hint align="end">{{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{
                        45 - laterefund.value.length }}</mat-hint>
                      <mat-error *ngIf="whatHappenedFormGroup.get('lateRefund').errors">
                        <div *ngIf="whatHappenedFormGroup.get('lateRefund').errors['required']">
                          {{ 'claims.edit.whatHappened.merchandise.description.late.how.refund.required' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-radio-group>
            </div>
            <mat-error class="standalone"
              *ngIf="whatHappenedFormGroup.get('lateCare').errors && whatHappenedFormGroup.controls['lateCare']?.touched">
              {{ 'claims.edit.whatHappened.merchandise.description.late.how.errors.required' | translate }}</mat-error>
          </div>
          <div class="merchandise-late" *ngIf="loss && cargo && (isUsaUser || isCanadaUser)">
            <div class="panel-section-title late-care">{{
              'claims.edit.whatHappened.merchandise.description.late.how.label' | translate }}*
            </div>
            <div class="mat-form-fields mb-3">
              <mat-radio-group class="radio-group" formControlName="lossCare" [(ngModel)]="lossCareData">
                <mat-radio-button value="0">{{'claims.edit.whatHappened.merchandise.description.late.how.reship.label'
                  | translate }}</mat-radio-button>
                <div class="reship-wrapper" *ngIf="whatHappenedFormGroup.get('lossCare').value==='0'">
                  <div class="mat-form-fields">
                    <mat-form-field class="medium-full reship-response">
                      <textarea matInput maxlength="45" #lossreship
                        placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.reship.placeholder' | translate }}"
                        formControlName="lossReship" [(ngModel)]="lossReshipDescription"></textarea>
                      <mat-hint align="end">{{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{
                        45 - lossreship.value.length }}</mat-hint>
                      <mat-error *ngIf="whatHappenedFormGroup.get('lossReship').errors">
                        <div *ngIf="whatHappenedFormGroup.get('lossReship').errors['required']">
                          {{ 'claims.edit.whatHappened.merchandise.description.late.how.reship.required' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <mat-radio-button value="1">{{'claims.edit.whatHappened.merchandise.description.late.how.refund.label'
                  | translate }} <mat-icon
                    ngbTooltip="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.tooltip' | translate }}"
                    tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                </mat-radio-button>
                <div class="refund-wrapper" *ngIf="whatHappenedFormGroup.get('lossCare').value==='1'">
                  <div class="mat-form-fields">
                    <mat-form-field class="medium-full refund-response">
                      <textarea matInput maxlength="45" #lossrefund
                        placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.placeholder' | translate }}"
                        formControlName="lossRefund" [(ngModel)]="lossRefundDescription"></textarea>
                      <mat-hint align="end">{{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{
                        45 - lossrefund.value.length }}</mat-hint>
                      <mat-error *ngIf="whatHappenedFormGroup.get('lossRefund').errors">
                        <div *ngIf="whatHappenedFormGroup.get('lossRefund').errors['required']">
                          {{ 'claims.edit.whatHappened.merchandise.description.late.how.refund.required' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <mat-radio-button value="2" #noinfo *ngIf="isCanadaUser">
                  {{'claims.edit.whatHappened.merchandise.description.late.how.noinfo.label'
                  | translate }} <mat-icon
                    ngbTooltip="{{'claims.edit.whatHappened.merchandise.description.late.how.noinfo.tooltip' | translate }}"
                    tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <mat-error class="standalone"
            *ngIf="whatHappenedFormGroup.get('lossCare').errors && whatHappenedFormGroup.controls['lossCare']?.touched">
            {{ 'claims.edit.whatHappened.merchandise.description.late.how.errors.required' | translate }}
          </mat-error>
          <div *ngIf="flex">
            <div class="title">{{ 'claims.edit.whatHappened.merchandise.description.return.title' | translate }}*</div>
            <mat-radio-group class="radio-group" formControlName="return" [(ngModel)]="isReturnShipment">
              <mat-radio-button value="yes">{{ 'claims.edit.whatHappened.merchandise.description.return.yes' | translate
                }}</mat-radio-button>
              <mat-radio-button value="no">{{ 'claims.edit.whatHappened.merchandise.description.return.no' | translate
                }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error class="standalone"
            *ngIf="whatHappenedFormGroup.get('return').errors && whatHappenedFormGroup.controls['return']?.touched">
            {{ 'claims.edit.whatHappened.merchandise.description.return.required' | translate }}
          </mat-error>
          <div class="mt-3" *ngIf="whatHappenedFormGroup.value.return=='yes'">
            <div class="title">{{ 'claims.edit.whatHappened.merchandise.description.return.otn.title' | translate }}*
            </div>
            <div class="mat-form-fields">
              <mat-form-field class="width-30">
                <input #OTN maxlength="100" matInput placeholder="" required formControlName="originalTrackingNumber"
                  [(ngModel)]="originalTrackNumber">
                <mat-error *ngIf="whatHappenedFormGroup.get('originalTrackingNumber').errors">
                  <div *ngIf="whatHappenedFormGroup.get('originalTrackingNumber').errors">
                    {{ 'claims.edit.whatHappened.merchandise.description.return.otn.required' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="header">
        <div class="text">{{ 'claims.edit.whatHappened.value.title' | translate }}</div>
      </div>
      <div class="section mb-3 mb-lg-5">
        <div class="subsection d-flex flex-column flex-xl-row justify-content-between">
          <div class="claim-amount-boxes pr-xl-2 currency">
            <!-- <div class="label">{{ 'claims.edit.whatHappened.value.form.currency.hint' | translate }}</div> -->
            <mat-form-field>
              <mat-select class="currency-select" required formControlName="currency" [(value)]="defaultCurrency"
                placeholder="{{ 'claims.edit.whatHappened.value.form.currency.hint' | translate }}">
                <mat-option *ngFor="let key of Object.keys(currencyList)" [value]="currencyList[key]">
                  {{currencyList[key]}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="whatHappenedFormGroup.get('currency').errors">
                <div *ngIf="whatHappenedFormGroup.get('currency').errors['required']">
                  {{ 'claims.edit.whatHappened.value.form.currency.errors.required' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="claim-amount-boxes pr-xl-2">
            <!-- <div class="label">{{ 'claims.edit.whatHappened.value.form.merchandise.label' | translate }}</div> -->
            <mat-form-field>
              <input matInput required formControlName="merchandise" appDecimalFieldMask class="currency-align"
                [(ngModel)]="claim.shippingDetailsDTO.merchandiseValue"
                placeholder="{{ 'claims.edit.whatHappened.value.form.merchandise.label' | translate }}">
              <mat-error *ngIf="whatHappenedFormGroup.get('merchandise').errors">
                <div *ngIf="whatHappenedFormGroup.get('merchandise').errors['required']">
                  {{ 'claims.edit.whatHappened.value.form.merchandise.errors.required' | translate }}
                </div>
                <div *ngIf="whatHappenedFormGroup.get('merchandise').errors['pattern']">
                  {{ 'claims.edit.whatHappened.value.form.merchandise.errors.pattern' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <span class="add mr-xl-2">+</span>
          <div class="claim-amount-boxes pr-xl-2">
            <!-- <div class="label">{{ 'claims.edit.whatHappened.value.form.shipping.label' | translate }}</div> -->
            <mat-form-field>
              <input matInput required formControlName="shipping" appDecimalFieldMask class="currency-align"
                [(ngModel)]="claim.shippingDetailsDTO.verifiedShippingCharge"
                placeholder="{{ 'claims.edit.whatHappened.value.form.shipping.label' | translate }}">
              <mat-error *ngIf="whatHappenedFormGroup.get('shipping').errors">
                <div *ngIf="whatHappenedFormGroup.get('shipping').errors['required']">
                  {{ 'claims.edit.whatHappened.value.form.shipping.errors.required' | translate }}
                </div>
                <div *ngIf="whatHappenedFormGroup.get('shipping').errors['pattern']">
                  {{ 'claims.edit.whatHappened.value.form.shipping.errors.pattern' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <span class="equal mr-xl-2">=</span>
          <div class="claim-amount-boxes">
            <!-- <div class="label">{{ 'claims.edit.whatHappened.value.form.amount.label' | translate}}</div> -->
            <mat-form-field>
              <input matInput required readonly formControlName="amount" appDecimalFieldMask class="currency-align"
                [(ngModel)]="claim.shippingDetailsDTO.invoiceAmount"
                placeholder="{{ 'claims.edit.whatHappened.value.form.amount.label' | translate}}">
              <mat-error *ngIf="whatHappenedFormGroup.get('amount').errors">
                <div *ngIf="whatHappenedFormGroup.get('amount').errors['required']">
                  {{ 'claims.edit.whatHappened.value.form.amount.errors.required' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="header">
        <div class="text">{{ 'claims.edit.whatHappened.documents.title' | translate }}</div>
      </div>
      <div class="section mb-3 mb-lg-5">
        <div class="subsection">
          <div class="subtitle mb-3">{{ 'claims.edit.whatHappened.documents.subtitle' | translate }}</div>
          <div class="subtitle mb-3" *ngIf="isCanadaUser">
            <span class="bold">{{ 'claims.edit.whatHappened.documents.subtitle2' | translate }} </span>
            {{ 'claims.edit.whatHappened.documents.subtitle3' | translate }}
          </div>
          <div class="document-upload panel-section">
            <div class="document-panel-left">
              <div class="document-needed">
                <div class="panel-section-title">
                  {{ 'claims.edit.whatHappened.documents.documentNeeded.title' | translate }}
                </div>
                <ul class="document-list">
                  <li>{{ 'claims.edit.whatHappened.documents.documentNeeded.documentList.invoice' | translate }}</li>
                  <li>
                    {{ 'claims.edit.whatHappened.documents.documentNeeded.documentList.proof' | translate }}
                    <mat-icon
                      ngbTooltip="{{ 'claims.edit.whatHappened.documents.documentNeeded.documentTooltips.proof' | translate }}"
                      tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                  </li>
                  <li *ngIf="cargo">{{ 'claims.edit.whatHappened.documents.documentNeeded.documentList.bol' | translate
                    }}
                  </li>
                  <li *ngIf="cargo">{{ 'claims.edit.whatHappened.documents.documentNeeded.documentList.carrier' |
                    translate }}
                  </li>
                  <li *ngIf="damage">
                    {{ 'claims.edit.whatHappened.documents.documentNeeded.documentList.photos' | translate }}
                    <mat-icon
                      ngbTooltip="{{ 'claims.edit.whatHappened.documents.documentNeeded.documentTooltips.photos' | translate }}"
                      tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                  </li>
                  <li>
                    {{ 'claims.edit.whatHappened.documents.carrierNote' | translate }}
                    <mat-icon ngbTooltip="{{ 'claims.edit.whatHappened.documents.carrierNoteTooltip' | translate }}"
                      tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                  </li>
                </ul>
                <div class="too-many-documents-error" *ngIf="tooManyDocs">
                  {{ 'claims.edit.whatHappened.documents.tooManyError'| translate }}
                </div>
                <div class="file-size-limit-error" *ngIf="fileSizeLimit">
                  {{ 'claims.edit.whatHappened.documents.fileSizeLimit'| translate }}
                </div>
                <div class="file-error" *ngIf="fileTypeError">
                  {{ 'claims.edit.whatHappened.documents.errors.fileType' | translate }}
                </div>
                <div class="file-error" *ngIf="fileNameError">
                  {{ 'claims.edit.whatHappened.documents.errors.fileName' | translate }}
                </div>
                <div class="file-error" *ngIf="systemError">
                  {{ 'common.systemError' | translate }}
                </div>
              </div>
            </div>
            <div class="document-panel-right">
              <div class="uploaded-documents d-flex justify-content-center align-items-center"
                (click)="fileInput.click()" upscDragNDrop (onFileDropped)="uploadDocument($event)">
                <input hidden type="file" #fileInput (change)="uploadDocument($event.target.files, $event)">
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <img class="pb-2" src="../../../../../assets/images/upload-file-icon.png">
                  <div class="attachments-title pb-2">
                    <span class="upload-underline">{{ 'claimsDetail.upload.message1' | translate }}</span> {{
                    'claimsDetail.upload.message2' | translate }}
                  </div>
                  <div class="pb-2">
                    {{ 'claimsDetail.upload.filesAllowed' | translate }}
                  </div>
                  <!-- <div class="file-selector d-flex justify-content-center align-items-center">
                    <input id="fileInput" type="file" #importFile class="file-input"
                     (change)="uploadDocument($event.target.files, $event)">
                    <input type="hidden" name="fileHidden" formControlName="documents"/>
                    <label for="fileInput" class="file-input-button">
                      Browse Files
                    </label>
                  </div> -->
                </div>
              </div>
              <div class="uploaded-attachments">
                <ul class="your-attachments">
                  <li class="d-inline-flex justify-content-start align-items-center" *ngFor="let file of fileList">
                    <div>{{file.file.name}}</div>
                    <mat-icon class="delete-icon ml-2" (click)="removeDocument(file)">clear</mat-icon>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header">
        <div class="text">{{ 'claims.edit.whatHappened.additional.title' | translate }}</div>
      </div>
      <div class="section">
        <div class="subsection">
          <div class="subtitle">
            {{ 'claims.edit.whatHappened.additional.subtitle' | translate }}
          </div>
          <mat-form-field>
            <textarea matInput #comments maxlength="250" formControlName="additionalComments"
              [(ngModel)]="claim.additionalNotes"></textarea>
            <mat-hint align="end">
              {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{ 250 - comments.value.length }}
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div *ngIf="scriptError" class="response-error">Invalid Input</div>

    <div class="step-controls-isop">
      <div class="d-flex continue-save">
        <button class="upsc-button format" *ngIf="!continuing" (click)="saveClaim2($event)">{{ 'claims.edit.continue' |
          translate }}<mat-icon>keyboard_arrow_right</mat-icon></button>
        <button class="next-step-isop loading d-flex align-items-center justify-content-between" *ngIf="continuing">
          <div>{{ 'claims.edit.continue' | translate }}</div>
          <div class="spinner-border" role="status">
            <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
          </div>
        </button>
        <button class="upsc-white-button" *ngIf="isUsaUser && !isGuestUser" (click)="openDraftConfirmation()">{{
          'claims.edit.navi.save' | translate }}<mat-icon>keyboard_arrow_right</mat-icon></button>
      </div>
      <div class="d-flex align-items-center ml-auto">
        <a class="link" (click)="previousStep($event)">{{ 'claims.edit.back' | translate }}</a>
        <div class="vertical-border"></div>
        <a class="link" (click)="openCancelDialog($event)">{{ 'claims.edit.cancel' | translate }}</a>
      </div>
    </div>
    <div class="red error" *ngIf="systemError">
      {{ 'common.systemError' | translate }}
    </div>
  </form>

</ng-template>

<ng-template #contactInfoTemplate>
  <div class="white-on-gray">
    <upsc-confirm-contact-card [policy]="policy" [userDetails]="userDetails" [persistPayeeInfo]="persistPayeeInfo"
      (selectedContact)="saveContact($event)" (saveDraft)="saveDraft($event)" (goBack)="previousStep()"
      (cancelClaim)="openCancelDialog()">
    </upsc-confirm-contact-card>
  </div>
</ng-template>

<!--  <form [formGroup]="contactInfoFormGroup">
    <div class="white-on-gray">
      <div class="header">
        <div class="title">
          <div class="text">{{ 'claims.edit.contactInfo.title' | translate }}</div>
        </div>
      </div>
      <div class="header">
        <div class="title">
          <div class="text">{{ 'claims.edit.contactInfo.contactInformation.title' | translate }}</div>
        </div>
      </div>
      <div class="section">
        <div class="contact-you">
          <div class="panel-section contact-info" [formGroup]="contactInfoFormGroup.get('contact')">
            <form [formGroup]="contactSelectionForm">
              <mat-radio-group formControlName="choice" class="my-2" [(ngModel)]="contactType">
                <div
                  class="contact-option-cards d-flex flex-column flex-md-row align-content-center justify-content-center justify-content-md-start">
                  <upsc-selection-card *ngIf="!isGuestUser" class="mr-md-3 d-flex" [radiobuttonValue]="policyHolder"
                    [radiobutton]="true"
                    [text]="'claims.edit.contactInfo.contactInformation.contactForm.policyHolderLabel' | translate"
                    [selected]="false" [isSelected]="isPolicyHolder" [selectionBackground]="true"
                    (click)="toggleFlow('policyHolder')" value="0" [class.custom-radio-selected]="">
                    <div class="section">
                      <div class="contents d-flex flex-column flex-md-column justify-content-md-between">
                        <div class="subsection mb-3">
                          <div class="subsection-text break contactTitle">{{ policy.insured }}</div>
                          <div class="subsection-text break email">{{userDetails.emailAddress}}</div>
                          <div class="subsection-text">{{userDetails.contactPhone | phoneNumber}}</div>
                        </div>
                        <div class="subsection">
                          <div class="subsection-text break">{{ policy.addressLine1_Ext }}</div>
                          <div class="subsection-text break">{{ policy.addressLine2_Ext }}</div>
                          <div class="subsection-text">{{ policy.city }},<div *ngIf="isUsaUser || isCanadaUser">{{
                              policy.state }}</div> {{ policy.zip }}</div>

                          <div class="subsection-text">{{ policy.policyCountry_Ext }}</div>
                        </div>
                      </div>
                    </div>
                  </upsc-selection-card>
                  <upsc-selection-card *ngIf="!hidePayeePanel" class="mr-md-3 d-flex" [radiobuttonValue]="recipient"
                    [radiobutton]="true"
                    [text]="'claims.edit.contactInfo.contactInformation.contactForm.recipientLabel' | translate"
                    [selected]="false" [isSelected]="isRecipient" [selectionBackground]="false"
                    (click)="toggleFlow('recipient')" value="1" [class.custom-radio-selected]="">
                    <div class="section">
                      <div class="contents d-flex flex-column flex-md-column justify-content-md-between">
                        <div class="subsection mb-3">
                          <div class="subsection-text break contactTitle">{{ persistPayeeInfo.payeeName }}</div>
                          <div class="subsection-text break email">{{ persistPayeeInfo.email }}</div>
                          <div class="subsection-text">{{ persistPayeeInfo.phoneNumber | phoneNumber }}</div>
                        </div>
                        <div class="subsection">
                          <div class="subsection-text break">{{ persistPayeeInfo.addressLine1 }}</div>
                          <div class="subsection-text break">{{ persistPayeeInfo.addressLine2 }} </div>
                          <div class="subsection-text">{{ persistPayeeInfo.city }},
                            <div *ngIf="isUsaUser || isCanadaUser"> {{ persistPayeeInfo.state }} </div>
                            {{ persistPayeeInfo.zip }}
                          </div>
                          <div class="subsection-text">{{ persistPayeeInfo.country }}</div>
                        </div>
                      </div>
                    </div>
                  </upsc-selection-card>
                </div>
                <mat-radio-button class="different-contact-label break" (click)="toggleFlow('custom')" value="2">
                  <span class="wrap-mat-radio-label">{{
                    'claims.edit.contactInfo.contactInformation.contactForm.choiceLabel' | translate }}
                  </span>
                </mat-radio-button>
              </mat-radio-group>
            </form>
            <div class="mat-form-fields mt-3" *ngIf="custom">
             <mat-form-field class="medium-full plain-form-field">
                <input matInput required formControlName="firstName" maxlength="30" [(ngModel)]="mainContact_firstName"
                  placeholder="{{ 'claims.edit.contactInfo.contactInformation.contactForm.firstName.label' | translate }}">
                <mat-error *ngIf="contactInfoFormGroup.get('contact').get('firstName').errors">
                  <div *ngIf="contactInfoFormGroup.get('contact').get('firstName').errors['required']">
                    {{ 'claims.edit.contactInfo.contactInformation.contactForm.firstName.errors.required' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="medium-full plain-form-field">
                <input matInput required formControlName="lastName" maxlength="30" [(ngModel)]="mainContact_lastName"
                  placeholder="{{ 'claims.edit.contactInfo.contactInformation.contactForm.lastName.label' | translate }}">
                <mat-error *ngIf="contactInfoFormGroup.get('contact').get('lastName').errors">
                  <div *ngIf="contactInfoFormGroup.get('contact').get('lastName').errors['required']">
                    {{ 'claims.edit.contactInfo.contactInformation.contactForm.lastName.errors.required' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>

              <div class="togetherCode d-flex flex-column flex-md-row width100"
                *ngIf="isItalyUser || isFranceUser || isGermanyUser || isUkUser">
                <div class="edit code mr-3">

                  <mat-form-field class="medium-full plain-form-field">
                    <span matPrefix>+ &nbsp;</span>
                    <input matInput formControlName="countryCode" (keypress)="numberOnly($event)" maxlength="3"
                      minlength="1" matInput placeholder="{{ 'payee.creation.form.countryCode' | translate }}">
                    <mat-error *ngIf="countryCode.errors">
                      <div *ngIf="countryCode.errors.required">
                        {{ 'claims.edit.contactInfo.contactInformation.contactForm.countryCode.errors.required' |
                        translate }}
                      </div>
                      <div *ngIf="countryCode.errors.pattern">
                        {{ 'claims.edit.contactInfo.contactInformation.contactForm.countryCode.errors.invalid' |
                        translate }}
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="edit half">

                  <mat-form-field class="medium-full plain-form-field">
                    <input required *ngIf="isItalyUser || isFranceUser || isGermanyUser || isUkUser" matInput
                      formControlName="phoneNumber" (keypress)="numberOnly($event)" maxlength="12"
                      placeholder="{{ 'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.label' | translate }}">

                    <input *ngIf="isGuestUser" matInput upscPhoneNumber required formControlName="phoneNumber"
                      maxlength="12" minlength="12"
                      placeholder="{{ 'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.label' | translate }}">
                    <mat-error *ngIf="contactInfoFormGroup.get('contact').get('phoneNumber').errors">
                      <div *ngIf="contactInfoFormGroup.get('contact').get('phoneNumber').errors['required']">
                        {{ 'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.errors.required' |
                        translate
                        }}
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <mat-form-field *ngIf="isCanadaUser || isUsaUser" class="medium-full plain-form-field">
                <input *ngIf="!isGuestUser" matInput upscPhoneNumber formControlName="phoneNumber" maxlength="12"
                  minlength="12" [(ngModel)]="mainContact_phoneNumber"
                  placeholder="{{ 'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.label' | translate }}">
                <input *ngIf="isGuestUser" matInput upscPhoneNumber required formControlName="phoneNumber"
                  maxlength="12" minlength="12"
                  placeholder="{{ 'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.label' | translate }}">
                <mat-error *ngIf="phoneNumber.errors && phoneNumber.touched">
                  <div *ngIf="phoneNumber.errors.minlength">
                    {{ 'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.errors.invalid' | translate
                    }}
                  </div>
                  <div *ngIf="phoneNumber.errors.required">
                    {{ 'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.errors.required' | translate
                    }}
                  </div>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="medium-full plain-form-field">
                <input matInput required formControlName="emailAddress" [(ngModel)]="mainContact_email"
                  placeholder="{{ 'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.label' | translate }}">
                <mat-error *ngIf="contactInfoFormGroup.get('contact').get('emailAddress').errors">
                  <div *ngIf="contactInfoFormGroup.get('contact').get('emailAddress').errors['required']">
                    {{ 'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.required' | translate
                    }}
                  </div>
                  <div
                    *ngIf="contactInfoFormGroup.get('contact').get('emailAddress').errors['pattern'] || contactInfoFormGroup.get('contact').get('emailAddress').errors['invalid'] || contactInfoFormGroup.get('contact').get('emailAddress').errors?.email">
                    {{ 'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.valid' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="scriptError" class="response-error">Invalid Input</div>
    <div class="step-controls">
      <div>
        <a class="previous-step" href="#" (click)="previousStep($event)">
          {{ 'claims.edit.back' | translate }}
        </a>
        <a class="reset-step" (click)="openCancelDialog($event)">
          {{ 'claims.edit.cancel' | translate }}
        </a>
      </div>
      <div class="d-flex flex-column flex-md-row">
        <button class="upsc-button save" type="button" *ngIf="isUsaUser && !isGuestUser"
          (click)="openDraftConfirmation()">{{ 'claims.edit.navi.save' | translate }}</button>
        <button class="next-step" role="button" type="button" (click)="saveClaim3($event)" *ngIf="!continuing">
          {{ 'claims.edit.continue' | translate }}
        </button>
        <button class="next-step loading d-flex align-items-center" role="button" type="button"
          *ngIf="continuing">
          <div>{{ 'claims.edit.continue' | translate }}</div>
          <div class="spinner-border" role="status">
            <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
          </div>
        </button>
      </div>
    </div>
  </form> -->


<ng-template #submitClaimTemplate>
  <form [formGroup]="submitClaimFormGroup">
    <div class="review-wrapper">

      <div class="section mb-3 mb-lg-5" *ngIf="persistPayeeInfo?.paymentMethod === 'wallet'">
        <div class="section-title">
          {{ 'claims.edit.review.reviewAndSubmit.reviewList.claimPaymentReceipent' | translate }}
        </div>
        <div class="bold mb-3">Pay to UPS<sup>®</sup> Wallet</div>
      </div>

      <div class="section mb-3 mb-lg-5">
        <div class="section-title">
          {{ 'claims.edit.review.reviewAndSubmit.reviewList.shipmentDetail' | translate }}
          <span class="edit" (click)="edit('shipment')">{{ 'claims.edit.review.reviewAndSubmit.reviewList.edit' |
            translate }}</span>
        </div>
        <div class="contents d-flex flex-column flex-md-row justify-content-md-between">
          <div class="column mr-md-2">
            <div class="subsection">
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.trackingNumber' | translate }}</div>
              <div class="subsection-text break">{{ startFormGroup.get('trackingNumber').value }}</div>
            </div>
            <div class="subsection">
              <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.carrier' | translate }}
              </div>
              <div class="subsection-text">{{ startFormGroup.get('carrier').value }}</div>
            </div>
            <div class="subsection">
              <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.role' | translate }}</div>
              <div class="subsection-text" *ngIf="startFormGroup.get('role').value == 0">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.sender' | translate }}</div>
              <div class="subsection-text" *ngIf="startFormGroup.get('role').value == 1">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.recipient' | translate }}</div>
              <div class="subsection-text" *ngIf="startFormGroup.get('role').value == 2">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.party' | translate }}</div>
            </div>
          </div>
          <div class="column mr-md-2">
            <div class="subsection">
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.shipperInformation' | translate }}</div>
              <div class="subsection-text">
                <div>{{ startFormGroup.get('sender').get('company').value }}</div>
                <div *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser">{{
                  startFormGroup.get('sender').get('address').value }},</div>
                <div>{{ startFormGroup.get('sender').get('city').value | titlecase }}, {{ senderStateDisplay }}</div>
                <div *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser">{{
                  startFormGroup.get('sender').get('zipCode').value }}</div>
                <div>{{ startFormGroup.get('sender').get('country').value }}</div>
              </div>
            </div>
            <div class="subsection">
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.recipientInformation' | translate }}</div>
              <div class="subsection-text">
                <div>{{ startFormGroup.get('recipient').get('company').value }}</div>
                <div *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser">{{
                  startFormGroup.get('recipient').get('address').value }},</div>
                <div>{{ startFormGroup.get('recipient').get('city').value | titlecase }}, {{ recipientStateDisplay }}
                </div>
                <div *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser">{{
                  startFormGroup.get('recipient').get('zipCode').value }}</div>
                <div>{{ startFormGroup.get('recipient').get('country').value }}</div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="subsection">
              <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.shipDate' | translate }}
              </div>
              <div *ngIf="isUsaUser" class="subsection-text">{{ startFormGroup.get('shipDate').value | date:
                'MM/dd/yyyy' }}</div>
              <div *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser || isCanadaUser"
                class="subsection-text">{{ startFormGroup.get('shipDate').value | date: 'dd/MM/yyyy' }}</div>
            </div>
            <div class="subsection">
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.referenceNumber' | translate }}</div>
              <div class="subsection-text" *ngIf="startFormGroup.get('referenceNumber').value">
                {{ startFormGroup.get('referenceNumber').value }}</div>
              <div class="subsection-text" *ngIf="!startFormGroup.get('referenceNumber').value">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.na' | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section mb-3 mb-lg-5">
        <div class="section-title">
          {{ 'claims.edit.review.reviewAndSubmit.reviewList.claimDetail' | translate }}
          <span class="edit" (click)="edit('claim')">{{ 'claims.edit.review.reviewAndSubmit.reviewList.edit' | translate
            }}</span>
        </div>
        <div class="contents d-flex flex-column flex-md-row justify-content-md-between">
          <div class="column mr-md-2">
            <div class="subsection">
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.reasonForClaim' | translate }}</div>
              <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('reason').value == 0">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.loss' | translate }}</div>
              <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('reason').value == 1">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.damage' | translate }}</div>
              <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('reason').value == 2">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.missing' | translate }}</div>
              <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('reason').value == 3">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.late' | translate }}</div>
              <div class="subsection-more" *ngIf="whatHappenedFormGroup.get('reason').value == 1 && dmgMore">
                <!--Only appears for Damage-->
                {{ shortenedDamageDescription }}
              </div>
              <div class="subsection-more" *ngIf="whatHappenedFormGroup.get('reason').value == 1 && (dmgMore == false)">
                <!--Only appears for Damage-->
                {{ whatHappenedFormGroup.get('damageDescription').value }}
              </div>
              <span class="more-less" *ngIf="longDamage && dmgMore" (click)="toggleDescription('damage')">{{
                'claims.edit.review.reviewAndSubmit.reviewList.more' | translate }}</span>
              <span class="more-less" *ngIf="longDamage && (dmgMore == false)" (click)="toggleDescription('damage')">{{
                'claims.edit.review.reviewAndSubmit.reviewList.less' | translate }}</span>
              <div class="subsection-more" *ngIf="whatHappenedFormGroup.get('reason').value == 2 && missingMore">
                <!--Only appears for Missing Contents-->
                {{ shortenedMissingDescription }}
              </div>
              <div class="subsection-more"
                *ngIf="whatHappenedFormGroup.get('reason').value == 2 && (missingMore == false)">
                {{ whatHappenedFormGroup.get('missingDescription').value }}
              </div>
              <span class="more-less" *ngIf="longMissing && missingMore" (click)="toggleDescription('missing')">{{
                'claims.edit.review.reviewAndSubmit.reviewList.more' | translate }}</span>
              <span class="more-less" *ngIf="longMissing && (missingMore == false)"
                (click)="toggleDescription('missing')">{{ 'claims.edit.review.reviewAndSubmit.reviewList.less' |
                translate }}</span>
            </div>
            <div class="subsection">
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.productCategory' | translate }}</div>
              <div class="subsection-text">{{ whatHappenedFormGroup.get('category').value.value }}</div>
            </div>
          </div>
          <div class="column mr-md-2">
            <div class="subsection">
              <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.quantity' | translate }}
              </div>
              <div class="subsection-text">{{ whatHappenedFormGroup.get('quantity').value }}</div>
            </div>
            <div class="subsection"
              *ngIf="(whatHappenedFormGroup.get('reason').value == 0) && (isUsaUser || isCanadaUser)">
              <!--Only appears on Lost-->
              <div class="subsection-title" *ngIf="cargo">{{ 'claims.edit.review.reviewAndSubmit.reviewList.howCare' |
                translate }}
              </div>
              <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('lossReship').value">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.reshippedNewOrder' | translate }}
              </div>
              <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('lossRefund').value">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.refundedTheirCharges' | translate }}
              </div>
              <div class="subsection-text break"
                *ngIf="whatHappenedFormGroup.get('lossCare').value == 2 && isCanadaUser">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.noinfo' | translate }}
              </div>
              <div class="subsection-text break" *ngIf="whatHappenedFormGroup.get('lossReship').value">
                {{ whatHappenedFormGroup.get('lossReship').value }}</div>
              <div class="subsection-text break" *ngIf="whatHappenedFormGroup.get('lossRefund').value">
                {{ whatHappenedFormGroup.get('lossRefund').value }}</div>
            </div>
            <div class="subsection"
              *ngIf="(whatHappenedFormGroup.get('reason').value == 3) && (isUsaUser || isCanadaUser)">
              <!--Only appears on Late Delivery-->
              <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.howCare' | translate }}
              </div>
              <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('lateReship').value">{{
                'claims.edit.review.reviewAndSubmit.reviewList.reshippedNewOrder' | translate }}
              </div>
              <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('lateRefund').value">{{
                'claims.edit.review.reviewAndSubmit.reviewList.refundedTheirCharges' | translate }}
              </div>
              <div class="subsection-text break" *ngIf="whatHappenedFormGroup.get('lateReship').value">
                {{ whatHappenedFormGroup.get('lateReship').value }}</div>
              <div class="subsection-text break" *ngIf="whatHappenedFormGroup.get('lateRefund').value">
                {{ whatHappenedFormGroup.get('lateRefund').value }}</div>
            </div>
            <div class="subsection" *ngIf="whatHappenedFormGroup.get('reason').value == 1">
              <!--Only appears on Damage-->
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.damageDiscovered' | translate }}</div>
              <div *ngIf="isUsaUser" class="subsection-text">{{ whatHappenedFormGroup.get('damageDate').value | date:
                'MM/dd/yyyy' }}
              </div>
              <div *ngIf="isUkUser || isCanadaUser || isGermanyUser || isFranceUser || isItalyUser"
                class="subsection-text">{{ whatHappenedFormGroup.get('damageDate').value | date: 'dd/MM/yyyy' }}
              </div>
            </div>
            <div class="subsection" *ngIf="whatHappenedFormGroup.get('reason').value == 2">
              <!--Only appears on Missing-->
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.issueDiscovered' | translate }}</div>
              <div class="subsection-text" *ngIf="isUsaUser">{{ whatHappenedFormGroup.get('missingDate').value | date:
                'MM/dd/yyyy' }}
              </div>
              <div class="subsection-text"
                *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser">{{
                whatHappenedFormGroup.get('missingDate').value | date: 'dd/MM/yyyy' }}
              </div>
            </div>
            <div class="subsection" *ngIf="whatHappenedFormGroup.get('reason').value == 1">
              <!--Only appears on Damage-->
              <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.repairable' | translate }}
              </div>
              <div class="subsection-text">{{ repairableDisplay }}</div>
            </div>
            <div class="subsection" *ngIf="whatHappenedFormGroup.get('reason').value == 1">
              <!--Only appears on Damage-->
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.packageLocation' | translate }}</div>
              <div class="subsection-text" *ngIf="!whatHappenedFormGroup.get('damageOther').value">{{ locationDisplay }}
              </div>
              <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('damageOther').value">
                {{ whatHappenedFormGroup.get('damageOther').value }}</div>
            </div>
            <div class="subsection" *ngIf="whatHappenedFormGroup.get('reason').value == 2">
              <!--Only appears on Missing-->
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.packageLocation' | translate }}</div>
              <div class="subsection-text" *ngIf="!whatHappenedFormGroup.get('missingOther').value">
                {{ locationDisplay }}</div>
              <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('missingOther').value">
                {{ whatHappenedFormGroup.get('missingOther').value }}</div>
            </div>
            <div class="subsection" *ngIf="whatHappenedFormGroup.get('return').value">
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.return' | translate }}
              </div>
              <div class="subsection-text">{{ whatHappenedFormGroup.get('return').value | titlecase }}</div>
            </div>
            <div class="subsection" *ngIf="whatHappenedFormGroup.get('originalTrackingNumber').value">
              <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.otn' | translate }}</div>
              <div class="subsection-text break">{{ whatHappenedFormGroup.get('originalTrackingNumber').value }}</div>
            </div>
            <div class="subsection">
              <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.amount' | translate }}
              </div>
              <div *ngIf="isUsaUser" class="subsection-text">${{ whatHappenedFormGroup.get('amount').value }}</div>
              <div *ngIf="isCanadaUser" class="subsection-text">{{ this.currencyKey == 'cad' ? 'CAD ' : '$'}}{{
                whatHappenedFormGroup.get('amount').value }}</div>
              <div *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser" class="subsection-text">
                {{ this.currencyKey == 'gbp' ? '£ ' : this.currencyKey == 'eur' ? '€ ' : '$' }} {{
                whatHappenedFormGroup.get('amount').value }}</div>
            </div>
          </div>
          <div class="column">
            <div class="subsection">
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.uploadedFiles' | translate }}</div>
              <div *ngIf="fileList.length==0">
                {{ 'claimsDetail.notProvided' | translate }}
              </div>
              <div class="subsection-text documents" *ngFor="let file of fileList">{{ file.file.name }}</div>
            </div>
            <div class="subsection">
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.merchandiseDescription' | translate }}</div>
              <div class="subsection-text break">{{ whatHappenedFormGroup.get('description').value }}</div>
            </div>
            <div class="subsection">
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.additionalComments' | translate }}</div>
              <div class="subsection-text break">{{ whatHappenedFormGroup.get('additionalComments').value }}</div>
            </div>
          </div>
        </div>
        <!-- <div class="additional-contents">
          <div class="subsection wide">
            <div class="subsection-title">
              {{ 'claims.edit.review.reviewAndSubmit.reviewList.merchandiseDescription' | translate }}</div>
            <div class="subsection-text break">{{ whatHappenedFormGroup.get('description').value }}</div>
          </div>
          <div class="subsection wide">
            <div class="subsection-title">
              {{ 'claims.edit.review.reviewAndSubmit.reviewList.additionalComments' | translate }}</div>
            <div class="subsection-text break mb-md-4">{{ whatHappenedFormGroup.get('additionalComments').value }}</div>
          </div>
        </div> -->
      </div>
      <div class="section mb-3 mb-lg-5">
        <div class="section-title">
          {{ 'claims.edit.review.reviewAndSubmit.reviewList.contactInfo' | translate }}
          <span class="edit" (click)="edit('contact')">{{ 'claims.edit.review.reviewAndSubmit.reviewList.edit' |
            translate }}</span>
        </div>
        <div class="subscript">{{ 'claims.edit.review.reviewAndSubmit.reviewList.disclaimer' | translate }}
          <a href="mailto:capitalus@ups.com" *ngIf="isUsaUser && !isDDUser">{{
            'claims.edit.review.reviewAndSubmit.reviewList.email'
            | translate }}</a>
          <a href="mailto:deliverydefensecustomerservice@ups.com"
            *ngIf="isDDUser">deliverydefensecustomerservice@ups.com</a>
          <a href="mailto:insureshieldca@ups.com" *ngIf="isCanadaUser">{{
            'claims.edit.review.reviewAndSubmit.reviewList.email' | translate }}</a>
          <a href="mailto:risksolutionsuk@ups.com" *ngIf="isUkUser">{{
            'claims.edit.review.reviewAndSubmit.reviewList.email' | translate }}</a>
          <a href="mailto:risksolutionsde@ups.com" *ngIf="isGermanyUser">{{
            'claims.edit.review.reviewAndSubmit.reviewList.email' | translate }}</a>
          <a href="mailto:risksolutionsfr@ups.com" *ngIf="isFranceUser">{{
            'claims.edit.review.reviewAndSubmit.reviewList.email' | translate }}</a>
          <a href="mailto:risksolutionsit@ups.com" *ngIf="isItalyUser">{{
            'claims.edit.review.reviewAndSubmit.reviewList.email' | translate }}</a>
        </div>
        <div class="contents d-flex flex-column flex-md-row justify-content-md-between">
          <div *ngIf="!isGuestUser" class="subsection ie mr-md-2">
            <div class="subsection-title">
              {{ 'claims.edit.review.reviewAndSubmit.reviewList.mailingAddress' | translate }}</div>
            <div class="subsection-text">{{ mailingInfo.insured }}</div>
            <div class="subsection-text break">{{ mailingInfo.streetAddress1 }}</div>
            <div class="subsection-text break">{{ mailingInfo.streetAddress2 }}</div>
            <div class="subsection-text">{{ mailingInfo.city }}, <span *ngIf="isUsaUser || isCanadaUser">{{
                mailingInfo.state }}</span> {{ mailingInfo.zip }}</div>
            <div class="subsection-text">{{ mailingInfo.country }}</div>

          </div>
          <div class="subsection ie mb-0">
            <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.contactDetails' | translate
              }}
            </div>
            <div class="subsection-text">{{claim.mainContact.firstName}} {{claim.mainContact.lastName}}</div>
            <div class="subsection-text">{{ claim.mainContact.workNumber | phoneNumber }}</div>
            <div class="subsection-text break">{{ claim.mainContact.emailAddress1 }}
            </div>
          </div>
          <div class="subsection blank"></div>
        </div>
      </div>
      <!-- claim receipent section -->
      <div class="section">
        <div class="section-title" *ngIf="persistPayeeInfo?.paymentMethod !== 'wallet'">
          {{ 'claims.edit.review.reviewAndSubmit.reviewList.claimPaymentReceipent' | translate }}
        </div>
        <div class="subscript" *ngIf="persistPayeeInfo?.paymentMethod !== 'wallet'">{{ 'claims.edit.review.reviewAndSubmit.reviewList.recdisclaimer' | translate }}</div>
        <div class="contents d-flex flex-column flex-md-row justify-content-md-between" *ngIf="persistPayeeInfo?.paymentMethod !== 'wallet'">
          <div class="column mr-md-2">
            <div class="subsection">
              <div class="subsection-text break bold">{{ persistPayeeInfo?.payeeName }}</div>
              <div class="subsection-text break email">{{ persistPayeeInfo?.email }}</div>
              <div class="subsection-text">{{ persistPayeeInfo?.phoneNumber | phoneNumber }}</div>
            </div>
            <div class="subsection">
              <div class="subsection-text break">{{ persistPayeeInfo?.addressLine1 }}</div>
              <div class="subsection-text break">{{ persistPayeeInfo?.addressLine2 }}</div>
              <div class="subsection-text">{{ persistPayeeInfo?.city }}</div>
              <div class="subsection-text" *ngIf="isUsaUser || isCanadaUser">{{ persistPayeeInfo?.state }}</div>
              <div class="subsection-text">{{ persistPayeeInfo?.zip }}</div>
              <div class="subsection-text">{{ persistPayeeInfo?.country }}</div>
            </div>
            <div class="subsection">
              <div class="subsection-text">
                <div
                  *ngIf="(persistPayeeInfo?.paymentMethod=='ACH') && (showSelections) && (isUsaUser || isCanadaUser)">
                  <div class=" subsection-title">{{ 'payee.table.achelectronic' | translate }}</div>
                  <div class="text">{{ persistPayeeInfo?.accountNumber }}</div>
                </div>
                <div
                  *ngIf="(persistPayeeInfo?.paymentMethod=='Check') && (showSelections) && (isUsaUser || isCanadaUser)"
                  class="d-table-cell">
                  <div class="check d-flex align-items-center subsection-title">{{ 'payee.table.check' | translate }}
                  </div>
                </div>
                <div
                  *ngIf="(persistPayeeInfo?.paymentMethod=='Zelle') && (showSelections) && (isUsaUser || isCanadaUser)"
                  class="d-table-cell">
                  <div class="check d-flex align-items-center subsection-title">{{ 'payee.table.zelle' | translate }}
                  </div>
                  <div class="text">{{ persistPayeeInfo?.accountNumber }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isDDUser" class="checkbox">
        <div class="legal" *ngIf="isUsaUser || isCanadaUser">{{ 'claims.edit.review.reviewAndSubmit.legal' | translate
          }}</div>
        <div class="subtitle" *ngIf="isUsaUser || isCanadaUser">{{ 'claims.edit.review.reviewAndSubmit.subrogation' |
          translate }}</div>
        <div class="legal" *ngIf="isUsaUser">
          {{ 'claims.edit.review.reviewAndSubmit.subrogationVerbiage' | translate }}
          {{ 'claims.edit.review.reviewAndSubmit.subrogationVerbiage2' | translate }}
          {{ 'claims.edit.review.reviewAndSubmit.subrogationVerbiage3' | translate }}
          {{ 'claims.edit.review.reviewAndSubmit.subrogationVerbiage4' | translate }}
        </div>
        <div class="legal" *ngIf="isCanadaUser">
          <div class="line1"> {{ 'claims.edit.review.reviewAndSubmit.subrogationVerbiage1' | translate }}</div>
          <div class="line2"> {{ 'claims.edit.review.reviewAndSubmit.subrogationVerbiage1a' | translate }}</div>
          <div class="line3"> {{ 'claims.edit.review.reviewAndSubmit.subrogationVerbiage1b' | translate }}</div>
          <div class="line4"> {{ 'claims.edit.review.reviewAndSubmit.subrogationVerbiage2' | translate }}</div>
          <div class="line5"> {{ 'claims.edit.review.reviewAndSubmit.subrogationVerbiage3' | translate }}</div>
          <div class="line6"> {{ 'claims.edit.review.reviewAndSubmit.subrogationVerbiage4' | translate }}</div>
          <div class="line7"> {{ 'claims.edit.review.reviewAndSubmit.subrogationVerbiage4a' | translate }}</div>
          <div class="line8"> {{ 'claims.edit.review.reviewAndSubmit.subrogationVerbiage4b' | translate }}</div>
          <div class="line9"> {{ 'claims.edit.review.reviewAndSubmit.subrogationVerbiage4c' | translate }}</div>
        </div>

        <upsc-us-checkbox *ngIf="isUsaUser" (cbChecked)="showCheckedHandler($event)"></upsc-us-checkbox>

        <mat-checkbox formControlName="checkboxFormControl"
          class="d-block light-green-background-checkbox-rounded size-30 claim-agreement-layout" *ngIf="!isUsaUser">
          {{ 'claims.edit.review.reviewAndSubmit.checkbox' | translate }}
          <div *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser"> {{
            'claims.edit.review.reviewAndSubmit.checkbox0' | translate }}</div>
          <div *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser"> {{
            'claims.edit.review.reviewAndSubmit.checkbox1' | translate }}</div>
          <div *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser"> {{
            'claims.edit.review.reviewAndSubmit.checkbox2' | translate }}</div>
        </mat-checkbox>
      </div>
      <mat-error class="standalone"
        *ngIf="submitClaimFormGroup.get('checkboxFormControl').errors?.required && submitClaimFormGroup.controls['checkboxFormControl']?.touched">
        {{ 'claims.edit.review.reviewAndSubmit.required' | translate }}</mat-error>
    </div>
  </form>
  <!-- <div class="red mt-1" *ngIf="systemError">
    {{ 'common.systemError' | translate }}
  </div> -->
  <div *ngIf="scriptError" class="response-error">Invalid Input</div>
  <div class="step-controls-isop">
    <div class="d-flex continue-save">
      <button class="upsc-button format" *ngIf="!continuing" (click)="submitClaim($event)">{{ 'claims.edit.submit' |
        translate }}<mat-icon>keyboard_arrow_right</mat-icon></button>
      <button class="next-step-isop loading d-flex align-items-center justify-content-between" *ngIf="continuing">
        <div>{{ 'claims.edit.submit' | translate }}</div>
        <div class="spinner-border" role="status">
          <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
        </div>
      </button>
      <button class="upsc-white-button" *ngIf="isUsaUser && !isGuestUser" (click)="openDraftConfirmation()">{{
        'claims.edit.navi.save' | translate }}<mat-icon>keyboard_arrow_right</mat-icon></button>
    </div>
    <div class="d-flex align-items-center ml-auto">
      <a class="link" (click)="previousStep($event)">{{ 'claims.edit.back' | translate }}</a>
      <div class="vertical-border"></div>
      <a class="link" (click)="openCancelDialog($event)">{{ 'claims.edit.cancel' | translate }}</a>
    </div>
  </div>
  <div class="red error" *ngIf="systemError">
    {{ 'common.systemError' | translate }}
  </div>
</ng-template>



<ng-template #successClaimTemplate>
  <div class="success-page d-flex flex-column justify-content-center align-items-center">
    <div class="success-image"><img src="../../../../assets/images/check_circle.png"></div>
    <div class="success-title pt-4">{{ 'claims.edit.success.title' | translate }}</div>
    <div class="success-subtitle pt-4 pb-2">{{ 'claims.edit.success.descr1' | translate }}<span class="bold">{{
        submittedClaimNumber }}</span>.</div>
    <div class="success-desc" *ngIf="isUsaUser">
      <div *ngIf="!isGuestUser && !isDDUser">
        {{ 'claims.edit.success.newDescr2' | translate }}
        <a class="success-link" (click)="exitToClaimDetails()">{{ 'claims.edit.success.tupssTccpDescr2Bold1' | translate
          }}</a>
        {{ 'claims.edit.success.page' | translate }}
      </div>
      <div *ngIf="isGuestUser">{{ 'claims.edit.success.guestDescr2' | translate }}</div>
      <div class="pt-4">
        {{ 'claims.edit.success.descr3' | translate }}
        <span *ngIf="!isGuestUser">
          {{ 'claims.edit.success.descrAllClaims' | translate }}
          <a [routerLink]="['/claims']" class="success-link">{{ 'claims.edit.success.descr3Bold1' | translate }}</a>
          {{ 'claims.edit.success.descrAllClaimsEnd' | translate }}
        </span>
      </div>
    </div>
    <div class="success-desc" *ngIf="!isUsaUser">
      <div *ngIf="!isGuestUser">{{ 'claims.edit.success.descr2' | translate }}</div>
      <div *ngIf="isGuestUser && isCanadaUser">{{ 'claims.edit.success.descr2' | translate }}</div>
      <div class="pt-3" *ngIf="isCanadaUser">{{ 'claims.edit.success.descr3' | translate }}</div>
    </div>
    
    <!-- <div class="success-desc" *ngIf="showWalletApprovalDisclaimer">
      <div *ngIf="canAccessDigitalWallet">Upon approval, claim payments will be available in your <span><a class="success-link" (click)="goToWallet()">UPS<sup>®</sup> Wallet<mat-icon>launch</mat-icon></a></span></div>
      <div *ngIf="!canAccessDigitalWallet">Upon approval, claim payments will be available in your UPS<sup>®</sup> Wallet.</div>
    </div> -->

    <img *ngIf="isUsaUser && isWalletUser" src="../../../../../assets/images/ups-wallet-star.PNG">
    <div class="success-desc" *ngIf="isUsaUser && isWalletUser">
      Once approved, you’ll find your payout in your UPS Wallet. To access it, log into the Online Portal and head to
      the Billing and Wallet tab.
    </div>
    <div class="success-desc" *ngIf="isUsaUser && isWalletUser">
      Your UPS Wallet offers various transfer options for approved claims, such as instant transfer, ACH, and Zelle.
    </div>
    <div class="success-desc" *ngIf="isUsaUser && isWalletUser">
      For more details and to explore your cash transfer options, please log into your <a class="success-link" (click)="goToWallet()">UPS
        Wallet</a>.
    </div>

    <div class="success-buttons">
      <button class="upsc-white-button" *ngIf="isCanadaUser && !isGuestUser" (click)="backToClaims($event)">
        {{ 'claims.edit.success.backtoClaims' | translate }}<mat-icon>keyboard_arrow_right</mat-icon>
      </button>
      <button class="upsc-button" (click)="reloadPage($event)">
        {{ 'claims.edit.success.startANewClaim' | translate }}<mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
    <div class="error" *ngIf="systemError">
      {{ 'common.systemError' | translate }}
    </div>
  </div>

  
</ng-template>