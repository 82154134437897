import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class HRAuthGuardService  {

  constructor(private router: Router, private auth: AuthService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    this.auth.redirectUrl = state.url;
    let userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    if (sessionStorage.getItem('access_token') && sessionStorage.getItem('policyDetails') && userDetails !== null && userDetails.isClaimsActive === true) {
      return true;
    }
    this.router.navigate(['/happy-returns']);
      return false;
  }
}
