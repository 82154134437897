import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { DDOnBoardingService } from 'src/app/services/dd-onboarding.service';
import { VisibilityPopupComponent } from '../dd-visibility/dialog/visibility-popup/visibility-popup.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-insights-container',
  templateUrl: './insights-container.component.html',
  styleUrls: ['./insights-container.component.scss']
})
export class InsightsContainerComponent implements OnInit {
  //policy: IPolicies;
  public isShowFeatures: boolean = true;
  public pageLoading: boolean = false;
  public systemError: boolean = false;
  visibilitySettings: any = {};
  public currentPlan: string = '';
  // userId: string = '';
  // userPolicy: string = '';
  // users: any = [];
  // contaxId: string = 'silcustomer';
  // public contaxOnBoarding: any = {};
  policy: any = {};
  constructor(
    public dialog: MatDialog,) {
  }

  ngOnInit() {
    // this.currentPlan = 'pro';
    // this.userId = JSON.parse(sessionStorage.getItem('userDetails'))?.userId;
    // this.userPolicy = this.userService.getUserInfo()?.policyNumber;
    // this.policy = JSON.parse(sessionStorage.getItem('policyDetails'));
    // this.contaxOnBoarding.name = this.policy.insured;
    //this.getUserInfo();
  }

  // getUserInfo() {
  //   this.pageLoading = true;
  //   this.systemError = false;
  //   let payload = {
  //     policyNumber: this.userPolicy,
  //     userIDs: [this.userId]
  //   }
  //   this.users = [];
  //   let user: any = {};
  //   this.userService.getUserInfoListForPolicy(payload).subscribe(
  //     data => {
  //       if (data !== null && data && data.data && data.data.users.length > 0) {
  //         data.data.users.forEach(element => {
  //           user.email = element.emailAddress;
  //           try {
  //             var name = element.contactName.split(" ");
  //             if (name) {
  //               user.firstName = name[0];
  //               user.lastName = name[1];
  //             }
  //           } catch {
  //             user.firstName = element.contactName;
  //           }
  //           this.users.push(user);
  //         });
  //         this.contaxOnBoarding.users = this.users;
  //         this.contaxOnBoarding.id = this.contaxId;
  //         this.pageLoading = false;
  //       } else {
  //         //check for not allowed
  //         this.pageLoading = false;
  //         this.systemError = true;
  //         this.contaxOnBoarding.id = this.contaxId;
  //         this.contaxOnBoarding.users = [];
  //       }
  //     },
  //     error => {
  //       this.pageLoading = false;
  //       this.systemError = true;
  //     }
  //   );
  // }



  public changeCancelPlan(subscriptionPlan: string) {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.width = '510px';
    this.visibilitySettings.action = "CreatePlan";
    this.visibilitySettings.subscription = subscriptionPlan;
    sessionStorage.setItem('visibilitySettings', JSON.stringify(this.visibilitySettings));
    let dialogRef = this.dialog.open(VisibilityPopupComponent, config.data);
    dialogRef.afterClosed().subscribe(
      closed => {
      }
    );
  }

  // All code below is to ensure that the background gradient image stays perfectly aligned no matter the screen size
  //Everything below is for new DD Visibility item
  // ngAfterViewInit() {
  //   this.detectScreenSize();
  // }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.detectScreenSize();
  // }
  // detectScreenSize(event?) {
  //   if (window?.innerWidth && window.innerWidth > 1448) {
  //     if (((window.innerWidth - 1438) / -2 ) > -15) {
  //       let page = document.getElementById('background') as HTMLLinkElement;
  //       page.style.marginLeft = "-15px";
  //       page.style.marginRight = "-15px";
  //     } else {
  //       let val = (window.innerWidth - 1438) / -2;
  //       let val2 = val + "px";
  //       let page = document.getElementById('background') as HTMLLinkElement;
  //       page.style.marginLeft = val2;
  //       page.style.marginRight = val2;
  //     }   
  //   } else {
  //     let page = document.getElementById('background') as HTMLLinkElement;
  //     page.style.marginLeft = "-15px";
  //     page.style.marginRight = "-15px";
  //   }
  // }

  showFeatures() {
    if (this.isShowFeatures) {
      this.isShowFeatures = false;
    } else {
      this.isShowFeatures = true;
    }
  }
}
