import { Component } from '@angular/core';
import { HappyReturnsService } from 'src/app/services/happy-returns.service';

@Component({
  selector: 'upsc-hr-messages',
  templateUrl: './hr-messages.component.html',
  styleUrls: ['./hr-messages.component.scss']
})
export class HrMessagesComponent {

  public isHappyReturns: boolean = true;
  public groupCode: string = '';

  constructor(private hpService: HappyReturnsService){
    this.groupCode = this.hpService.getHappyReturnsInfo().groupCode;
  }

}
