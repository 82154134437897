import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'upsc-dw-recipient',
  templateUrl: './dw-recipient.component.html',
  styleUrls: ['./dw-recipient.component.scss']
})
export class DwRecipientComponent {

  @Output() cpRecipient = new EventEmitter<any>();


  continue() {
    this.cpRecipient.emit("continue");
  }

  cancel() {
    this.cpRecipient.emit("cancel");
  }

}
