import { Component } from '@angular/core';

@Component({
  selector: 'upsc-hr-faq',
  templateUrl: './hr-faq.component.html',
  styleUrls: ['./hr-faq.component.scss']
})
export class HrFaqComponent {

}
