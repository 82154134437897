<div class="backGroundHeader">
  <upsc-login-header></upsc-login-header>
</div>
<div class="upsc-custom-create-account">
  <div class="bg-color d-flex flex-column">
    <div class="big-wrapper">
      <div class="content-wrapper d-flex flex-column mx-auto">
        <div class="create-account-title d-flex justify-content-center">{{ 'createAccount.pageTitle' | translate }}
        </div>

        <div class="labelMobile">
          <mat-icon class="back-icon" (click)="setCurrentStepMobile(currentStep-1)">chevron_left</mat-icon>
          {{currentStepText}}
        </div>

        <form [formGroup]="formGroup" (ngSubmit)="onSubmit($event, formGroup.value)">
          <upsc-stepper5 [steps]="steps" [currentStep]="currentStep"></upsc-stepper5>
          <div class="register-sections">
            <div class="register-sections account" *ngIf="currentStep == 1 && showMultiPolicy == false">
              <ng-container *ngTemplateOutlet="accountTemplate"></ng-container>
            </div>
            <div class="register-sections account" *ngIf="currentStep == 1 && showMultiPolicy == true">
              <ng-container *ngTemplateOutlet="multiPolicy"></ng-container>
            </div>
            <div class="register-sections confirm" *ngIf="currentStep === 2">
              <ng-container *ngTemplateOutlet="confirmTemplate"></ng-container>
            </div>
            <div class="register-sections review" *ngIf="currentStep === 3">
              <ng-container *ngTemplateOutlet="reviewTemplate"></ng-container>
            </div>
            <div class="register-sections success" *ngIf="currentStep === 4">
              <ng-container *ngTemplateOutlet="successTemplate"></ng-container>
            </div>
          </div>
        </form>
        <div class="login-wrapper align-self-center" *ngIf="currentStep < 4">
          <div class="already">{{ 'createAccount.already' | translate }}</div>
          <div class="login">
            <a class="loginLink" (click)="goToLoginPage()">
              {{ 'createAccount.login' | translate }}
            </a>
          </div>
        </div>
        <div class="success-spacer" *ngIf="currentStep == 4"></div>
      </div>
    </div>
    <div class="footer">
      <upsc-footer></upsc-footer>
    </div>
  </div>

  <ng-template #accountTemplate>
    <form [formGroup]="accountFormGroup">
      <div class="center-wrapper">
        <div class="description">{{ 'createAccount.account.description' | translate }}</div>
        <div class="input-er-step-wrapper">
          <mat-form-field ngbTooltip="{{ 'createAccount.account.tooltip.policyTip' | translate }}"
            tooltipClass="ngb-tooltip-class">
            <input autocomplete="off" matInput required
              placeholder="{{ 'createAccount.account.form.policy' | translate }}"
              formControlName="policyNumberFormControl" (focusout)="formatPolicy();">
              <mat-error *ngIf="accountFormGroup.controls['policyNumberFormControl'].errors?.required">
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'createAccount.account.form.policyRequired' | translate }}</div>
                </div>
              </mat-error>
              <mat-error *ngIf="accountFormGroup.controls['policyNumberFormControl'].errors && !accountFormGroup.controls['policyNumberFormControl'].errors?.required">
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'createAccount.account.form.validPolicy' | translate }}</div>
                </div> 
              </mat-error>
          </mat-form-field>
        </div>
        <div class="input-er-step-wrapper">
          <mat-form-field ngbTooltip="{{ 'createAccount.account.tooltip.emailTip' | translate }}"
            tooltipClass="ngb-tooltip-class">
            <input matInput required placeholder="{{ 'createAccount.account.form.email' | translate }}"
              formControlName="emailFormControl">
              <mat-error *ngIf="accountFormGroup.controls['emailFormControl'].errors?.required">              
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'createAccount.account.form.emailRequired' | translate }}</div>
                </div> 
              </mat-error>
              <mat-error *ngIf="(accountFormGroup.controls['emailFormControl'].errors?.invalid || accountFormGroup.controls['emailFormControl'].errors?.email) && !accountFormGroup.controls['emailFormControl'].errors?.required">
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'createAccount.account.form.validEmail' | translate }}</div>
                </div> 
              </mat-error>
              <mat-error *ngIf="accountFormGroup.controls['emailFormControl'].errors?.inuse">
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'createAccount.account.form.emailInUse' | translate }}</div>
                </div>               
              </mat-error>
          </mat-form-field>
        </div>
        <div  class="input-er-step-wrapper">
          <mat-form-field *ngIf="isUsaUser || isGermanyUser || isFranceUser || isItalyUser"
            ngbTooltip="{{ 'createAccount.account.tooltip.zipTip' | translate }}" tooltipClass="ngb-tooltip-class">
            <input matInput required placeholder="{{ 'createAccount.account.form.zip' | translate }}"
              formControlName="zipFormControl" maxlength="5">
              <mat-error *ngIf="accountFormGroup.controls['zipFormControl'].errors?.required">              
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'createAccount.account.form.zipRequired' | translate }}</div>
                </div> 
              </mat-error>
              <mat-error *ngIf="accountFormGroup.controls['zipFormControl'].errors?.pattern">              
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'common.validZip' | translate }}</div>
                </div> 
              </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="isUkUser" ngbTooltip="{{ 'createAccount.account.tooltip.zipTip' | translate }}"
            tooltipClass="ngb-tooltip-class">
            <input matInput required placeholder="{{ 'createAccount.account.form.zip' | translate }}"
              formControlName="zipFormControl" maxlength="8" minlength="2">
              <mat-error *ngIf="accountFormGroup.controls['zipFormControl'].errors?.required">              
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'createAccount.account.form.zipRequired' | translate }}</div>
                </div> 
              </mat-error>
              <mat-error *ngIf="accountFormGroup.controls['zipFormControl'].errors?.pattern">              
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'common.validZip' | translate }}</div>
                </div>
              </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="isCanadaUser" ngbTooltip="{{ 'createAccount.account.tooltip.zipTip' | translate }}"
            tooltipClass="ngb-tooltip-class">
            <input matInput required placeholder="{{ 'createAccount.account.form.zip' | translate }}"
              formControlName="zipFormControl" maxlength="7">
              <mat-error *ngIf="accountFormGroup.controls['zipFormControl'].errors?.required">             
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'createAccount.account.form.zipRequired' | translate }}</div>
                </div> 
              </mat-error>
              <mat-error *ngIf="accountFormGroup.controls['zipFormControl'].errors?.pattern">              
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'common.validZip' | translate }}</div>
                </div> 
              </mat-error>
          </mat-form-field>
        </div>
        <div class="error-returned" *ngIf="errorReturned">
          {{ 'createAccount.account.form.errorReturned' | translate}}
        </div>
        <div class="error-returned" *ngIf="systemError">
          {{ 'createAccount.account.form.systemError' | translate}}
        </div>
        <div class="error-returned" *ngIf="tempUserNotFound">
          {{ 'createAccount.account.form.tempUserNotFound' | translate}}
        </div>
        <div *ngIf="scriptError" class="response-error">
          Invalid Input
        </div> 
        <div class="add" (click)="showMultiPolicy = true" *ngIf="!isFlexAppUser">
          <mat-icon>add_circle</mat-icon><span>Add another policy</span>
        </div>
      </div>
      <div class="step-controls d-flex flex-column align-items-center">
        <button class="upsc-button" role="button" type="button" [disabled]="apiInProgress || !accountFormGroup.valid"
          (click)="checkEmail()">
          {{ 'createAccount.account.form.send' | translate }}
        </button>
        <a class="cancel" (click)="resetStep($event); goToLoginPage()">
          {{ 'createAccount.account.form.cancel' | translate }}
        </a>
      </div>     
    </form>
  </ng-template>

  <ng-template #confirmTemplate>
    <form [formGroup]="confirmFormGroup">
      <div class="confirm-information d-flex flex-column align-items-center">
        <span class="req align-self-end">
          <span class="red">{{ 'createAccount.red' | translate }}</span> {{ 'createAccount.required' | translate }}
        </span>
        <div class="title">{{ 'createAccount.confirm.title' | translate }}</div>
        <div class="description">
          {{ 'createAccount.confirm.description' | translate }}
          <br>
          {{'createAccount.confirm.description2' | translate }}
        </div>
        <div class="container-wrapper">
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" autofocus class="center-text" #name1 (keyup)="focusThis(2, $event)"
                (input)="pasted()" formControlName="firstDigit" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name2 (keyup)="focusThis(3, $event)"
                formControlName="secondDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name3 (keyup)="focusThis(4, $event)"
                formControlName="thirdDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name4 (keyup)="focusThis(5, $event)"
                formControlName="fourthDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name5 (keyup)="focusThis(6, $event)"
                formControlName="fifthDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name6 (keyup)="focusThis(7, $event)"
                formControlName="sixthDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
        </div>
        <div class="error-returned" *ngIf="codeErrorReturned">
          {{ 'createAccount.confirm.form.errorReturned' | translate }}
        </div>
      </div>

      <div class="step-controls d-flex flex-column align-items-center">
        <button class="upsc-button" role="button" type="button" [disabled]="!confirmFormGroup.valid" upscDebounce
          (debounceClick)="checkVerificationCode()" [debounceTime]="1000">
          {{ 'createAccount.confirm.form.send' | translate }}
        </button>
        <a class="cancel" [upscRouterLink]="['/create-account']" (click)="resetStep($event)" *ngIf="!isTempUser">
          {{ 'createAccount.confirm.form.cancel' | translate }}
        </a>
        <a class="cancel" [upscRouterLink]="['/login']" (click)="resetStep($event)" *ngIf="isTempUser">
          {{ 'createAccount.account.form.cancel' | translate }}
        </a>
      </div>
      <div *ngIf="scriptError" class="response-error">Invalid Input</div>
    </form>
  </ng-template>

  <ng-template #reviewTemplate>
    <form [formGroup]="reviewFormGroup">
      <div class="review-information">
        <span class="req"><span class="red">{{ 'createAccount.red' | translate }}</span> {{
          'createAccount.required' | translate }}</span>
        <div class="title">{{ 'createAccount.review.title' | translate }}</div>
        <div class="description">{{ 'createAccount.review.description' | translate }}</div>
        <div class="form-wrapper">
          <div class="full-wrapper">
            <div class="half-wrapper">
              <div class="first-last-half">
                <mat-form-field>
                  <input matInput required formControlName="firstNameFormControl"
                    placeholder="{{ 'createAccount.review.form.firstName' | translate }}">                
                    <mat-error *ngIf="reviewFormGroup.get('firstNameFormControl').errors?.required">
                      <div class="error-input">
                        <mat-icon>report_problem</mat-icon>
                        <div class="error-text">{{ 'createAccount.review.form.firstNameError' | translate }}</div>
                      </div> 
                    </mat-error>   
                    <mat-error *ngIf="reviewFormGroup.get('firstNameFormControl').errors && (!reviewFormGroup.get('firstNameFormControl').errors?.required)">                  
                      <div class="error-input">
                        <mat-icon>report_problem</mat-icon>
                        <div class="error-text">{{ 'createAccount.review.form.firstNamePattern' | translate }}</div>
                      </div> 
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput required formControlName="lastNameFormControl"
                    placeholder="{{ 'createAccount.review.form.lastName' | translate }}">
                    <mat-error *ngIf="reviewFormGroup.get('lastNameFormControl').errors?.required">                 
                      <div class="error-input">
                        <mat-icon>report_problem</mat-icon>
                        <div class="error-text"> {{ 'createAccount.review.form.lastNameError' | translate }}</div>
                      </div> 
                    </mat-error>
                    <mat-error *ngIf="reviewFormGroup.get('lastNameFormControl').errors && (!reviewFormGroup.get('lastNameFormControl').errors?.required)">
                      <div class="error-input">
                        <mat-icon>report_problem</mat-icon>
                        <div class="error-text"> {{ 'createAccount.review.form.lastNamePattern' | translate }}</div>
                      </div> 
                    </mat-error>
                </mat-form-field>
              </div>
              <ng-template #userIdContent>
                <strong>{{ 'createAccount.review.form.userIdTooltip.title' | translate }}</strong>
                <div>{{ 'createAccount.review.form.userIdTooltip.bullet1' | translate }}</div>
                <div>{{ 'createAccount.review.form.userIdTooltip.bullet2' | translate }}</div>
              </ng-template>
              <mat-form-field [ngbTooltip]="userIdContent" tooltipClass="ngb-tooltip-class">
                <input matInput required formControlName="userIdFormControl"
                  placeholder="{{ 'createAccount.review.form.userId' | translate }}" pattern="\S+">
                <mat-icon matSuffix
                  *ngIf="!reviewFormGroup.get('userIdFormControl').errors">{{'check_circle'}}</mat-icon>
                  <mat-error *ngIf="reviewFormGroup.get('userIdFormControl').errors?.required">                  
                    <div class="error-input">
                      <mat-icon>report_problem</mat-icon>
                      <div class="error-text">{{ 'createAccount.review.form.userIdError' | translate }}</div>
                    </div> 
                  </mat-error>
                  <mat-error *ngIf="reviewFormGroup.controls['userIdFormControl'].errors?.pattern || reviewFormGroup.controls['userIdFormControl'].errors?.minlength || reviewFormGroup.controls['userIdFormControl'].errors?.maxlength">
                    <div class="error-input">
                      <mat-icon>report_problem</mat-icon>
                      <div class="error-text">{{ 'createAccount.review.form.userIdPatternError' | translate }}</div>
                    </div> 
                  </mat-error>
                  <mat-error *ngIf="reviewFormGroup.controls['userIdFormControl'].errors?.inuse">
                    <div class="error-input">
                      <mat-icon>report_problem</mat-icon>
                      <div class="error-text">{{ 'createAccount.review.form.userIdInUse' | translate }}</div>
                    </div> 
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="half-wrapper">
              <ng-template #passwordContent>
                <strong>{{ 'createAccount.review.form.tooltip.title' | translate }}</strong>
                <div>{{ 'createAccount.review.form.tooltip.bullet1' | translate }}</div>
                <div>{{ 'createAccount.review.form.tooltip.bullet2' | translate }}</div>
                <div>{{ 'createAccount.review.form.tooltip.bullet3' | translate }}</div>
                <div>{{ 'createAccount.review.form.tooltip.bullet4' | translate }}</div>
                <div>{{ 'createAccount.review.form.tooltip.bullet5' | translate }}</div>
                <div>{{ 'createAccount.review.form.tooltip.bullet6' | translate }}</div>
                <div>{{ 'createAccount.review.form.tooltip.bullet7' | translate }}</div>
                <div>{{ 'createAccount.review.form.tooltip.bullet8' | translate }}</div>
              </ng-template>
              <mat-form-field class="half-wrapper" [ngbTooltip]="passwordContent" tooltipClass="ngb-tooltip-class">
                <input matInput required formControlName="passwordFormControl" (focus)="checkiPhoneTips('on')"
                  (blur)="checkiPhoneTips('off')" placeholder="{{ 'createAccount.review.form.password' | translate }}"
                  [type]="hide ? 'password' : 'text'">
                <mat-icon matSuffix
                  *ngIf="!reviewFormGroup.get('passwordFormControl').errors">{{'check_circle'}}</mat-icon>
                <mat-icon matSuffix class="icon" (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}
                </mat-icon>
                <mat-error *ngIf="reviewFormGroup.get('passwordFormControl').errors">
                  <div class="error-input" *ngIf="reviewFormGroup.get('passwordFormControl').errors['required'] || reviewFormGroup.get('passwordFormControl').errors['minlength'] || reviewFormGroup.get('passwordFormControl').errors['maxlength'] || reviewFormGroup.get('passwordFormControl').errors['invalid'] || reviewFormGroup.get('passwordFormControl').errors['pattern']">
                    <mat-icon>report_problem</mat-icon>
                    <div class="error-text">{{ 'createAccount.review.form.passwordRequirementsError' | translate }}</div>
                  </div>                   
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput required formControlName="reenterPasswordFormControl"
                  placeholder="{{ 'createAccount.review.form.reenter' | translate }}"
                  [type]="hide2 ? 'password' : 'text'">
                <mat-icon matSuffix *ngIf="!reviewFormGroup.get('reenterPasswordFormControl').errors && reviewFormGroup.get('passwordFormControl').touched">
                  {{'check_circle'}}
                </mat-icon>
                <mat-icon matSuffix class="icon" (click)="hide2 = !hide2">{{hide2 ? 'visibility' : 'visibility_off'}}
                </mat-icon>              
                <mat-error *ngIf="reviewFormGroup.controls['reenterPasswordFormControl'].errors?.notEquivalent && reviewFormGroup.controls['reenterPasswordFormControl'].touched">
                  <div class="error-input">
                    <mat-icon>report_problem</mat-icon>
                    <div class="error-text">{{ 'createAccount.review.form.reenterMismatch' | translate }}</div>
                  </div> 
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="showiPhoneTips" class="iphone-tooltips">
            <span class="pl-2">{{ 'createAccount.review.form.tooltip.title' | translate }}</span>
            <ul>
              <li>{{ 'createAccount.review.form.tooltip.bullet1dotless' | translate }}</li>
              <li>{{ 'createAccount.review.form.tooltip.bullet2dotless' | translate }}</li>
              <li>{{ 'createAccount.review.form.tooltip.bullet3dotless' | translate }}</li>
              <li>{{ 'createAccount.review.form.tooltip.bullet4dotless' | translate }}</li>
              <li>{{ 'createAccount.review.form.tooltip.bullet5dotless' | translate }}</li>
              <li>{{ 'createAccount.review.form.tooltip.bullet6dotless' | translate }}</li>
              <li>{{ 'createAccount.review.form.tooltip.bullet7dotless' | translate }}</li>
              <li>{{ 'createAccount.review.form.tooltip.bullet8dotless' | translate }}</li>
            </ul>
          </div>
          <div *ngIf="showAdminToggle" class="d-flex justify-content-center mb-3">
            {{ 'createAccount.review.admin' | translate }}
            <span class="ml-2">
              <mat-slide-toggle formControlName="adminControl" color="primary" [(ngModel)]="isChecked"></mat-slide-toggle>
            </span>
          </div>
          <div class="error-returned2" *ngIf="systemError">
            {{ 'createAccount.account.form.systemError' | translate}}
          </div>
          <div class="checkbox-wrapper">
            <mat-checkbox class="green-background-checkbox create-account-layout"
              formControlName="checkboxFormControl"></mat-checkbox>
            {{ 'createAccount.review.form.checkbox' | translate }}
            <a href="{{ privacyLink }}" *ngIf="isUsaUser" target="_blank" rel="noopener noreferrer">{{
              'createAccount.review.form.checkboxLink' | translate }}</a>

            <a href="{{ ukprivacyNotice }}" *ngIf="isUkUser" target="_blank" rel="noopener noreferrer">{{
              'createAccount.review.form.checkboxLink' | translate }}</a>

            <a href="{{ deprivacyNotice }}" *ngIf="isGermanyUser" target="_blank" rel="noopener noreferrer">{{
              'createAccount.review.form.checkboxLink' | translate }}</a>

            <a href="{{ frprivacyNotice }}" *ngIf="isFranceUser" target="_blank" rel="noopener noreferrer">{{
              'createAccount.review.form.checkboxLink' | translate }}</a>

            <a href="{{ itprivacyNotice }}" *ngIf="isItalyUser" target="_blank" rel="noopener noreferrer">{{
              'createAccount.review.form.checkboxLink' | translate }}</a>

            <a href="{{ canadaprivacyLink }}" *ngIf="isCanadaUser" target="_blank" rel="noopener noreferrer">{{
              'createAccount.review.form.checkboxLink' | translate }}</a>

            {{ 'createAccount.review.form.checkbox1.5' | translate }}
            <a href="{{ techAgreementLink }}" target="_blank"
              *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser">{{
              'createAccount.review.form.checkboxLink2' | translate }}</a>
            <a href="{{ techAgreementLink }}" target="_blank" *ngIf="isUsaUser">{{
              'createAccount.review.form.checkboxLink2' | translate }}</a>
            <a href="{{ techAgreementLink }}" *ngIf="isCanadaUser" target="_blank">{{
              'createAccount.review.form.checkboxLink2' | translate }}</a>
            {{ 'createAccount.review.form.checkbox2' | translate }}
          </div>
          <div class="step-controls">
            <button class="upsc-button" role="button" type="button" [disabled]="!reviewFormGroup.valid" upscDebounce
              (debounceClick)="checkUserID()" [debounceTime]="1000">{{
              'createAccount.review.form.send' | translate }} </button>
            <a class="cancel" [upscRouterLink]="['/create-account']" (click)="resetStep($event)">
              {{ 'createAccount.review.form.cancel' | translate }}
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="scriptError" class="response-error">Invalid Input</div>
      <div *ngIf="dataError" class="response-error">
        {{ 'common.systemError' | translate }}
      </div>
    </form>
  </ng-template>

  <ng-template #successTemplate>
    <div class="success-wrapper">
      <img class="circle-width" src="../../../assets/images/check_circle.png">
      <div class="success-title" *ngIf="user.approved">{{'createAccount.success.title' | translate}} </div>
      <div class="success-subtext bold" *ngIf="user.approved">{{'createAccount.success.title2' | translate}} </div>
      <div class="success-title" *ngIf="!user.approved">  {{'createAccount.success.non_Admin_title' | translate}}</div>
      <div class="success-subtext bold" *ngIf="!user.approved"> {{'createAccount.success.non_Admin_title2' | translate}}</div>
      <!-- If the user is the first person to create an account under said policy number, have a wallet, and are rewards eligible, we display to them the earned points message -->
      <div class="success-subtext" *ngIf="allowedToEarnDWPoints">{{'createAccount.success.DWEarnedPoints1' | translate}}
        <span class="green">{{'createAccount.success.DWEarnedPoints2' | translate}}</span>{{'createAccount.success.DWEarnedPoints3' | translate}}<sup>®</sup>
        {{'createAccount.success.DWEarnedPoints4' | translate}}<sup>®</sup>{{'createAccount.success.DWEarnedPoints5' | translate}}
      </div>
      <div class="success-buttons">
        <button class="upsc-button" (click)="tagging(); goToLoginPage()">{{'createAccount.success.button' | translate}}<mat-icon>keyboard_arrow_right</mat-icon></button>
        <!-- <button class="upsc-white-button" *ngIf="allowedToEarnDWPoints" (click)="goToWallet()">{{'createAccount.success.DWGoToWallet' | translate}}<mat-icon>keyboard_arrow_right</mat-icon></button> -->
      </div>
      <div class="error" *ngIf="systemError">
        {{ 'common.systemError' | translate }}
      </div>
      <div class="error" *ngIf="multiPolicySaveHadAnError">
        The system encountered an issue while trying to add additional policies to your account. Please login and try again from the dashboard.
      </div>
    </div>

    <div *ngIf="dataError" class="response-error">
      {{ 'common.systemError' | translate }}
    </div>
  </ng-template>

  <ng-template #multiPolicy>
    <form [formGroup]="multiPolicyFormGroup" class="multi-policy-wrapper">
    <div class="description">{{ 'createAccount.account.description' | translate }}</div>
      <div class="multi-policy-input-wrapper">
        <div class="title">{{ 'createAccount.account.form.email' | translate }}</div>
        <mat-form-field class="full-width">
          <mat-label>{{ 'createAccount.account.form.email' | translate }}</mat-label>
          <input matInput required formControlName="email">
          <mat-error>
            <mat-icon>report_problem</mat-icon>
            <span *ngIf="mpEmail.errors?.required">{{ 'createAccount.account.form.emailRequired' | translate }}</span>
            <span *ngIf="mpEmail.errors?.invalid || mpEmail.errors?.email">{{ 'createAccount.account.form.validEmail' | translate }}</span>
            <span *ngIf="mpEmail.errors?.inuse">{{ 'createAccount.account.form.emailInUse' | translate }}</span>
          </mat-error>
        </mat-form-field>
        <div class="title">Policy 1</div>
        <div class="policy-spread">
          <mat-form-field>
            <mat-label>{{ 'createAccount.account.form.policy' | translate }}</mat-label>
            <input matInput required formControlName="policy1">
            <mat-error>
              <mat-icon>report_problem</mat-icon>
              <span *ngIf="policy1.errors?.required">{{ 'createAccount.account.form.policyRequired' | translate }}</span>
              <span *ngIf="!policy1.errors?.required">{{ 'createAccount.account.form.validPolicy' | translate }}</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'createAccount.account.form.zip' | translate }}</mat-label>
            <input matInput required formControlName="zip1">
            <mat-error>
              <mat-icon>report_problem</mat-icon>
              <span *ngIf="zip1.errors?.required">{{ 'createAccount.account.form.zipRequired' | translate }}</span>
              <span *ngIf="!zip1.errors?.required">{{ 'common.validZip' | translate }}</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="title">Policy 2</div>
        <div class="policy-spread">
          <mat-form-field>
            <mat-label>{{ 'createAccount.account.form.policy' | translate }}</mat-label>
            <input matInput required formControlName="policy2">
            <mat-error>
              <mat-icon>report_problem</mat-icon>
              <span *ngIf="policy2.errors?.required">{{ 'createAccount.account.form.policyRequired' | translate }}</span>
              <span *ngIf="!policy2.errors?.required">{{ 'createAccount.account.form.validPolicy' | translate }}</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'createAccount.account.form.zip' | translate }}</mat-label>
            <input matInput required formControlName="zip2">
            <mat-error>
              <mat-icon>report_problem</mat-icon>
              <span *ngIf="zip2.errors?.required">{{ 'createAccount.account.form.zipRequired' | translate }}</span>
              <span *ngIf="!zip2.errors?.required">{{ 'common.validZip' | translate }}</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="add third-pol" *ngIf="showAddPolicy3" (click)="showPolicy3 = true; showAddPolicy3 = false">
          <mat-icon>add_circle</mat-icon><span>Add another policy</span>
        </div>
        <div class="title" *ngIf="showPolicy3">Policy 3</div>
        <div class="policy-spread" *ngIf="showPolicy3">
          <mat-form-field>
            <mat-label>{{ 'createAccount.account.form.policy' | translate }}</mat-label>
            <input matInput required formControlName="policy3">
            <mat-error>
              <mat-icon>report_problem</mat-icon>
              <span *ngIf="policy3.errors?.required">{{ 'createAccount.account.form.policyRequired' | translate }}</span>
              <span *ngIf="!policy3.errors?.required">{{ 'createAccount.account.form.validPolicy' | translate }}</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'createAccount.account.form.zip' | translate }}</mat-label>
            <input matInput required formControlName="zip3">
            <mat-error *ngIf="showPolicy3">
              <mat-icon>report_problem</mat-icon>
              <span *ngIf="zip3.errors?.required">{{ 'createAccount.account.form.zipRequired' | translate }}</span>
              <span *ngIf="!zip3.errors?.required">{{ 'common.validZip' | translate }}</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="error-returned" *ngIf="errorReturned">
        {{ 'createAccount.account.form.errorReturned' | translate}}
      </div>
      <div class="error-returned" *ngIf="systemError">
        {{ 'createAccount.account.form.systemError' | translate}}
      </div>
      <div class="step-controls d-flex flex-column align-items-center">
        <button class="upsc-button" role="button" type="button" [disabled]="apiInProgress"
          (click)="checkEmail()">
          {{ 'createAccount.account.form.send' | translate }}
        </button>
        <a class="cancel" (click)="resetStep($event); goToLoginPage()">
          {{ 'createAccount.account.form.cancel' | translate }}
        </a>
      </div>  
    </form>
  </ng-template>
 
</div>