import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from 'src/app/shared/components/layout/layout.component';
import { ChangePlanComponent } from './change-plan/change-plan.component';
import { VisibilityLayoutComponent } from 'src/app/shared/components/visibility-layout/visibility-layout/visibility-layout.component';

const routes: Routes = [
  {
    path: '',
    component: VisibilityLayoutComponent,
    children: [
      {
        path: '',
        component: ChangePlanComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DDVisibilityRoutingModule { }
