import { Component } from '@angular/core';

@Component({
  selector: 'upsc-hr-claim-list',
  templateUrl: './hr-claim-list.component.html',
  styleUrls: ['./hr-claim-list.component.scss']
})
export class HrClaimListComponent {

}
