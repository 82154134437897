
<div class="progress-bar-container">
    <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
    <div class="labelMobile" *ngIf="currentStep<=steps.length">
      Step {{currentStep}} of {{steps.length}}
    </div>
    <div class="labelMobile" *ngIf="currentStep>steps.length">
      Step 3 of {{steps.length}}
    </div>
  </div>
  
  <ul class="steps">
    <li class="step" *ngFor="let step of steps" [class.active]="step.isActive">
      <div *ngIf="step.order<currentStep" class="order"></div>
      <div *ngIf="step.order===currentStep" class="orderActive"></div>
      <div *ngIf="step.order>currentStep" class="orderNext"></div>
  
      <div *ngIf="step.order<currentStep" class="label" (click)="setCurrentStepManual(step.order)">{{ step.label }}</div>
      <div *ngIf="step.order===currentStep" class="labelActive">{{ step.label }}</div>
      <div *ngIf="step.order>currentStep" class="labelNext">{{ step.label }}</div>  
    </li>
  </ul>