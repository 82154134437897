import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LoginService } from 'src/app/services/login.service';
import { PolicyService } from 'src/app/services/policy.service';
import { ScriptService } from 'src/app/services/script.service';
import { UserService } from 'src/app/services/user.service';
import { AccessPendingComponent } from 'src/app/shared/dialogs/access-pending/access-pending.component';

@Component({
  // templateUrl: './auth.component.html',
  selector: 'upsc-auth',
  templateUrl: './unified.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  tokenError: boolean;
  errorOccurred: boolean;
  idData: boolean = false;
  workflow: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private loginService: LoginService,
    private policyService: PolicyService,
    private scriptService: ScriptService,
    private dialog: MatDialog,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) {}

  ngOnInit() {
    let returnURL = "login";
    let queryParams = this.route.snapshot.queryParams;
    let urlFragment = this.route.snapshot.fragment;
    if(queryParams['returnUrl']) {
      sessionStorage.setItem('returnUrl', queryParams['returnUrl']),
      this.router.navigate([], {
        queryParams: {
          'returnUrl': null,
        },
        queryParamsHandling: 'merge'
      })
    }
    if (queryParams.UID && queryParams.page) {
      this.userService.getAutoAuthProfile(queryParams.UID).subscribe(
        profileData => {
          if (profileData) {
            this.idData = true;
            sessionStorage.setItem('access_token', profileData.accessToken);
            sessionStorage.setItem('userid', profileData.userId);
            delete profileData.accessToken;
            sessionStorage.setItem('userDetails', JSON.stringify(profileData));
            this.userService.setUserPermissions(profileData.permissions);
            if(profileData.userRoleID == '7') {
              this.workflow='DD';
              sessionStorage.setItem('DDFlow', queryParams.page);
            }
            this.userService.getUserInfo();
            // if (profileData.userRoleID !== '7') {
            sessionStorage.setItem('refresh_token', profileData.refreshToken);
            this.userService.getPolicySearch(profileData.policyNumber).subscribe(
              policyData => {
                if (policyData?.data && policyData?.message?.toLowerCase() == 'succeeded') {
                  sessionStorage.setItem('policyDetails', JSON.stringify(policyData.data));
                  returnURL = queryParams.page;
                  this.router.navigateByUrl(returnURL);
                } else {
                  sessionStorage.clear();
                  this.errorOccurred = true;
                }
              },
              error => {
                sessionStorage.clear();
                this.errorOccurred = true;
              }
            );
            // }
            // else {
            // if(profileData.policyNumber) {
            // sessionStorage.setItem('userDetails', JSON.stringify({...profileData, userId: profileData.policyNumber.split('-')[1]}));
            // sessionStorage.setItem('policyDetails', JSON.stringify({policyNumber: profileData.policyNumber, policyType: {value: ''}}));
            // returnURL = `/${queryParams.page}`;
            // sessionStorage.setItem('DDFlow', queryParams.page);
            // this.router.navigateByUrl(returnURL);
            // }
            // else {
            // this.errorOccurred = true;
            // }
            // }
          }
          else {
            this.tokenError = true;
            this.errorOccurred = true;
          }
        },
        err => {
          this.errorOccurred = true;
        }
      );
    }
    else if(urlFragment) {
      if(urlFragment.includes('id_token=')) {
        let token = urlFragment.split('=')[1];
        this.loginService.userDetails(token).subscribe(
          data => {
            sessionStorage.setItem('access_token', data.access_token);
            sessionStorage.setItem('refresh_token', data.refresh_token);
            this.userService.getUserInfo();
            this.router.navigateByUrl('/dashboard');
          },
          error => {
            this.errorOccurred = true;
            this.router.navigateByUrl(returnURL)
          }
        );
      }
      else if(urlFragment.includes('&code=')) {
        /* if(this.msalService.instance.getAllAccounts().length > 0) {
          this.decodeToken(this.msalService.instance.getAllAccounts()[0].idToken);
        }
        else { */
          this.msalBroadcastService.msalSubject$.pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
            takeUntil(this._destroying$)
          )
          .subscribe((result: EventMessage) => {
            console.log('Log in succeeded - ', result);
            sessionStorage.setItem('idToken', result.payload['idToken']);
            sessionStorage.setItem('refresh_token', result.payload['refreshToken']);
            this.decodeToken(result.payload['accessToken']);
          });
        // }
      }
      else if(urlFragment.includes('error=')) {
        this.errorOccurred = true;
      }
    }
    else {
      if(sessionStorage.getItem('access_token')) {
        this.decodeToken(sessionStorage.getItem('access_token'));
      }
      else {

          if (this.msalGuardConfig.authRequest){
            this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
          } else {
            this.msalService.loginRedirect();
          }
      }
    }
  }

  decodeToken (token) {
    sessionStorage.setItem('access_token', token)
    this.router.navigateByUrl('authorize');
    /* this.loginService.userDetails2(token).subscribe(
      data => {
        //deny access if user data is missing
        if(!data.policyNumber || !data.approved || !data.active || !data.permissions || data.permissions.length <= 0) {
          if (this.dialog.openDialogs.length == 0) {
            let dialogRef = this.dialog.open(AccessPendingComponent);
            dialogRef.afterClosed().subscribe(
              d => {
                this.onErrors();
              }
            );
          }
        }
        else {
          sessionStorage.setItem('access_token', token);

          if (data.policyNumber.search(/^\d+-p\d+$/) == 0) {
            data.policyNumber = data.policyNumber.toUpperCase();
          }
          this.userService.setUserInfo(data);
          sessionStorage.setItem('userid', data.userId);

          this.userService.setUserPermissions(data.permissions);

          this.userService.getPolicySearch(data.policyNumber).subscribe(
            policydata => {
              if(!policydata?.data) {
                this.errorOccurred = true;
              }
              else {
                sessionStorage.setItem('policyDetails', JSON.stringify(policydata.data));
                if (this.userService.isUserAdjuster()) {
                  this.router.navigateByUrl('/adjusterDashboard');
                }
                else if (policydata.data.policyType.key == 'Flex' && policydata.data.policyType.value == 'Flexible Parcel Insurance' && policydata.data.policySource == 'ISHIPFLEXMC') { // Tupss flex USPS policies check
                  this.router.navigateByUrl('/billing');
                }
                else if (policydata.data.ref1 == 'HK') {
                  let smartUploadURL = environment.smartUpload.url;
                  this.policyService.getSmartUploadUrl({
                    policyno: policydata.data.policyNumber,
                    username: this.userService.getUserInfo().firstName
                  }).subscribe(
                    data => {
                      if (data?.url && data.url != '') {
                        smartUploadURL = data.url;
                      }
                      window.location.href = smartUploadURL;
                    },
                    error => {
                      window.location.href = smartUploadURL;
                    }
                  );

                }
                else {
                  this.scriptService.pendoInit();
                  this.router.navigateByUrl(returnUrl);
                }
              }
            },
            error => this.errorOccurred = true
          )
        }
      },
      error => {
        this.errorOccurred = true;
      }
    ) */
  }

  private readonly _destroying$ = new Subject<void>();
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.msalService.instance.clearCache();
  }

  onErrors() {
    this.msalService.instance.clearCache();
    sessionStorage.clear();
    this.errorOccurred = true;
  }
}

