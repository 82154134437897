import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../../shared/components/layout/layout.component';
import { DashboardComponent } from './dashboard.component';
import { ClaimListComponent } from '../claims/components/list/claim-list.component';
import { AuthGuardService } from '../../core/services/auth-guard.service';
import { DashboardResolver } from 'src/app/resolvers/dashboard.resolver';

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      component: LayoutComponent,
      children:[
      {
      path: '',
          component: DashboardComponent,
          canActivate: [AuthGuardService],
          resolve: {DashboardData: DashboardResolver},
      children:[
        {
        path: '',
        component: ClaimListComponent,
      }]
    }]
}])],
 exports: [RouterModule]
})
export class DashboardRoutingModule { }
