import { Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { EMAIL_PATTERN } from 'src/app/shared/regex-patterns';
import { IVerificationInterface, emptyVerificationInterface } from 'src/app/shared/models/verification.interface';
import { ClaimService } from 'src/app/services/claim.service';
import { PolicyService } from 'src/app/services/policy.service';

@Component({
  selector: 'upsc-happy-returns-landing',
  templateUrl: './happy-returns-landing.component.html',
  styleUrls: ['./happy-returns-landing.component.scss']
})
export class HappyReturnsLandingComponent {

  public currentStep: number = 0;
  public loading: boolean = false;
  emailForm: UntypedFormGroup;
  confirmFormGroup: UntypedFormGroup;
  systemError: boolean = false;
  verifyCodeError: boolean = false;
  public loading2: boolean = false;
  public createVerificationInterface: IVerificationInterface = emptyVerificationInterface;
  verifyCodeInterface = { emailId: "", toVerify: "" };
  public emailAddress: string = '';
  public policyNumber: string = '';
  public groupCode: string = '';


  @ViewChild("name1") field1: ElementRef;
  @ViewChild("name2") field2: ElementRef;
  @ViewChild("name3") field3: ElementRef;
  @ViewChild("name4") field4: ElementRef;
  @ViewChild("name5") field5: ElementRef;
  @ViewChild("name6") field6: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private fb: UntypedFormBuilder,
    private router: Router,
    private userService: UserService,
    private claimService: ClaimService,
    private policyService: PolicyService
  ) {

  }


  ngOnInit(): void {
    this.userService.setLocale('US');
    this.titleService.setTitle(`CCP Consumer Portal | Happy Returns`);
    this.emailForm = this.fb.group({
      emailAddress: ['', Validators.compose([Validators.pattern(EMAIL_PATTERN), Validators.required])],
      recaptchaControl: [null, Validators.required]
      //terms: [false, Validators.requiredTrue]
    });
  }

  getStarted() {

    if (this.emailForm.valid) {
      this.saveVerificationCode();
    }
  }


  saveVerificationCode() {
    this.createVerificationInterface.data.emailAddress = this.email.value;
    this.createVerificationInterface.emailFor = "CCPVerificationEmail";
    this.claimService.sendCCPVerificationCodeEmail(this.createVerificationInterface).subscribe(
      data => {
        if ((data.responseMessage).toLowerCase() === 'new verification code created' ||
          (data.responseMessage).toLowerCase() === 'code not yet expired') {
          this.loading = false;
          this.emailAddress = this.email.value;
          this.currentStep = 1;
        }
      }, error => {
        this.loading = false;
        this.systemError = true;
      }
    );
  }

  get email() { return this.emailForm.controls.emailAddress }

  verifyResponse($event) {
    if ($event.isVerified && $event.message === 'verification code matched') {
      let request = {
        emailAddress: this.email.value
      };
      this.policyService.getPolicyGroupCodeDetails(request).subscribe(response => {
        if (response && response.data && response.data.policyNumber !== null) {
          sessionStorage.setItem('happyReturnsInfo',
            JSON.stringify({
              email: this.email.value,
              policyNumber: response.data.policyNumber,
              groupCode: response.data.groupCode,
              partnerName: 'happy-returns'
            }));
          this.policyNumber = response.data.policyNumber;
          this.groupCode = response.data.groupCode;
          this.policySearch(this.policyNumber);
        }/* else {
          sessionStorage.setItem('happyReturnsInfo',
            JSON.stringify({
              email: this.email.value,
              policyNumber: '15914024-P0017044',
              groupCode: 'SHEIN',
              partnerName: 'happy-returns'
            }));
          this.policyNumber = '15914024-P0017044';
          this.groupCode = 'SHEIN';
          this.policySearch(this.policyNumber);
        }*/        
      });
    } else if (!$event.isVerified && $event.message === 'cancel') {
      this.currentStep = 0;
    }
  }


  policySearch(policyNumber: any) {
    this.userService.getPolicySearch(policyNumber).subscribe(
      policyData => {
        if (policyData?.message?.toLowerCase() != 'succeeded') {
          this.currentStep = 0;
          this.systemError = true;
          return;
        }
        //Continue
        let policy = policyData.data;
        this.setUserPermissions(policyData.data);
      }, error => {
        //System error
        this.currentStep = 0;
        this.systemError = true;
      }
    );
  }

  setUserPermissions(policy: any) {
    let permissions: any[] = [];
    permissions.push('ClaimFile');
    permissions.push('HVCM');
    this.userService.setUserPermissions(permissions);
    sessionStorage.setItem('policyDetails', JSON.stringify(policy));
    this.userService.updateUserInfo({ policyNumber: this.policyNumber, isClaimsActive: true }); //Stored this so that user could continue to claim detail page
    this.router.navigate([`/happy-returns/claims`]);
  }

  cancel() {
    this.loading2 = false;
    this.verifyCodeError = false;
    this.confirmFormGroup.reset();
    this.currentStep = 0;
  }


}
