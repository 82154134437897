<div class="header-container">
    <div class="table-header-container">
        <table class="tbl-header">
            <tr>
                <th>Feature</th>
                <th>Basic</th>
                <th>Starter</th>
                <th>Pro</th>
                <!-- <th>Premium</th> -->
            </tr>
        </table>
    </div>
</div>
<div class="row-container">
    <div class="table-row-container">
        <table class="tbl-header">
            <tr>
                <td>Tracking Data</td>
                <td><span class="top-align">UPS, USPS, DHL, and FedEx</span></td>
                <td><span class="top-align">Small Package Carriers</span></td>
                <td><span class="top-align">Small Package Carriers</span></td>
                <!-- <td><span class="top-align">Small Package Carriers;<br>Multi-Model Carriers</span></td> -->
            </tr>
            <tr>
                <td>Carrier Billing Data</td>
                <td><span class="icon-normal"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-normal"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-normal"><mat-icon>close</mat-icon></span></td>
                <!-- <td><span class="icon-circle-normal"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td>Volume Thresholds</td>
                <td><span class="top-align">No Limit</span></td>
                <td><span class="top-align">Up to 5,000 shipments per month</span></td>
                <td><span class="top-align">No Limit</span></td>
                <!-- <td><span class="top-align">No Limit</span></td> -->
            </tr>
            <tr>
                <td>Number of Users</td>
                <td><span class="top-align">2</span></td>
                <td><span class="top-align">5</span></td>
                <td><span class="top-align">10</span></td>
                <!-- <td><span class="top-align">No Limit</span></td> -->
            </tr>
            <tr>
                <td colspan="5">Key Dashboards</td>
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Stale Shipments - No activity in 48 hours</span>
                    </span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Delayed Shipments - Last 60 days</span>
                    </span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Exception Distribution - Last 18 weeks</span>
                    </span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Damaged Trend - Last 120 days</span>
                    </span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Carrier Pick Up Trend</span>
                    </span></td>
                <td><span class="icon"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Scheduled Delivery Analysis</span>
                    </span></td>
                <td><span class="icon"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Specific Reference List</span>
                    </span></td>
                <td><span class="icon"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Exception Type Account Analysis</span>
                    </span></td>
                <td><span class="icon"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Manifest Time Period Analysis</span>
                    </span></td>
                <td><span class="icon"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Carrier Billing Overview</span>
                    </span></td>
                <td><span class="icon"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Billed Account Breakdown</span>
                    </span></td>
                <td><span class="icon"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Address Correction Analysis</span>
                    </span></td>
                <td><span class="icon"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Spend by Period Overview</span>
                    </span></td>
                <td><span class="icon"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td><span class="right-align">
                        <span class="sub-text">Invoice List</span>
                    </span></td>
                <td><span class="icon"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td>
                <!-- <td><span class="icon-circle"><mat-icon>check_circle</mat-icon></span></td> -->
            </tr>
            <tr>
                <td>Data Export Limit</td>
                <td><span class="icon-normal"><mat-icon>close</mat-icon></span></td>
                <td><span class="top-align">250,000</span></td>
                <td><span class="top-align">250,000</span></td>
                <!-- <td><span class="top-align">250,000</span></td> -->
            </tr>
            <tr>
                <td>Proof Of Delivery Retention</td>
                <td><span class="icon-normal"><mat-icon>close</mat-icon></span></td>
                <td><span class="top-align">No Limit</span></td>
                <td><span class="top-align">No Limit</span></td>
                <!-- <td><span class="top-align">No Limit</span></td> -->
            </tr>
            <tr>
                <td>Schedule Data Export</td>
                <td><span class="icon-normal"><mat-icon>close</mat-icon></span></td>
                <td><span class="top-align">Cloud or Email Export</span></td>
                <td><span class="top-align">Cloud or Email Export</span></td>
                <!-- <td><span class="top-align">Cloud or Email Export</span></td> -->
            </tr>
            <tr>
                <td>Internal Document Scans</td>
                <td><span class="icon-normal"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-normal"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-normal"><mat-icon>close</mat-icon></span></td>
                <!-- <td><span class="top-align">Available to Store</span></td> -->
            </tr>
            <tr>
                <td>Data Export Limit</td>
                <td><span class="icon-normal"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-normal"><mat-icon>close</mat-icon></span></td>
                <td><span class="icon-normal"><mat-icon>close</mat-icon></span></td>
                <!-- <td><span class="top-align">Available to Store</span></td> -->
            </tr>
            <tr>
                <td>UPS Delivery Intercept</td>
                <td><span class="icon-normal"><mat-icon>close</mat-icon></span></td>
                <td><span class="top-align">5 Intercepts</span></td>
                <td><span class="top-align">10 Intercepts</span></td>
                <!-- <td><span class="top-align">Customizable</span></td> -->
            </tr>
        </table>
    </div>
</div>

