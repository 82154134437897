import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';
import { switchMap } from 'rxjs/operators';
import { PolicyService } from 'src/app/services/policy.service';
import { DDOnBoardingService } from 'src/app/services/dd-onboarding.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'upsc-visibility-popup',
  templateUrl: './visibility-popup.component.html',
  styleUrls: ['./visibility-popup.component.scss']
})
export class VisibilityPopupComponent implements OnInit {
  configData;
  constructor(public dialogRef: MatDialogRef<VisibilityPopupComponent>,
    private userService: UserService,
    private loginService: LoginService,
    private policyService: PolicyService,
    private ddOnBoardingService: DDOnBoardingService,) {
    this.policyDetails = this.loginService.getPolicyDetails();
  }

  policyDetails;
  visibilitySettings;
  public isShowFeatures: boolean = false;
  public createPlan: boolean = false;
  public updatePlan: boolean = false;
  public changed: boolean = false;
  public planIsInprogress: boolean = false;
  planType: string = '';
  planAmount: string = '';
  userId: string = '';
  public pageLoading: boolean = false;
  public systemError: boolean = false;
  userPolicy: string = '';
  users: any = [];
  contaxId: string = 'silcustomer';
  public contaxOnBoarding: any = {};
  policy: any = {};
  showBannerOnVisibilityPageOnly: boolean = false;

  errorPage: boolean = false;
  loading: boolean = false;
  termsAndConditionsIsChecked: boolean = false;
  ngOnInit(): void {
    this.userPolicy = this.userService.getUserInfo()?.policyNumber;
    this.visibilitySettings = JSON.parse(sessionStorage.getItem('visibilitySettings'));
    if (this.visibilitySettings.action == "inProgress") {
      this.planIsInprogress = true;
    }
    else if (this.visibilitySettings.action == "CreatePlan") {
      this.createPlan = true;
    } else { this.updatePlan = true; }
    if (this.visibilitySettings.subscription == "starter") {
      this.planType = "Starter";
      this.planAmount = "249";
    }
    else if (this.visibilitySettings.subscription == "pro") {
      this.planType = "Pro";
      this.planAmount = "399";
    }
    else {
      this.planType = "Basic";
      this.planAmount = "0";
    }
  }

  // getUserInfo() {
  //   this.pageLoading = true;
  //   this.systemError = false;
  //   let payload = {
  //     policyNumber: this.userPolicy,
  //     userIDs: [this.userId]
  //   }
  //   this.users = [];
  //   let user: any = {};
  //   this.userService.getUserInfoListForPolicy(payload).subscribe(
  //     data => {
  //       if (data !== null && data && data.data && data.data.users.length > 0) {
  //         data.data.users.forEach(element => {
  //           user.email = element.emailAddress;
  //           try {
  //             var name = element.contactName.split(" ");
  //             if (name) {
  //               user.firstName = name[0];
  //               user.lastName = name[1];
  //             }
  //           } catch {
  //             user.firstName = element.contactName;
  //           }
  //           this.users.push(user);
  //         });
  //         this.contaxOnBoarding.users = this.users;
  //         this.contaxOnBoarding.id = this.contaxId;
  //         this.pageLoading = false;
  //       } else {
  //         //check for not allowed
  //         this.pageLoading = false;
  //         this.systemError = true;
  //         this.contaxOnBoarding.id = this.contaxId;
  //         this.contaxOnBoarding.users = [];
  //       }
  //     },
  //     error => {
  //       this.pageLoading = false;
  //       this.systemError = true;
  //     }
  //   );
  // }

  // @Output() refreshWithdrawStatus = new EventEmitter<any>();

  public close() {
    this.dialogRef.close();
  }

  public createChangePlan() {
    if (this.visibilitySettings.action == "changePlan") {
      this.updateSubscription();
    }
    else {
      this.createSubscription();
    }
    this.close();
  }

  // signUp() {
  //   this.loading = true;
  //   this.systemError = false;
  //   let request = {
  //     policyNumber: this.policyDetails?.policyNumber,
  //     action: "create"
  //   }
  //   //get existing admins and pass them for action 'create'
  //   let adminUsers = [];
  //   this.userService.getUserAdminCount({
  //     policyNumber: this.policyDetails?.policyNumber
  //   }).pipe(
  //     switchMap(resp => {
  //       if (resp.data?.adminDetails?.length > 0) {
  //         resp.data.adminDetails.forEach(admin => {
  //           let names = admin.contactName.split(/\s+/);
  //           adminUsers.push({
  //             email: admin.adminEmail,
  //             firstName: names[0],
  //             lastName: names[names.length - 1]
  //           });
  //         });
  //       }
  //       return this.policyService.ddVisibilityContract({ ...request, users: adminUsers });
  //     })
  //   ).subscribe(
  //     // this.policyService.ddVisibilityContract(request).subscribe(
  //     data => {
  //       this.loading = false;
  //       // Scenario #1: A contractNumber is returned, which means we were able to sign the user up for the product, show 24 hour wait message
  //       // Scenario #2: No contractNumber is returned, something failed so show error message.
  //       if (data?.contractNo) {
  //         //this.policyService.ddVisibilityEmail(adminUsers);
  //       } else {
  //         this.systemError = true;
  //       }
  //       // Scenario #3: API timed out or failed completely, show error message.
  //     }, error => {
  //       this.loading = false;
  //       this.systemError = true;
  //     }
  //   );
  // }

  createSubscription() {
    this.pageLoading = true;
    this.systemError = false;
    this.users = [
      // {
      //   email: "jeff@contax.com",
      //   firstName: "Jeff",
      //   lastName: "Smith"
      // },
      // {
      //   email: "jane@contax.com",
      //   firstName: "Jane",
      //   lastName: "Clark"
      // },
    ];

    let payload = {
      policyNumber: this.userPolicy,
      action: "create",
      subscription: this.visibilitySettings.subscription,
      users: this.users,
    }
    // let payload2 = {
    //   id: "silcustomer",
    //   name: "SIL Inc",
    //   subscriptionPlan: this.visibilitySettings.subscription.toUpperCase(),
    //   users: this.users,
    // }
    // this.ddOnBoardingService.contaxOnBoarding(payload2).subscribe(
    //   data => {
    //     if (data !== null && data) {
    //       this.pageLoading = false;
    //     }
    //   },
    //   error => {
    //     this.pageLoading = false;
    //     this.systemError = true;
    //   }
    // );

    this.policyService.ddVisibilityByAction(payload).subscribe(
      data => {
        if (data?.customerNo) {
          let subscription = data?.subscription;
          console.log("The subscription Plan:", subscription);
        }
      }, error => {
        this.pageLoading = false;
        this.systemError = true;
      }
    );
  }

  updateSubscription() {
    this.pageLoading = true;
    this.systemError = false;
    let existingdetails = JSON.parse(sessionStorage.getItem('existingPlanDetails'));
    let payload1 = {
      policyNumber: this.userPolicy,
      contractID: existingdetails?.contractNo,
      action: "update",
      subscription: this.visibilitySettings.subscription
    }
    // let payload2 = {
    //   subscriptionPlan: this.visibilitySettings.subscription
    // }

    this.policyService.ddVisibilityByAction(payload1).subscribe(
      data => {
        if (data?.statusCode == "200" && data?.message == "Subscription update successful") {
          this.pageLoading = false;
          this.changed = true;
          // this.ddOnBoardingService.contaxUpdateSubscription(payload2, existingdetails?.customerNo).subscribe(
          //   data => {
          //     if (data && data?.status !== "ACTIVE") {
          //       this.pageLoading = false;
          //       this.changed = true;
          //     }
          //   },
          //   error => {
          //     this.pageLoading = false;
          //     this.systemError = true;
          //   }
          // );

        }
      }, error => {
        this.pageLoading = false;
        this.systemError = true;
      }
    );
  }
}
