import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { ClaimService } from 'src/app/services/claim.service';
import { emptyIEmail, IEmail } from 'src/app/shared/models/email.interface';
import { EMAIL_PATTERN } from 'src/app/shared/regex-patterns';
import { TupssService } from '../../../services/tupss.service';
import { IVerificationInterface, emptyVerificationInterface } from 'src/app/shared/models/verification.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'upsc-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  @Output() nextEvent = new EventEmitter<any>();

  franchiseForm: UntypedFormGroup;
  customerForm: UntypedFormGroup;
  showEmailVerification: boolean;
  codeErrorReturned: boolean;
  private emailModel: IEmail = emptyIEmail;
  currentlyCheckingDupeTrackingNumbers = false;
  systemError: boolean;
  verificationInterface = { emailId: "" };
  tupssType;
  loading: boolean = false;
  errors: string[] = [];
  claimSubmittedAlready;

  public createVerificationInterface: IVerificationInterface = emptyVerificationInterface;
  verifyCodeInterface = { emailId: "", toVerify: ""};

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private accountService: AccountService,
    private claimService: ClaimService,
    private tupssService: TupssService
  ) { }

  ngOnInit(): void {
    this.tupssType = this.router.url.includes('tfcp') ? 'tfcp' : 'tccp';
    sessionStorage.removeItem('policyDetails'); //reset the data for tccp. guarantees new contractID and policy search apis
    this.tupssService.userData = undefined; //reset the data for tccp. guarantees new contractID and policy search apis
    sessionStorage.setItem('userDetails', JSON.stringify({ type: this.tupssType, stage: 'pre' }));

    this.claimSubmittedAlready = this.tupssService._claimSubmittedFor ? true : false;
    if (this.claimSubmittedAlready) {
      this.tupssService.claimSubmittedFor = null;
    }

    this.customerForm = this.fb.group({
      trackingNumber: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z\-0-9]+$/i)])],
      email: ['', Validators.compose([Validators.pattern(EMAIL_PATTERN)])],
      shipmentId: ['',],
      transactionId: ['',],
      recaptchaControl: [null, Validators.required]
    }, { validator: this.atLeastOneRequired });

    if (this.tupssType == 'tccp') {
      this.email.setValidators([Validators.required, Validators.pattern(EMAIL_PATTERN)]);
    } else {
      this.email.setValidators([Validators.pattern(EMAIL_PATTERN)]);
    }

    this.customerForm.controls.shipmentId.valueChanges.subscribe(
      data => {
        this.customerForm.controls.transactionId.setErrors(null);
        this.customerForm.controls.shipmentId.setErrors(null);
      }
    );

    this.customerForm.controls.transactionId.valueChanges.subscribe(
      data => {
        this.customerForm.controls.transactionId.setErrors(null);
        this.customerForm.controls.shipmentId.setErrors(null);
      }
    );
  }

  openFAQs(event) {
    event.preventDefault();
    this.tupssService.openFaqsQ14();
  }

  atLeastOneRequired(group: UntypedFormGroup): { [s: string]: boolean } {
    if (group) {
      if (group.controls['shipmentId'].value || group.controls['transactionId'].value) {
        return null;
      }
    }
    return { 'error': true };
  }

  //Check for available tracking number information
  getShipmentDetails() {
    //Don't call any API's if the field is not valid
    if (!this.customerForm.get('trackingNumber').valid) {
      return;
    }
    let trackingNumber = this.customerForm.get('trackingNumber').value.toUpperCase();
    this.currentlyCheckingDupeTrackingNumbers = true;

    //Check if the tracking number has been used to file a claim previously
    this.claimService.checkDuplicateClaims(trackingNumber).subscribe(
      data => {
        this.currentlyCheckingDupeTrackingNumbers = false;
        if (data.IsDuplicateTrackingNumber) {
          this.customerForm.get('trackingNumber').setErrors({ 'inuse': true });
          this.errors.push('claims.edit.tupssErrors.existingClaim');
        } else {
          this.errors = this.errors.filter(x => x !== 'claims.edit.tupssErrors.existingClaim'); //removes existing duplicate error if any
          if (this.customerForm.get('trackingNumber').hasError('pattern')) {
            this.customerForm.get('trackingNumber').setErrors(null);
            this.customerForm.get('trackingNumber').setErrors({ 'pattern': true });
          } else if (this.customerForm.get('trackingNumber').hasError('required')) {
            this.customerForm.get('trackingNumber').setErrors(null);
            this.customerForm.get('trackingNumber').setErrors({ 'required': true });
          } else {
            this.customerForm.get('trackingNumber').setErrors(null);
          }
        }
      }, error => {
        this.currentlyCheckingDupeTrackingNumbers = false;
      }
    );
  }

  processLinks(event) {
    const element: HTMLElement = event.target;
    if (this.errors.includes('claims.edit.tupssErrors.existingClaim')) {
      if (element.nodeName === 'A') {
        event.preventDefault();
        this.tupssService.openFaqs();
      }
    }
  }
  nextStep() {
    this.loading = true;
    this.systemError = false;
    this.errors = [];
    if (this.trackingNumber.value.toUpperCase().startsWith('1Z') && this.trackingNumber.value.length != 18) {
      this.errors.push('claims.edit.tupssErrors.invalidTrackingNo');
      this.loading = false;
      return;
    }

    if (this.customerForm.controls['email'].invalid) {
      this.errors.push('claims.edit.tupssErrors.invalidEmail');
      this.loading = false;
      return;
    } //added this error since it's in the flow, but seemingly impossible to ever reach due to FormControl
    if (this.customerForm.valid) {
      let tupssRelatedInfo: any = {
        trackingNumber: this.trackingNumber.value.toUpperCase(),
        email: this.email.value,
        shipmentID: this.shipmentID.value,
        transactionID: this.transactionID.value,
        type: this.tupssType
      }
      let payload;
      if (tupssRelatedInfo.type == 'tfcp') {
        payload = {
          transactionSrc: 'Capital_TFCP',
          trackNo: this.trackingNumber.value.toUpperCase(),
          emailAddress: this.email.value,
          posReceiptID: this.transactionID.value, //transID got changed to posReceiptID
        }
      } else {
        payload = {
          transactionSrc: 'Capital_TCCP',
          trackNo: this.trackingNumber.value.toUpperCase(),
          emailAddress: this.email.value,
          posReceiptID: this.transactionID.value,
          shipmentId: this.shipmentID.value
        }
      }
      tupssRelatedInfo = {
        ...tupssRelatedInfo,
        type: payload.transactionSrc == 'Capital_TCCP' ? 'tccp' : 'tfcp',
        stage: 'pre'
      }
      this.tupssService.userDetails = tupssRelatedInfo;
      this.tupssService.getIShipData(payload).subscribe(
        data => {
          /* if (payload.transactionSrc == 'Capital_TCCP') {
            this.tupssService.setSiteId(data.data.storeNumber);//Need to use this for getContractID()
          } */
          this.loading = false;
          if (data && data.message == 'Success') {
            if (!data.data.storeNumber || !data.data.companyName) {
              this.systemError = true;
            } else {
              this.systemError = false;
              this.tupssService.getContractId(data.data.storeNumber, '', data.data.shipDate).subscribe(
                res => {
                  if (res && res.contractIDResponse) {
                    //This tracking number is returning with more than 1 policy.
                    //If TN was 1Z, use UPSSTORE programtype.
                    //If TN was NOT 1Z, use FLEX programtype.
                    let applicableContract = this.trackingNumber.value.toUpperCase().startsWith('1Z') ? res.contractIDResponse.find(cd => cd && cd.programType == 'UPSSTORES') : res.contractIDResponse.find(cd => cd && cd.programType == 'FLEX');
                    if (applicableContract) {
                      //hardcoded for UAT testing - its fine to remove this later
                      if (environment.baseUrl.urlType == 'uat') {
                        applicableContract.contractID = '15914024-P0001027';
                      }
                      this.tupssService.setContractId(applicableContract.contractID)
                      this.saveVerificationCode(data);
                    }
                    else {
                      this.errors.push('claims.edit.tupssErrors.notEligible');
                      this.loading = false;
                    }
                  }
                  else {
                    this.systemError = true;
                    this.loading = false;
                  }
                },
                error => {
                  this.systemError = true;
                  this.loading = false;
                }
              )
            }
          }
          else if (data && data.message == 'NotFound' && !this.trackingNumber.value.toUpperCase().startsWith('1Z')) {
            this.errors.push('claims.edit.tupssErrors.notEligible');
          }
          else if (data && data.message == 'NotFound') {
            this.errors.push('claims.edit.tupssErrors.notCreatedFromTupss');
          }
          else if (data && data.message == 'ValidationError') { //Currently checkShipment returns 400 bad request error, so this is unreachable until API gets fixed to display 200 OK w/"ValidationError"
            this.errors.push('claims.edit.tupssErrors.oneIDRequired');
            this.customerForm.get('shipmentId').setErrors({ 'pattern': true });
            this.customerForm.get('transactionId').setErrors({ 'pattern': true });
          }
          else if (data && data.message == 'BillRecipient') {
            this.errors.push('claims.edit.tupssErrors.billRecipientTCCP');
          }
          else if (data && data.message == 'BillThirdParty') {
            this.errors.push('claims.edit.tupssErrors.billThirdPartyTCCP');
          }
          else if (data && data.message == 'BMAUPS') {
            this.errors.push('claims.edit.tupssErrors.billMyAccountTCCP');
          }
          else if (data && data.message == 'DateExceeded') {
            this.errors.push('claims.edit.tupssErrors.periodPassedTCCP');
          }
          else {
            this.errors.push('common.systemError');
          }
        }, error => {
          this.loading = false;
          this.systemError = true;
        }
      );
    }
  }

  closeErrorMsg() {
    this.errors = [];
  }
  public saveVerificationCode(data2?: any) {
    // this.verificationInterface.emailId = this.customerForm.get('email').value;
    this.createVerificationInterface.data.emailAddress = this.customerForm.get('email').value;
    this.accountService.createVerificationCode(this.createVerificationInterface).subscribe(
      data => {
        //     this.accountService.getVerificationCode(this.verificationInterface).subscribe(
        //       data1 => {
        //         if (data1.verificationCode) {
        //           this.sendVerificationCode(data2, data1.verificationCode, event); //change to data1.verificationCode
        //         } else {
        //           //There was no verification code, need an error message
        //           this.loading = false;
        //           this.systemError = true;
        //         }
        //       }, error => {
        //         //Add error message here
        //         this.loading = false;
        //         this.systemError = true;
        //       }
        //     );
        //   }, error => {
        //     //Add error message here
        //     this.loading = false;
        //     this.systemError = true;
        //   }
        // );
        if((data.responseMessage).toLowerCase() === 'new verification code created' || (data.responseMessage).toLowerCase() === 'code not yet expired') {
          //this.sendVerificationCode(data2);
          this.showEmailVerification = true;
        }
    }, error => {
      //Add error message here
      this.loading = false;
      this.systemError = true;
      this.showEmailVerification = false;
    }
  );
}

  //Send an email to user-entered email
  public sendVerificationCode(data2?: any) {
    this.verificationInterface.emailId = this.customerForm.get('email').value;
    let fullAddress = data2.data.address1 + ", " + data2.data.address2;
    let lastName = data2.data.name?.slice(data2.data.name.indexOf(' ') + 1);
    let firstName = data2.data.name?.slice(0, data2.data.name.indexOf(' '));
    this.emailModel = {
      ...this.emailModel,
      data: {
        email: this.verificationInterface.emailId,
        // verificationCode: code,
        companyName: data2.data.companyName,
        streetAddress: fullAddress,
        city: data2.data.city,
        state: data2.data.state,
        zip: data2.data.zip,
        firstName: firstName,
        lastName: lastName,
      },
      emailFor: "TCCPClaimVerification",
      CountryLocale: sessionStorage.getItem('locale'),
    }




    this.accountService.sendTupssTccpVerification(this.emailModel).subscribe(
      data => {
        this.loading = false;
        if (data == true) {
          this.showEmailVerification = true;
        }
      }, error => {
        this.loading = false;
        this.showEmailVerification = false;
      }
    );
  }

  cancel() {
    this.showEmailVerification = false;
  }

  get email() { return this.customerForm.get('email'); }
  get trackingNumber() { return this.customerForm.get('trackingNumber'); }
  get shipmentID() { return this.customerForm.get('shipmentId'); }
  get transactionID() { return this.customerForm.get('transactionId'); }
}
