import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CssThemesService {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  loadStyle() {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = 'tupss.css';
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = 'tupss.css';
      head.appendChild(style);
    }
  }

  loadMyChoiceStyle() {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = 'my-choice.css';
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = 'my-choice.css';
      head.appendChild(style);
    }
  }

  loadCustomizedClaimsStyle(flowType: string) {
    const head = this.document.getElementsByTagName('head')[0];
    let themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = 'customized-claims-portal.css';
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet'; 
      if (flowType === 'customized-claims-portal') {
        style.href = 'customized-claims-portal.css';
      } else if (flowType === 'ajg') {
        style.href = 'ajg.css';
      } else if (flowType === 'inxpress') {
        style.href = 'inxpress.css';
      } else if (flowType === 'pirateship') {
        style.href = 'pirateship.css';
      } 
      else {
        style.href = 'dynamic.css';
      }   
       head.appendChild(style);
    }
  } 
  
  loadSspStyle() {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.id = 'client-theme';
    style.rel = 'stylesheet'; 
    style.href = 'self-service-portal.css';
    head.appendChild(style);
  }

  loadHappyReturnsStyle() {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.id = 'client-theme';
    style.rel = 'stylesheet'; 
    style.href = 'happy-returns.css';
    head.appendChild(style);
  }
}
