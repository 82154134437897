<div class="banner">
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
  <div class="d-flex flex-column">
    <div class="title pb-3">
      <span *ngIf="createPlan">Select {{planType}}</span>
      <span *ngIf="updatePlan">Change to {{planType}} </span>
      <span *ngIf="planIsInprogress">Data Not Processed Yet </span>
      <span *ngIf="changed">Plan Changed to {{planType}}</span>
    </div>
    <div class="green-bar"></div>
    <div *ngIf="createPlan">By Selecting {{planType}} you agree to be billed ${{planAmount}} per month.
      This invoice will be sent<br /> to you at the end of the billing period.
      Please allow 24 hours to process your tracking data.<br /> We will email you when the tool is ready to use!</div>
    <div class="subtext" *ngIf="planIsInprogress">Please allow 24 hours to process your tracking data. We will email you
      when the tool is ready to use!</div>
    <div *ngIf="updatePlan">Would you like to change your plan to {{planType}}? Your features will be automatically
      updated. Your bill will reflect this change in the next billing cycle.</div>
    <div *ngIf="changed">Your plan has now switched to Starter! Your features have been automatically updated. Your bill
      will reflect this change in the next billing cycle.</div>
    <div *ngIf="!planIsInprogress" class="small-text"><br /> By continuing, you state that you have read and agree to
      the <a class="upsc-link"
        href="../../../../assets/docs/UPS Capital - DeliveryDefense Visibility - Terms and Conditions.pdf"
        target="_blank">DeliveryDefense<sup>™</sup> Visibility Terms & Conditions</a>, including the limitations imposed
      with respect to my use of data provided by UPS Capital pursuant to this Agreement,particularly those set out in
      Section [5,3]
    </div>
    <div class="buttons" *ngIf="!planIsInprogress">
      <button class="upsc-button" (click)="createChangePlan()">
        <span *ngIf="createPlan">Select {{planType}}</span>
        <span *ngIf="updatePlan">Change to {{planType}}</span>
        <mat-icon>keyboard_arrow_right</mat-icon></button>
      <button class="upsc-white-button" (click)="close()">Cancel <mat-icon>keyboard_arrow_right</mat-icon></button>
    </div>
    <div class="buttons" *ngIf="planIsInprogress || changed">
      <button class="upsc-button" (click)="close()">Close
        <mat-icon>keyboard_arrow_right</mat-icon></button>
    </div>
    <div class="error" *ngIf="systemError">
      {{'deliveryDefense.errorPopup.text1' | translate}}
      <a href="mailto:DeliveryDefenseCustomerService@ups.com">
        {{'deliveryDefense.errorPopup.text2' | translate}}
      </a>
      {{'deliveryDefense.errorPopup.text3' | translate}}
    </div>

  </div>
</div>