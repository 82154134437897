import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HappyReturnsService } from 'src/app/services/happy-returns.service';
import { LoginService } from 'src/app/services/login.service';
import { environment as ENV } from '../../../../environments/environment';
import { UserService } from 'src/app/services/user.service';
import { ClaimService } from 'src/app/services/claim.service';

@Component({
  selector: 'upsc-hr-new-claim',
  templateUrl: './hr-new-claim.component.html',
  styleUrls: ['./hr-new-claim.component.scss']
})
export class HrNewClaimComponent {

  public systemError: boolean = false;
  public hvcmFlow: boolean = false;
  policy: any = {};
  userDetails: any = {};

  placeholderPayee: any = '';
  placeholderStatus: any = '';
  placeholderClaimNum: any;
  placeholderPolicyNum: any;

  constructor(private breakpointObserver: BreakpointObserver,
    private router: Router, private loginService: LoginService,
    private hpService: HappyReturnsService,
    private userService: UserService,
    private claimService: ClaimService,
    private route: ActivatedRoute,
  ) {
    this.policy = this.loginService.getPolicyDetails();
    this.userDetails = this.userService.getUserInfo();   
  }

  openHVCM(policyNumber?: any) {
    
    let happyReturnsInfo = this.hpService.getHappyReturnsInfo();

    this.userService.openHVCM(
      {
        policyNo: policyNumber,
        username: this.userService.userInfo.firstName,
        firstName: this.userService.userInfo.firstName,
        lastName: this.userService.userInfo.lastName,
        emailAddress: this.userService.userInfo.emailAddress,
        contactPhone: this.userService.userInfo.contactPhone,
        uniqEmailId: happyReturnsInfo.email,
        groupCode: happyReturnsInfo.groupCode,
        userDetails: {
          accountNumber: this.policy.accountNumber,
          userId: '',
          policyHolderName: this.policy.insured,
          locale: sessionStorage.getItem('locale')
        }
      }
    )
  }

  get isMobile() {
      return this.breakpointObserver.isMatched('(max-width: 767px)');
    }
  

  goToMultiClaim() {
      this.hvcmFlow = true;
      sessionStorage.setItem('isHvcm', JSON.stringify('true'));
      sessionStorage.setItem('policyNumber', this.userDetails.policyNumber);
      sessionStorage.setItem('hvcmTabOpened', JSON.stringify("true"));
      this.openHVCM(this.userDetails.policyNumber);
    } 



  getStarted() {
    this.router.navigate([`/happy-returns/claims/file-claim`]);
  }

}
