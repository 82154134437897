import { Component } from '@angular/core';

@Component({
  selector: 'upsc-happy-returns-layout',
  templateUrl: './happy-returns-layout.component.html',
  styleUrls: ['./happy-returns-layout.component.scss']
})
export class HappyReturnsLayoutComponent {

}
