import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePlanComponent } from './change-plan/change-plan.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { DDVisibilityRoutingModule } from './dd-visibility-routing.module';
import { PlansFeaturesComponent } from './plans-features/plans-features.component';
import { VisibilityLayoutModule } from 'src/app/shared/components/visibility-layout/visibility-layout.module';
import { VisibilityPopupComponent } from './dialog/visibility-popup/visibility-popup.component';


@NgModule({
  declarations: [
    ChangePlanComponent,
    PlansFeaturesComponent,
    VisibilityPopupComponent
  ],
  imports: [
    CommonModule,
    DDVisibilityRoutingModule,
    CommonModule,
    SharedModule,
    VisibilityLayoutModule,
    MatCheckboxModule,
    MatSlideToggleModule
  ], 
  exports: [PlansFeaturesComponent]
})
export class DdVisibilityModule { }
