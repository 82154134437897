import { Component } from '@angular/core';

@Component({
  selector: 'upsc-visibility-layout',
  templateUrl: './visibility-layout.component.html',
  styleUrls: ['./visibility-layout.component.scss']
})
export class VisibilityLayoutComponent {

}
