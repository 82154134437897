import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { debounceTime, startWith, map } from 'rxjs/operators';
import { ConfirmCancelComponent } from 'src/app/pages/claims/components/confirm-cancel/confirm-cancel.component';
import { DialogContentComponent } from 'src/app/pages/claims/components/dialog-content/dialog-content.component';
import { ClaimService } from 'src/app/services/claim.service';
import { LoginService } from 'src/app/services/login.service';
import { PayeeService } from 'src/app/services/payee.service';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ErrordialogComponent } from 'src/app/shared/components/errordialog/errordialog.component';
import { IStep } from 'src/app/shared/components/stepper/models/step.interface';
import { AccountInfo } from 'src/app/shared/models/account-info.interface';
import { IClaim, emptyClaim, IDocument, IContact, IDocumentDisplay, ISummary, ICategory } from 'src/app/shared/models/claim.interface';
import { emptyIEmail } from 'src/app/shared/models/email.interface';
import { ILogger } from 'src/app/shared/models/logger.interface';
import { IPolicy, IPolicyProperties, IPolicies } from 'src/app/shared/models/policies.interface';
import { IShippingDetailsAutofill, IShipperConsigneeAddress, emptyShippingDetails, IChannelPartnerShipmentDetails } from 'src/app/shared/models/shippingDetails.interface';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number.pipe';
import { States, limitedCarriers, countries, productCategory, carriers } from 'src/config/metadata-config';
import { environment as ENV } from '../../../../../environments/environment';
import * as REGEX from '../../../../shared/regex-patterns'
import { TupssService } from '../services/tupss.service';
import { DataService } from '../../../../services/data.service';
import { IShipInterface } from '../interfaces/claim-data.interface';
import { CcpTermsComponent } from 'src/app/shared/dialogs/ccp-terms/ccp-terms.component';
import { DynamicFlowService } from 'src/app/services/dynamic-flow.service';
import { ALL_SPECIAL_CHARACTERS } from '../../../../shared/regex-patterns';

@Component({
  selector: 'upsc-tupss-edit',
  templateUrl: './tupss-edit.component.html',
  styleUrls: ['./tupss-edit.component.scss']
})
export class TupssEditComponent implements OnInit {
  @HostBinding('class') public hostClass = 'claim-edit';
  @HostListener('window:resize', ['$event'])


  //Prevents Internet Explorer from navigating backwards when the Backspace is pressed.
  @HostListener('document:keydown', ['$event'])
  onKeyDown(evt: KeyboardEvent) {
    if (
      evt.keyCode === 8 || evt.which === 8
    ) {
      let doPrevent = true;
      const types = ['text', 'password', 'file', 'search', 'email', 'number', 'date', 'color', 'datetime', 'datetime-local', 'month', 'range', 'search', 'tel', 'time', 'url', 'week'];
      const target = (<HTMLInputElement>evt.target);

      const disabled = target.disabled || (<HTMLInputElement>event.target).readOnly;
      if (!disabled) {
        if (target.isContentEditable) {
          doPrevent = false;
        } else if (target.nodeName === 'INPUT') {
          let type = target.type;
          if (type) {
            type = type.toLowerCase();
          }
          if (types.indexOf(type) > -1) {
            doPrevent = false;
          }
        } else if (target.nodeName === 'TEXTAREA') {
          doPrevent = false;
        }
      }
      if (doPrevent) {
        evt.preventDefault();
        return false;
      }
    }
  }

  //Form Groups
  formGroup: UntypedFormGroup;
  payeeFormGroup: UntypedFormGroup;
  startFormGroup: UntypedFormGroup;
  whatHappenedFormGroup: UntypedFormGroup;
  contactInfoFormGroup: UntypedFormGroup;
  submitClaimFormGroup: UntypedFormGroup;
  whoToContactFormGroup: UntypedFormGroup;
  differentContactFormGroup: UntypedFormGroup

  //Interfaces
  public claim: IClaim = emptyClaim;
  public steps: IStep[];
  public logInterface: ILogger = {};
  public docRequest: IDocument = {};
  public userDetails: AccountInfo = {};
  public mainContact: IContact = {};
  public policyDetails: IPolicy = {};
  public policyProperties: IPolicyProperties = {};
  public policyPropertiesList: IPolicyProperties[] = [];
  public fileList: IDocumentDisplay[] = []; //This list is used to display to the user the names of the documents
  public track3ShipmentDetails: IShippingDetailsAutofill = {};
  public consigneeAddress: IShipperConsigneeAddress[] = [];
  public summary: ISummary = {};
  public policy: IPolicies = {};

  //File Upload
  public fileToUpload: File = null;
  public tooManyDocs: boolean = false;
  public fileSizeLimit: boolean = false;
  fileNameError = false;
  fileTypeError = false;
  systemError = false;
  systemAPIError = false;
  systemErrorDPAT = false;

  //Scenarios
  public loss: boolean = false;
  public damage: boolean = false;
  public missing: boolean = false;
  public late: boolean = false;
  public other: boolean = false;
  public cargo: boolean = false;
  public flex: boolean = false;
  public ups: boolean = false;
  public istnt: boolean = false;
  public late_delivery: boolean = false;
  unishippers_late_delivery: boolean = false;
  tupssType; //will be tfcp or tccp
  showACH = false;
  //contact selections
  public policyHolder: boolean = false;
  public recipient: boolean = false;
  public custom: boolean = false;
  //Notification booleans
  errorNotification: boolean = false;
  hideAdditionalCoverageWarning: boolean = false;
  hideDeclaredWarning = true;
  hideNoDeclaredWarning = true;
  showACHError = false;
  getPayeeDetailError = false;
  //Metadata / Data Lists
  public countryList = [];
  public stateList: any[] = States; //We never pull list of states from metadata API, only comes from config file
  public currencyList = {};
  public carrierList: [] | {} = [];
  public categoryList = [];
  ccDigits;

  //Displayed values
  public senderStateDisplay = "";
  public recipientStateDisplay = "";
  public locationDisplay = "";
  public repairableDisplay = "";
  longDamage = false; //Controls the ...More feature of damage description
  longMissing = false; //Controls the ...More feature of missing description
  dmgMore = false;
  missingMore = false;
  shortenedDamageDescription = "";
  shortenedMissingDescription = "";
  accountNumberLast4 = '';
  packedBy;
  coverageType;
  selectedCategories: any[] = [];
  insuredValue: string;

  //Key values from key value pairs
  public carrierKey = "";
  public currencyKey = 'usd';

  showSelections: boolean = this.router.url == '/claims/new';
  //Helpers
  public currentStep = 1;
  // public tooltipPosition$: any;
  public maxDate = new Date();
  Object = Object;
  public valController = 0;
  public initialClaimCreation: boolean = true;
  public shipDateError: boolean = false;
  public filteredCategories: Observable<ICategory[]>;
  public queryCarrier: string;
  loopCounter = 0;
  privacyLink = ENV.links.privacyNotice;
  termsLink = ENV.tupss.terms;
  public submittedClaimNumber: string = "";
  currentlyCheckingDupeTrackingNumbers = false;
  show = false;
  continuing = false; //Used with the continue button's progress
  payeeSelectionValid: boolean = false; //Let user continue from payee page. data received from payee-table component
  mobilePayeeSelectionValid: boolean = false;
  windowSize: any = window.innerWidth;
  subscription: Subscription;
  showAddressValidation = false;
  showDPSDenied = false;
  showDPSUnavailable = false;
  disableCDButtons = false;
  payeePaymentMethod: any;
  emailAddress: string;
  pirateShipErrorPage: boolean = false;

  //Persisted Data used throughout claims process
  persistIShipData;
  public persistClaimNumber: string = "tempClaimNumber";
  public persistPublicId: string = "";
  public persistExpirationDate: Date; //These dates handle what dates a user is allowed to select from based on their policy number
  public persistEffectiveDate: Date;
  persistPayeeInfo;
  mailingInfo = {
    "insured": "",
    "streetAddress1": "",
    "streetAddress2": "",
    "city": "",
    "state": "",
    "zip": "",
    "country": ""
  }
  //Default values (Remove this? [(value)] not supported in Angular 7+ ?)
  defaultCurrency = 'usd';

  //(?)
  private saveClaimSubscription: Subscription;
  isGuestUser: boolean;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;

  //Translated dropdown texts
  public roles: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.start.role.roles.sender') },
    { id: 1, text: this.translateService.instant('claims.edit.start.role.roles.recipient') },
    { id: 2, text: this.translateService.instant('claims.edit.start.role.roles.third') },
  ];
  public claimReasons: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.whatHappened.reason.tupssLoss') },
    { id: 1, text: this.translateService.instant('claims.edit.whatHappened.reason.tupssDamage') },
    { id: 2, text: this.translateService.instant('claims.edit.whatHappened.reason.missing') }
  ];
  public repairables: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.whatHappened.reason.yes') },
    { id: 1, text: this.translateService.instant('claims.edit.whatHappened.reason.no') },
  ];
  public damagedLocations: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.one') },
    { id: 1, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.two') },
    { id: 2, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.three') }
  ];

  //new dpat stuff
  placeholderPayee: any;
  placeholderStatus: any;
  placeholderClaimNum: any;
  placeholderPolicyNum: any;
  claimStep2: any = {};
  claimInfo: any;
  claimPlaceholder: any = {};
  isTfcp: boolean;

  //late delivery store info
  lateDeliveryEmail: any;
  lateDeliveryPhone: any;
  lateDeliveryPhoneFormatted: any;
  // showMerchWarning: boolean = false;

  //flowType variables
  myChoiceFlow: boolean = this.dataService.flowType == 'my-choice' ? true : false;
  tupssFlow: boolean = this.dataService.flowType == 'tupss' ? true : false;
  customizedClaimsPortalFlow: boolean = this.dataService.flowType == 'customized-claims-portal' ? true : false;
  ajgFlow: boolean = this.dataService.flowType == 'ajg' ? true : false;
  inxpressFlow: boolean = this.dataService.flowType == 'inxpress' ? true : false;
  upshealthcareFlow: boolean = this.dataService.flowType == 'upshealthcare' ? true : false;
  freightcenterincFlow: boolean = this.dataService.flowType == 'freightcenterinc' ? true : false;
  pirateShipFlow: boolean = this.dataService.flowType == 'pirateship' ? true : false;



  flowType = '';

  constructor(private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private utilityService: UtilityService,
    private claimService: ClaimService,
    private loginService: LoginService,
    private userService: UserService,
    private tupssService: TupssService,
    public dialog: MatDialog,
    private router: Router,
    private titleService: Title,
    private breakpointObserver: BreakpointObserver,
    private phonePipe: PhoneNumberPipe,
    private payeeService: PayeeService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private dataService: DataService,
    private dynamicFlowService: DynamicFlowService
  ) {
    fromEvent(window, 'resize').pipe(
      debounceTime(500)).subscribe(
        data => {
          if (data.target['innerWidth'] >= 768 && this.windowSize < 768) {
            this.mobilePayeeSelectionValid = false;
          } else if (data.target['innerWidth'] < 768 && this.windowSize >= 768) {
            this.payeeSelectionValid = false;
          }
          this.windowSize = data.target['innerWidth'];
        }
      )
    const prefetch = this.route.snapshot.data.prefetch;
    if (prefetch) {
      this.claim = prefetch.claim;
    }
    this.userDetails = this.userService.getUserInfo();

    this.flowType = sessionStorage.getItem("flowType");

    this.steps = [
      { order: 1, label: this.translateService.instant('claims.edit.navi.tupssOne'), isActive: false },
      { order: 2, label: this.translateService.instant('claims.edit.navi.three'), isActive: false },
      { order: 3, label: this.translateService.instant('claims.edit.navi.tupssThree'), isActive: false },
      { order: 4, label: this.translateService.instant('claims.edit.navi.tupssFour'), isActive: false },
    ];

    this.isGuestUser = this.userService.isGuestUser();
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();

    if (this.isGuestUser) {
      this.currentStep = 0;
    }
    if (sessionStorage.getItem("setCurrentStep")) {
      this.currentStep = 1;
    }
    sessionStorage.removeItem("setCurrentStep")
    if (this.tupssService.userDetails && this.tupssService.userDetails.type && this.dataService.flowType != 'my-choice'
      && this.dataService.flowType != 'customized-claims-portal'
      && this.dataService.flowType != 'ajg' && this.dataService.flowType != 'inxpress' && this.dataService.flowType != 'upshealthcare' && this.dataService.flowType != 'freightcenterinc') {
      this.tupssType = this.tupssService.userDetails.type;
    }

    //if there are params in the URL, set them as variables for future use
    this.route.queryParams.subscribe(params => {
      if (params) {
        this.placeholderPayee = params.payeeID;
        this.placeholderStatus = params.status;
      }
    });

    //Form groups
    this.startFormGroup = this.formBuilder.group({
      trackingNumber: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z\-0-9]+$/i)])],
      carrier: ['', Validators.compose([Validators.required])],
      role: ['', Validators.compose([Validators.required])],
      sender: this.formBuilder.group({
        company: ['', Validators.compose([Validators.required])],
        city: ['', (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? [Validators.required] : Validators.compose([Validators.required, Validators.pattern(/^[\w\-\s]+$/)])],
        state: [''],
        country: ['', Validators.compose([Validators.required])],
        zipCode: ['', (this.isCanadaUser || this.isUsaUser) ? [] : Validators.compose([Validators.required])],
      }),
      recipient: this.formBuilder.group({
        company: ['', Validators.compose([Validators.required])],
        city: ['', (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? [Validators.required] : Validators.compose([Validators.required, Validators.pattern(/^[\w\-\s]+$/)])],
        state: [''],
        country: ['', Validators.compose([Validators.required])],
        zipCode: ['', (this.isCanadaUser || this.isUsaUser) ? [] : Validators.compose([Validators.required])],
      }),
      shipDate: ['', Validators.compose([Validators.required])],
      referenceNumber: ['', Validators.compose([Validators.maxLength(24)])],
      // techAgreement: ['',  [(control) => {
      //   return !control.value ? { 'required': true } : null;
      // }]]
    });

    this.whatHappenedFormGroup = this.formBuilder.group({
      reason: ['', Validators.compose([Validators.required])],
      category: ['', Validators.compose([Validators.required])],
      quantity: ['', Validators.compose([Validators.required, Validators.pattern(/^[1-9]\d*$/)])],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(250)])],
      currency: [{ value: 'USD', disabled: true }, Validators.compose([Validators.required])],
      merchandise: ['', Validators.compose([Validators.required])],
      shipping: [{ value: this.tupssFlow ? '25' : '0', disabled: true }, Validators.compose([Validators.required])],
      amount: [{ value: 0, disabled: true }, Validators.compose([Validators.required])],
      lossCare: ['',],
      lossReship: ['',],
      lossRefund: ['',],
      damageDescription: ['', Validators.maxLength(800)],
      damageDate: ['',],
      damageRepairable: ['',],
      damageLocations: ['',],
      damageOther: ['', Validators.maxLength(35)],
      missingDescription: ['', Validators.maxLength(800)],
      missingDate: ['',],
      missingLocations: ['',],
      missingOther: ['', Validators.maxLength(35)],
      lateCare: ['',],
      lateDelivery: ['',],
      lateReship: ['',],
      lateRefund: ['',],
      documents: ['',],
      additionalComments: ['', Validators.maxLength(250)],
      purpose: ['', Validators.compose([Validators.required])],
      purposeOther: [''],
      originalTrackingNumber: ['']
    });

    this.contactInfoFormGroup = this.formBuilder.group({
      name: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required])],
      email: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required, Validators.email])],
      phone: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required, Validators.pattern(/\d{1,3}/)])],
      addressLine1: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required])],
      addressLine2: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }],
      city: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required])],
      state: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required])],
      zip: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required, Validators.pattern(REGEX.TUPSS_FLOW_ZIP_PATTERN)])],
      ach: [{ value: this.tupssType == 'tccp' ? 'ach' : 'check', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required])]
    });

    this.whoToContactFormGroup = this.formBuilder.group({
      option: [null, Validators.required]
    });

    this.differentContactFormGroup = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern(/\d{1,3}/)])],
    });

    this.submitClaimFormGroup = this.formBuilder.group({
      checkboxFormControl: ['', Validators.compose([Validators.required])]
    });

    this.formGroup = this.formBuilder.group({
      start: this.startFormGroup,
      whatHappened: this.whatHappenedFormGroup,
      contactInfo: this.contactInfoFormGroup,
      submitClaim: this.submitClaimFormGroup,
    });

    //Customs validations based on valuechanges
    this.whatHappenedFormGroup.controls['category'].valueChanges.subscribe(val => {
      this.whatHappenedFormGroup.controls['category'].setErrors({ 'invalid': true });
      if (typeof val === 'object') { //selected from the list
        this.whatHappenedFormGroup.controls['category'].setErrors(null);
      }
      else { //typed in
        for (let cat of this.categoryList) {
          if (cat.value.toLowerCase() === val.toLowerCase()) {
            if (cat.value !== val) {
              this.whatHappenedFormGroup.controls['category'].setValue(cat);
            }
            this.whatHappenedFormGroup.controls['category'].setErrors(null);
            break;
          }
        }
      }
    });

    //Clear the values when radio button changes
    this.whatHappenedFormGroup.get('lateCare').valueChanges.subscribe(val => {
      if (val == 0) {
        this.whatHappenedFormGroup.get('lateRefund').setValue(undefined);
        this.whatHappenedFormGroup.get('lateRefund').updateValueAndValidity();
      } else if (val == 1) {
        this.whatHappenedFormGroup.get('lateReship').setValue(undefined);
        this.whatHappenedFormGroup.get('lateReship').updateValueAndValidity();
      }
    });

    //Clear the values when radio button changes
    this.whatHappenedFormGroup.get('lossCare').valueChanges.subscribe(val => {
      if (val == 0) {
        this.whatHappenedFormGroup.get('lossRefund').setValue(undefined);
        this.whatHappenedFormGroup.get('lossRefund').updateValueAndValidity();
      } else if (val == 1) {
        this.whatHappenedFormGroup.get('lossReship').setValue(undefined);
        this.whatHappenedFormGroup.get('lossReship').updateValueAndValidity();
      }
    });

    //Clear the values when radio button changes
    // this.whatHappenedFormGroup.get('return').valueChanges.subscribe(val => {
    //   if (val == 'yes') {
    //     this.whatHappenedFormGroup.get('originalTrackingNumber').setValidators(Validators.required);
    //     this.whatHappenedFormGroup.get('originalTrackingNumber').updateValueAndValidity();
    //   } else if (val == 'no') {
    //     this.whatHappenedFormGroup.get('originalTrackingNumber').setValue(undefined);
    //     this.whatHappenedFormGroup.get('originalTrackingNumber').clearValidators();
    //     this.whatHappenedFormGroup.get('originalTrackingNumber').updateValueAndValidity();
    //   }
    // });

    //Hides shipping fee warning message when user types in value above 0
    //The warning message will re-appear if the user types in value of 0, but that logic is put into checkDV()
    //The business does not want this requirement anymore currently but keeping the logic in case it is needed later on
    // this.whatHappenedFormGroup.get('merchandise').valueChanges.subscribe(
    //   (value) => {
    //     if (value > 0) {
    //       this.showMerchWarning = false;
    //     }   
    //   }
    // );

    this.whatHappenedFormGroup.get('currency').valueChanges.subscribe(val => {
      this.currencyKey = val.toLowerCase();
    });

    //If user selects US or CA, make the state field required. Need different controls
    //Since recipient and sender don't have to be same country
    this.startFormGroup.get('sender').get('country').valueChanges.subscribe(val => {
      if (val == 'US' || val == 'CA') {
        if (this.isUsaUser || this.isCanadaUser) {
          this.startFormGroup.get('sender').get('state').setValidators([Validators.required]);
          this.startFormGroup.get('sender').get('state').updateValueAndValidity();
        }
        else {
          this.startFormGroup.get('sender').get('state').setValue(undefined);
          this.startFormGroup.get('sender').get('state').setValidators(null);
          this.startFormGroup.get('sender').get('state').updateValueAndValidity();
        }
      } else {
        this.startFormGroup.get('sender').get('state').setValue(undefined);
        this.startFormGroup.get('sender').get('state').setValidators(null);
        this.startFormGroup.get('sender').get('state').updateValueAndValidity();
      }
      if (this.startFormGroup.get('sender').get('state').value > 0) {
        if (val === 'US' || val === 'CA') {
          this.startFormGroup.get('sender').get('country').setErrors(null);
        } else {
          this.startFormGroup.get('sender').get('country').setErrors({ 'mismatch': true });
        }
      }
    });

    this.startFormGroup.get('recipient').get('country').valueChanges.subscribe(val => {
      if (val == 'US' || val == 'CA') {
        if (this.isUsaUser || this.isCanadaUser) {
          this.startFormGroup.get('recipient').get('state').setValidators([Validators.required]);
          this.startFormGroup.get('recipient').get('state').updateValueAndValidity();
        }
        else {
          this.startFormGroup.get('recipient').get('state').setValue(undefined);
          this.startFormGroup.get('recipient').get('state').setValidators(null);
          this.startFormGroup.get('recipient').get('state').updateValueAndValidity();
        }
      } else {
        this.startFormGroup.get('recipient').get('state').setValue(undefined);
        this.startFormGroup.get('recipient').get('state').setValidators(null);
        this.startFormGroup.get('recipient').get('state').updateValueAndValidity();
      }
      if (this.startFormGroup.get('recipient').get('state').value > 0) {
        if (val === 'US' || val === 'CA') {
          this.startFormGroup.get('recipient').get('country').setErrors(null);
        } else {
          this.startFormGroup.get('recipient').get('country').setErrors({ 'mismatch': true });
        }
      }
    });

    this.whatHappenedFormGroup.get('originalTrackingNumber').valueChanges.subscribe(val => {
      try {
        if (val.trim().length === 0) {
          this.whatHappenedFormGroup.get('originalTrackingNumber').setErrors({ 'whitespace': true });
        } else if (val.length == 0) {
          this.whatHappenedFormGroup.get('originalTrackingNumber').setErrors({ 'required': true });
        } else {
          this.whatHappenedFormGroup.get('originalTrackingNumber').setErrors(null);
        }
      } catch (e) { }
    })


    //Using these valueChanges to remove the red notifiation box, if it is active and the form is valid.
    this.startFormGroup.valueChanges.subscribe(data => {
      if (this.errorNotification) {
        if (this.startFormGroup.valid) {
          this.errorNotification = false;
        } else {
          this.errorNotification = true;
        }
      }
    });

    this.whatHappenedFormGroup.valueChanges.subscribe(data => {
      if (this.errorNotification) {
        if (this.whatHappenedFormGroup.valid) {
          this.errorNotification = false;
        } else {
          this.errorNotification = true;
        }
      }
    });

    this.contactInfoFormGroup.valueChanges.subscribe(data => {
      if (this.errorNotification) {
        if (this.contactInfoFormGroup.valid) {
          this.errorNotification = false;
        } else {
          this.errorNotification = true;
        }
      }
    });

    this.submitClaimFormGroup.valueChanges.subscribe(data => {
      if (this.errorNotification) {
        if (this.submitClaimFormGroup.valid) {
          this.errorNotification = false;
        } else {
          this.errorNotification = true;
        }
      }
    });

    this.differentContactFormGroup.valueChanges.subscribe(data => {
      if (this.errorNotification) {
        if (this.differentContactFormGroup.valid) {
          this.errorNotification = false;
        } else {
          this.errorNotification = true;
        }
      }
    });

    //When user starts typing, filteredCategories will populate.
    this.filteredCategories = this.whatHappenedFormGroup.get('category').valueChanges
      .pipe(
        startWith<string | ICategory>(''),
        map(value => typeof value === 'string' ? value : value.key),
        map(name => name ? this.filter(name) : this.categoryList.slice())
      );

    //On selecting different claims, determine which controls are required
    this.whatHappenedFormGroup.controls.reason.valueChanges.subscribe(val => {
      this.valController = val;
      //Re-enable possibly disabled formcontrol items
      this.whatHappenedFormGroup.controls['category'].enable();
      this.whatHappenedFormGroup.controls['quantity'].enable();
      this.whatHappenedFormGroup.controls['description'].enable();
      this.whatHappenedFormGroup.controls['purpose'].enable();
      this.whatHappenedFormGroup.controls['merchandise'].enable();
      this.whatHappenedFormGroup.controls['additionalComments'].enable();
      this.whatHappenedFormGroup.controls['documents'].enable();
      if (val == 0) {
        //Loss claim selected
        //Clear ALL other flow validators
        this.whatHappenedFormGroup.controls.damageDescription.clearValidators();
        this.whatHappenedFormGroup.controls.damageDate.clearValidators();
        this.whatHappenedFormGroup.controls.damageRepairable.clearValidators();
        this.whatHappenedFormGroup.controls.damageLocations.clearValidators();
        this.whatHappenedFormGroup.controls.damageOther.clearValidators();
        this.whatHappenedFormGroup.controls.missingDate.clearValidators();
        this.whatHappenedFormGroup.controls.missingDescription.clearValidators();
        this.whatHappenedFormGroup.controls.missingLocations.clearValidators();
        this.whatHappenedFormGroup.controls.missingOther.clearValidators();
        this.whatHappenedFormGroup.controls.lateDelivery.clearValidators();
        this.whatHappenedFormGroup.controls.lateCare.clearValidators();
        this.whatHappenedFormGroup.controls.lateRefund.clearValidators();
        this.whatHappenedFormGroup.controls.lateReship.clearValidators();
        //If cargo policy & loss selected, show the care options
        if (this.cargo && (this.isUsaUser || this.isCanadaUser)) {
          this.whatHappenedFormGroup.controls.lossCare.setValidators([Validators.required]);
          this.whatHappenedFormGroup.controls.lossCare.updateValueAndValidity();
          this.whatHappenedFormGroup.controls.lossCare.valueChanges.subscribe(
            val1 => {
              if (this.whatHappenedFormGroup.controls.reason.value == 0) {
                if (val1 === '0') {
                  this.whatHappenedFormGroup.controls.lossReship.setValidators([Validators.required]);
                  this.whatHappenedFormGroup.controls.lossRefund.clearValidators();
                  this.whatHappenedFormGroup.controls.lossReship.updateValueAndValidity();
                  this.whatHappenedFormGroup.controls.lossRefund.updateValueAndValidity();
                } else if (val1 === '1') {
                  this.whatHappenedFormGroup.controls.lossRefund.setValidators([Validators.required]);
                  this.whatHappenedFormGroup.controls.lossReship.clearValidators();
                  this.whatHappenedFormGroup.controls.lossReship.updateValueAndValidity();
                  this.whatHappenedFormGroup.controls.lossRefund.updateValueAndValidity();
                }
              }
            }
          );
        }
      } else if (val == 1) {
        //Damage claim selected
        //Clear all other flow validators
        this.whatHappenedFormGroup.controls.lossRefund.clearValidators();
        this.whatHappenedFormGroup.controls.lossReship.clearValidators();
        this.whatHappenedFormGroup.controls.lossCare.clearValidators();
        this.whatHappenedFormGroup.controls.missingDate.clearValidators();
        this.whatHappenedFormGroup.controls.missingDescription.clearValidators();
        this.whatHappenedFormGroup.controls.missingLocations.clearValidators();
        this.whatHappenedFormGroup.controls.missingOther.clearValidators();
        this.whatHappenedFormGroup.controls.lateCare.clearValidators();
        this.whatHappenedFormGroup.controls.lateDelivery.clearValidators();
        this.whatHappenedFormGroup.controls.lateRefund.clearValidators();
        this.whatHappenedFormGroup.controls.lateReship.clearValidators();
        this.whatHappenedFormGroup.controls.damageDescription.setValidators([Validators.required]);
        this.whatHappenedFormGroup.controls.damageDate.setValidators([Validators.required]);
        this.whatHappenedFormGroup.controls.damageRepairable.setValidators([Validators.required]);
        this.whatHappenedFormGroup.controls.damageLocations.setValidators([Validators.required]);

        this.whatHappenedFormGroup.controls.damageLocations.valueChanges.subscribe(otherVal => {
          if (otherVal == 3 && this.valController == 1) {
            //Other location has been selected
            this.whatHappenedFormGroup.controls.damageOther.setValidators([Validators.required]);
          } else {
            this.whatHappenedFormGroup.controls.damageOther.clearValidators();
          }
          this.whatHappenedFormGroup.controls.damageOther.updateValueAndValidity();
        });

      } else if (val == 2) {
        //Missing claim selected
        //Clear all other flow validators
        this.whatHappenedFormGroup.controls.lossRefund.clearValidators();
        this.whatHappenedFormGroup.controls.lossReship.clearValidators();
        this.whatHappenedFormGroup.controls.lossCare.clearValidators();
        this.whatHappenedFormGroup.controls.damageDescription.clearValidators();
        this.whatHappenedFormGroup.controls.damageDate.clearValidators();
        this.whatHappenedFormGroup.controls.damageRepairable.clearValidators();
        this.whatHappenedFormGroup.controls.damageLocations.clearValidators();
        this.whatHappenedFormGroup.controls.damageOther.clearValidators();
        this.whatHappenedFormGroup.controls.lateCare.clearValidators();
        this.whatHappenedFormGroup.controls.lateRefund.clearValidators();
        this.whatHappenedFormGroup.controls.lateReship.clearValidators();
        this.whatHappenedFormGroup.controls.missingDescription.setValidators([Validators.required]);
        this.whatHappenedFormGroup.controls.missingDate.setValidators([Validators.required]);
        this.whatHappenedFormGroup.controls.missingLocations.setValidators([Validators.required]);

        this.whatHappenedFormGroup.controls.missingLocations.valueChanges.subscribe(otherValMissing => {
          if (otherValMissing == 3 && this.valController == 2) {
            //Other location has been selected
            this.whatHappenedFormGroup.controls.missingOther.setValidators([Validators.required]);
          } else {
            this.whatHappenedFormGroup.controls.missingOther.clearValidators();
          }
          this.whatHappenedFormGroup.controls.missingOther.updateValueAndValidity();
        });

      } else if (val == 3) {
        //Late claim selected
        //clear all other flow validators
        this.whatHappenedFormGroup.controls.lossRefund.clearValidators();
        this.whatHappenedFormGroup.controls.lossReship.clearValidators();
        this.whatHappenedFormGroup.controls.lossCare.clearValidators();
        this.whatHappenedFormGroup.controls.damageDescription.clearValidators();
        this.whatHappenedFormGroup.controls.damageDate.clearValidators();
        this.whatHappenedFormGroup.controls.damageRepairable.clearValidators();
        this.whatHappenedFormGroup.controls.damageLocations.clearValidators();
        this.whatHappenedFormGroup.controls.damageOther.clearValidators();
        this.whatHappenedFormGroup.controls.missingDate.clearValidators();
        this.whatHappenedFormGroup.controls.missingDescription.clearValidators();
        this.whatHappenedFormGroup.controls.missingLocations.clearValidators();
        this.whatHappenedFormGroup.controls.missingOther.clearValidators();
        this.whatHappenedFormGroup.controls.lateDelivery.clearValidators();
        this.whatHappenedFormGroup.controls.lateCare.setValidators([Validators.required]);

        this.whatHappenedFormGroup.controls.lateCare.valueChanges.subscribe(reVal => {
          if (reVal == 0 && this.valController == 3) {
            //Reship option was selected
            this.whatHappenedFormGroup.controls.lateReship.setValidators([Validators.required]);
            this.whatHappenedFormGroup.controls.lateRefund.clearValidators();
          } else if (reVal == 1 && this.valController == 3) {
            //Refund option was selected
            this.whatHappenedFormGroup.controls.lateRefund.setValidators([Validators.required]);
            this.whatHappenedFormGroup.controls.lateReship.clearValidators();
          } else {
            this.whatHappenedFormGroup.controls.lateReship.clearValidators();
            this.whatHappenedFormGroup.controls.lateRefund.clearValidators();
          }
          this.whatHappenedFormGroup.controls.lateReship.updateValueAndValidity();
          this.whatHappenedFormGroup.controls.lateRefund.updateValueAndValidity();
        });

      } else if (val == 4) {
        this.whatHappenedFormGroup.controls['category'].disable();
        this.whatHappenedFormGroup.controls['quantity'].disable();
        this.whatHappenedFormGroup.controls['description'].disable();
        this.whatHappenedFormGroup.controls['purpose'].disable();
        this.whatHappenedFormGroup.controls['merchandise'].disable();
        this.whatHappenedFormGroup.controls['additionalComments'].disable();
        this.whatHappenedFormGroup.controls['documents'].disable();

      } else {
      }
      //Update all controls at the end (Except for the second tier optionals, those will be updated at the end of their own subscribe)
      this.whatHappenedFormGroup.controls.lossReship.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.lossRefund.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.lossCare.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.damageDescription.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.damageDate.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.damageRepairable.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.damageLocations.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.missingDescription.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.missingDate.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.missingLocations.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.lateCare.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.lateDelivery.updateValueAndValidity();
    });

    this.startFormGroup.controls.shipDate.valueChanges.subscribe(val => {
      var selectedDate = new Date(val).setUTCHours(0, 0, 0, 0);
      if ((selectedDate.valueOf() <= this.persistExpirationDate.setUTCHours(0, 0, 0, 0)).valueOf() && (selectedDate.valueOf() >= this.persistEffectiveDate.setUTCHours(0, 0, 0, 0)).valueOf()) {
        this.startFormGroup.controls['shipDate'].setErrors(null);
        this.startFormGroup.controls.shipDate.markAsTouched();
        this.shipDateError = false;
      } else {
        this.startFormGroup.controls['shipDate'].setErrors({ 'invalid': true });
        this.startFormGroup.controls.shipDate.markAsTouched();
        this.shipDateError = true;
      }
    });

    //Search validators for the metadata typelists
    this.startFormGroup.controls['carrier'].valueChanges.subscribe(val => {
      this.queryCarrier = val;
      if (this.startFormGroup.get('carrier').value != "UPS") {
        if (this.late) {
          this.late = false;
          this.whatHappenedFormGroup.controls.lateCare.clearValidators();
          this.whatHappenedFormGroup.controls.lateRefund.clearValidators();
          this.whatHappenedFormGroup.controls.lateReship.clearValidators();
          this.whatHappenedFormGroup.controls.reason.setValue(null);
        }
      }
      Object.keys(this.carrierList).forEach(key => {
        if ((this.startFormGroup.get('carrier').value) && this.carrierList[key].toLowerCase() == this.startFormGroup.get('carrier').value.toLowerCase()) {
          this.carrierKey = key;
        }
      });
      for (let key in this.carrierList) {
        if ((val) && val.toLowerCase() == this.carrierList[key].toLowerCase()) {
          if (val !== this.carrierList[key]) {
            this.startFormGroup.controls['carrier'].setValue(this.carrierList[key]);
          }
          this.startFormGroup.controls['carrier'].setErrors(null);
          break;
        } else {
          this.startFormGroup.controls['carrier'].setErrors({ 'invalid': true });
        }
      }
    });

    this.contactInfoFormGroup.get('email').valueChanges.subscribe(val => {
      if (val) {
        var atIndex = val.indexOf('@');
        if (atIndex == -1) {
        } else {
          //Check if there is a period after the @
          var dotIndex = val.substring(atIndex).indexOf('.');
          if (dotIndex == -1) {
            this.contactInfoFormGroup.get('email').setErrors({ 'invalid': true });
          }
        }
      }
    });

    this.differentContactFormGroup.get('email').valueChanges.subscribe(val => {
      if (val) {
        var atIndex = val.indexOf('@');
        if (atIndex == -1) {
        } else {
          //Check if there is a period after the @
          var dotIndex = val.substring(atIndex).indexOf('.');
          if (dotIndex == -1) {
            this.differentContactFormGroup.get('email').setErrors({ 'invalid': true });
          }
        }
      }
    });

    this.monitorValueChanged();

    this.subscription = this.claimService.getStepValue().subscribe(data => {
      if (data) {
        this.setStep(data.a, data.b);
      }
    });

    if (this.dataService.flowType == 'my-choice') {
      //My choice doesn't require a checkbox on the submit page?
      this.submitClaimFormGroup.controls.checkboxFormControl.clearValidators();
      //My choice doesn't require the "Purpose of shipment" question
      this.whatHappenedFormGroup.controls.purpose.clearValidators();
      //My choice shipping amount is editable.
      this.whatHappenedFormGroup.controls.shipping.enable();
    }

    // check if current flow is for channel partner portals
    if (this.dataService.checkForCcp() === true) {
      //CCP doesn't require a checkbox on the submit page
      this.submitClaimFormGroup.controls.checkboxFormControl.clearValidators();
      //CCP shipping amount is editable.
      this.whatHappenedFormGroup.controls.shipping.enable();
      //CCP doesn't require the "Purpose of shipment" question
      this.whatHappenedFormGroup.controls.purpose.clearValidators();
      //CCP wants a different text here
      this.damagedLocations[2].text = 'With the carrier';
    }

  }  //End of constructor

  //Listen for state changes, if possible, autoselect country when state is chosen
  public senderAutoCountry() {
    let state = this.startFormGroup.get('sender').get('state').value;
    if (state > 0) {
      if (state == 2 || state == 9 || state == 26 || state == 34 || state == 40 || state == 42 || state == 43 ||
        state == 44 || state == 49 || state == 52 || state == 54 || state == 58 || state == 68) {
        this.startFormGroup.get('sender').get('country').setValue('CA');
        this.startFormGroup.get('sender').get('country').updateValueAndValidity();
      } else {
        this.startFormGroup.get('sender').get('country').setValue('US');
        this.startFormGroup.get('sender').get('country').updateValueAndValidity();
      }
    }
  }
  public recipientAutoCountry() {
    let state = this.startFormGroup.get('recipient').get('state').value;
    if (state > 0) {
      if (state == 2 || state == 9 || state == 26 || state == 34 || state == 40 || state == 42 || state == 43 ||
        state == 44 || state == 49 || state == 52 || state == 54 || state == 58 || state == 68) {
        this.startFormGroup.get('recipient').get('country').setValue('CA');
        this.startFormGroup.get('recipient').get('country').updateValueAndValidity();
      } else {
        this.startFormGroup.get('recipient').get('country').setValue('US');
        this.startFormGroup.get('recipient').get('country').updateValueAndValidity();
      }
    }
  }

  public ngOnInit() {
    this.getInsuredValue();
    if (!this.isUsaUser) {
      this.dateAdapter.setLocale('en-GB');
    }
    this.maxDate.setDate(this.maxDate.getDate());
    this.getMetaData();
    this.checkLists();
    this.instantiateEmptyClaimModel();

    //Load userDetails and policyDetails from sessionStorage.
    var reloading = sessionStorage.getItem("reloading");
    if (reloading) {
      this.currentStep = 1;
    }

    this.setActiveStep();
    window.onpopstate = (event) => {
      if (event.state && event.state.step !== undefined) {
        this.currentStep = event.state.step;
      }
    }
    //this occurs once user comes back from DPAT with a successful payee creation
    if (this.placeholderStatus == "success") {
      this.populateStep2(); //re-populates form field on step 2 of claims process (only important if they press back button to go back to step 2 and press continue again)
      if (this.flowType == 'pirateship') {
        // this.currentStep = 4;
        this.tupssService.pirateShipClaimStep = 4;
        this.setStep(null, 4);
      } else {
        this.currentStep = 3; //assuming a successful payee creation, we take them to step 3
      }
      // here we get payee details
      let request = {
        'payeeID': this.placeholderPayee,
        'policyNumber': this.userDetails?.policyNumber ? this.userDetails?.policyNumber : this.policyDetails?.policyNumber ? this.policyDetails?.policyNumber : this.userDetails?.contractId ? this.userDetails.contractId : ''
      }
      //retrieves the payee details they just created and display it under Claim Payment Recipient on step 3
      this.claimService.getPayeeDetails(request).subscribe(
        data => {
          if (data && data?.success && data?.success.toLowerCase() == 'false') {
            this.getPayeeDetailError = true;
          } else {
            this.getPayeeDetailError = false;
            this.contactInfoFormGroup.controls.name.setValue(data.data.payeeName);
            this.contactInfoFormGroup.controls.phone.setValue(data.data.phoneNumber);
            if (this.flowType === 'pirateship') {
              this.claim.mainContact.workNumber = data.data.phoneNumber;
              this.claim.mainContact.firstName = data.data.payeeName;
              this.dataService.updateCommonClaimObject(this.claim);
            }
            this.contactInfoFormGroup.controls.email.setValue(data.data.email);
            this.contactInfoFormGroup.controls.addressLine1.setValue(data.data.addressLine1);
            this.contactInfoFormGroup.controls.addressLine2.setValue(data.data.addressLine2);
            this.contactInfoFormGroup.controls.city.setValue(data.data.city);
            this.contactInfoFormGroup.controls.state.setValue(data.data.state);
            this.contactInfoFormGroup.controls.zip.setValue(data.data.zip);
            if (data.data.paymentMethod == "ACH") {
              this.accountNumberLast4 = data.data.accountNumber;
              this.payeePaymentMethod = "ACH";
            } else if (data.data.paymentMethod == "Check") {
              this.payeePaymentMethod = "Check";
            } else if (data.data.paymentMethod == "Zelle") {
              this.accountNumberLast4 = data.data.accountNumber;
              this.payeePaymentMethod = "Zelle";
            } else {
              this.payeePaymentMethod = "Check"; //default to the safest option to display as a failsafe, but should never occur
            }
          }
        }, error => {
          this.getPayeeDetailError = true;
        }
      );


      // const currentUrl = this.router.url;
      // this.pirateShipFlow  = currentUrl.startsWith('/pirate-ship');

      // if (currentUrl === '/pirate-ship/file') {
      //   this.createClaim(this.dataService._commonClaimsInterface);
      // }
      //if they do not create a payee from DPAT and navigate back/cancel, take user back to step 2
    } else if (this.placeholderStatus == "back" || this.placeholderStatus == "cancel") {
      this.populateStep2();
      if (this.flowType == 'pirateship') {
        this.tupssService.pirateShipClaimStep = 1;
        this.setStep(null, 1);
      } else {
        this.currentStep = 2;
      }
    } else if (this.router.url === '/pirate-ship/file') {
      this.tupssService.pirateShipClaimStep = 1;
      this.createClaim();
    }




  } //End of ngOnInit

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  //For some reason, categoryList and stateList would randomly return empty, OR with the correct data,
  //So, I am making a loop to keep checking until all lists are populated with the correct data, and only
  //then will I attempt to autofill the fields.
  //Will terminate itself after 10 tries.
  checkLists() {
    if (this.loopCounter > 10) {
      return;
    } else {
      if (this.categoryList.length === 0) { //Lists not yet populated
        setTimeout(() => this.checkLists(), 500);
      } else {  //Lists are populated, continue as normal
        //We need to find the OBJECT reference for the key that the API Returned
        // if (this.userDetails.userPreference.productCategory != "" && this.userDetails.userPreference.productCategory != undefined && this.userDetails.userPreference.productCategory != null) {
        //   let cat = this.categoryList.find(x => x.key === this.userDetails.userPreference.productCategory);
        //   this.whatHappenedFormGroup.get('category').setValue(cat); //This value must be an object, not a string
        // }
        // if (this.userDetails.userPreference.address.state != "" && this.userDetails.userPreference.address.state != undefined && this.userDetails.userPreference.address.state != null) {
        //   let stat = this.stateList.find(x => x.state === this.userDetails.userPreference.address.state);
        //   this.startFormGroup.get('sender').get('state').setValue(stat.id); //Value must be the ID of the state
        // }
      }
    }
  }

  //Used to filter categories based on what user is typing.
  //Works as a "contains" instead of "startsWith" due to !== -1
  public filter(name: string): ICategory[] {
    return this.categoryList.filter(option =>
      option.value.toLowerCase().indexOf(name.toLowerCase()) !== -1);
  }

  //Handles how the data gets displayed vs how the value is represented in the database.
  public displayCategories(obj?: ICategory): string | undefined {
    return obj ? obj.value : undefined;
  }

  //Custom validator to require the user to select from the dropdown
  private requireMatch(control: UntypedFormControl): ValidationErrors | null {
    let selection: any = control.value;
    if (typeof selection === 'string') {
      return { requireMatch: true }
    }
    return null;
  }

  //Used in Stepper component - Use case: User selects green number to go backwards
  public setCurrentStepManually(order: number) {
    if (this.currentStep >= order) {
      this.currentStep = order;
      this.setActiveStep();
    }
  }

  public setStep(event, stepToSetTo: number) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.currentStep = stepToSetTo;
    this.setActiveStep();
    sessionStorage.setItem("currentStep", this.currentStep.toString());
  }

  public ngOnDestroy() {
    this.utilityService.clearSubscriptions([
      this.saveClaimSubscription
    ]);
    if (this.payeeService.isInDesktopView()) {
      this.payeeService.setClaimsPayeeInfo(null, true);
    } else {
      this.payeeService.setClaimsPayeeInfo(null, false);
    }
  }

  //If Merchandise or Shipping inputs are updated, total amount must be too
  private monitorValueChanged() {
    this.merchandiseValue.valueChanges.subscribe(
      value => {
        if (!value && value != 0) {
          return;
        }
        if (isNaN(value)) {
          this.totalAmount.setValue(0);
          return;
        }
        this.totalAmount.setValue(+this.merchandiseValue.value + +this.shippingValue.value);
      }
    );

    this.shippingValue.valueChanges.subscribe(
      value => {
        if (!value && value != 0) {
          return;
        }
        if (isNaN(value)) {
          this.totalAmount.setValue(0);
        }
        this.totalAmount.setValue(+this.shippingValue.value + +this.merchandiseValue.value);
      }
    );
  }

  public onSubmit(event, value) {
    event.preventDefault();
  }

  public resetStep(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.currentStep = 0;
  }

  public previousStep(event) {
    this.errorNotification = false;
    this.systemError = false;
    this.systemAPIError = false;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.currentStep == 3) {
      this.clearDocumentErrors();
    }
    //Handle navigation from first step
    if (this.tupssType == 'tfcp' && this.currentStep == 1) {
      this.tfcpStartOver();
    } else if (this.tupssType == 'tccp' && this.currentStep == 1) {
      this.router.navigate(['tccp']);
    } else if (this.dataService.flowType == 'my-choice' && this.currentStep == 1) {
      this.router.navigate(['ups-my-choice/claim-portal']);
    }
    // check if current flow is for channel partner portals
    else if (this.dataService.checkForCcp() === true && this.currentStep === 1) {
      //redirect to ccp portal based on channel partner/flow type and current step
      this.tupssService.pirateShipClaimStep = 0;
      this.dynamicFlowService.checkStepNRedirectForCcp(this.currentStep);
    } else
      if (this.flowType === 'pirateship' && this.currentStep === 4) {
        this.tupssService.pirateShipClaimStep = 1;
        this.currentStep = 1;
      }
      else {
        this.currentStep--;
        this.setActiveStep();
      }
  }

  public nextStep(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.currentStep++;
    this.setActiveStep();
  }

  private setActiveStep() {
    this.steps.forEach(
      (step) => {
        step.isActive = step.order === this.currentStep;
      },
    );
    window.scrollTo(0, 0);
    if (this.currentStep == 0) {
      window.history.replaceState({ step: this.currentStep }, '');
    }
    else {
      window.history.pushState({ step: this.currentStep }, '');
    }
  }

  tfcpStartOver() {
    this.router.navigate(['tfcp']);
  }

  clearDocumentErrors() {
    this.fileSizeLimit = false;
    this.tooManyDocs = false;
    this.fileNameError = false;
    this.fileTypeError = false;
    this.systemError = false;
  }

  public uploadDocument(files?: FileList, event?) {

    let documentModel: IDocumentDisplay = { file: files.item(0) }

    if (files.item(0)) {
      this.clearDocumentErrors();

      this.fileToUpload = files.item(0);

      if (this.fileToUpload.name.length > 80) {
        this.fileNameError = true;
        return;
      }
      // let fileNameParts = this.fileToUpload.name.split('.');
      // if (length <= 1 || ALLOWED_DOC_TYPES.indexOf(fileNameParts[fileNameParts.length - 1].toLowerCase()) < 0) {
      //   this.fileTypeError = true;
      //   return;
      // }
      if (this.fileToUpload.size > 7340032) {
        this.fileSizeLimit = true;
      } else {
        this.fileSizeLimit = false;

        if (this.fileList.length == 10) {
          this.tooManyDocs = true;
        } else {
          this.tooManyDocs = false;

          //All validations have passed
          this.claimService.generateUploadToken().subscribe(
            data => {
              this.docRequest.sessionID = data;
            }, error => {
              this.systemError = true;
            }, () => {
              this.docRequest.claimNumber = this.persistClaimNumber;
              this.docRequest.name = this.formatFileName(this.fileToUpload);
              this.docRequest.docUID = "001";
              this.docRequest.documentType = "letter_received";
              this.docRequest.securityType = "unrestricted";
              this.docRequest.status = "approved";
              this.docRequest.postFnolDoc = "false";

              if (this.fileToUpload.name.endsWith('.msg')) {
                this.docRequest.mimeType = "application/vnd.ms-outlook"
              } else {
                this.docRequest.mimeType = this.fileToUpload.type;
              }

              let formData: FormData = new FormData();
              formData.append('documentUploadRequest', JSON.stringify(this.docRequest));
              formData.append('file', this.fileToUpload, this.formatFileName(this.fileToUpload));

              //Ready to upload document
              this.claimService.uploadDocument(formData).subscribe(
                data => {
                  documentModel.publicID = data.publicID;
                  this.fileList.push(documentModel);
                  if (event) {
                    event.target.value = "";
                  }
                }, error => {
                  this.systemError = true;
                }
              );
            }
          );
        }
      }
    }
  }

  //When user tries to upload a document with special characters, sned it to GW with spaces replacing the special characters
  formatFileName(file) {
    let extension = file.name.substring(file.name.lastIndexOf('.'));
    let fileNameWithoutExtension = file.name.substring(0, file.name.lastIndexOf('.'));

    try {
      fileNameWithoutExtension = fileNameWithoutExtension.replace(/[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/g, ' ');
      fileNameWithoutExtension = fileNameWithoutExtension.replace(ALL_SPECIAL_CHARACTERS, ' ');
      fileNameWithoutExtension = fileNameWithoutExtension.trim();
    } catch (e) { }

    return `${fileNameWithoutExtension}${extension}`;
  }

  ccpTermsDialog() {
    let dialogRef = this.dialog.open(CcpTermsComponent);
    dialogRef.afterClosed().subscribe(
      result => {
        if (result == "accept") {
          this.submitClaimFormGroup.controls.checkboxFormControl.setValue(true);
        } else {
          this.submitClaimFormGroup.controls.checkboxFormControl.setValue(null);
        }
      }
    );
  }

  //When a user tries to remove a document, we show a popup
  public openDialog(file: IDocumentDisplay): void {
    let dialogRef = this.dialog.open(DialogContentComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result == "confirm") {
        this.removeDocument(file);
      }
    });
  }

  //If a user tries to cancel the claim, ask them if they are sure
  public openCancelDialog(event): void {
    let dialogRef = this.dialog.open(
      ConfirmCancelComponent,
      { data: { mychoice: this.myChoiceFlow ? true : false } }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result == "confirm") {
        if (this.tupssType == 'tfcp') {
          this.tfcpStartOver();
        } else if (this.tupssType == 'tccp') {
          this.router.navigate(['tccp']);
        } else if (this.dataService.flowType == 'my-choice') {
          this.router.navigate(['ups-my-choice/claim-portal']);
        } else {
          //redirect to ccp portal based on channel partner/flow type
          this.dynamicFlowService.redirectToCcp();
        }
      }
    });
  }

  public removeDocument(file: IDocumentDisplay) {
    this.claimService.removeDocument(file).subscribe(
      data => {
        this.fileList.splice(this.fileList.indexOf(file), 1);
      }, error => { }
    );
  }

  //Switch through the different flows
  public toggleFlow(type: string) {
    this.loss = false;
    this.damage = false;
    this.missing = false;
    this.late = false;
    this.policyHolder = false;
    this.custom = false;
    this.recipient = false;
    this.late_delivery = false;
    this.unishippers_late_delivery = false;
    if (type == "loss") {
      this.loss = true;
    } else if (type == "damage") {
      this.damage = true;
    } else if (type == "missing") {
      this.missing = true;
    } else if (type == "late") {
      this.late = true;
    } else if (type == "policyHolder") {
      this.policyHolder = true;
    } else if (type == "recipient") {
      this.recipient = true;
    } else if (type == "custom") {
      this.custom = true;
    } else if (type == "late_delivery") {
      this.late_delivery = true;
    } else if (type == 'unishippers_late_delivery') {
      this.unishippers_late_delivery = true;
    } else if (type == "piracy_lost") { //May just be the same as regular loss.
      this.loss = true;
    }
  }

  //Handles the "Other" text field in damage and missing flows
  public toggleOther(type: boolean) {
    this.other = false;
    if (type) {
      this.other = true;
    }
  }

  //This is what actually autofills the startFormGroup form.
  //Uses track3 details to .setValue() on the form.
  //In TUPSS and MyChoice this form is not actually shown to the user (StartForm).
  //But to keep it the same as regular claim flow, this way still works for populating the claim interface.
  //Because later on gatherFormValues() will be called.
  populateStartFormGroupWithTrack3() {
    let senderInfo = this.track3ShipmentDetails.shipperConsigneeAddress.find(x => x.description?.toLowerCase() == 'shipper address');
    let recipInfo = this.track3ShipmentDetails.shipperConsigneeAddress.find(x => x.description?.toLowerCase() == 'shipto address');
    if (this.countryList.find(x => x.key == senderInfo?.countryCode)) {
      this.startFormGroup.controls.sender.get('country').setValue(senderInfo.countryCode);
    }
    if (senderInfo?.city) {
      this.startFormGroup.controls.sender.get('city').setValue(senderInfo.city);
      this.startFormGroup.controls.sender.get('city').markAsTouched();
    }
    if (senderInfo?.companyName) {
      this.startFormGroup.controls.sender.get('company').setValue(senderInfo.companyName);
      this.startFormGroup.controls.sender.get('company').markAsTouched();
    }
    if (senderInfo?.postalCode) {
      this.startFormGroup.controls.sender.get('zipCode').setValue(senderInfo.postalCode);
      this.startFormGroup.controls.sender.get('zipCode').markAsTouched();
    }
    if (senderInfo?.stateProvinceCode) {
      if (this.stateList.find(x => x.state == senderInfo.stateProvinceCode)) {
        this.startFormGroup.controls.sender.get('state').setValue(this.stateList.find(x => x.state == senderInfo.stateProvinceCode).id);
      }
    }
    if (recipInfo?.countryCode) {
      if (this.countryList.find(x => x.key == recipInfo?.countryCode)) {
        this.startFormGroup.controls.recipient.get('country').setValue(recipInfo.countryCode);
      }
    }
    if (recipInfo?.city) {
      this.startFormGroup.controls.recipient.get('city').setValue(recipInfo.city);
      this.startFormGroup.controls.recipient.get('city').markAsTouched();
    }
    if (recipInfo?.companyName) {
      this.startFormGroup.controls.recipient.get('company').setValue(recipInfo.companyName);
      this.startFormGroup.controls.recipient.get('company').markAsTouched();
    }
    if (recipInfo?.postalCode) {
      this.startFormGroup.controls.recipient.get('zipCode').setValue(recipInfo.postalCode);
      this.startFormGroup.controls.recipient.get('zipCode').markAsTouched();
    }
    if (recipInfo?.stateProvinceCode) {
      if (this.stateList.find(x => x.state == recipInfo.stateProvinceCode)) {
        this.startFormGroup.controls.recipient.get('state').setValue(this.stateList.find(x => x.state == recipInfo.stateProvinceCode).id);
      }
    }
    if (this.track3ShipmentDetails.shipDate) {
      this.startFormGroup.controls.shipDate.setValue(this.track3ShipmentDetails.shipDate);
    }
  }

  populateStartFormGroupWithChannelPartnerShipmentInfo(shipmentInfo: IChannelPartnerShipmentDetails) {
    if (shipmentInfo?.originCountryCode && this.countryList.find(x => x.key == shipmentInfo?.originCountryCode)) {
      this.startFormGroup.controls.sender.get('country').setValue(shipmentInfo.originCountryCode);
    }
    if (shipmentInfo?.originCity) {
      this.startFormGroup.controls.sender.get('city').setValue(shipmentInfo.originCity);
      this.startFormGroup.controls.sender.get('city').markAsTouched();
    }
    if (shipmentInfo?.shipperName) {
      this.startFormGroup.controls.sender.get('company').setValue(shipmentInfo.shipperName);
      this.startFormGroup.controls.sender.get('company').markAsTouched();
    }
    if (shipmentInfo?.originPostalCode) {
      this.startFormGroup.controls.sender.get('zipCode').setValue(shipmentInfo.originPostalCode);
      this.startFormGroup.controls.sender.get('zipCode').markAsTouched();
    }
    if (shipmentInfo?.originState && (this.stateList.find(x => x.state == shipmentInfo?.originState))) {
      this.startFormGroup.controls.sender.get('state').setValue(this.stateList.find(x => x.state == shipmentInfo.originState).id);
    }
    if (shipmentInfo?.destinationCountryCode && this.countryList.find(x => x.key == shipmentInfo?.destinationCountryCode)) {
      this.startFormGroup.controls.recipient.get('country').setValue(shipmentInfo.destinationCountryCode);
    }
    if (shipmentInfo?.destinationCity) {
      this.startFormGroup.controls.recipient.get('city').setValue(shipmentInfo.destinationCity);
      this.startFormGroup.controls.recipient.get('city').markAsTouched();
    }
    if (shipmentInfo?.consigneeName) {
      this.startFormGroup.controls.recipient.get('company').setValue(shipmentInfo.consigneeName);
      this.startFormGroup.controls.recipient.get('company').markAsTouched();
    }
    if (shipmentInfo?.destinationPostalCode) {
      this.startFormGroup.controls.recipient.get('zipCode').setValue(shipmentInfo.destinationPostalCode);
      this.startFormGroup.controls.recipient.get('zipCode').markAsTouched();
    }
    if (shipmentInfo?.destinationState && this.stateList.find(x => x.stae == shipmentInfo.destinationState)) {
      this.startFormGroup.controls.recipient.get('state').setValue(this.stateList.find(x => x.state == shipmentInfo.destinationState).id);
    }
    if (shipmentInfo?.shipDate) {
      this.startFormGroup.controls.shipDate.setValue(shipmentInfo.shipDate);
    }
  }

  populateAllFormsWithIShipData(tupssInfo: IShipInterface) {
    this.senderStateDisplay = tupssInfo.cdsresponse.shipmentInfo.fromAddress.stateProv;
    this.recipientStateDisplay = tupssInfo.cdsresponse.shipmentInfo.toAddress.stateProv;
    this.whatHappenedFormGroup.controls.shipping.setValue(this.calculateShippingCharge(tupssInfo));
    this.contactInfoFormGroup.controls.name.setValue(tupssInfo.cdsresponse.shipmentInfo.fromAddress.company);
    this.contactInfoFormGroup.controls.email.setValue(tupssInfo.cdsresponse.shipmentInfo.fromAddress.email);
    this.contactInfoFormGroup.controls.phone.setValue(this.phonePipe.transform(tupssInfo.cdsresponse.shipmentInfo.fromAddress.telephone));
    this.contactInfoFormGroup.controls.addressLine1.setValue(tupssInfo.cdsresponse.shipmentInfo.fromAddress.address1);
    this.contactInfoFormGroup.controls.addressLine2.setValue(tupssInfo.cdsresponse.shipmentInfo.fromAddress.address2);
    this.contactInfoFormGroup.controls.state.setValue(tupssInfo.cdsresponse.shipmentInfo.fromAddress.stateProv);
    this.contactInfoFormGroup.controls.city.setValue(tupssInfo.cdsresponse.shipmentInfo.fromAddress.city);
    this.contactInfoFormGroup.controls.zip.setValue(tupssInfo.cdsresponse.shipmentInfo.fromAddress.postal);

    //If fields come as empty, make them editable
    if (!this.contactInfoFormGroup.controls.name.value) {
      this.contactInfoFormGroup.controls.name.reset({ value: '', disabled: false });
    }
    if (!this.contactInfoFormGroup.controls.email.value) {
      this.contactInfoFormGroup.controls.email.reset({ value: '', disabled: false });
    }
    if (!this.contactInfoFormGroup.controls.phone.value) {
      this.contactInfoFormGroup.controls.phone.reset({ value: '', disabled: false });
    }
    if (!this.contactInfoFormGroup.controls.addressLine1.value) {
      this.contactInfoFormGroup.controls.addressLine1.reset({ value: '', disabled: false });
    }
    if (!this.contactInfoFormGroup.controls.state.value) {
      this.contactInfoFormGroup.controls.state.reset({ value: '', disabled: false });
    }
    if (!this.contactInfoFormGroup.controls.city.value) {
      this.contactInfoFormGroup.controls.city.reset({ value: '', disabled: false });
    }
    if (!this.contactInfoFormGroup.controls.zip.value) {
      this.contactInfoFormGroup.controls.zip.reset({ value: '', disabled: false });
    }

    //Load store details in case it is needed in error message
    let deliveryEmail = tupssInfo.cdsresponse.shipmentInfo.siteInfo.email;
    if (deliveryEmail) {
      this.lateDeliveryEmail = tupssInfo.cdsresponse.shipmentInfo.siteInfo.email;
    } else {
      this.lateDeliveryEmail = "store" + tupssInfo.cdsresponse.shipmentInfo.siteInfo.storeNumber + "@theupsstore.com";
    }

    let deliveryPhone = tupssInfo.cdsresponse.shipmentInfo.siteInfo.telephone;
    if (deliveryPhone) {
      this.lateDeliveryPhone = deliveryPhone;
      this.lateDeliveryPhoneFormatted = "(" + deliveryPhone.substring(0, 3) + ") " + deliveryPhone.substring(3, 6) + "-" + deliveryPhone.substring(6, 10);
    } else {
      this.lateDeliveryPhone = "";
      this.lateDeliveryPhoneFormatted = "";
    }

    this.dataService.findOriginScan("", "hardcode"); //check for hardcoded values

    //Set some flags. Not really sure if these are used anymore.
    if (this.claim.trackingNumber_Ext.toUpperCase().startsWith('1Z')) {
      this.ups = true;
    } else {
      this.ups = false;
    }

    this.dataService.formatPayloadBeforeAnyAPIRequest();

    //Component of course handles the steps / navigations
    this.claimService.createClaim(this.dataService._commonClaimsInterface).subscribe(
      response => {
        this.continuing = false;
        if (response.claimNumber) {
          this.setStep(event, 2);
          this.persistClaimNumber = response.claimNumber;
          this.persistPublicId = response.publicId;
          this.initialClaimCreation = false;
          this.systemAPIError = false;
        } else {
          this.systemAPIError = true;
        }
      }, error => {
        this.continuing = false;
        this.systemAPIError = true;
        this.logInterface.routeName = this.router.url;
        this.logInterface.exceptionMessage = error.toString();
        this.utilityService.uiLog(this.logInterface).subscribe(
          data => { },
          error => { }
        );
      }
    );
  }

  myChoiceCreateClaim() {
    this.continuing = true;
    this.dataService.getTrack3ShipmentDetails().subscribe(
      data => {
        if (data == null) {
          return;
        } else {
          this.track3ShipmentDetails = data;
          this.populateStartFormGroupWithTrack3();
          this.dataService.populateClaimInterfaceUsingTrack3Details(this.track3ShipmentDetails);
          this.dataService.formatPayloadBeforeAnyAPIRequest();
          //Component of course handles the steps / navigations
          this.claimService.createClaim(this.dataService._commonClaimsInterface).subscribe(
            response => {
              this.continuing = false;
              if (response.claimNumber) {
                this.setStep(event, 2);
                this.persistClaimNumber = response.claimNumber;
                this.persistPublicId = response.publicId;
                this.initialClaimCreation = false;
                this.systemAPIError = false;
              } else {
                this.systemAPIError = true;
              }
            }, error => {
              this.continuing = false;
              this.systemAPIError = true;
              this.logInterface.routeName = this.router.url;
              this.logInterface.exceptionMessage = error.toString();
              this.utilityService.uiLog(this.logInterface).subscribe(
                data => { },
                error => { }
              );
            }
          );
        }
      }, error => { }
    );
  }

  customizedClaimsCreateClaim() {
    this.continuing = true;
    this.dataService.getChannelPartnerShipmentDetails().subscribe(
      data => {
        if (data == null) {
          return;
        } else {
          this.populateStartFormGroupWithChannelPartnerShipmentInfo(data);
          this.dataService.populateClaimInterfaceUsingChannelPartnerInfo(data);
          this.dataService.formatPayloadBeforeAnyAPIRequest();

          this.claimService.createClaim(this.dataService._commonClaimsInterface).subscribe(
            response => {
              this.continuing = false;
              if (response.claimNumber) {
                if (this.flowType != 'pirateship') {
                  this.setStep(event, 2);
                }
                this.persistClaimNumber = response.claimNumber;
                this.persistPublicId = response.publicId;
                this.initialClaimCreation = false;
                this.systemAPIError = false;
              } else {
                this.systemAPIError = true;
              }
            }, error => {
              this.continuing = false;
              this.systemAPIError = true;
              this.logInterface.routeName = this.router.url;
              this.logInterface.exceptionMessage = error.toString();
              this.utilityService.uiLog(this.logInterface).subscribe(
                data => { },
                error => { }
              );
            }
          );
        }
      }, error => { }
    );
  }

  //Input: 1Z tracking number, Output: CreateClaim with model filled with Track 3 + iShip.
  //Track 3 data is potentially overwritten by iShip API details.
  get1ZTrackingData() {
    this.continuing = true;
    let trackingNumber = this.userService.getUserInfo().trackingNumber?.toUpperCase();
    this.dataService.gatherTrack3ShipmentDetails(trackingNumber); //Simply gathers the data in the service
    this.dataService.getTrack3ShipmentDetails().subscribe(
      data => {
        if (data == null) {
          //Behavior Subjects have a quirk where the first .subscribe() of it will produce 2 calls - 1 for instantiation (setting the value to null), and 1 for when we actually set the data in gatherTrack3ShipmentDetails().
          //So to prevent the 2 calls from firing, check if the data is null. If it is null, this is just the initial instantiation.
          return;
        }
        //At this point, data could be a filled in track3 model, or an EMPTY one if the API returned no data.
        this.track3ShipmentDetails = data;
        this.populateStartFormGroupWithTrack3(); //Populate some of the startFormGroup using values from track 3. 

        //At this point, we have a bit of data inside of startFormGroup, and our Data Service has all of the track 3 data.
        //Next step is attempt to put track3 data into the claims interface. It will either be full of data or empty depending on if the API had any response.
        this.dataService.populateClaimInterfaceUsingTrack3Details(this.track3ShipmentDetails);

        //Then try to put iShipData into the claims interface. iShip should have data otherwise this process is broken.
        this.dataService.populateClaimInterfaceUsingIShipData();
        this.persistIShipData = this.dataService._iShipDetails; //Needs to be called after populateIShip function
        this.getPackedBy(this.dataService._iShipDetails);
        this.getCoverageType(this.dataService._iShipDetails);

        //Finally, call create claim
        this.populateAllFormsWithIShipData(this.dataService._iShipDetails);
      }, error => {
        //This should never really error as it is subscribing to a behavior subject in our service.
        //In any case, set track3 shipment details to an empty object and call populateishipdata.
        this.track3ShipmentDetails = emptyShippingDetails;
        this.dataService.populateClaimInterfaceUsingIShipData();
        //Finally, call create claim
        this.populateAllFormsWithIShipData(this.dataService._iShipDetails);
      }
    );
  }
  //Sorts an array alphabetically
  //If needing case insensitive, add .toUpperCase()
  //To use: this.someArray.sort(this.compare);
  public compare(a, b) {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  }

  //Loads all MetaData for claims flow
  public getMetaData() {
    this.claimService.getMetaData().subscribe(
      data => {
        try {
          this.countryList = Object.entries(data.metaData.country).map(([key, value]) => ({ key, value }));
          this.countryList.sort(this.compare);
          if (this.isCanadaUser) {
            this.currencyList = { 'cad': 'CAD', 'usd': 'USD' }
          }
          if (this.isUsaUser) {
            this.currencyList = { 'usd': 'USD' }
          }
          else if (this.isUkUser) {
            this.currencyList = { 'gbp': 'GBP', 'usd': 'USD', 'eur': 'EUR' }
          }
          else if (this.isGermanyUser || this.isFranceUser || this.isItalyUser) {
            this.currencyList = { 'eur': 'EUR', 'gbp': 'GBP', 'usd': 'USD', }
          }

          this.categoryList = Object.entries(data.metaData.productCategory).map(([key, value]) => ({ key, value }));

          if (Object.keys(data.metaData.transportationid_Ext).find(x => x === 'UPS Freight')) { //UPS Freight needs to be removed from the carrier list
            delete data.metaData.transportationid_Ext['UPS Freight'];
          }
          //Load certain carriers based on if the policy is Cargo or Flex
          if (this.flex) {
            this.carrierList = Object.fromEntries(Object.entries(limitedCarriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
          } else {
            this.carrierList = data.metaData.transportationid_Ext;
          }
        } catch (e) {
          //Load local metadata
          this.loadLocalMetaData();
        }
      }, error => {
        // Load local metadata
        this.loadLocalMetaData();
      }
    );
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  loadLocalMetaData() {
    this.countryList = Object.entries(countries).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.country.${key}`) } });//translated countries
    this.countryList.sort(this.compare);
    this.currencyList = { 'usd': 'USD', 'cad': 'CAD', 'eur': 'EUR', 'gbp': 'GBP' }
    this.categoryList = Object.entries(productCategory).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.productCategory.${key}`) } }); //translated categories
    //Load certain carriers based on if the policy is Cargo or Flex
    if (this.flex) {
      this.carrierList = Object.fromEntries(Object.entries(limitedCarriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
    } else {
      this.carrierList = Object.fromEntries(Object.entries(carriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
    }
  }

  //Used to manually check all fields & show errors
  validateAllFormFields(formGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  instantiateEmptyClaimModel() {
    //Hold policy information in policy variable to use in future
    let tempFix = JSON.parse(sessionStorage.getItem('policyDetails'));
    //upon refreshing page for tfcp/navigating to dpat, we lose the ability to call policyDetails from tupss service
    //so if we cannot find it from tupss service, find it from sessionStorage to use 
    this.policy = this.tupssType == 'tfcp' ? (this.tupssService.userData ? this.tupssService.userData.policyDetails : tempFix) : this.loginService.getPolicyDetails(); //tfcp comes from service variable. tccp comes from session data.
    if (!this.policy && (this.myChoiceFlow || this.customizedClaimsPortalFlow || this.ajgFlow || this.inxpressFlow || this.upshealthcareFlow || this.freightcenterincFlow)) {  //Doing this check simply stops the process, and it will eventually re-route back to the login page.
      return;
    }

    if (this.Object.keys(this.policy).length != 0) {
      this.claim = emptyClaim;
      this.claim.policy.policyNumber = this.policy?.policyNumber;
      this.claim.policy.effectiveDate = this.policy?.effectiveDate;
      this.claim.policy.expirationDate = this.policy?.expirationDate;
      this.claim.policy.insured = this.policy?.insured;
      this.claim.policy.policyType = this.policy?.policyType.key;
      this.persistEffectiveDate = new Date(this.loginService.getPolicyDetails()?.effectiveDate);
      this.persistExpirationDate = new Date(this.loginService.getPolicyDetails()?.expirationDate);
    }
    if (this.userDetails?.userRoleID == '1') {
      this.claim.userRole = 'ONL-Admin';  //Must have no spaces
    } else if (this.userDetails?.userRoleID == '3') {
      this.claim.userRole = 'ONL-Guest';
    } else if (this.userDetails?.userRoleID == '4') {
      this.claim.userRole = 'ONL-COI';
    }
  }

  gatherFormValues() {
    //Payee Data
    this.claim.payeeId = this.persistPayeeInfo ? this.persistPayeeInfo.payeeID : '';

    //StartFormGroup
    if (this.startFormGroup.valid) {
      this.claim.trackingNumber_Ext = this.startFormGroup.value.trackingNumber ? this.startFormGroup.value.trackingNumber : "";
      this.claim.shippingDetailsDTO.shipmentIdentificationNumber = this.startFormGroup.value.trackingNumber ? this.startFormGroup.value.trackingNumber : "";
      this.claim.transportationCarrierId_Ext = this.startFormGroup.value.carrier ? this.startFormGroup.value.carrier : "";
      this.claim.shippingDetailsDTO.senderDetails.companyName = this.startFormGroup.value.sender.company ? this.startFormGroup.value.sender.company : "";
      this.claim.shippingDetailsDTO.senderDetails.city = this.startFormGroup.value.sender.city ? this.startFormGroup.value.sender.city : "";
      this.claim.shippingDetailsDTO.senderDetails.state = this.startFormGroup.value.sender.state ? this.stateList[this.startFormGroup.value.sender.state - 1].state : "NA";
      this.claim.shippingDetailsDTO.senderDetails.zipCode = this.startFormGroup.value.sender.zipCode ? this.startFormGroup.value.sender.zipCode : "";
      this.claim.shippingDetailsDTO.shipperPostalCode = this.startFormGroup.value.sender.zipCode ? this.startFormGroup.value.sender.zipCode : "";
      this.claim.shippingDetailsDTO.senderDetails.country = this.startFormGroup.value.sender.country ? this.startFormGroup.value.sender.country : "";
      this.claim.shippingDetailsDTO.receiverDetails.companyName = this.startFormGroup.value.recipient.company ? this.startFormGroup.value.recipient.company : "";
      this.claim.shippingDetailsDTO.receiverDetails.city = this.startFormGroup.value.recipient.city ? this.startFormGroup.value.recipient.city : "";
      this.claim.shippingDetailsDTO.receiverDetails.state = this.startFormGroup.value.recipient.state ? this.stateList[this.startFormGroup.value.recipient.state - 1].state : "NA";
      this.claim.shippingDetailsDTO.receiverDetails.zipCode = this.startFormGroup.value.recipient.zipCode ? this.startFormGroup.value.recipient.zipCode : "";
      this.claim.shippingDetailsDTO.consigneePostalCode = this.startFormGroup.value.recipient.zipCode ? this.startFormGroup.value.recipient.zipCode : "";
      this.claim.shippingDetailsDTO.receiverDetails.country = this.startFormGroup.value.recipient.country ? this.startFormGroup.value.recipient.country : "";
      this.claim.shippingDetailsDTO.shipDate = this.startFormGroup.value.shipDate ? this.startFormGroup.value.shipDate : "";
      this.claim.lossDate = this.startFormGroup.value.shipDate ? this.startFormGroup.value.shipDate : "";
      this.claim.shippingDetailsDTO.referenceNumber = this.startFormGroup.value.referenceNumber ? this.startFormGroup.value.referenceNumber : "";
      this.senderStateDisplay = this.startFormGroup.value.sender.state ? this.stateList[this.startFormGroup.get('sender').get('state').value - 1].state : "";
      this.recipientStateDisplay = this.startFormGroup.value.recipient.state ? this.stateList[this.startFormGroup.get('recipient').get('state').value - 1].state : "";
    }
    //WhatHappenedFormGroup
    if (this.whatHappenedFormGroup.valid) {
      if (this.whatHappenedFormGroup != null) {
        if (this.whatHappenedFormGroup.get('reason').value == 0) {
          this.claim.lossCause = "loss_Ext";
        } else if (this.whatHappenedFormGroup.get('reason').value == 1) {
          this.claim.lossCause = "damage_Ext";
        } else if (this.whatHappenedFormGroup.get('reason').value == 2) {
          this.claim.lossCause = "fallout_Ext";
        } else if (this.whatHappenedFormGroup.get('reason').value == 3 || this.whatHappenedFormGroup.get('reason').value == 5) {
          this.claim.lossCause = "delay";
        }
      }
      this.claim.purposeOfShipment = this.whatHappenedFormGroup.controls.purpose.value;
      this.claim.productCategory_Ext = this.whatHappenedFormGroup.value.category.key ? this.whatHappenedFormGroup.value.category.key : "";
      this.claim.shippingDetailsDTO.merchandiseDescription = this.whatHappenedFormGroup.value.description;
      this.claim.upsmerchandise_Ext = "Description of merchandise in detail = " + this.whatHappenedFormGroup.value.description;
      this.claim.shippingDetailsDTO.insuredValueCurrency = this.currencyKey;
      this.claim.shippingDetailsDTO.merchandiseValue = this.merchandiseValue.value.toString();
      this.claim.shippingDetailsDTO.verifiedShippingCharge = this.shippingValue.value.toString();
      this.claim.shippingDetailsDTO.insuredAmount = this.totalAmount.value.toString();
      this.claim.claimAmount_Ext = this.totalAmount.value.toString();
      this.claim.additionalNotes = this.whatHappenedFormGroup.value.additionalComments;
      this.claim.customerNotes = this.whatHappenedFormGroup.value.additionalComments;
      this.claim.quantity = this.whatHappenedFormGroup.value.quantity;
      if (this.loss) {
        this.claim.lossDescription = this.formLossDescription();
        this.claim.reshipTrackingNumber = this.whatHappenedFormGroup.value.lossReship;
        this.claim.creditMemoRefundNo = this.whatHappenedFormGroup.value.lossRefund;
        this.claim.shipmentStatus = ""; //Shipment Status only used in Damage and Missing
      } else if (this.damage) {
        this.claim.creditMemoRefundNo = ""; //CreditMemoRefundNo only used in Loss and Late
        this.claim.reshipTrackingNumber = ""; //ReshipTrackingNumber only used in Loss and Late
        this.claim.lossDescription = this.formLossDescription();
        this.repairableDisplay = this.repairables[this.whatHappenedFormGroup.value.damageRepairable].text;
        if (this.whatHappenedFormGroup.value.damageLocations < 3) {
          if (this.whatHappenedFormGroup.value.damageLocations != '') {
            this.locationDisplay = this.damagedLocations[this.whatHappenedFormGroup.value.damageLocations].text;
            this.claim.shipmentStatus = this.damagedLocations[this.whatHappenedFormGroup.value.damageLocations].text;
          }
        } else if (this.whatHappenedFormGroup.value.damageLocations == 3) {
          this.claim.shipmentStatus = this.whatHappenedFormGroup.value.damageOther;
        }
      } else if (this.missing) {
        this.claim.creditMemoRefundNo = ""; //CreditMemoRefundNo only used in Loss and Late
        this.claim.reshipTrackingNumber = ""; //ReshipTrackingNumber only used in Loss and Late
        this.claim.lossDescription = this.formLossDescription();
        if (this.whatHappenedFormGroup.value.missingLocations < 3) {
          if (this.whatHappenedFormGroup.value.missingLocations != '') {
            this.locationDisplay = this.damagedLocations[this.whatHappenedFormGroup.value.missingLocations].text;
            this.claim.shipmentStatus = this.damagedLocations[this.whatHappenedFormGroup.value.missingLocations].text;
          }
        } else if (this.whatHappenedFormGroup.value.missingLocations == 3) {
          this.claim.shipmentStatus = this.whatHappenedFormGroup.value.missingOther;
        }
      } else if (this.late) {
        this.claim.lossDescription = this.formLossDescription();
        this.claim.reshipTrackingNumber = this.whatHappenedFormGroup.value.lateReship;
        this.claim.creditMemoRefundNo = this.whatHappenedFormGroup.value.lateRefund;
        this.claim.shipmentStatus = ""; //Shipment Status only used in Damage and Missing
      }
    }
    //Contact Info Form Group
    if (!this.contactInfoFormGroup.invalid) {
      this.claim.mainContact.firstName = this.contactInfoFormGroup.controls.name.value;
      this.claim.mainContact.workNumber = this.contactInfoFormGroup.controls.phone.value;
      this.claim.mainContact.emailAddress1 = this.contactInfoFormGroup.controls.email.value;
      this.mailingInfo.insured = this.contactInfoFormGroup.controls.name.value;
      this.mailingInfo.streetAddress1 = this.contactInfoFormGroup.controls.addressLine1.value;
      this.mailingInfo.streetAddress2 = this.contactInfoFormGroup.controls.addressLine2.value;
      this.mailingInfo.city = this.contactInfoFormGroup.controls.city.value;
      this.mailingInfo.state = this.contactInfoFormGroup.controls.state.value;
      this.mailingInfo.zip = this.contactInfoFormGroup.controls.zip.value;
      this.mailingInfo.country = 'US';

      if (this.differentContactFormGroup.valid) {
        this.claim.mainContact.firstName = this.differentContactFormGroup.controls.name.value;
        this.claim.mainContact.workNumber = this.differentContactFormGroup.controls.phone.value;
        this.claim.mainContact.emailAddress1 = this.differentContactFormGroup.controls.email.value;
      }
    }

    if (this.flowType === 'pirateship') {
      this.claim.mainContact.emailAddress1 = sessionStorage.getItem('emailAddress');
    }

    //Everytime we gather form details we want to update the data service with the new claim model
    this.dataService.updateCommonClaimObject(this.claim);
  }

  //Only called once, after user moves to second page of claims flow for the first time
  public createClaim(event?) {
    this.continuing = true;
    this.errorNotification = false;
    if (event) {
      event.preventDefault();
    }
    if (this.dataService.flowType == 'my-choice') {
      this.myChoiceCreateClaim();
    }
    // check if current flow is for channel partner portals
    else if (this.dataService.checkForCcp() === true) {
      this.customizedClaimsCreateClaim();
    } else {
      //Tupss
      //Get track3 data now, this function will subsequently populate the claim, populate iShipData, & call create claim api
      this.get1ZTrackingData();
    }
  }

  //Everytime user clicks Continue button, saveClaim is called
  //For tupss this is specifically called on what-happened form page.
  public saveClaim2(event) {
    this.clearDocumentErrors();
    if (this.whatHappenedFormGroup.valid) {
      this.continuing = true;
      this.errorNotification = false;
      this.systemAPIError = false;
      event.preventDefault();

      this.claim = this.dataService._commonClaimsInterface;
      //Need these 4 values prepopulated for the save claim API.
      this.claim.claimNumber = this.persistClaimNumber;
      this.claim.publicID = this.persistPublicId;
      this.claim.lossType = "PR";
      this.claim.lossCause = "loss_Ext";
      this.gatherFormValues();
      this.dataService.formatPayloadBeforeAnyAPIRequest();
      this.gatherStep2ValuesOnly(); //save step 2 form field data to put into sessionstorage later
      this.claimService.saveClaim(this.dataService._commonClaimsInterface).subscribe(
        data => {
          if (data.claimNumber) {
            // store claim info so when dpat navigates back to tupss, we can retrieve this info (it gets lost otherwise)
            sessionStorage.setItem('claim', JSON.stringify(this.claim));
            sessionStorage.setItem('claimDetails', JSON.stringify(this.claimStep2));
            let formatFileList = [];  //Needed to format the filelist this way because JSON.stringify does not work on deep object structure
            for (let i = 0; i < this.fileList.length; i++) {
              let f = {
                file: {
                  lastModified: this.fileList[i].file.lastModified,
                  name: this.fileList[i].file.name,
                  size: this.fileList[i].file.size,
                  type: this.fileList[i].file.type,
                },
                publicID: this.fileList[i].publicID
              }
              formatFileList.push(f);
            }
            sessionStorage.setItem('fileList', JSON.stringify(formatFileList));

            let request;

            if (this.dataService.flowType == 'tupss') {
              if (this.tupssType == 'tfcp') {
                this.isTfcp = true;
              } else if (this.tupssType == 'tccp') {
                this.isTfcp = false;
              }
              let persistIShipData = JSON.parse(sessionStorage.getItem('persistIShipData'));
              let checkCountry = sessionStorage.getItem('locale').slice(3, 5);
              request = {
                action: "New Payee",
                paymentDirection: "Claims",
                country: checkCountry ? checkCountry : "US",
                paymentMode: "",
                policyIdentifier: this.policy.policyNumber ? this.policy.policyNumber : "",
                email: this.userDetails.email ? this.userDetails.email : "",
                callBackURL: `${ENV.baseUrl.web}tupss/file`,
                scenario: "TUPSS",
                createdByUserId: this.userDetails.userId ? this.userDetails.userId : "",
                guestUserIndicator: "N",
                locale: sessionStorage.getItem('locale'),
                tupssFlow: this.tupssType,
                policyData: {
                  insured: this.policy.insured
                },
                iShipData: {
                  companyName: persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.company ? persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.company : "",
                  email: persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.email ? persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.email : "",
                  phone: persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.telephone ? persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.telephone : "",
                  addressLine1: persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.address1 ? persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.address1 : "",
                  addressLine2: persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.address2 ? persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.address2 : "",
                  city: persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.city ? persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.city : "",
                  state: persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.stateProv ? persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.stateProv : "",
                  zipCode: persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.postal ? persistIShipData.data.response.cdsresponse.shipmentInfo.fromAddress.postal.slice(0, 5) : "",
                  payeeId: ""
                }
              }
            } else if (this.dataService.flowType == 'my-choice') {
              let recipient = this.dataService._track3ShipmentDetails.shipperConsigneeAddress.find(x => x.description.toLowerCase() == 'shipto address');
              request = {
                action: 'New Payee',
                paymentDirection: 'Claims',
                country: 'US', //My choice is US only
                paymentMode: '',
                policyIdentifier: this.policy.policyNumber ? this.policy.policyNumber : '',
                email: JSON.parse(sessionStorage.getItem('my-choice-claim-landing')).email,
                callBackURL: `${ENV.baseUrl.web}ups-my-choice/claim-portal/file`,
                scenario: 'tupss',
                createdByUserId: '',
                guestUserIndicator: "N",
                locale: sessionStorage.getItem('locale'),
                tupssFlow: 'mychoice',
                policyData: {
                  insured: this.policy.insured
                },
                iShipData: {
                  companyName: recipient.companyName,
                  email: JSON.parse(sessionStorage.getItem('my-choice-claim-landing')).email,
                  phone: '',
                  addressLine1: recipient.addressLine,
                  addressLine2: recipient.addressLine2 ? recipient.addressLine2 : '',
                  city: recipient.city,
                  state: recipient.stateProvinceCode,
                  zipCode: recipient.postalCode,
                  payeeId: ""
                }
              }
            }
            // check if current flow is for channel partner portals
            else if (this.dataService.checkForCcp() === true) {
              var email = JSON.parse(sessionStorage.getItem('customized-claims-landing')).email;
              //get ccp request to redirect to DPAT
              request = this.dynamicFlowService.getCcpRequest(this.policy, email);
            }
            this.claimService.getRedirect(request).subscribe(
              data => {
                window.location.replace(data.data.url);
              }, error => {
                this.systemErrorDPAT = true;
              }
            );

          }
          else {
            this.systemAPIError = true;
            window.scrollTo(0, 0);
          }
        }, error => {
          this.continuing = false;
          this.logInterface.routeName = this.router.url;
          this.logInterface.exceptionMessage = error.toString();
          this.utilityService.uiLog(this.logInterface).subscribe(
            data => { },
            error => { }
          );
        }, () => {
          this.continuing = false;
        }
      );
    } else {
      this.validateAllFormFields(this.whatHappenedFormGroup);
      this.errorNotification = true;
      window.scrollTo(0, 0);
    }
  }


  gatherStep2ValuesOnly() {
    if (this.whatHappenedFormGroup.valid) {
      if (this.whatHappenedFormGroup != null) {
        if (this.whatHappenedFormGroup.get('reason').value == 0) {
          this.claimStep2.lossCause = "loss_Ext";
        } else if (this.whatHappenedFormGroup.get('reason').value == 1) {
          this.claimStep2.lossCause = "damage_Ext";
        } else if (this.whatHappenedFormGroup.get('reason').value == 2) {
          this.claimStep2.lossCause = "fallout_Ext";
        } else if (this.whatHappenedFormGroup.get('reason').value == 3) {
          this.claimStep2.lossCause = "delay";
        } else if (this.whatHappenedFormGroup.get('reason').value == 4) {
          // Since user cannot proceed past step 2 if they select late delivery, this scenario will never occur
        } else if (this.whatHappenedFormGroup.get('reason').value == 5) {
          //Unishippers late delivery - they should still be able to continue.
          this.claimStep2.lossCause = 'delay'
        }
      }
      this.claimStep2.purposeOfShipment = this.whatHappenedFormGroup.controls.purpose.value;
      this.claimStep2.productCategory_Ext = this.whatHappenedFormGroup.value.category.value;
      this.claimStep2.shippingDetailsDTO = {};
      this.claimStep2.lossDescription = {};
      this.claimStep2.shippingDetailsDTO.merchandiseDescription = this.whatHappenedFormGroup.value.description;
      this.claimStep2.shippingDetailsDTO.insuredValueCurrency = this.currencyKey;
      this.claimStep2.shippingDetailsDTO.merchandiseValue = this.merchandiseValue.value.toString();
      this.claimStep2.shippingDetailsDTO.verifiedShippingCharge = this.shippingValue.value;
      this.claimStep2.shippingDetailsDTO.insuredAmount = this.totalAmount.value.toString();
      this.claimStep2.additionalComments = this.whatHappenedFormGroup.value.additionalComments;
      this.claimStep2.quantity = this.whatHappenedFormGroup.value.quantity;
      if (this.loss) {
      } else if (this.damage) {
        if (this.whatHappenedFormGroup.value.damageLocations < 3) {
          if (this.whatHappenedFormGroup.value.damageLocations != '') {
            this.locationDisplay = this.damagedLocations[this.whatHappenedFormGroup.value.damageLocations].text;
            this.claimStep2.shipmentStatus = this.damagedLocations[this.whatHappenedFormGroup.value.damageLocations].text;
          }
        } else if (this.whatHappenedFormGroup.value.damageLocations == 3) {
          this.claimStep2.shipmentStatus = this.whatHappenedFormGroup.value.damageOther;
        }
        this.claimStep2.lossDescription.damageDescription = this.damageDescription.value;
        this.claimStep2.lossDescription.damageDate = this.damageDate.value;
        this.claimStep2.lossDescription.damageRepairable = this.damageRepairable.value;
        this.claimStep2.lossDescription.damageLocations = this.damageLocations.value;
        this.claimStep2.lossDescription.damageOther = this.damageOther.value;
      } else if (this.missing) {
        if (this.whatHappenedFormGroup.value.missingLocations < 3) {
          if (this.whatHappenedFormGroup.value.missingLocations != '') {
            this.locationDisplay = this.damagedLocations[this.whatHappenedFormGroup.value.missingLocations].text;
            this.claimStep2.shipmentStatus = this.damagedLocations[this.whatHappenedFormGroup.value.missingLocations].text;
          }
        } else if (this.whatHappenedFormGroup.value.missingLocations == 3) {
          this.claimStep2.shipmentStatus = this.whatHappenedFormGroup.value.missingOther;
        }
        this.claimStep2.lossDescription.missingDescription = this.missingDescription.value;
        this.claimStep2.lossDescription.missingDate = this.missingDate.value;
        this.claimStep2.lossDescription.missingLocations = this.missingLocations.value;
        this.claimStep2.lossDescription.missingOther = this.missingOther.value;
      }
    }
  }

  retrieveClaimInfo() {
    this.claim = JSON.parse(sessionStorage.getItem('claim'));
    this.claimInfo = JSON.parse(sessionStorage.getItem('claimDetails'));
    this.fileList = JSON.parse(sessionStorage.getItem('fileList'));
    if (!this.claim) {
      if (JSON.parse(sessionStorage.getItem('userDetails')).type == 'tccp') {
        this.router.navigate(['tccp']);
      } else if (this.dataService.flowType == 'my-choice') {
        sessionStorage.clear();
        this.router.navigate(['/ups-my-choice']);
        window.location.reload();
      } else {
        this.router.navigate(['tfcp']);
      }
    }
  }

  populateStep2() {
    this.categoryList = Object.entries(productCategory).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.productCategory.${key}`) } });
    this.currencyList = { 'usd': 'USD', 'cad': 'CAD', 'eur': 'EUR', 'gbp': 'GBP' }
    this.retrieveClaimInfo(); //we stored the entire claim including step 2 whatHappenedFormGroup data into sessionStorage, retrieve it here and re-populate the form on step 2
    // this entire function does not impact the user after successful DPAT payee creation if they just continue with the flow- 
    // this part is important if they press the back button and navigate to DPAT for a second time
    this.persistClaimNumber = this.claim.claimNumber;
    this.persistPublicId = this.claim.publicID;

    if (this.claimInfo.lossCause == "loss_Ext") {
      this.whatHappenedFormGroup.controls.reason.setValue('0');
      this.loss = true;
      this.damage = false;
      this.missing = false;
    } else if (this.claimInfo.lossCause == "damage_Ext") {
      this.whatHappenedFormGroup.controls.reason.setValue('1');
      this.loss = false;
      this.damage = true;
      this.missing = false;
    } else if (this.claimInfo.lossCause == "fallout_Ext") {
      this.whatHappenedFormGroup.controls.reason.setValue('2');
      this.loss = false;
      this.damage = false;
      this.missing = true;
    } else if (this.claimInfo.lossCause == 'delay') { //Right now just using delay for Unishippers Late Deliveries. this happens when value = "5"
      this.whatHappenedFormGroup.controls.reason.setValue('5');
      this.loss = false;
      this.damage = false;
      this.missing = false;
      this.unishippers_late_delivery = true;
    }
    this.whatHappenedFormGroup.controls.category.setValue(this.claimInfo.productCategory_Ext.toLowerCase());
    this.whatHappenedFormGroup.controls.quantity.setValue(this.claimInfo.quantity);
    this.whatHappenedFormGroup.controls.description.setValue(this.claimInfo.shippingDetailsDTO.merchandiseDescription);
    this.whatHappenedFormGroup.controls.currency.setValue(this.claimInfo.shippingDetailsDTO.insuredValueCurrency.toUpperCase());
    this.whatHappenedFormGroup.controls.merchandise.setValue(parseFloat(this.claimInfo.shippingDetailsDTO.merchandiseValue).toFixed(2));
    this.whatHappenedFormGroup.controls.shipping.setValue(parseFloat(this.claimInfo.shippingDetailsDTO.verifiedShippingCharge).toFixed(2));

    if (this.claimInfo.lossCause == "loss_Ext") {
      // nothing extra needs to be filled out in the form for Loss
    } else if (this.claimInfo.lossCause == "damage_Ext") {
      this.whatHappenedFormGroup.controls.damageDescription.setValue(this.claimInfo.lossDescription.damageDescription);
      this.whatHappenedFormGroup.controls.damageDate.setValue(this.claimInfo.lossDescription.damageDate);

      if (this.claimInfo.lossDescription.damageRepairable == '0') {
        this.whatHappenedFormGroup.controls.damageRepairable.setValue('0');
      } else if (this.claimInfo.lossDescription.damageRepairable == '1') {
        this.whatHappenedFormGroup.controls.damageRepairable.setValue('1');
      }

      if (this.claimInfo.lossDescription.damageLocations == '0') {
        this.whatHappenedFormGroup.controls.damageLocations.setValue('0');
      } else if (this.claimInfo.lossDescription.damageLocations == '1') {
        this.whatHappenedFormGroup.controls.damageLocations.setValue('1');
      } else if (this.claimInfo.lossDescription.damageLocations == '2') {
        this.whatHappenedFormGroup.controls.damageLocations.setValue('2');
      } else if (this.claimInfo.lossDescription.damageLocations == '3') {
        this.whatHappenedFormGroup.controls.damageLocations.setValue('3');
        this.whatHappenedFormGroup.controls.damageOther.setValue(this.claimInfo.lossDescription.damageOther);
      }
      this.repairableDisplay = this.repairables[this.whatHappenedFormGroup.value.damageRepairable].text;
      if (this.whatHappenedFormGroup.value.damageLocations < 3) {
        if (this.whatHappenedFormGroup.value.damageLocations != '') {
          this.locationDisplay = this.damagedLocations[this.whatHappenedFormGroup.value.damageLocations].text;
        }
      }
    } else if (this.claimInfo.lossCause == "fallout_Ext") {
      this.whatHappenedFormGroup.controls.missingDescription.setValue(this.claimInfo.lossDescription.missingDescription);
      this.whatHappenedFormGroup.controls.missingDate.setValue(this.claimInfo.lossDescription.missingDate);

      if (this.claimInfo.lossDescription.missingLocations == '0') {
        this.whatHappenedFormGroup.controls.missingLocations.setValue('0');
      } else if (this.claimInfo.lossDescription.missingLocations == '1') {
        this.whatHappenedFormGroup.controls.missingLocations.setValue('1');
      } else if (this.claimInfo.lossDescription.missingLocations == '2') {
        this.whatHappenedFormGroup.controls.missingLocations.setValue('2');
      } else if (this.claimInfo.lossDescription.missingLocations == '3') {
        this.whatHappenedFormGroup.controls.missingLocations.setValue('3');
        this.whatHappenedFormGroup.controls.missingOther.setValue(this.claimInfo.lossDescription.missingOther);
      }
      if (this.whatHappenedFormGroup.value.missingLocations < 3) {
        if (this.whatHappenedFormGroup.value.missingLocations != '') {
          this.locationDisplay = this.damagedLocations[this.whatHappenedFormGroup.value.missingLocations].text;
        }
      }
    }

    this.whatHappenedFormGroup.controls.additionalComments.setValue(this.claimInfo.additionalComments);

    if (this.claimInfo.purposeOfShipment == "personal") {
      this.whatHappenedFormGroup.controls.purpose.setValue('personal');
    } else if (this.claimInfo.purposeOfShipment == "sale") {
      this.whatHappenedFormGroup.controls.purpose.setValue('sale');
    } else if (this.claimInfo.purposeOfShipment == "other") {
      this.whatHappenedFormGroup.controls.purpose.setValue('other');
    }
  }
  contactVerificationChange(data) {
    if (data === 'same') {
      if (this.differentContactFormGroup !== undefined) {
        this.differentContactFormGroup.reset();
      }
    }
  }
  // Since we don't do address validation on our end anymore, this is the new function to go from step 3 to step 4 in the flow
  justContinue() {
    if (this.contactInfoFormGroup.invalid || !this.whoToContactFormGroup.valid || (this.whoToContactFormGroup.controls.option.value == 'different' && !this.differentContactFormGroup.valid)) {
      //Some form was invalid.
      this.validateAllFormFields(this.contactInfoFormGroup);
      if (this.whoToContactFormGroup.valid && this.whoToContactFormGroup.controls.option.value == 'different') {
        this.validateAllFormFields(this.differentContactFormGroup);
      }
      this.validateAllFormFields(this.whoToContactFormGroup);
      window.scrollTo(0, 0);
      this.continuing = false;
    } else {
      this.claim = this.dataService._commonClaimsInterface;
      if (this.whoToContactFormGroup.controls.option.value == 'same') { //same as claim payment recipient
        this.claim.mainContact.firstName = this.contactInfoFormGroup.controls.name.value;
        this.claim.mainContact.workNumber = this.contactInfoFormGroup.controls.phone.value;
        this.claim.mainContact.emailAddress1 = this.contactInfoFormGroup.controls.email.value;
      } else if (this.whoToContactFormGroup.controls.option.value == 'different') { // i would like to enter a different
        this.claim.mainContact.firstName = this.differentContactFormGroup.controls.name.value;
        this.claim.mainContact.workNumber = this.differentContactFormGroup.controls.phone.value;
        this.claim.mainContact.emailAddress1 = this.differentContactFormGroup.controls.email.value;
      }
      this.dataService.updateCommonClaimObject(this.claim);
      this.currentStep = 4;
      this.continuing = false;
    }
    // this.currentStep = 4;
    this.continuing = false;
    this.errorNotification = false;

  }
  //This function just does addressValidation(), and then it will subsequently call DPS
  public addressValidation(event) {
    this.continuing = true;
    this.errorNotification = false;

    if (this.contactInfoFormGroup.invalid || !this.whoToContactFormGroup.valid || (this.whoToContactFormGroup.controls.option.value == 'different' && !this.differentContactFormGroup.valid)) {
      //Some form was invalid.
      this.validateAllFormFields(this.contactInfoFormGroup);
      if (this.whoToContactFormGroup.valid && this.whoToContactFormGroup.controls.option.value == 'different') {
        this.validateAllFormFields(this.differentContactFormGroup);
      }
      this.validateAllFormFields(this.whoToContactFormGroup);
      this.errorNotification = true;
      this.continuing = false;
      window.scrollTo(0, 0);
    } else {
      //Forms were valid, continue with address validation
      this.gatherFormValues();
      this.claimService.addressValidation({
        customerName: this.contactInfoFormGroup.controls.name.value,
        addressLine1: this.contactInfoFormGroup.controls.addressLine1.value,
        addressLine2: this.contactInfoFormGroup.controls.addressLine2.value,
        city: this.contactInfoFormGroup.controls.city.value,
        countrycode: 'US',
        zipcode: this.contactInfoFormGroup.controls.zip.value,
        state: this.contactInfoFormGroup.controls.state.value
      }).subscribe(
        data => {
          if (data.candidateList.length > 0) {
            let hasMatch = false;
            for (let a of data.candidateList) {
              if (
                a.AddressLine[0] == this.contactInfoFormGroup.controls.addressLine1.value &&
                a.AddressLine2 == this.contactInfoFormGroup.controls.addressLine2.value &&
                a.PoliticalDivision2 == this.contactInfoFormGroup.controls.city.value &&
                a.PoliticalDivision1 == this.contactInfoFormGroup.controls.state.value &&
                `${a.PostcodePrimaryLow}-${a.PostcodeExtendedLow}` == this.contactInfoFormGroup.controls.zip.value &&
                a.CountryCode == 'US'
              ) {
                hasMatch = true;
                break;
              }
            }
            if (hasMatch) {
              //Address was perfectly entered. Continue to next part
              this.deniedPartyAndCreatePayee();
            } else {
              //Let user pick from address validation
              this.continuing = false;
              this.claimService.setTupssAddress({
                address1: this.contactInfoFormGroup.controls.addressLine1.value,
                address2: this.contactInfoFormGroup.controls.addressLine2.value,
                city: this.contactInfoFormGroup.controls.city.value,
                state: this.contactInfoFormGroup.controls.state.value,
                zip: this.contactInfoFormGroup.controls.zip.value,
              });
              this.claimService.setAddValData(data.candidateList);
              this.showAddressValidation = true;
            }
          } else {
            //If address validation returns nothing... Just continue?
            this.deniedPartyAndCreatePayee();
          }
        }, error => {
          this.deniedPartyAndCreatePayee();
          //If address validation fails... Just continue?
        }
      );
    }
  }

  addBankingInfo() {
    this.payeeService.getCardInfo(this.persistPayeeInfo.payeeID, this.userService.getUserInfo().policyNumber).subscribe(
      data => {
        if (data.accountNumberLast4) {
          //ACH was ALREADY saved for this payee. They cannot update, so just move forward.
          this.setStep(event, 4);
        } else {
          this.payeeService.initiatePublicSessionTransfer(
            {
              "payeeId": this.persistPayeeInfo.payeeID,
              "returnSessionId": this.persistPayeeInfo.payeeID,  //This will be the same as payeeId. Used to update ACH indicator after adding bank info
              "returnUrl": `${ENV.baseUrl.web}claims/bankSuccess`
            }
          ).subscribe(
            data => {
              //Check if result.code == S031 for success
              if (data.result.code == 'S031') {
                this.showACHError = false;
                this.payeeService.achURL = data.sessionTransferUrl;
                this.payeeService.recentPayeeSubmission = { ...this.persistPayeeInfo, payeeID: this.persistPayeeInfo.payeeID };
                this.showACH = true;
              } else {
                //Send error notification to service, display error message instead of iframe
                this.showACHError = true;
                this.showACH = true;
              }
            },
            error => {
              this.showACHError = true;
              this.showACH = true;
            }
          );
        }
      }
    );
  }

  updateAddress(newAddress?) {
    this.claimService.setLoading(true);
    this.errorNotification = false;
    this.systemAPIError = false;
    if (newAddress && !(newAddress == 'edit')) {
      let adline1 = '';
      //Update new address for contact info.. then call deniedPartyAndCreatePayee();
      for (let i = 0; i < newAddress.AddressLine.length; i++) {
        adline1 += newAddress.AddressLine[i] + " ";
      }
      this.contactInfoFormGroup.controls.addressLine1.setValue(adline1.trim());
      this.contactInfoFormGroup.controls.addressLine2.setValue(newAddress.AddressLine2);
      this.contactInfoFormGroup.controls.city.setValue(newAddress.PoliticalDivision2);
      this.contactInfoFormGroup.controls.state.setValue(newAddress.PoliticalDivision1);
      this.contactInfoFormGroup.controls.zip.setValue(`${newAddress.PostcodePrimaryLow}-${newAddress.PostcodeExtendedLow}`);
      this.deniedPartyAndCreatePayee();
    } else if (newAddress && newAddress == 'edit') {  //They wanted to go back
      this.showAddressValidation = false;
      this.claimService.setLoading(false);
    } else if (!newAddress) {  //Continue with what was originally entered
      this.claimService.getTupssAddress().subscribe(
        data => {
          this.contactInfoFormGroup.controls.addressLine1.setValue(data.address1);
          this.contactInfoFormGroup.controls.addressLine2.setValue(data.address2);
          this.contactInfoFormGroup.controls.city.setValue(data.city);
          this.contactInfoFormGroup.controls.state.setValue(data.state);
          this.contactInfoFormGroup.controls.zip.setValue(data.zip);
          this.deniedPartyAndCreatePayee();
        }
      );
    }
  }

  deniedPartyAndCreatePayee() {
    this.userService.deniedPartyCheckV2({
      company: this.contactInfoFormGroup.controls.name.value,
      address: this.contactInfoFormGroup.controls.addressLine1.value,
      city: this.contactInfoFormGroup.controls.city.value,
      state: this.contactInfoFormGroup.controls.state.value,
      country: 'US',
      zip: this.contactInfoFormGroup.controls.zip.value
    }).subscribe(
      deniedPartyFound => {
        this.errorNotification = false;
        this.systemAPIError = false;
        if (!deniedPartyFound) {
          this.showDPSDenied = false;
          this.showDPSUnavailable = false;
          this.createPayee();
        } else {
          this.payeeService.dpsUnavailable = false;
          this.continuing = false;
          this.showAddressValidation = false;
          this.claimService.setLoading(false);
          this.showDPSDenied = true;
        }
      }, error => {
        window.scrollTo(0, 0);
        this.errorNotification = true;
        this.showAddressValidation = false;
        this.payeeService.dpsUnavailable = true;
        this.continuing = false;
        this.showDPSUnavailable = true;
        this.claimService.setLoading(false);
      }
    );
  }

  createPayee() {
    if (!this.contactInfoFormGroup.invalid) {
      this.continuing = true;
      this.payeeService.createPayee({
        userId: '-987654321',
        createdByUserId: '-987654321',
        policyNumber: this.policy.policyNumber,
        policyCountry: 'US',
        emailAddress: this.contactInfoFormGroup.controls.email.value,
        payeeName: this.contactInfoFormGroup.controls.name.value,
        domesticPhoneNumber: this.contactInfoFormGroup.controls.phone.value.replaceAll('.', ''),
        guestAccessIndicator: 'F',
        domesticMailingAddress: {
          streetAddress1: this.contactInfoFormGroup.controls.addressLine1.value,
          streetAddress2: this.contactInfoFormGroup.controls.addressLine2.value == null ? '' : this.contactInfoFormGroup.controls.addressLine2.value,
          city: this.contactInfoFormGroup.controls.city.value,
          stateProvince: this.contactInfoFormGroup.controls.state.value,
          zip: this.contactInfoFormGroup.controls.zip.value.split("-")[0] ? this.contactInfoFormGroup.controls.zip.value.split("-")[0] : this.contactInfoFormGroup.controls.zip.value,
          country: 'US'
        },
        payeeId: this.persistPayeeInfo ? this.persistPayeeInfo.payeeID : ''
      }).subscribe(
        data => {
          this.errorNotification = false;
          //Attach payeeID to claim requests
          this.claim.payeeId = data.payeeID;
          this.persistPayeeInfo = {
            payeeID: data.payeeID,
            payeeName: this.contactInfoFormGroup.controls.name.value,
            payeeEmail: this.contactInfoFormGroup.controls.email.value,
            payeePhone: this.contactInfoFormGroup.controls.phone.value,
            address: {
              streetAddress1: this.contactInfoFormGroup.controls.addressLine1.value,
              streetAddress2: this.contactInfoFormGroup.controls.addressLine2.value,
              city: this.contactInfoFormGroup.controls.city.value,
              stateProvince: this.contactInfoFormGroup.controls.state.value,
              zip: this.contactInfoFormGroup.controls.zip.value,
              country: 'US'
            },
            achIndicator: 'F'
          };
          // this.saveClaim3();
        }, error => {
          this.continuing = false;
          this.errorNotification = true;
          this.systemAPIError = true;
        }
      );
    } else {
      this.validateAllFormFields(this.submitClaimFormGroup);
      this.errorNotification = true;
      window.scrollTo(0, 0);
    }
  }

  achContinue(event) {
    this.continuing = true;
    this.payeeService.getCardInfo(this.persistPayeeInfo.payeeID, this.userService.getUserInfo().policyNumber).subscribe(
      data => {
        if (data.accountNumberLast4) {
          //ACH was saved. Update the ACH info indicator & continue to next page.
          this.accountNumberLast4 = data.accountNumberLast4;
          this.payeeService.updateACHIndicator({
            payeeID: this.persistPayeeInfo.payeeID,
            achIndicator: 'T'
          }).subscribe(
            data => {
              this.showACH = false;
              this.showACHError = false;
              this.continuing = false;
              this.setStep(event, 4);
            },
            error => {
              this.showACH = false;
              this.showACHError = false;
              this.continuing = false;
              this.setStep(event, 4);
            }
          );
        } else {
          this.continuing = false;
          this.showACHError = true;
        }
      }
    );
  }

  achBack() {
    //Go back to contact info page
    this.showACH = false;
  }

  calculateTotalCharge(tupssInfo): string {
    let packageDimensions = tupssInfo.cdsresponse.shipmentInfo.packageInfo.find(x => x.trackNo.toUpperCase() == this.userService.getUserInfo().trackingNumber.toUpperCase() && x.number != '0').dimensions;
    if (!packageDimensions || !tupssInfo.cdsresponse.receipt) {
      return '0';
    } else {
      packageDimensions = packageDimensions.split('x');
      packageDimensions.forEach((o, i, a) => {
        a[i] = (Number(a[i]) / 100).toString();
        if (a[i].length == 1) {
          a[i] = `0${a[i]}`;
        }
      });
      let convertedStringDimensions = `${packageDimensions[0]}x${packageDimensions[1]}x${packageDimensions[2]}`;
      let box = tupssInfo.cdsresponse.receipt.filter(x => x.name.includes(convertedStringDimensions) && x.name.toLowerCase().includes('box'));
      let materials = tupssInfo.cdsresponse.receipt.filter(x => x.name.includes(convertedStringDimensions) && x.name.toLowerCase().includes('pack materials'));
      let service = tupssInfo.cdsresponse.receipt.filter(x => x.name.includes(convertedStringDimensions) && x.name.toLowerCase().includes('pack service'));
      let total = 0;

      //Test to see if found multiple boxes of same dimensions, ex: 12x12x12 box shows up twice in receipt[]. be wary of box, pack materials, and pack service
      if (box.length > 1 || materials.length > 1 || service.length > 1) {
        return '0';
      }
      if (box.length == 1) {
        total = total + Number(box[0].totalcharge);
      }
      if (materials.length == 1) {
        total = total + Number(materials[0].totalcharge);
      }
      if (service.length == 1) {
        total = total + Number(service[0].totalcharge);
      }
      if (box.length < 1 && materials.length < 1 && service.length < 1) {
        //If same dimensions not found in receipt, but there was 1 package, then business wants to go ahead and try to use the receipt details even though the dimensions don't match.
        if (tupssInfo.cdsresponse.shipmentInfo.totalPkgs == 1) {
          total = total + Number(this.calculateChargeOfOnePackage(tupssInfo));
        }
      }
      return total.toFixed(2);
    }
  }

  calculateChargeOfOnePackage(tupssInfo): string {
    let box = tupssInfo.cdsresponse.receipt?.filter(x => x.name.toLowerCase().includes('box'));
    let materials = tupssInfo.cdsresponse.receipt?.filter(x => x.name.toLowerCase().includes('pack materials'));
    let service = tupssInfo.cdsresponse.receipt?.filter(x => x.name.toLowerCase().includes('pack service'));
    let total = 0;

    if (box?.length == 1) {
      total = total + Number(box[0].totalcharge);
    }
    if (materials?.length == 1) {
      total = total + Number(materials[0].totalcharge);
    }
    if (service?.length == 1) {
      total = total + Number(service[0].totalcharge);
    }
    return total.toFixed(2);
  }

  calculateChargeShip(): string {
    let pkg = this.persistIShipData.data.response.cdsresponse.shipmentInfo.packageInfo.find(x => x.trackNo.toUpperCase() == this.userService.getUserInfo().trackingNumber.toUpperCase() && x.number != '0');
    return pkg && pkg.chargeShipRetail ? pkg.chargeShipRetail : '';
  }

  calculateChargeDV(): string {
    let pkg = this.persistIShipData.data.response.cdsresponse.shipmentInfo.packageInfo.find(x => x.trackNo.toUpperCase() == this.userService.getUserInfo().trackingNumber.toUpperCase() && x.number != '0');
    return pkg && pkg.chargeDVRetail ? pkg.chargeDVRetail : '';
  }

  //Final submission of claim
  public submitClaim(event?) {
    if (this.submitClaimFormGroup.valid) {
      this.errorNotification = false;
      this.continuing = true;
      this.systemAPIError = false;
      event?.preventDefault();

      this.claim = this.dataService._commonClaimsInterface;
      this.claim.payeeId = this.placeholderPayee;
      this.dataService.updateCommonClaimObject(this.claim);
      this.dataService.formatPayloadBeforeAnyAPIRequest();
      //set this.dataService_commonClaimsInterface to null
      // this.dataService._commonClaimsInterface = null;

      this.claimService.submitClaim(this.dataService._commonClaimsInterface).subscribe(
        data => {
          this.submittedClaimNumber = data.claimNumber;
          this.tupssService.claimSubmittedFor = this.userService.getUserInfo().trackingNumber;
          if (this.tupssType == 'tfcp' || this.tupssType == 'tccp') {
            sessionStorage.setItem('userDetails', JSON.stringify({ ...JSON.parse(sessionStorage.getItem('userDetails')), stage: 'post' }));
          }
          if (data.claimNumber) {
            if (this.isGuestUser) {
              let emailTemplate = emptyIEmail;
              emailTemplate.emailFor = 'ClaimDetails';
              emailTemplate.data = {
                email: this.claim.mainContact.emailAddress1,
                fName: this.claim.mainContact.firstName,
                lName: this.claim.mainContact.lastName,
                claimNumber: data.claimNumber,
                claimDetailLink: ENV.baseUrl.web + btoa(`${sessionStorage.getItem('userid')}/${data.claimNumber}`)
              }
              this.utilityService.sendGuestFilingEmail(emailTemplate).subscribe();
            } else if (this.dataService.flowType === 'my-choice') {
              this.sendMyChoiceClaimSubmissionEmail(data.claimNumber);
            }
            // check if current flow is for channel partner portals
            else if (this.dataService.checkForCcp() === true) {
              //Send Claim submission email for channel partner portals
              this.sendCCPClaimSubmissionEmail(data.claimNumber);
            } else {
              if (this.tupssType == 'tfcp') {
                let policyInfo = JSON.parse(sessionStorage.getItem('policyDetails'));
                if (this.tupssService.getSiteIds().find(x => policyInfo.policyNumber.endsWith(x)) && policyInfo) {
                  this.disableCDButtons = false;
                  this.claimService.getClaimDetails(data.claimNumber, policyInfo.policyNumber).subscribe(
                    data => {
                      this.claimService.setTupssClaimData(data);
                    }, error => { }
                  );
                } else {
                  this.disableCDButtons = true;
                }
                this.sendTFCPClaimSubmissionEmail(data.claimNumber);
              } else if (this.tupssType == 'tccp') {
                this.sendTCCPClaimSubmissionEmail(data.claimNumber);
              }
            }
            sessionStorage.removeItem('claim');
            sessionStorage.removeItem('claimDetails');
            sessionStorage.removeItem('persistIShipData');
            this.tupssService.pirateShipClaimStep = 5;
            this.setStep(event, 5);
          } else {
            this.systemAPIError = true;
            window.scrollTo(0, 0);
          }
        }, error => {
          if (this.flowType == 'pirateship') {
            this.tupssService.pirateShipClaimStep = 6;
            this.pirateShipErrorPage = this.tupssService.pirateShipClaimStep === 6;
          } else {
            this.systemAPIError = true;
          }
          this.continuing = false;
          window.scrollTo(0, 0);
          this.logInterface.routeName = this.router.url;
          this.logInterface.policyNumber = this.claim.policy.policyNumber;
          this.logInterface.exceptionMessage = error.message;
          this.logInterface.errorCode = error.status;
          this.utilityService.uiLog(this.logInterface).subscribe(
            data => { },
            error => { }
          );
        }, () => {
          this.continuing = false;
        }
      );
    } else {
      this.validateAllFormFields(this.submitClaimFormGroup);
      this.errorNotification = true;
      window.scrollTo(0, 0);
    }
  }

  public setCurrentStep() {
    this.currentStep = 1;
  }

  public reloadPage(event) {
    sessionStorage.setItem("reloading", "true");
    location.reload();
  }

  public backToClaims(event) {
    this.router.navigate(['/claims']);
  }

  public printClaim(event) {
    this.router.navigate(['/tupss/detail'], { queryParams: { claimNumber: this.submittedClaimNumber, print: 'true' } });
  }

  public exitToClaimDetails(event) {
    if (this.tupssType == 'tfcp' || this.tupssType == 'tccp') {
      this.router.navigate(['/tupss/detail'], { queryParams: { claimNumber: this.submittedClaimNumber } });
    } else if (this.myChoiceFlow) {
      this.router.navigate(['/ups-my-choice/detail'], { queryParams: { claimNumber: this.submittedClaimNumber } });
    } else {
      //redirect to claim details for ccp portals
      this.dynamicFlowService.exitToClaimDetails(this.submittedClaimNumber);
    }
  }

  onClick(event: any) {
    if (navigator.appVersion.indexOf('iPhone') > -1) {
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          let overlay = document.getElementById(`cdk-overlay-${i}`);
          if (overlay && overlay.style.top) {
            let offset = Number(overlay.style.top.split('px')[0]) - event.clientY;
            // console.log('offset - ', offset);
            if (offset > 25) {
              overlay.style.top = (event.clientY + 25) + 'px';
            }
            break;
          }
        }
      }, 200);
    }
  }

  toggleDescription(type: string) {
    switch (type) {
      case 'damage':
        this.dmgMore = !this.dmgMore;
        break;
      case 'missing':
        this.missingMore = !this.missingMore;
        break;
    }
  }

  edit(page: string) {
    switch (page) {
      case 'claim':
        if (this.flowType === 'pirateship') {
          this.currentStep = 1;
          this.tupssService.pirateShipClaimStep = 1;
        } else {
          this.currentStep = 2;
        }
        window.scrollTo(0, 0);
        break;
      case 'contact':
        this.currentStep = 3;
        window.scrollTo(0, 0);
    }
  }

  //Ask user if they want to cancel filing a claim
  errorDialog() {
    let dialogRef = this.dialog.open(ErrordialogComponent);
    dialogRef.afterClosed().subscribe();
  }

  payeeChanged(payeeInfo) {
    if (payeeInfo == false) {
      this.payeeSelectionValid = false;
      this.mobilePayeeSelectionValid = false;
    }
    else {
      if (payeeInfo.mobilePayee) {
        this.persistPayeeInfo = payeeInfo.mobilePayee;
        this.payeeService.setClaimsPayeeInfo(this.persistPayeeInfo, false);
        this.mobilePayeeSelectionValid = true;
      } else if (payeeInfo.payee) {
        this.persistPayeeInfo = payeeInfo.payee;
        this.payeeService.setClaimsPayeeInfo(this.persistPayeeInfo, true);
        this.payeeSelectionValid = true;
      }
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && charCode != 43 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkInsuredValue() {
    if (this.dataService.flowType !== 'pirateship') {
      return;
    }
  
    const shipmentDetailsString = sessionStorage.getItem('channelPartner');
    if (shipmentDetailsString) {
      const shipmentDetails = JSON.parse(shipmentDetailsString);
      const merchandise: number = this.merchandiseValue.value;
      const insuredValue: number = parseFloat(shipmentDetails.insuredValue);
      if (!insuredValue || insuredValue === 0) {
        this.hideDeclaredWarning = false;
        this.hideNoDeclaredWarning = true;
      } else {
        if (merchandise > insuredValue) {
          this.hideNoDeclaredWarning = false;
        } else  {
          this.hideNoDeclaredWarning = true;
        }
      }
    } else {
      this.hideDeclaredWarning = true;
      this.hideNoDeclaredWarning = true;
    }
  }

  //Handles notification appearing near the merchandise and shipping boxes
  //only needs to be done for tupss flow
  checkDV() {
    if (this.dataService.flowType != 'tupss') {
      return;
    }
    let iShipData = this.dataService._iShipDetails;
    let merchandise: Number = this.merchandiseValue.value;
    let declaredValue: Number = parseFloat(iShipData.cdsresponse.shipmentInfo.insuredValue_Total)
    if (!declaredValue || declaredValue == 0) {
      this.hideNoDeclaredWarning = false;
    } else {
      this.hideNoDeclaredWarning = true;
    }
    if (merchandise > declaredValue) {
      this.hideDeclaredWarning = false;
    } else {
      this.hideDeclaredWarning = true;
    }
    // if (merchandise == 0) {
    //   this.showMerchWarning = true;
    // } else {
    //   this.showMerchWarning = false;
    // }
  }

  sendTFCPClaimSubmissionEmail(claimNumber) {
    let request = emptyIEmail;
    request = {
      ...request,
      emailFor: 'TFCPClaimSubmission',
      CountryLocale: sessionStorage.getItem('locale'),
      data: {
        email: this.claim.mainContact.emailAddress1,
        claimNumber: claimNumber,
        claimDetailLink: ENV.baseUrl.web + btoa(`${this.claim.policy.policyNumber}/${claimNumber}/${this.tupssType}/${this.claim.mainContact.emailAddress1}/${this.claim.trackingNumber_Ext}`),
        firstName: this.claim.mainContact.firstName,
        lastName: '',
        companyName: this.claim.shippingDetailsDTO.senderDetails.companyName,
        city: this.claim.shippingDetailsDTO.senderDetails.city,
        zip: this.claim.shippingDetailsDTO.senderDetails.zipCode,
        state: this.claim.shippingDetailsDTO.senderDetails.state,
        streetAddress: this.contactInfoFormGroup.controls.addressLine1.value
      }
    }

    this.tupssService.sendTFCPClaimSubmissionEmail(request).subscribe(
      data => { },
      error => { }
    );
  }

  sendTCCPClaimSubmissionEmail(claimNumber) {
    let request = emptyIEmail;
    request = {
      ...request,
      emailFor: 'TCCPClaimSubmission',
      CountryLocale: sessionStorage.getItem('locale'),
      data: {
        email: this.claim.mainContact.emailAddress1,
        claimNumber: claimNumber,
        claimDetailLink: ENV.baseUrl.web + btoa(`${this.claim.policy.policyNumber}/${claimNumber}/${this.tupssType}/${this.claim.mainContact.emailAddress1}/${this.claim.trackingNumber_Ext}`),
        firstName: this.claim.mainContact.firstName,
        lastName: '',
        companyName: this.claim.shippingDetailsDTO.senderDetails.companyName,
        city: this.claim.shippingDetailsDTO.senderDetails.city,
        zip: this.claim.shippingDetailsDTO.senderDetails.zipCode,
        state: this.claim.shippingDetailsDTO.senderDetails.state,
        streetAddress: this.contactInfoFormGroup.controls.addressLine1.value
      }
    }

    this.tupssService.sendTCCPClaimSubmissionEmail(request).subscribe(
      data => { },
      error => { }
    );
  }

  sendMyChoiceClaimSubmissionEmail(claimNumber) {
    let request = emptyIEmail;
    request = {
      ...request,
      emailFor: 'MCClaimSubmission',
      CountryLocale: sessionStorage.getItem('locale'),
      data: {
        email: this.claim.mainContact.emailAddress1,
        claimNumber: claimNumber,
        claimDetailURL: ENV.baseUrl.web + btoa(`ups-my-choice/detail?policy=${this.claim.policy.policyNumber}&claimNumber=${claimNumber}&email=${this.claim.mainContact.emailAddress1}`),
        firstName: this.claim.mainContact.firstName,
        claimStatusURL: ENV.baseUrl.web + btoa(`ups-my-choice/detail?policy=${this.claim.policy.policyNumber}&claimNumber=${claimNumber}&email=${this.claim.mainContact.emailAddress1}`)
      }
    }

    this.claimService.sendMyChoiceClaimSubmissionEmail(request).subscribe(
      data => { },
      error => { }
    );
  }

  //Send Claim submission email for channel partner portals
  sendCCPClaimSubmissionEmail(claimNumber) {
    //get ccp request to send email for claim submission
    let request = this.dynamicFlowService.getCCPClaimSubmissionEmailRequest(this.claim.policy.policyNumber, this.claim.mainContact.emailAddress1,
      claimNumber, this.claim.mainContact.firstName);
    this.claimService.sendCCPClaimSubmissionEmail(request).subscribe(
      data => { },
      error => { }
    );
  }

  findOriginScan(track3Data?, reason?) {
    //sometimes people want to test with hard coded data and the below "if" block is to handle such cases

    if (ENV.hardCodedValuesForLowerEnvironments && reason == "hardcode") {
      let trackNo = JSON.parse(sessionStorage.getItem('persistIShipData'));
      let shipment = ENV.hardCodedValuesForLowerEnvironments.find(s => s['Tracking Number'] == trackNo.data.response.cdsresponse.shipmentInfo.trackNo);

      if (shipment) {
        if (shipment['Delivery Date'] && shipment['Delivery Date'] != 'N/A' && shipment['Delivery Date'].includes('/')) {
          this.claim.deliveryDate = new Date(shipment['Delivery Date']).toISOString().replace('.000Z', 'Z');
        }

        if (shipment['Origin Scan Date'] && shipment['Origin Scan Date'] != 'N/A' && shipment['Origin Scan Date'].includes('/')) {
          this.claim.originScan = new Date(shipment['Origin Scan Date']).toISOString().replace('.000Z', 'Z');
        }

        if (shipment['Expected Delivery Date'] && shipment['Expected Delivery Date'] != 'N/A' && shipment['Expected Delivery Date'].includes('/')) {
          this.claim.expectedDelivery = new Date(shipment['Expected Delivery Date']).toISOString().replace('.000Z', 'Z');
          this.claim.scheduledDeliveryDate = new Date(shipment['Expected Delivery Date']).toISOString().replace('.000Z', 'Z');
        }

        let address1 = shipment['Address 1'];
        let address2 = shipment['Address 2'];
        let city = shipment['City'];
        let state = shipment['State'];
        let zip = shipment['Zip'];
        let declaredValue = shipment['Declared Value'];
        if (address1) { this.claim.shippingDetailsDTO.receiverDetails.address1 = address1; }
        if (address2) { this.claim.shippingDetailsDTO.receiverDetails.address2 = address2; }
        if (city) { this.claim.shippingDetailsDTO.receiverDetails.city = city; }
        if (state) { this.claim.shippingDetailsDTO.receiverDetails.state = state; }
        if (zip) { this.claim.shippingDetailsDTO.receiverDetails.zipCode = zip; }
        if (declaredValue) {
          this.claim.shippingDetailsDTO.insuredValue = declaredValue;
          this.claim.tupssDeclaredValue = declaredValue;
        }

        console.log("Hardcoded Origin Scan: " + this.claim.originScan); //for UAT debugging purposes to make sure the hardcode worked
        console.log("Hardcoded Delivery Date: " + this.claim.deliveryDate);
        console.log("Hardcoded Expected/Scheduled Delivery Date: " + this.claim.expectedDelivery);
        console.log("Hardcoded Address: (see object below, address1 address2 city state zipCode)");
        console.log(this.claim.shippingDetailsDTO.receiverDetails);
        console.log("Hardcoded Declared Value: " + this.claim.tupssDeclaredValue);
        return;
      }
    }

    try {
      if (track3Data && track3Data.shipmentPackage && track3Data.shipmentPackage.activity && reason == "origin") {
        if (track3Data.shipmentPackage.activity.length > 0) {
          let originScanActivityObject = track3Data.shipmentPackage.activity.find(x => x.status.description.toLowerCase() == 'arrived at facility');
          if (originScanActivityObject && originScanActivityObject.date) {
            return new Date(`${originScanActivityObject.date.substring(0, 4)}-${originScanActivityObject.date.substring(4, 6)}-${originScanActivityObject.date.substring(6, 8)}T00:00:00`).toISOString().replace('.000Z', 'Z');
          }
          else {
            return '';
          }
        } else { return ''; }
      } else { return ''; }

    } catch {
      return '';
    }




  }

  //mark todo - we still need to call a function to set the packedBy values for the HTML to use.
  getPackedBy(tupssdata): string {
    let packed = tupssdata?.cdsresponse?.shipmentInfo?.packageInfo?.find(x => x.trackNo.toUpperCase() == this.userService.getUserInfo().trackingNumber.toUpperCase() && x.packedBy != null && x.number != '0');
    if (packed) {
      this.packedBy = packed.packedBy.startsWith('0') ? 'The UPS Store' : 'Customer'
      return packed && packed.packedBy ? packed.packedBy : '';
    } else {
      this.packedBy = '';
      return packed && packed.packedBy ? packed.packedBy : '';
    }
  }

  //mark todo - we still need to call a function to set the coverageType values for the HTML to use.
  getCoverageType(tupssdata): string {
    let coverage = tupssdata?.cdsresponse?.shipmentInfo?.packageInfo?.find(x => (x.trackNo.toUpperCase() == this.userService.getUserInfo().trackingNumber.toUpperCase()) && (x.coverageType != null) && x.number != '0');
    if (coverage) {
      this.coverageType = coverage.coverageType == 3 ? 'Flex Multi-Carrier' : coverage.coverageType == 2 ? 'Pack and Ship Guarantee' : coverage.coverageType == 1 ? 'Enhanced Declared Value' : '';
      return coverage && coverage.coverageType ? coverage.coverageType : coverage?.coverageType;
    } else {
      this.coverageType = '';
      return coverage && coverage.coverageType ? coverage.coverageType : coverage?.coverageType;
    }
  }

  calculateShippingCharge(tupssInfo): string {
    let chargeShip = '';
    let chargeDV = '';
    let totalCharge = this.calculateTotalCharge(tupssInfo);
    if (tupssInfo.cdsresponse.shipmentInfo.totalPkgs > 1) {
      let pkg = tupssInfo?.cdsresponse?.shipmentInfo?.packageInfo?.find(x => (x.trackNo == this.userService.getUserInfo().trackingNumber) && (x.number != '0'));
      if (pkg) {
        chargeShip = pkg.chargeShipRetail;
        chargeDV = pkg.chargeDVRetail;
      }
    } else {
      chargeShip = tupssInfo.cdsresponse.shipmentInfo.chargeShipRetail_Total;
      chargeDV = tupssInfo.cdsresponse.shipmentInfo.chargeDVRetail_Total;
    }

    return (Number(chargeShip) - Number(chargeDV) + Number(totalCharge)).toFixed(2);
  }

  formLossDescription() {
    let lossDescription = null;
    lossDescription = {
      shipmentRole: this.role.value,
      reason: this.reason.value
    }
    //Fill up dynamic data
    if (this.reason.value == 0) {
      //loss
      lossDescription = {
        ...lossDescription,
        care: this.lossCare.value,
        careDetails: this.lossCare.value == '0' ? this.lossReship.value : this.lossCare.value == '1' ? this.lossRefund.value : ''
      }
    } else if (this.reason.value == 1) {
      //damage
      lossDescription = {
        ...lossDescription,
        damageDescription: this.damageDescription.value,
        damageDate: this.damageDate.value,
        repairable: this.damageRepairable.value,
        packageLocation: this.damageLocations.value,
        packageLocationDetails: this.damageOther.value
      }
    } else if (this.reason.value == 2) {
      //missing
      lossDescription = {
        ...lossDescription,
        missingDescription: this.missingDescription.value,
        missingDate: this.missingDate.value,
        packageLocation: this.missingLocations.value,
        packageLocationDetails: this.missingOther.value
      }
    } else if (this.reason.value == 3) {
      //late
      lossDescription = {
        ...lossDescription,
        care: this.lateCare.value,
        careDetails: this.lateCare.value == '0' ? this.lateReship.value : this.lateCare.value == '1' ? this.lateRefund.value : ''
      }
    } else if (this.reason.value == 4) {
      // late delivery
    }
    return JSON.stringify(lossDescription);
  }


  showWhatHappenedTemplate(): boolean {
    if (this.flowType === 'pirateship') {
      // this.tupssService.pirateShipClaimStep = 1;
      return this.currentStep === 1;
    } else {
      return this.currentStep === 2;
    }
  }

  onCategorySelected(event: any, category: any): void {
    if (event.isUserInput) {
      const categoryExists = this.selectedCategories.some(category => category.value === category.value);
      if (!categoryExists) {
        this.selectedCategories.push(category);
        this.whatHappenedFormGroup.get('category').setValue('');
      }
    }
  }

  removeCategory(category: any): void {
    const index = this.selectedCategories.indexOf(category);
    if (index >= 0) {
      this.selectedCategories.splice(index, 1);
    }
  }

  getInsuredValue() {
    const shipmentDetailsString = JSON.parse(sessionStorage.getItem('channelPartner'));
    if (shipmentDetailsString) {
      this.insuredValue = shipmentDetailsString?.insuredValue;
    }
  }


  get role() { return this.startFormGroup.controls.role };
  get merchandiseValue() { return this.whatHappenedFormGroup.controls.merchandise };
  get shippingValue() { return this.whatHappenedFormGroup.controls.shipping };
  get totalAmount() { return this.whatHappenedFormGroup.controls.amount };
  get reason() { return this.whatHappenedFormGroup.controls.reason };
  get lossCare() { return this.whatHappenedFormGroup.controls.lossCare };
  get lossReship() { return this.whatHappenedFormGroup.controls.lossReship };
  get lossRefund() { return this.whatHappenedFormGroup.controls.lossRefund };
  get damageDescription() { return this.whatHappenedFormGroup.controls.damageDescription };
  get damageDate() { return this.whatHappenedFormGroup.controls.damageDate };
  get damageRepairable() { return this.whatHappenedFormGroup.controls.damageRepairable };
  get damageLocations() { return this.whatHappenedFormGroup.controls.damageLocations };
  get damageOther() { return this.whatHappenedFormGroup.controls.damageOther };
  get missingDescription() { return this.whatHappenedFormGroup.controls.missingDescription };
  get missingDate() { return this.whatHappenedFormGroup.controls.missingDate };
  get missingLocations() { return this.whatHappenedFormGroup.controls.missingLocations };
  get missingOther() { return this.whatHappenedFormGroup.controls.missingOther };
  get lateCare() { return this.whatHappenedFormGroup.controls.lateCare };
  get lateDelivery() { return this.whatHappenedFormGroup.controls.lateDelivery };
  get lateReship() { return this.whatHappenedFormGroup.controls.lateRership };
  get lateRefund() { return this.whatHappenedFormGroup.controls.lateRefund };
}
