import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-ht-terms',
  templateUrl: './ht-terms.component.html',
  styleUrls: ['./ht-terms.component.scss']
})
export class HrTermsComponent {

  constructor(public dialogRef: MatDialogRef<HrTermsComponent>) { }

  public cancel(){
    this.dialogRef.close('denied');
  }

  public confirm(){
    this.dialogRef.close('accept');
  }

}
