<form [formGroup]="confirmFormGroup">
    <div class="verification-wrapper">
      <div class="d-flex justify-content-center align-items-center flex-column">
        <div class="header">Enter the unique code sent to your email.</div>
        <div class="description">Please enter the one-time verification code that was emailed to you to confirm your
          request. If you can't find the email, check your spam folder for a message from UPS Capital Customer Support.
        </div>
      </div>
      <div class="verification-code-block">
        <div class="container-wrapper">
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" autofocus class="center-text" #num1 (keyup)="focusThis(2, $event)"
                (input)="pasted()" formControlName="firstDigit" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #num2 (keyup)="focusThis(3, $event)"
                formControlName="secondDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #num3 (keyup)="focusThis(4, $event)"
                formControlName="thirdDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #num4 (keyup)="focusThis(5, $event)"
                formControlName="fourthDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #num5 (keyup)="focusThis(6, $event)"
                formControlName="fifthDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #num6 (keyup)="focusThis(7, $event)"
                formControlName="sixthDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
        </div>
        <div class="error2" *ngIf="verifyCodeError">
          Verification failed. Please try again.
        </div>
        <div class="verification-buttons">
          <button type="button" class="upsc-white-button mr-3"
            (click)="cancel()"><mat-icon>keyboard_arrow_left</mat-icon>Cancel</button>
          <button type="button" class="upsc-button" (click)="checkVerificationCode()"
            [disabled]="confirmFormGroup.invalid">Next<mat-icon>keyboard_arrow_right</mat-icon></button>         
        </div>
      </div>
    </div>
  </form>