<table class="tbl-recipient">
    <tr class="tbl-header">
        <th>Recipient Name</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Address</th>
        <th>Payment Method</th>
    </tr>
    <tr class="tbl-row">
        <td colspan="5">
            Your approved claims will be added to your <span class="ups-wallet">UPS® Wallet</span>
        </td>
    </tr>
</table>

<div class="img-container">
    <img src="../../../../assets/images/dwallet-payment-recipient.svg">
</div>

<div class="text-container">
    With your UPS Wallet, you have several options for transferring your approved payouts, including instant transfer, ACH transfer, and Zelle. As an administrator, billing manager, or account manager, you can also enable Auto Transfer to automatically send your payouts to your designated bank account. For more details and to explore your transfer options, please log into your UPS Wallet.
</div>

<div class="btn-container">
    <div class="container-left">
        <span class="button-container-center-text">
            <button class="upsc-button">
            <div class="btn-text-icon">
              <div class="text" (click)="continue()">Continue</div><mat-icon>chevron_right</mat-icon>
            </div>
          </button>
        </span>      
    </div>
    <div class="container-right">
        <a class="link" (click)="cancel()">Cancel</a>
    </div>
</div>