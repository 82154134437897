import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { WarningDialogComponent } from './dialog/warning-dialog/warning-dialog.component';
import { LoginService } from './services/login.service';
import { AccountInfo } from './shared/models/account-info.interface';
import { UserService } from './services/user.service';
import { filter, pairwise, takeUntil } from 'rxjs/operators';
import { RouteObserverService } from './services/route-observer.service';
import { PagesThatDontNeedIdleTracking } from 'src/config/app-config';
import { ClaimService } from './services/claim.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from './services/utility.service';
import { IPolicies } from './shared/models/policies.interface';
import { environment as ENV } from '../environments/environment';
import { ScriptService } from './services/script.service';
@Component({
  selector: 'upsc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NgbTooltipConfig]
})
export class AppComponent implements OnDestroy {

  userActivityWarning;
  userActivityLogout;
  userInactiveLogout = new Subject<void>();
  userInactiveWarning = new Subject<void>();
  userDetails: AccountInfo;
  public Username: string;
  countryCode: string;
  ignoredPages = PagesThatDontNeedIdleTracking;
  policies: IPolicies;

  public constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    public dialog: MatDialog,
    public loginService: LoginService,
    public router: Router,
    private userService: UserService,
    private utilService: UtilityService,
    private claimservice: ClaimService,
    private routeObserverService: RouteObserverService,
    private scriptService: ScriptService,
    config: NgbTooltipConfig
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    // this.translateService.setDefaultLang('en');
    // this.setLanguage(this.translateService.getBrowserLang() || 'en');
    //global ngbtooltip config
    config.autoClose = false;

    sessionStorage.removeItem('isValidGuestUser');
    sessionStorage.removeItem('guestData');
    sessionStorage.removeItem('pageId');
    sessionStorage.removeItem('isadvanced');
    sessionStorage.removeItem('localefrmURL');
    this.claimservice.clearStorage();

    let urlPath = window.location.pathname.toLowerCase();

    this.policies = this.loginService.getPolicyDetails();
    if(this.policies){this.countryCode = this.policies.policyCountry_Ext;}
    if(!this.countryCode){
    //Always take locale from URL if it is present.
    if (urlPath.includes('ca-en') || urlPath.includes('en-ca') || urlPath.includes('en-gb')
    || urlPath.includes('gb-en') || urlPath.includes('en-fr') || urlPath.includes('fr-en') || urlPath.includes('fr-fr')
    || urlPath.includes('en-de') || urlPath.includes('de-en') || urlPath.includes('de-de') || urlPath.includes('en-it') || urlPath.includes('it-en')
    || urlPath.includes('it-it') || urlPath.includes('en')) {
      if (urlPath.includes('ca-en') || urlPath.includes('en-ca')) {
        this.setLanguage('en-CA');
        this.userService.setLocale('CA');
      } else if (urlPath.includes('en-gb') || urlPath.includes('gb-en')) {
        this.setLanguage('en-GB');
        this.userService.setLocale('GB');
      } else if (urlPath.includes('en-fr') || urlPath.includes('fr-en')) {
        this.setLanguage('en-FR');
        this.userService.setLocale('FR');
      } else if (urlPath.includes('fr-fr')) {
        this.setLanguage('fr-FR');
        this.userService.setLocale('FR');
      } else if (urlPath.includes('en-de') || urlPath.includes('de-en')) {
        this.setLanguage('en-DE');
        this.userService.setLocale('DE');
      } else if (urlPath.includes('de-de')) {
        this.setLanguage('de-DE');
        this.userService.setLocale('DE');
      } else if (urlPath.includes('en-it') || urlPath.includes('it-en')) {
        this.setLanguage('en-IT');
        this.userService.setLocale('IT');
      } else if (urlPath.includes('it-it')) {
        this.setLanguage('it-IT');
        this.userService.setLocale('IT-IT');
      } else {
        this.setLanguage('en');
        this.userService.setLocale('EN');
      }
    } else {  //If not in URL, take from session.
      if (sessionStorage.getItem('locale')) {
        this.setLanguage(sessionStorage.getItem('locale'));
        this.userService.setLocale(sessionStorage.getItem('locale'));
      } else {  //If locale not found anywhere, default to english.
        this.setLanguage('en');
        this.userService.setLocale('EN');
      }
    }

  }
  else {
    this.userService.setLocale(this.countryCode);
  }
    this.userService.getLocale().subscribe(locale => {
      //Locale was changed. Need to update translation now. Translation will load instantly because it subscribes to behaviorsubject.
      if (locale) {
        this.setLanguage(locale);
        if(locale.match(/^\w{2}[-_]\w{2}$/)) {
          let codes = locale.split(/[-_]/);
          document.getElementsByName('DCSext.pCC')[0].setAttribute('content', codes[1].toUpperCase());
          document.getElementsByName('DCSext.pLL')[0].setAttribute('content', codes[0].toLocaleLowerCase());
        }
        else {
          document.getElementsByName('DCSext.pCC')[0].setAttribute('content', 'US');
          document.getElementsByName('DCSext.pLL')[0].setAttribute('content', 'en');
        }
      }
    });

    //Begins keeping track of the timers
    this.setWarningTimeout();
    this.setLogoutTimeout();
    //If the inactivity reaches the threshold, run these functions
    this.userInactiveWarning.subscribe(() => this.openWarningDialog());
    this.userInactiveLogout.subscribe(() => this.logoutUser());

    this.router.events.pipe(
      filter((event: any) => event instanceof RoutesRecognized), pairwise()).subscribe(
        (events: RoutesRecognized[]) => {
          this.routeObserverService.setPreviousUrl(events[0].urlAfterRedirects);
          this.scriptService.loadGlia();
        }
      );

    this.scriptService.loadGlia();
  }

  ngOnDestroy(): void {
    sessionStorage.clear();
  }

  //Sets the warning timer to 25 minutes
  private setWarningTimeout() {
    this.userActivityWarning = setTimeout(() => this.userInactiveWarning.next(), 1500000);
  }

  //Sets the logout timer to 30 minutes
  private setLogoutTimeout() {
    this.userActivityLogout = setTimeout(() => this.userInactiveLogout.next(), 1800000);
  }

  //Opens the warning dialog if user has been idle
  private openWarningDialog() {
    if (!this.ignoredPages.find(x => this.router.url.includes(x))) {
      let dialogRef = this.dialog.open(WarningDialogComponent);
      dialogRef.afterClosed().subscribe();
    }
  }

  //Logs the user out
  private logoutUser() {
    if (!this.ignoredPages.find(x => this.router.url.includes(x))) {
      this.userDetails = this.userService.getUserInfo();
      this.Username = sessionStorage.getItem('userid');
      this.loginService.removeLocalStorage(this.Username, this.userDetails?.objectUID).subscribe();
      if(this.userService.isDeliveryDefenseUser() || sessionStorage.getItem('prospectPortal')) {
        window.location.href=`${ENV.deliveryDefense.timeoutUrl}`;
      }
      else {
        this.router.navigate(this.utilService.getURLWithLocale(['/login']));
      }
    }
  }

  //Listens for mouse movement
  //If user moves mouse, then it will reset the timer
  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivityWarning);
    clearTimeout(this.userActivityLogout);
    this.setWarningTimeout();
    this.setLogoutTimeout();
  }

  private setLanguage(lang: string) {
    if (!lang) {
      lang = 'en';
    }
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use(lang);
  }
}
