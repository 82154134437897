import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, interval,BehaviorSubject, Observable, timer } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { ApiEndpoints as API } from '../../config/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class DDOnBoardingService {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(private http: HttpClient) { } 

  // public contaxOnBoarding(request: any): Observable<any> {
  //   return this.http.post<any>(`${ENV.baseUrl.api}${API.DD_Contax_OnBoarding}`, request, this.options)
  // }
  // public contaxUpdateSubscription(request: any,customerNo: any): Observable<any> {
  //   return this.http.post<any>(`${ENV.baseUrl.api}${API.DD_Contax_Update_Subscription}/${customerNo}`, request, this.options)
  // }

}