import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as PATTERNS from '../../shared/regex-patterns';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { IPolicies } from 'src/app/shared/models/policies.interface';
import { ScriptService } from 'src/app/services/script.service';
import { BillingService } from 'src/app/services/billing.service';
import { PolicyService } from 'src/app/services/policy.service';
import { GuestLinkService } from 'src/app/services/guest-link.service';

@Component({
  selector: 'upsc-internal-user',
  templateUrl: './internal-user.component.html',
  styleUrls: ['./internal-user.component.scss']
})
export class InternalUserComponent implements OnInit {
  //Error scenarios
  systemError: boolean;
  notFound = false;

  //Data
  policyForm: UntypedFormGroup;
  policy: IPolicies;
  ccpSelfservice = {
    ccp_selfService_Check: false,
    unique_Partner_Name: ""
  };

  //Page logic
  submitting: boolean = false;
  showSMBButton: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private titleService: Title,
    private translateService: TranslateService,
    private scriptService: ScriptService,
    private billingService: BillingService,
    private policyService: PolicyService,
    private guestLinkService: GuestLinkService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.translateService.instant('app.Settitle.ups_capital_internal'));

    this.route.data.subscribe(
      data => {
        if (data.RLSAuthentication?.accessToken && data.RLSAuthentication?.message == 'Valid' && data.RLSAuthentication?.rlsUserDetails?.permissions && data.RLSAuthentication?.rlsUserDetails?.permissions.length > 0) {
          sessionStorage.setItem('access_token', data.RLSAuthentication.accessToken);
          sessionStorage.setItem('permissions', JSON.stringify(data.RLSAuthentication.rlsUserDetails.permissions));
          sessionStorage.setItem('userid', JSON.stringify(data.RLSAuthentication.rlsUserDetails.id));

          this.userService.setUserPermissions(data.RLSAuthentication.rlsUserDetails.permissions);

          let userDetails = {
            active: true,
            approved: true,
            contactName: data.RLSAuthentication.rlsUserDetails.name,
            emailAddress: data.RLSAuthentication.rlsUserDetails.email,
            firstName: data.RLSAuthentication.rlsUserDetails.given_name,
            lastName: data.RLSAuthentication.rlsUserDetails.family_name,
            permissions: data.RLSAuthentication.rlsUserDetails.permissions,
            policyHoldersName: '',
            userId: data.RLSAuthentication.rlsUserDetails.id,
            userRoleID: data.RLSAuthentication.rlsUserDetails.app_Role == 'Adjuster' ? "2" : "1",
            app_role_id: data.RLSAuthentication.rlsUserDetails.app_Role_Id,
            internalUser: true,
            userPreference: {
              userPreferenceID: '',
              preferenceName: '',
              productCategory: '',
              carrier: '',
              address: {
                addressID: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                state: '',
                zip: '',
                country: ''
              }
            },
            smbIndicator: data.RLSAuthentication.rlsUserDetails.smbIndicator
          }
          this.userService.setUserInfo(userDetails);
          sessionStorage.setItem('userDetails', JSON.stringify(userDetails));

          this.checkSMB();

          if (data.RLSAuthentication.rlsUserDetails.app_Role == 'Adjuster') {
            this.router.navigate(['adjusterDashboard']);
          }
        }
        else {
          this.router.navigate(['unauthorized']);
        }
      },
      error => {
        this.systemError = true;
      }
    )

    this.policyForm = this.fb.group({
      policy: ['', [Validators.required, Validators.pattern(PATTERNS.POLICY_PATTERN)]],
    });
  }

  startOver(event: any) {

  }

  continue() {
    this.notFound = false;
    this.systemError = false;

    if (!this.policyForm.valid) {
      this.policyForm.markAllAsTouched();
      return;
    }

    this.submitting = true;
    this.userService.getPolicySearch(this.policyForm.controls.policy.value).subscribe(
      policydata => {
        this.submitting = false;
        if (policydata?.data && policydata?.message?.toLowerCase() == 'succeeded') {
          this.policy = policydata.data;
          sessionStorage.setItem('policyDetails', JSON.stringify(policydata.data));
          if(JSON.parse(sessionStorage.getItem('userDetails'))?.app_role_id === 1) {
            this.checkPolicyExist(this.policy.policyNumber);
          }
          this.userService.updateUserInfo({
            policyNumber: policydata.data.policyNumber,
            policyHoldersName: policydata.data.insured
          });
          this.scriptService.pendoInit();

          // add DWgetSummary call here before finishing the login process and navigating user in to ONL.
          // regardless of if this call succeeds or fails, we will still navigate to the dashboard after
          let dwRequest = {
            productSourceSystem: "gw",
            sourceSystemIdentifierName: "PolicyNumber",
            sourceSystemIdentifier: policydata?.data?.policyNumber,
            country: "US",
            roleType: "string",
            productType: "string"
          }
          this.billingService.DWgetSummary(dwRequest).subscribe(
            data => {
              try {
                if (data?.data?.wallet) {
                  sessionStorage.setItem('dwDetails', JSON.stringify(data.data.wallet))
                }
                this.checkForChildPolicies();
              } catch (e) {
                this.checkForChildPolicies();
              }
            }, error => {
              this.checkForChildPolicies();
            }
          );
        } else {
          //Error was still 200, but not "succeeded", most likely it got a "nodata"
          this.notFound = true;
        }
      },
      err => {
        this.submitting = false;
        if (err?.error?.message.toLowerCase() == 'nodata') {
          this.notFound = true;
        }
        else {
          this.systemError = true;
        }
      }
    )
  }

  checkIS4UPSQuoteDetails() {
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    sessionStorage.removeItem('is4upsQuoteDetails');
    // Flow 1: User does not have TC coverage. We're trying to convert some IS4U policyholders to TC right now, see if their policy exists in the control table.
    if (policyDetails?.hasTCCoverage == false) {
      this.policyService.retrieveIS4UPSInfo(policyDetails?.policyNumber).subscribe(
        data => {
          // Only store value to sessionStorage if they have a pre-approved quote.
          if (data?.data && data?.data?.newRate) {
            sessionStorage.setItem('is4upsQuoteDetails', JSON.stringify(data?.data));
            this.router.navigate(['dashboard']);
          } else if (this.userService.isUSPSUser()) {
            this.router.navigate(['billing']);
          }
          else {
            this.router.navigate(['dashboard']);
          }
        }, error => {
          this.router.navigate(['dashboard']);
        }
      );
    } else if (this.userService.isUSPSUser()) {
      this.router.navigate(['billing']);
    }
     else { // Flow #2: They already have TC coverage, just continue logging in.
      this.router.navigate(['dashboard']);
    }
  }

  checkPolicyExist(policynum) {
    var guestLinkRequest = {
      PolicyNumber: policynum
    }

    this.policyService.checkPolicyExist(guestLinkRequest).subscribe(response => {
      if (response && response.data && response.message === 'Found' && response.data.portalType.toLowerCase() === 'ssp') {
        this.ccpSelfservice.ccp_selfService_Check = true;
        this.ccpSelfservice.unique_Partner_Name = response.data.partnerName;
        sessionStorage.setItem("ccpUniquePartnerName", JSON.stringify(this.ccpSelfservice));
        this.guestLinkService.ccpSSPChecked(this.ccpSelfservice.unique_Partner_Name);
      }
    }, error => {
      console.log(error);
    });
  }

  checkForChildPolicies() {
    if (this.userService.isParentPolicy()) {
      this.policyService.getListOfChildPolicies({ parentPolicyNumber: this.userService.getUserInfo().policyNumber }).subscribe(
        data => {
          if (data && data.data && data.data.childPolicies) {
            sessionStorage.setItem('childPolicies', JSON.stringify(data.data.childPolicies));
            this.checkIS4UPSQuoteDetails();
          } else {
            this.checkIS4UPSQuoteDetails();
          }
        }, error => {
          this.checkIS4UPSQuoteDetails();
        }
      );
    } else {
      this.checkIS4UPSQuoteDetails();
    }
  }

  checkSMB() {
    let userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    if (userDetails.smbIndicator && !userDetails.app_role_id) {
      // console.log('should auto navigate to SMB');
      this.showSMBButton = false;
      this.navigateToSMB();
    } else if (userDetails.smbIndicator && userDetails.app_role_id) {
      // console.log('should show button option to navigate to SMB');
      this.showSMBButton = true;
    } else {
      this.showSMBButton = false;
      // console.log("not smb");
    }
  }

  navigateToSMB() {
    this.router.navigate(['/prospect']);
    window.scrollTo(0,0);
  }
}
