
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CssThemesService } from 'src/app/services/css-themes.service';
import { UserService } from 'src/app/services/user.service';
import * as _ from 'lodash';
import { Title } from '@angular/platform-browser';
import { environment as ENV } from '../../environments/environment';
import { IPolicies } from '../shared/models/policies.interface';
import { emptyIEmail } from '../shared/models/email.interface';
import { HappyReturnsDataService } from './happy-returns-data-service';

@Injectable({
  providedIn: 'root'
})
export class HappyReturnsService {

  constructor(
    private router: Router,
    private userService: UserService,
    private titleService: Title,
    private dataService: HappyReturnsDataService
  ) {

  }

  loadStyles() {
    this.userService.updateUserInfo({ type: 'happy-returns' });
  }

  public getHappyReturnsInfo() {
    return JSON.parse(sessionStorage.getItem('happyReturnsInfo'));
  }

  //Redirects to file a claim for current channel partner
  public redirectToFileClaim(partner: string) {
    sessionStorage.setItem(`${partner}File`, "file");
    this.router.navigate([`/${partner}/claims/file-claim`]);
  }

  setLandingTitle(partner: string) {
    this.titleService.setTitle(`CCP | ${partner}`);
  }

  setFileTitle(partner: string) {
    this.titleService.setTitle(`CCP File Claim | ${partner}`);
  }

  //Returns request object for redirection to DPAT based on current channel partner
  getDpatRequest(policy: IPolicies, email: string, partner: string, groupCode: string) {
    let shipmentInfo = this.dataService._channelPartnerShipmentDetails;
    let request = {
      action: 'Get Started',
      paymentDirection: 'Claims',
      country: 'US',
      paymentMode: '',
      policyIdentifier: policy.policyNumber ? policy.policyNumber : '',
      email: email,
      callBackURL: `${ENV.baseUrl.web}${partner}/claims/file-claim`,
      scenario: '',
      createdByUserId: '',
      guestUserIndicator: 'N',
      locale: 'en',
      tupssFlow: '',
      groupCode: groupCode,
      CUDAccess: true,
      policyData: {
        insured: policy.insured
      },
      iShipData: {
        companyName: shipmentInfo.shipperName,
        email: email,
        phone: '',
        addressLine1: shipmentInfo.originAddress,
        addressLine2: '',
        city: shipmentInfo.originCity,
        state: shipmentInfo.originState,
        zipCode: shipmentInfo.originPostalCode,
        payeeId: ''
      }
    }
    return request;
  }

  //Returns request object to send email for claim submission, for current CCP portal
  getClaimSubmissionEmailRequest(policyNumber: string, email: string, claimNumber: string, firstName: string, partner: string) {
    let shipmentInfo = this.dataService._channelPartnerShipmentDetails;
    let request = emptyIEmail;
    request = {
      ...request,
      emailFor: 'CCPClaimConfirmation',
      CountryLocale: sessionStorage.getItem('locale'),
      data: {
        email: email,
        claimNumber: claimNumber,
        claimDetailLink: ENV.baseUrl.web + btoa(`${partner}/claims/detail?policy=${policyNumber}&claimNumber=${claimNumber}&email=${email}`),
        firstName: firstName,
        companyName: shipmentInfo.consigneeName,
        streetAddress: shipmentInfo.destinationAddress,
        city: shipmentInfo.destinationCity,
        state: shipmentInfo.destinationState,
        zip: shipmentInfo.destinationPostalCode,
        lastName: '',
        channelPartnerName: partner,
        policyNumber: policyNumber,
        trackingNumber: shipmentInfo.trackingNumber,
        shipDate: shipmentInfo.shipDate
      }
    }
    return request;
  }

}
