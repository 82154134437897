import { Component } from '@angular/core';

@Component({
  selector: 'upsc-hr-claim-detail',
  templateUrl: './hr-claim-detail.component.html',
  styleUrls: ['./hr-claim-detail.component.scss']
})
export class HrClaimDetailComponent {

}
