import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HappyReturnsStepperComponent } from './happy-returns-stepper.component';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

@NgModule({
  declarations: [
    HappyReturnsStepperComponent
  ],
  imports: [
    CommonModule,
    MatProgressBarModule
  ],
  exports: [HappyReturnsStepperComponent]
})
export class HappyReturnsStepperModule { }
