import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HappyReturnsLayoutComponent } from './happy-returns-layout.component';
import { FooterModule } from '../footer/footer.module';
import { HeaderModule } from '../header/header.module';
import { NavigationModule } from '../navigation/navigation.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    HappyReturnsLayoutComponent
  ],
  imports: [
    CommonModule,
    FooterModule,
    RouterModule
  ],
  exports: [
    HappyReturnsLayoutComponent
  ]
})
export class HappyReturnsLayoutModule { }
