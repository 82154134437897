import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { BillingService } from '../services/billing.service';
import { forkJoin, Observable, of } from 'rxjs';
import { PolicyService } from '../services/policy.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardResolver  {
  constructor(
    private router: Router,
    private billingService: BillingService,
    private policyService: PolicyService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let dwDetails = JSON.parse(sessionStorage.getItem('dwDetails'));
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    //dwDetails will not be in session storage for non-us users and even for US users since they come from the Auth component
    if(policyDetails.policyCountry_Ext == 'US') {
      return forkJoin({
        dwDetails: !dwDetails ? this.billingService.DWgetSummary({
          productSourceSystem: "gw",
          sourceSystemIdentifierName: "PolicyNumber",
          sourceSystemIdentifier: policyDetails.policyNumber,
          country: "US",
          roleType: "string",
          productType: "string"
        }) : of(dwDetails),
        ccpUniquePartnerName: policyDetails.isChannelPartner === true ? this.policyService.checkPolicyExist({ policyNumber: policyDetails.policyNumber, partnerName: '' }) : of(false),
        childPolicies: policyDetails.isParentPolicy === true ? this.policyService.getListOfChildPolicies({ parentPolicyNumber: policyDetails.policyNumber }) : of(false),
        is4upsQuoteDetails: policyDetails.hasTCCoverage === false ? this.policyService.retrieveIS4UPSInfo(policyDetails.policyNumber) : of(false)
    });
    }
    else {
      return of({
        dwDetails: of(false),
        ccpUniquePartnerName: of(false),
        childPolicies: of(false),
        is4upsQuoteDetails: of(false)
      });
    }
  }
}
