import {
  Component,
  HostBinding,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import { IStep } from './models/step.interface';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'upsc-happy-returns-stepper',
  templateUrl: './happy-returns-stepper.component.html',
  styleUrls: ['./happy-returns-stepper.component.scss']
})
export class HappyReturnsStepperComponent {

  @HostBinding('class') public hostClass = 'stepper';

  @Input() public steps: IStep[];
  @Input() public currentStep: number;
  @Output() stepEvent = new EventEmitter<any>();

  public progress: number;

  constructor() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.onCurrentStepChanged(changes['currentStep']);
  }

  private onCurrentStepChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }

    this.setProgress();
  }

  public setCurrentStepManual(order: number) {
    this.stepEvent.emit(order);
  }

  private setProgress() {
    if (!this.steps || !this.steps.length) {
      this.progress = 0;
      return;
    }

    this.steps.forEach(
      (step) => {
        step.isActive = step.order === this.currentStep;
      },
    );

    this.progress = (this.currentStep - 1) * 100 / (this.steps.length - 1);
  }

}
