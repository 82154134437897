import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisibilityLayoutComponent } from './visibility-layout/visibility-layout.component';
import { RouterModule } from '@angular/router';
import { TopNavigationModule } from '../top-navigation/top-navigation.module';
import { FooterModule } from '../footer/footer.module';

@NgModule({
  declarations: [
    VisibilityLayoutComponent
  ],
  imports: [
    CommonModule,
    TopNavigationModule,
    FooterModule,
    RouterModule
  ],
  exports: [VisibilityLayoutComponent]
})
export class VisibilityLayoutModule { }
