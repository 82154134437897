<div class="upsc-custom-claims-detail">
  <div class="container">
    <div *ngIf="!isGuestUser" class="back-icon-container">
      <div *ngIf="!isMessage && isBackPage && !isHappyReturns" class="back" [routerLink]="['/claims']">
        <mat-icon class="back-icon">chevron_left</mat-icon>
        <div>{{ 'common.back' | translate }}</div>
      </div>
      <div *ngIf="isHappyReturns && !isMessage" class="back" [routerLink]="['/happy-returns/claims']">
        <mat-icon class="back-icon">chevron_left</mat-icon>
        <div>{{ 'common.back' | translate }}</div>
      </div>
      <div *ngIf="isHappyReturns && isMessage" class="back" [routerLink]="['/happy-returns/claims/message-list']">
        <mat-icon class="back-icon">chevron_left</mat-icon>
        <div>{{ 'common.back' | translate }}</div>
      </div>
      <div *ngIf="!isMessage && !isBackPage && !isHappyReturns" class="back" [routerLink]="['/dashboard']">
        <mat-icon class="back-icon">chevron_left</mat-icon>
        <div>{{ 'common.back' | translate }}</div>
      </div>
      <div *ngIf="isMessage && !isHappyReturns" class="back-icon-container" [routerLink]="['/claims/message-list']">
        <mat-icon class="back-icon">chevron_left</mat-icon>
        <div>{{ 'common.back' | translate }}</div>
      </div>
    </div>
    <div>
      <div *ngIf="currentStep == 0">
        <ng-container *ngTemplateOutlet="welcomeTemplate"></ng-container>
      </div>
      <div *ngIf="currentStep == 1">
        <ng-container *ngTemplateOutlet="successTemplate"></ng-container>
      </div>
    </div>

    <ng-template #welcomeTemplate>
      <div class="gray-drop">

        <div class="main-panel-mobile">
          <div class="download-buttons">
            <img src="../../../../assets/images/download-icon.svg" (click)="export('download')">
            &nbsp;<img alt="" (click)="export('print')" src="../../../../../assets/images/printer-icon.svg">
          </div>
          <div class="claim-number">
            <span class="bold">{{ 'claimsDetail.title' | translate }}
              <span class="text">{{ claim.claimNumber }}</span></span>
          </div>
          <div class="status-box-container">
            <span class="status-box" [ngClass]="
            claim.customerDisplayStatus == 'Claim Received' ? 'light-green' :
            claim.customerDisplayStatus && claim.customerDisplayStatus.includes('Closed') ? 'lighter-green' :
            claim.customerDisplayStatus == 'Withdrawn' ? 'gray-light' :
            claim.customerDisplayStatus == 'Submitted for Payment' ? 'light-blue' :
            claim.customerDisplayStatus == 'Incomplete Submission' ? 'light-red' :
            claim.customerDisplayStatus == 'Investigation Pending' ? 'light-blue' :
            claim.customerDisplayStatus == 'Documents Pending' ? 'light-red' :
            claim.customerDisplayStatus == 'Pending Delivery Confirmation' ? 'light-blue' :
            claim.customerDisplayStatus == 'Paid' ? 'lighter-green' :
            claim.customerDisplayStatus == 'Denied' ? 'light-red' :
            'none'">

              <span *ngIf="!statusDetails">{{ claim.customerDisplayStatusTitle }}</span>
              <span *ngIf="statusDetails">{{ statusDetails }}</span>
            </span>
          </div>
          <div class="paid-amount-container">
            <span class="bold">{{ 'claimsDetail.paidAmount' | translate }}
              <span *ngIf="claim.currency=='usd'" class="text">${{ claim.totalPaymentAmount | number:'1.2-2' }}</span>
              <span *ngIf="claim.currency=='gbp'" class="text">Â£{{ claim.totalPaymentAmount | number:'1.2-2' }}</span>
              <span *ngIf="claim.currency=='eur'" class="text">â‚¬{{ claim.totalPaymentAmount | number:'1.2-2' }}</span>
            </span>
          </div>
          <div class="adjuster-info">
            <span class="bold">{{ 'claimsDetail.adjuster.title' | translate }}: <span class="text">{{ claim.adjusterName
                }}</span></span>
          </div>
          <div class="title-alone">
            {{ 'claimsDetail.d&n' | translate }}
          </div>
          <div class="bar">
            <div class="login-bar" *ngIf="!isHappyReturns"></div>
            <div class="login-bar-yellow" *ngIf="isHappyReturns"></div>
          </div>
          <div *ngIf="!isGuestUser" class="back-icon-container-mobile">
            <div *ngIf="!isMessage && isBackPage" class="back" [routerLink]="['/claims']">
              <mat-icon class="back-icon">chevron_left</mat-icon>
              <div> {{ 'claimsDetail.goBack' | translate }}</div>
            </div>
            <div *ngIf="!isMessage && !isBackPage" class="back" [routerLink]="['/dashboard']">
              <mat-icon class="back-icon">chevron_left</mat-icon>
              <div> {{ 'claimsDetail.goBack' | translate }}</div>
            </div>
            <div *ngIf="isMessage" class="back-icon-container" [routerLink]="['/claims/message-list']">
              <mat-icon class="back-icon">chevron_left</mat-icon>
              <div> {{ 'claimsDetail.goBack' | translate }}</div>
            </div>
          </div>
          <div class="claim-table">
            <div class="overflow-format">
              <table *ngIf="sgDocuments.length > 0" class="upsc-table-isop">
                <tbody>
                  <tr>
                    <th>{{ 'claimsDetail.docDate' | translate }}</th>
                    <th>{{ 'claimsDetail.file' | translate }}</th>
                  </tr>
                  <tr *ngFor="let doc of sgDocuments">
                    <td *ngIf="isUsaUser" class="date">{{ doc.dateModified | date: 'MM/dd/yyyy': 'UTC' }}</td>
                    <td *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser" class="date">{{
                      doc.dateModified | date: 'dd/MM/yyyy': 'UTC' }}</td>
                    <td (click)="download(doc)" tabindex="0"><span class="download-link">{{ doc.name }}</span></td>
                  </tr>
                </tbody>
              </table>
              <table *ngIf="sgDocuments.length == 0" class="not-provided" class="upsc-table-isop">
                <tbody>
                  <tr>
                    <th>{{ 'claimsDetail.docDate' | translate }}</th>
                    <th>{{ 'claimsDetail.file' | translate }}</th>
                  </tr>
                  <tr>
                    <td colspan="2">{{ 'claimsDetail.not_p' | translate }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="widthdraw-buttons" *ngIf="!isHappyReturns">
            <button *ngIf="!isWithdrawn && isUsaUser" class="withdraw" (click)="withdrawPopup()"
              [disabled]="!isWithdrawable || !userPermission.isFileClaim">
              <div class="text">{{ 'claimsDetail.withdraw.withdraw' | translate }}</div>
              <mat-icon class="back-icon">chevron_right</mat-icon>
            </button>
            <button class="withdraw" *ngIf="claim.customerDisplayStatus == 'Withdrawn' && !isReopen && isUsaUser"
              [disabled]="!userPermission.isFileClaim" (click)="openReopenConfirmation()">
              <div class="text">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.reOpenClaim' |
                translate }}</div>
              <mat-icon class="back-icon">chevron_right</mat-icon>
            </button>
          </div>

          <div class="widthdraw-buttons" *ngIf="isHappyReturns">
            <button *ngIf="!isWithdrawn && isUsaUser" class="withdraw-black" (click)="withdrawPopup()"
              [disabled]="!isWithdrawable || !userPermission.isFileClaim">
              <div class="text">{{ 'claimsDetail.withdraw.withdraw' | translate }}</div>
              <mat-icon class="back-icon">chevron_right</mat-icon>
            </button>
            <button class="withdraw-black" *ngIf="claim.customerDisplayStatus == 'Withdrawn' && !isReopen && isUsaUser"
              [disabled]="!userPermission.isFileClaim" (click)="openReopenConfirmation()">
              <div class="text">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.reOpenClaim' |
                translate }}</div>
              <mat-icon class="back-icon">chevron_right</mat-icon>
            </button>
          </div>
        </div>

        <div class="main-panel">
          <div class="newClaimDetail">
            <div class="first">
              <span class="bold">{{ 'claimsDetail.title' | translate }}
                <span class="text">{{ claim.claimNumber }}</span></span>
            </div>
            <div class="second">
              <span class="status-box" [ngClass]="
              claim.customerDisplayStatus == 'Claim Received' ? 'light-green' :
              claim.customerDisplayStatus && claim.customerDisplayStatus.includes('Closed') ? 'lighter-green' :
              claim.customerDisplayStatus == 'Withdrawn' ? 'gray-light' :
              claim.customerDisplayStatus == 'Submitted for Payment' ? 'light-blue' :
              claim.customerDisplayStatus == 'Incomplete Submission' ? 'light-red' :
              claim.customerDisplayStatus == 'Investigation Pending' ? 'light-blue' :
              claim.customerDisplayStatus == 'Documents Pending' ? 'light-red' :
              claim.customerDisplayStatus == 'Pending Delivery Confirmation' ? 'light-blue' :
              claim.customerDisplayStatus == 'Paid' ? 'lighter-green' :
              claim.customerDisplayStatus == 'Denied' ? 'light-red' :
              'none'">
                <span *ngIf="!statusDetails">{{ claim.customerDisplayStatusTitle }}</span>
                <span *ngIf="statusDetails">{{ statusDetails }}</span>
              </span>&nbsp;
            </div>
            <div class="third">
              <img src="../../../../assets/images/download-icon.svg" (click)="export('download')">
              &nbsp;<img alt="" (click)="export('print')" src="../../../../../assets/images/printer-icon.svg">
            </div>
          </div>

          <div class="newClaimDetail">
            <div class="first">
              <span class="bold">{{ 'claimsDetail.paidAmount' | translate }}
                <span *ngIf="claim.currency=='usd'" class="text">${{ claim.totalPaymentAmount | number:'1.2-2' }}</span>
                <span *ngIf="claim.currency=='gbp'" class="text">£{{ claim.totalPaymentAmount | number:'1.2-2' }}</span>
                <span *ngIf="claim.currency=='eur'" class="text">€{{ claim.totalPaymentAmount | number:'1.2-2' }}</span>
              </span>
              <div class="bar">
                <div class="login-bar" *ngIf="!isHappyReturns"></div>
                <div class="login-bar-yellow" *ngIf="isHappyReturns"></div>
              </div>
            </div>
          </div>
          <div class="title-alone">
            {{ 'claimsDetail.adjuster.title' | translate }}:<div class="name">{{ claim.adjusterName }}</div>
          </div>
          <!--  <div class="title-alone">
            {{ 'claimsDetail.adjuster.title' | translate }}
          </div>
          <div class="adjuster">
            <div class="name">{{ claim.adjusterName }}</div>
          </div> -->
          <div class="title-alone">
            {{ 'claimsDetail.d&n' | translate }}
          </div>
          <!--  <table *ngIf="sgDocuments.length > 0">
            <tr *ngFor="let doc of sgDocuments">
              <td *ngIf="isUsaUser" class="date">{{ doc.dateModified | date: 'MM/dd/yyyy': 'UTC' }}</td>
              <td *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser" class="date">{{
                doc.dateModified | date: 'dd/MM/yyyy': 'UTC' }}</td>
              <td class="name" (click)="download(doc)" tabindex="0">{{ doc.name }}</td>
            </tr>
          </table>
          <table *ngIf="sgDocuments.length == 0" class="not-provided">
            <tr>
              <td class="date">{{ 'claimsDetail.not_p' | translate }}</td>
            </tr>
          </table>
          <div class="dn">
            <div class="dn-mobile" *ngFor="let doc of sgDocuments">
              <div *ngIf="isUsaUser" class="date">{{ doc.dateModified | date: 'MM/dd/yyyy': 'UTC' }}</div>
              <div *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser" class="date">{{
                doc.dateModified | date: 'dd/MM/yyyy': 'UTC' }}</div>
              <div class="name">{{ doc.name }}</div>
            </div>
          </div> -->

          <div class="desktop-claim-table">
            <div class="overflow-format">
              <table *ngIf="sgDocuments.length > 0" class="upsc-table-isop">
                <tbody>
                  <tr>
                    <th>{{ 'claimsDetail.docDate' | translate }}</th>
                    <th>{{ 'claimsDetail.file' | translate }}</th>
                  </tr>
                  <tr *ngFor="let doc of sgDocuments">
                    <td *ngIf="isUsaUser" class="date">{{ doc.dateModified | date: 'MM/dd/yyyy': 'UTC' }}</td>
                    <td *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser" class="date">{{
                      doc.dateModified | date: 'dd/MM/yyyy': 'UTC' }}</td>
                    <td (click)="download(doc)" tabindex="0"><span class="download-link">{{ doc.name }}</span></td>
                  </tr>
                </tbody>
              </table>
              <table *ngIf="sgDocuments.length == 0" class="not-provided" class="upsc-table-isop">
                <tbody>
                  <tr>
                    <th>{{ 'claimsDetail.docDate' | translate }}</th>
                    <th>{{ 'claimsDetail.file' | translate }}</th>
                  </tr>
                  <tr>
                    <td colspan="2">{{ 'claimsDetail.not_p' | translate }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="widthdraw-buttons" *ngIf="!isHappyReturns">
            <button *ngIf="!isWithdrawn && isUsaUser" class="withdraw" (click)="withdrawPopup()"
              [disabled]="!isWithdrawable || !userPermission.isFileClaim">
              {{ 'claimsDetail.withdraw.withdraw' | translate }} >
            </button>
            <button class="withdraw" *ngIf="claim.customerDisplayStatus == 'Withdrawn' && !isReopen && isUsaUser"
              [disabled]="!userPermission.isFileClaim" (click)="openReopenConfirmation()">{{
              'claims.edit.review.reviewAndSubmit.reviewList.reOpenClaim' |
              translate }} >
            </button>
          </div>

          <div class="widthdraw-buttons" *ngIf="isHappyReturns">
            <button *ngIf="!isWithdrawn && isUsaUser" class="withdraw-black" (click)="withdrawPopup()"
              [disabled]="!isWithdrawable || !userPermission.isFileClaim">
              {{ 'claimsDetail.withdraw.withdraw' | translate }} >
            </button>
            <button class="withdraw-black" *ngIf="claim.customerDisplayStatus == 'Withdrawn' && !isReopen && isUsaUser"
              [disabled]="!userPermission.isFileClaim" (click)="openReopenConfirmation()">{{
              'claims.edit.review.reviewAndSubmit.reviewList.reOpenClaim' |
              translate }} >
            </button>
          </div>
        </div>

        <form [formGroup]="resubmitFormGroup">

          <div id='targetMessages'>
            <upsc-collapsible-panel-white (collapsedEvent)="loadMessages($event)" [isCollapsed]="isMessageCollapsed"
              *ngIf="isUsaUser && !isDDUser" [title]="'claims.MessageList.MessageAdjuster' | translate"
              [subtitle]="'claims.MessageList.MessageAdjusterSubTitle' | translate">
              <div class="section">
                <div class="contents" id='subtargetMessages'>
                  <upsc-message-adjuster *ngIf="toggleMessage" [claimData]="selectedClaimNumber"
                    [canMessageAdjuster]="canMessageAdjuster"></upsc-message-adjuster>
                </div>
              </div>
            </upsc-collapsible-panel-white>
          </div>
          <upsc-collapsible-panel-white
            [title]="'claims.edit.review.reviewAndSubmit.reviewList.shipmentDetail' | translate"
            [isCollapsed]="isOtherCollapsed">
            <div class="section">
              <div class="contents d-flex flex-column flex-md-row justify-content-md-between">
                <div class="column mr-md-2">
                  <div class="subsection">
                    <div class="subsection-title">
                      {{ 'claims.edit.review.reviewAndSubmit.reviewList.trackingNumber' | translate }}</div>
                    <div class="subsection-text text-break">{{ claim.tracking | uppercase }}</div>
                  </div>
                  <div class="subsection">
                    <div class="subsection-title">
                      {{ 'claims.edit.review.reviewAndSubmit.reviewList.carrier' | translate }}</div>
                    <div class="subsection-text">{{ claim.carrier }}</div>
                  </div>
                </div>
                <div class="column mr-md-2">
                  <div class="subsection">
                    <div class="subsection-title">
                      {{ 'claims.edit.review.reviewAndSubmit.reviewList.shipperInformation' | translate }}</div>
                    <div class="subsection-text">
                      <div>{{ claim.shipperCompany }}</div>
                      <div *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser">
                        {{ claim.shipperAddress }},</div>
                      <div>{{ claim.shipperCity | titlecase }}<span *ngIf="isUsaUser || isCanadaUser">, {{
                          claim.shipperState }}</span></div>
                      <div *ngIf="(isUkUser || isGermanyUser || isFranceUser || isItalyUser) && claim.shipperZipcode">
                        <span>{{ claim.shipperZipcode }}</span>
                      </div>
                      <div>{{ claim.shipperCountry }}</div>
                    </div>
                  </div>
                  <div class="subsection">
                    <div class="subsection-title">
                      {{ 'claims.edit.review.reviewAndSubmit.reviewList.recipientInformation' | translate }}</div>
                    <div class="subsection-text">
                      <div>{{ claim.recipientCompany }}</div>
                      <div *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser">
                        {{ claim.recipientAddress }},</div>
                      <div>{{ claim.recipientCity | titlecase }}<span *ngIf="isUsaUser || isCanadaUser">, {{
                          claim.recipientState }}</span></div>
                      <div *ngIf="(isUkUser || isGermanyUser || isFranceUser || isItalyUser) && claim.recipientZipcode">
                        <span>{{ claim.recipientZipcode }}</span>
                      </div>
                      <div>{{ claim.recipientCountry }}</div>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="subsection">
                    <div class="subsection-title">
                      {{ 'claimsDetail.shipDate' | translate }}</div>
                    <div *ngIf="isUsaUser" class="subsection-text">{{ claim.shipDate | date: 'MM/dd/yyyy': 'UTC' }}
                    </div>
                    <div *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser"
                      class="subsection-text">{{ claim.shipDate | date: 'dd/MM/yyyy': 'UTC' }}</div>
                  </div>
                  <div class="subsection">
                    <div class="subsection-title">
                      {{ 'claims.edit.review.reviewAndSubmit.reviewList.referenceNumber' | translate }}</div>
                    <div class="subsection-text" *ngIf="claim.reference">{{ claim.reference }}</div>
                  </div>
                  <div class="subsection" *ngIf="isCanadaUser || isUsaUser">
                    <div class="subsection-title">
                      {{ 'claims.edit.review.reviewAndSubmit.reviewList.upsAccountNumber' | translate }}</div>
                    <div class="subsection-text">{{ claim.upsAccountNumber | uppercase }}</div>
                  </div>
                </div>
              </div>
            </div>
          </upsc-collapsible-panel-white>
          <div id='targetReopen'>
            <upsc-collapsible-panel-white [title]="claimDetailsTitle" [isCollapsed]="isOtherCollapsed">
              <div class="section">
                <div class="row spaceTop" *ngIf="isReopen">
                  <div class="column-reopen">
                    <b>{{ 'claims.edit.review.reviewAndSubmit.reviewList.reasonForResubmission' | translate }}</b>
                    <mat-form-field class="totalWidth">
                      <mat-select formControlName="resubmissionReason"
                        [(ngModel)]="resubmitClaimDetails.resubmissionReason" (selectionChange)="onReasonChange()">
                        <mat-option *ngFor="let item of claimReasons" [value]="item.id">
                          {{item.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="column-reopen"><b>{{ 'claimsDetail.claimFiled' | translate }}</b>
                    <div *ngIf="isUsaUser">
                      <mat-form-field class="totalWidth">
                        <input name="claimFileDate" matInput formControlName="claimFileDate" [(ngModel)]="claimFileDate"
                          readonly />
                      </mat-form-field>
                    </div>
                    <div *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser">
                      <mat-form-field class="totalWidth">
                        <input name="claimFileDate" matInput formControlName="claimFileDate"
                          [(ngModel)]="claim.fileDate" readonly />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row spaceTop" *ngIf="isReopen">
                  <div class="column-reopen">
                    <b>Shipping Amount</b>
                    <mat-form-field class="totalWidth">
                      <input matInput required formControlName="shippingAmount" appDecimalFieldMask
                        class="currency-align" [(ngModel)]="resubmitClaimDetails.shippingAmount">

                    </mat-form-field>
                  </div>
                  <div class="column-reopen"><b>{{ 'claims.edit.review.reviewAndSubmit.reviewList.merchandiseAmount' |
                      translate }}</b>
                    <mat-form-field class="totalWidth">
                      <input matInput formControlName="merchandiseAmount" appDecimalFieldMask class="currency-align"
                        [(ngModel)]="resubmitClaimDetails.merchandiseAmount">
                    </mat-form-field>
                  </div>
                </div>

                <div *ngIf="isReopen">
                  <div class="subtitle squeeze">
                    <b>{{ 'claims.edit.whatHappened.merchandise.description.title' | translate
                      }}</b><span class="red">*</span>
                  </div>
                  <div class="subtitle">
                    <b>{{ 'claims.edit.whatHappened.merchandise.description.subtitle' | translate }}</b>
                  </div>
                  <mat-form-field class="full-width">
                    <textarea matInput #descr maxlength="250" formControlName="merchandiseDescription"
                      [(ngModel)]="resubmitClaimDetails.merchandiseDescription"></textarea>
                    <mat-hint align="end">
                      {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{250 -
                      descr.value.length}}
                    </mat-hint>
                    <mat-error *ngIf="resubmitFormGroup.get('merchandiseDescription').errors">
                      <div *ngIf="resubmitFormGroup.get('merchandiseDescription').errors['required']">
                        {{ 'claims.edit.whatHappened.merchandise.description.required' | translate }}
                      </div>
                      <div *ngIf="resubmitFormGroup.get('merchandiseDescription').errors['maxlength']">
                        {{ 'claims.edit.whatHappened.merchandise.description.limit' | translate }}
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div *ngIf="isReopen && damage" class="spaceTop">
                  <div class="row">
                    <div class="column-reopen">
                      <b>{{ 'claims.edit.whatHappened.reason.damageDescription' | translate }}</b>
                      <mat-form-field class="totalWidth">
                        <textarea #dmgdescr matInput maxlength="800" rows="1" formControlName="damageDescription"
                          [(ngModel)]="resubmitClaimDetails.damageDescription" required></textarea>
                        <mat-hint align="end">
                          {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{800 -
                          dmgdescr.value.length}}
                        </mat-hint>
                        <mat-error *ngIf="resubmitFormGroup.get('damageDescription').errors">
                          <div *ngIf="resubmitFormGroup.get('damageDescription').errors['required']">
                            {{ 'claims.edit.whatHappened.reason.damageDescriptionRequired' | translate }}
                          </div>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="column-reopen-full">
                      <b>{{ 'claims.edit.whatHappened.merchandise.description.damage.day.title' | translate }}</b>
                      <span class="red">*</span>
                      <mat-form-field class="totalWidth" (click)="damagePicker.open()">
                        <input matInput [matDatepicker]="damagePicker" [max]="maxDate"
                          [(ngModel)]="resubmitClaimDetails.damageDate" required readonly
                          formControlName="damagemissingDate"
                          placeholder="{{ 'claims.edit.whatHappened.merchandise.description.damage.day.label' | translate }}">
                        <mat-datepicker-toggle matSuffix [for]="damagePicker">
                          <svg matDatepickerToggleIcon id="baseline-date_range-24px" xmlns="http://www.w3.org/2000/svg"
                            width="31.711" height="31.711" viewBox="0 0 31.711 31.711">
                            <path id="Path_219" data-name="Path 219"
                              d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                              transform="translate(0.964 0.643)" fill="#737373" />
                            <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z" fill="none" />
                          </svg>
                        </mat-datepicker-toggle>
                        <mat-datepicker #damagePicker></mat-datepicker>
                        <mat-error *ngIf="resubmitFormGroup.get('damagemissingDate').errors">
                          <div *ngIf="resubmitFormGroup.get('damagemissingDate').errors['required']">
                            {{ 'claims.edit.whatHappened.merchandise.description.damage.day.required' | translate }}
                          </div>
                        </mat-error>
                        <!--<div *ngIf="resubmitFormGroup.get('damagemissingDate').errors['matDatepickerMax']">
                        {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.max' |
                        translate }}
                      </div>
                    -->
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="column-reopen">
                      <b>{{ 'claims.edit.whatHappened.merchandise.description.damage.repair.title' | translate }}</b>
                      <span class="red">*</span>
                      <mat-radio-group required class="radio-group" formControlName="damageRepairable"
                        [(ngModel)]="resubmitClaimDetails.damageRepairable">
                        <mat-radio-button *ngFor="let repairable of repairables" value="{{repairable.id}}">{{
                          repairable.text
                          }}
                        </mat-radio-button>
                      </mat-radio-group>
                      <mat-error class="standalone"
                        *ngIf="resubmitFormGroup.get('damageRepairable').errors && resubmitFormGroup.controls['damageRepairable']?.touched">
                        {{ 'claims.edit.whatHappened.merchandise.description.damage.repair.errors.required' | translate
                        }}
                      </mat-error>
                    </div>
                    <div class="column-reopen-full">
                      <b>{{ 'claims.edit.whatHappened.merchandise.description.damage.where.title' | translate }}</b>
                      <span class="red">*</span>
                      <mat-radio-group required class="radio-group" formControlName="damageLocations"
                        [(ngModel)]="resubmitClaimDetails.damageLocations">
                        <mat-radio-button *ngFor="let locations of damagedLocations" value="{{locations.id}}"
                          (click)="toggleOther(false)">{{
                          locations.text }}</mat-radio-button>
                        <mat-radio-button value="3" (click)="toggleOther(true)">{{
                          'claims.edit.whatHappened.merchandise.description.damage.where.other' |
                          translate }}</mat-radio-button>
                      </mat-radio-group>
                      <mat-error class="standalone"
                        *ngIf="resubmitFormGroup.get('damageLocations').errors && resubmitFormGroup.controls['damageLocations']?.touched">
                        {{ 'claims.edit.whatHappened.merchandise.description.damage.where.errors.required' | translate
                        }}
                      </mat-error>
                      <mat-form-field class="medium-full other-response totalWidth" *ngIf="other">
                        <textarea #dmgother maxlength="35" matInput placeholder=""
                          formControlName="damageLocationDetails" required
                          [(ngModel)]="resubmitClaimDetails.damageLocationDetails"></textarea>
                        <mat-hint align="end">
                          {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{35 -
                          dmgother.value.length}}
                        </mat-hint>
                        <mat-error *ngIf="resubmitFormGroup.get('damageLocationDetails').errors">
                          <div *ngIf="resubmitFormGroup.get('damageLocationDetails').errors['required']">
                            {{ 'claims.edit.whatHappened.merchandise.description.damage.where.otherRequired' | translate
                            }}
                          </div>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div *ngIf="isReopen && missing" class="spaceTop">
                  <div class="totalWidth">
                    <div class="row">
                      <div class="column-reopen-full">
                        <b>{{ 'claims.edit.whatHappened.reason.missingDescription' | translate }}</b>
                        <mat-form-field class="fill-width totalWidth">
                          <textarea #missingdescr matInput maxlength="800" formControlName="missingDescription"
                            [(ngModel)]="resubmitClaimDetails.missingDescription" required></textarea>
                          <mat-hint align="end">
                            {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{800 -
                            missingdescr.value.length}}
                          </mat-hint>
                          <mat-error *ngIf="resubmitFormGroup.get('missingDescription').errors">
                            <div *ngIf="resubmitFormGroup.get('missingDescription').errors['required']">
                              {{ 'claims.edit.whatHappened.reason.missingDescriptionRequired' | translate }}
                            </div>
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="column-reopen">
                      <b> {{ 'claims.edit.whatHappened.merchandise.description.missing.day.title' | translate }}</b>
                      <span class="red">*</span>
                      <div>
                        <mat-form-field class="width-30 totalWidth" (click)="missingPicker.open()">
                          <input matInput [matDatepicker]="missingPicker" [max]="maxDate" required
                            [(ngModel)]="resubmitClaimDetails.missingDate" readonly formControlName="missingDate"
                            class="date-icon"
                            placeholder="{{ 'claims.edit.whatHappened.merchandise.description.missing.day.label' | translate }}">
                          <mat-datepicker-toggle matSuffix [for]="missingPicker">
                            <svg matDatepickerToggleIcon id="baseline-date_range-24px"
                              xmlns="http://www.w3.org/2000/svg" width="31.711" height="31.711"
                              viewBox="0 0 31.711 31.711">
                              <path id="Path_219" data-name="Path 219"
                                d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                                transform="translate(0.964 0.643)" fill="#737373" />
                              <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z" fill="none" />
                            </svg>
                          </mat-datepicker-toggle>
                          <mat-datepicker #missingPicker></mat-datepicker>
                          <mat-error *ngIf="resubmitFormGroup.get('missingDate').errors">
                            <div *ngIf="resubmitFormGroup.get('missingDate').errors['required']">
                              {{ 'claims.edit.whatHappened.merchandise.description.damage.day.required' | translate }}
                            </div>
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="column-reopen">
                      <b>{{ 'claims.edit.whatHappened.merchandise.description.damage.where.title' | translate }}</b>
                      <span class="red">*</span>
                      <div>
                        <mat-radio-group required class="radio-group" formControlName="missingLocations"
                          [(ngModel)]="resubmitClaimDetails.missingLocations">
                          <mat-radio-button *ngFor="let locations of damagedLocations" value="{{locations.id}}"
                            (click)="toggleOther(false)">{{
                            locations.text
                            }}</mat-radio-button>
                          <mat-radio-button #other value="3" (click)="toggleOther(true)">{{
                            'claims.edit.whatHappened.merchandise.description.damage.where.other' |
                            translate }}</mat-radio-button>
                        </mat-radio-group>
                        <mat-error class="standalone"
                          *ngIf="resubmitFormGroup.get('missingLocations').errors && resubmitFormGroup.controls['missingLocations']?.touched">
                          {{ 'claims.edit.whatHappened.merchandise.description.damage.where.errors.required' | translate
                          }}
                        </mat-error>
                        <div *ngIf="other.checked">
                          <mat-form-field class="medium-full other-response totalWidth">
                            <textarea #missingother maxlength="35" matInput placeholder=""
                              formControlName="missingLocationDetails" required
                              [(ngModel)]="resubmitClaimDetails.missingLocationDetails"></textarea>
                            <mat-hint align="end">
                              {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{35 -
                              missingother.value.length}}
                            </mat-hint>
                            <mat-error *ngIf="resubmitFormGroup.get('missingLocationDetails').errors">
                              <div *ngIf="resubmitFormGroup.get('missingLocationDetails').errors['required']">
                                {{ 'claims.edit.whatHappened.merchandise.description.missing.otherRequired' | translate
                                }}
                              </div>
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="column"></div> -->
                  </div>
                </div>

                <!-- Cargo -->
                <div class="spaceTop" *ngIf="isReopen && late">
                  <div class="row">
                    <div class="column-reopen-full">
                      <b>{{
                        'claims.edit.whatHappened.merchandise.description.late.how.label' | translate }}</b>
                      <span class="red">*</span>
                    </div>
                  </div>
                  <div class="totalWidth">
                    <div class="row">
                      <div class="column-reopen-full">
                        <mat-radio-group required class="radio-group" formControlName="lateCare"
                          [(ngModel)]="resubmitClaimDetails.lateCare">
                          <mat-radio-button value="0" #reship>
                            {{'claims.edit.whatHappened.merchandise.description.late.how.reship.label'
                            | translate }}</mat-radio-button>
                          <div class="reship-wrapper" *ngIf="reship.checked">
                            <div class="mat-form-fields">
                              <mat-form-field class="medium-full reship-response totalWidth">
                                <textarea matInput maxlength="45" #latereship required
                                  placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.reship.placeholder' | translate }}"
                                  formControlName="lateReship" [(ngModel)]="resubmitClaimDetails.lateReship"></textarea>
                                <mat-hint align="end">{{ 'claims.edit.whatHappened.merchandise.description.hint' |
                                  translate
                                  }}{{
                                  45 - latereship.value.length }}</mat-hint>
                                <mat-error *ngIf="resubmitFormGroup.get('lateReship').errors">
                                  <div *ngIf="resubmitFormGroup.get('lateReship').errors['required']">
                                    {{ 'claims.edit.whatHappened.merchandise.description.late.how.reship.required' |
                                    translate
                                    }}
                                  </div>
                                </mat-error>
                              </mat-form-field>
                            </div>
                          </div>
                          <mat-radio-button value="1" #refund>
                            {{'claims.edit.whatHappened.merchandise.description.late.how.refund.label'
                            | translate }} <mat-icon
                              ngbTooltip="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.tooltip' | translate }}"
                              tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                          </mat-radio-button>
                          <div class="refund-wrapper" *ngIf="refund.checked">
                            <div class="mat-form-fields">
                              <mat-form-field class="medium-full refund-response totalWidth">
                                <textarea matInput maxlength="45" #laterefund required
                                  placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.placeholder' | translate }}"
                                  formControlName="lateRefund" [(ngModel)]="resubmitClaimDetails.lateRefund"></textarea>
                                <mat-hint align="end">{{ 'claims.edit.whatHappened.merchandise.description.hint' |
                                  translate
                                  }}{{
                                  45 - laterefund.value.length }}</mat-hint>
                                <mat-error *ngIf="resubmitFormGroup.get('lateRefund').errors">
                                  <div *ngIf="resubmitFormGroup.get('lateRefund').errors['required']">
                                    {{ 'claims.edit.whatHappened.merchandise.description.late.how.refund.required' |
                                    translate
                                    }}
                                  </div>
                                </mat-error>
                              </mat-form-field>
                            </div>
                          </div>
                        </mat-radio-group>
                        <mat-error class="standalone"
                          *ngIf="resubmitFormGroup.get('lateCare').errors && resubmitFormGroup.controls['lateCare']?.touched">
                          {{ 'claims.edit.whatHappened.merchandise.description.late.how.errors.required' | translate }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Return Shipment -->
                <div *ngIf="isReopen && flex">
                  <div class="title"><b>{{ 'claims.edit.whatHappened.merchandise.description.return.title' | translate
                      }}</b><span class="red">*</span></div>
                  <mat-radio-group required class="radio-group" formControlName="return"
                    [(ngModel)]="resubmitClaimDetails.isReturnShipment">
                    <mat-radio-button value="yes">{{ 'claims.edit.whatHappened.merchandise.description.return.yes' |
                      translate
                      }}</mat-radio-button>
                    <mat-radio-button value="no">{{ 'claims.edit.whatHappened.merchandise.description.return.no' |
                      translate
                      }}</mat-radio-button>
                  </mat-radio-group>
                  <div class="mt-3" *ngIf="resubmitFormGroup.value.return=='yes'">
                    <div class="title">
                      <b>{{ 'claims.edit.whatHappened.merchandise.description.return.otn.title' |
                        translate
                        }}</b><span class="red">*</span>
                    </div>
                    <div class="mat-form-fields">
                      <mat-form-field class="width-30">
                        <input #OTN maxlength="100" matInput placeholder="" formControlName="originalTrackingNumber"
                          [(ngModel)]="resubmitClaimDetails.originalTrackNumber" required>
                        <mat-error *ngIf="resubmitFormGroup.get('originalTrackingNumber').errors">
                          <div *ngIf="resubmitFormGroup.get('originalTrackingNumber').errors">
                            {{ 'claims.edit.whatHappened.merchandise.description.return.otn.required' | translate }}
                          </div>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>


                <div class="subsection stand-alone mb-0" *ngIf="isReopen">
                  <br />
                  <div class="subsection-title">
                    <b>{{ 'claims.edit.review.reviewAndSubmit.reviewList.additionalCommentsforReopening' | translate
                      }}</b>
                  </div>
                  <div class="subsection-text">
                    <mat-form-field class="totalWidth">
                      <textarea matInput #comments maxlength="250" name="reopenNotes"
                        [(ngModel)]="resubmitClaimDetails.reopenNotes" matInput formControlName="reopenNotes" rows="2"
                        cols="250">
                      </textarea>
                      <mat-hint align="end">
                        {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{ 250 -
                        comments.value.length }}
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>


                <div class="row spaceTop" *ngIf="isReopen">
                  <div class="column-reopen">
                    <b>{{ 'claims.edit.review.reviewAndSubmit.reviewList.productCategory' | translate }}</b>
                    <div class="subsection-text">{{ claim.productCategory }}</div>
                  </div>
                  <div class="column-reopen"><b>
                      {{ 'claims.edit.review.reviewAndSubmit.reviewList.quantity' | translate }}</b>
                    <div class="subsection-text"> {{ claim.quantity }}</div>
                  </div>
                </div>

                <div class="row spaceTop" *ngIf="isReopen">
                  <div class="column-reopen">
                    <b>{{ 'claims.edit.review.reviewAndSubmit.reviewList.checkAchNumber' |
                      translate }}</b>
                    <div class="subsection-text">{{ claim.checkNumber }}</div>
                  </div>
                  <div class="column-reopen"><b>{{
                      'claims.edit.review.reviewAndSubmit.reviewList.checkElectronicIssueDate' | translate }}</b>
                    <div class="subsection-text" *ngIf="claim.checkIssueDate == 'null'">{{ 'claimsDetail.notProvided' |
                      translate }}</div>
                    <div class="subsection-text" *ngIf="(claim.checkIssueDate !== 'null') && isUsaUser">{{
                      claim.checkIssueDate | date: 'MM/dd/yyyy' }}</div>
                    <div class="subsection-text"
                      *ngIf="(claim.checkIssueDate !== 'null') && (isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser)">
                      {{ claim.checkIssueDate | date: 'dd/MM/yyyy' }}</div>
                  </div>
                </div>

                <div *ngIf="!isReopen" class="contents d-flex flex-column flex-md-row justify-content-md-between">
                  <div class="column mr-md-2">
                    <div class="subsection">
                      <div class="subsection-title">{{ 'claimsDetail.claimFiled' | translate }}</div>
                      <div *ngIf="isUsaUser" class="subsection-text">{{ claim.fileDate | date: 'MM/dd/yyyy': 'UTC' }}
                      </div>
                      <div *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser"
                        class="subsection-text">{{ claim.fileDate | date: 'dd/MM/yyyy': 'UTC' }}</div>
                    </div>
                    <div class="subsection">
                      <div class="subsection-title">
                        {{ 'claims.edit.review.reviewAndSubmit.reviewList.reasonForClaim' | translate }}</div>
                      <div class="subsection-text">{{ claim.reason }}</div>
                    </div>
                    <div class="subsection">
                      <div class="subsection-title">
                        {{ 'claims.edit.review.reviewAndSubmit.reviewList.productCategory' | translate }}</div>
                      <div class="subsection-text">{{ claim.productCategory }}</div>
                    </div>
                    <div class="subsection">
                      <div class="subsection-title">
                        {{ 'claims.edit.review.reviewAndSubmit.reviewList.quantity' | translate }}</div>
                      <div class="subsection-text">
                        {{ claim.quantity }}
                      </div>
                    </div>
                  </div>
                  <div class="column mr-md-2">
                    <div class="subsection">
                      <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.filedClaimAmount'
                        |
                        translate }}</div>
                      <div *ngIf="currencyType=='usd'" class="subsection-text">${{ claim.claimedAmount }}</div>
                      <div *ngIf="currencyType=='cad'" class="subsection-text">{{ claim.claimedAmount | currency : 'CAD'
                        +
                        '
                        ':'code' }}</div>
                      <div *ngIf="currencyType=='gbp'" class="subsection-text">£{{ claim.claimedAmount }}</div>
                      <div *ngIf="currencyType=='eur'" class="subsection-text">€{{ claim.claimedAmount }}</div>
                    </div>
                    <div class="subsection" *ngIf="isUsaUser || isCanadaUser">
                      <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.checkAchNumber' |
                        translate }}</div>
                      <div class="subsection-text">{{ claim.checkNumber }}</div>
                    </div>
                    <div class="subsection" *ngIf="isUsaUser || isCanadaUser">
                      <div class="subsection-title">{{
                        'claims.edit.review.reviewAndSubmit.reviewList.checkElectronicIssueDate' | translate }}</div>
                      <div class="subsection-text" *ngIf="claim.checkIssueDate == 'null'">{{ 'claimsDetail.notProvided'
                        |
                        translate }}</div>
                      <div class="subsection-text" *ngIf="(claim.checkIssueDate !== 'null') && isUsaUser">{{
                        claim.checkIssueDate | date: 'MM/dd/yyyy' }}</div>
                      <div class="subsection-text"
                        *ngIf="(claim.checkIssueDate !== 'null') && (isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser)">
                        {{ claim.checkIssueDate | date: 'dd/MM/yyyy' }}</div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="subsection" *ngIf="uuDocuments.length > 0">
                      <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.uploadedFiles' |
                        translate }}</div>
                      <div class="subsection-text document" *ngFor="let file of uuDocuments">
                        <div (click)="download(file)">{{ file.name }}</div>
                      </div>
                    </div>
                    <div class="subsection" *ngIf="uuDocuments.length == 0">
                      <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.uploadedFiles' |
                        translate }}</div>
                      <div class="subsection-text">{{ 'claimsDetail.notProvided' | translate }}</div>
                    </div>
                  </div>
                </div>
                <hr class="mt-3 mb-2">
                <upsc-notification-box [isDanger]="true"
                  *ngIf="claim?.idrdocList?.length > 0 && claim?.idrdocList[0] != '' && (claim?.customerDisplayStatus == 'Documents Pending' || claim?.closedOutCome == 'incomplete_documentation_Ext')">
                  <div class="left mr-3">
                    <svg id="warning" xmlns="http://www.w3.org/2000/svg" width="22.607" height="20"
                      viewBox="0 0 22.607 20">
                      <g id="Group_69" data-name="Group 69">
                        <g id="Group_68" data-name="Group 68">
                          <path id="Path_74" data-name="Path 74"
                            d="M22.391,47.132,12.682,30.315a1.591,1.591,0,0,0-2.757,0L.216,47.132a1.591,1.591,0,0,0,1.378,2.387H21.013a1.591,1.591,0,0,0,1.378-2.387Zm-1.149.928a.257.257,0,0,1-.23.133H1.594a.265.265,0,0,1-.23-.4l9.709-16.817a.265.265,0,0,1,.459,0l9.709,16.817A.257.257,0,0,1,21.242,48.06Z"
                            transform="translate(0 -29.519)" fill="#c61e1e" />
                          <rect id="Rectangle_168" data-name="Rectangle 168" width="1.178" height="6.285"
                            transform="translate(10.714 6.398)" fill="#c61e1e" />
                          <circle id="Ellipse_20" data-name="Ellipse 20" cx="0.786" cy="0.786" r="0.786"
                            transform="translate(10.518 14.586)" fill="#c61e1e" />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div class="right">
                    <div *ngFor="let doc of claim?.idrdocList" class="mb-1">{{ 'claimsDetail.upload.alert' | translate
                      }}<span class="b">{{ doc }}</span></div>
                  </div>
                </upsc-notification-box>
                <div class="upload"
                  *ngIf="!userPermission.isClaimView || userPermission.isFileClaim || userPermission.isClaimPayee">
                  <div class="subsection stand-alone notes">
                    <div class="subsection-title">{{ 'claimsDetail.upload.note' | translate }}</div>
                    <div class="subsection-text">{{ 'claimsDetail.upload.note1' | translate }}</div>
                    <div class="subsection-text">{{ 'claimsDetail.upload.note2' | translate }}</div>
                    <div class="subsection-text spaceBottom">{{ 'claimsDetail.upload.note3' | translate }}</div>

                    <div class="uploaded-documents d-flex justify-content-center align-items-center"
                      (click)="fileInput.click()" upscDragNDrop (onFileDropped)="uploadFile($event)">
                      <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
                      <div class="d-flex flex-column justify-content-center align-items-center">
                        <img class="pb-2" src="../../../../../assets/images/upload-file-icon.png">
                        <div class="attachments-title pb-2">
                          <span class="upload-underline">{{ 'claimsDetail.upload.message1' | translate }}</span> {{
                          'claimsDetail.upload.message2' | translate }}
                        </div>
                        <div class="pb-2">
                          {{ 'claimsDetail.upload.filesAllowed' | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="uploaded-attachments">
                      <ul class="your-attachments">
                        <li class="d-inline-flex justify-content-start align-items-center"
                          *ngFor="let file of listOfFiles">
                          <div>{{file.name}}</div>
                          <mat-icon class="delete-icon ml-2" (click)="remove(file)">clear</mat-icon>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="error" *ngIf="fileNameError">{{ 'claimsDetail.upload.fileName' | translate }}</div>
                  <div class="error" *ngIf="fileTypeError">{{ 'claimsDetail.upload.fileType' | translate }}</div>
                  <div class="error" *ngIf="fileTooBig">{{ 'claimsDetail.upload.limit' | translate }}</div>
                  <div class="error" *ngIf="systemError">{{ 'common.systemError' | translate }}</div>
                </div>
                <div class="additional-contents">
                  <div class="subsection stand-alone">
                    <div class="subsection-title spaceTop">
                      {{ 'claims.edit.review.reviewAndSubmit.reviewList.merchandiseDescription' | translate }}</div>
                    <div class="subsection-text break">{{ claim.description }}</div>
                  </div>
                  <div class="subsection stand-alone mb-0">
                    <div class="subsection-title">
                      {{ 'claims.edit.review.reviewAndSubmit.reviewList.additionalComments' | translate }}</div>
                    <div *ngIf="isNotePresent" class="subsection-text break">
                      {{ claim.customerNotes }}
                    </div>
                    <div *ngIf="!isNotePresent" class="subsection-text break">
                      {{ notesNotPresent }}
                    </div>
                  </div>
                </div>
              </div>
            </upsc-collapsible-panel-white>
          </div>
          <upsc-collapsible-panel-white
            [title]="'claims.edit.review.reviewAndSubmit.reviewList.claimPaymentReceipent' | translate"
            *ngIf="claim.payeeDetails" [isCollapsed]="isOtherCollapsed">
            <div class="section">
              <div class="contents" *ngIf="claim.payeeDetails?.walletIndicator != 'wallet'">
                <div class="subsection stand-alone">
                  <div class="subsection-text">{{ claim.payeeDetails.fullName }}</div>
                  <div class="subsection-text text-break"><a href="mailto:{{ claim.payeeDetails.email }}">{{
                      claim.payeeDetails.email }}</a></div>
                  <div class="subsection-text" *ngIf="claim.payeeDetails.phone">{{ claim.payeeDetails.phone |
                    phoneNumber
                    }}</div>
                </div>
                <div class="subsection stand-alone">
                  <div class="subsection-text">{{ claim.payeeDetails.addressLine1 }} </div>
                  <div class="subsection-text">{{ claim.payeeDetails.addressLine2 }}</div>
                  <div class="subsection-text">{{ claim.payeeDetails.city }}, <span *ngIf="isUsaUser || isCanadaUser">{{
                      claim.payeeDetails.state }}</span>
                    {{ claim.payeeDetails.zip }}</div>
                  <div class="subsection-text">{{ claim.payeeDetails.country }}</div>
                </div>
                <div class="subsection stand-alone mb-0">
                  <div class="subsection-title" *ngIf="claim.ach?.startsWith('**')">{{ 'payee.table.achelectronic' |
                    translate }}</div>
                  <div class="subsection-title">{{ claim.ach }}</div>
                </div>
              </div>
              <div class="contents" *ngIf="claim.payeeDetails?.walletIndicator == 'wallet'">
                <div class="subsection stand-alone bold">
                  Pay to UPS<sup>®</sup> Wallet
                </div>
              </div>
            </div>
          </upsc-collapsible-panel-white>
          <upsc-collapsible-panel-white
            [title]="'claims.edit.review.reviewAndSubmit.reviewList.contactInfo' | translate"
            [isCollapsed]="isOtherCollapsed">
            <div class="section">
              <!--  <hr> -->
              <div class="contents">
                <div class="subsection stand-alone mb-0">
                  <div class="subsection-title">{{ 'claimsDetail.contactDetails' | translate }}</div>
                  <div class="subsection-text">{{ claim.fName }} {{ claim.lName }}</div>
                  <div class="subsection-text">{{ claim.phoneNumber | phoneNumber }}</div>
                  <div class="subsection-text text-break">{{ claim.email }}</div>
                </div>
              </div>
            </div>
          </upsc-collapsible-panel-white>

          <div class="dbutton" *ngIf="isReopen">
            <button *ngIf="!isHappyReturns" class="btn btn-white" (click)="cancelReopen()">{{
              'claims.edit.review.reviewAndSubmit.reviewList.reOpenClaimCancel' | translate }}</button>
            <button *ngIf="isHappyReturns" class="btn btn-black" (click)="cancelReopen()">{{
              'claims.edit.review.reviewAndSubmit.reviewList.reOpenClaimCancel' | translate }}</button>
            <button *ngIf="!isHappyReturns" class="btn btn-solid" (click)="resubmitClaim()">
              {{ 'claims.edit.review.reviewAndSubmit.reviewList.reSubmitClaim' | translate }}</button>
            <button *ngIf="isHappyReturns" class="btn btn-solid-black" (click)="resubmitClaim()">
              {{ 'claims.edit.review.reviewAndSubmit.reviewList.reSubmitClaim' | translate }}</button>
          </div>

        </form>
      </div>
    </ng-template>

    <ng-template #successTemplate>
      <div class="inside-form2">
        <div class="success-wrapper d-flex flex-column align-items-center">
          <div class="content-wrapper">
            <div class="title">
              {{ 'claimsDetail.withdraw.title' | translate }}
            </div>
            <div class="description1">
              {{ 'claimsDetail.withdraw.descr1' | translate }}
              <span class="bold-black">{{ submittedClaimNumber }}</span>.
            </div>
            <div class="description2">
              {{ 'claimsDetail.withdraw.descr2' | translate }}
              <a class="claims-link" (click)="reloadPage()">{{ 'claimsDetail.withdraw.descr2Detail' |
                translate }}</a>
              {{ 'claimsDetail.withdraw.descr2Page' | translate }}
            </div>
            <div class="description3">
              {{ 'claimsDetail.withdraw.descr3' | translate }}
              <a [routerLink]="['/claims']" class="claims-link">{{ 'claimsDetail.withdraw.descr3Allclaims' | translate
                }}</a>
              {{ 'claimsDetail.withdraw.descr2Page' | translate }}
            </div>
          </div>
          <div class="start-new-claim-wrapper">
            <button class="start-new-claim claims" (click)="fileNewClaim($event)">{{
              'claimsDetail.withdraw.fileAnotherClaim' | translate }}</button>
            <button class="start-new-claim" (click)="exitToDashboard($event)">{{ 'claimsDetail.withdraw.dashboard' |
              translate }}</button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>