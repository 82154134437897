<div class="upsc-resetpassword">
<div class="wrapper">
  <div class="header d-flex justify-content-between">
    <div class="title">{{ 'resetPassword.title' | translate }}</div>
    <div class="required">{{ 'resetPassword.required' | translate }}</div>
  </div>

  <form [formGroup]="resetPasswordForm">
    <ng-template #resetPasswordContent>
      <strong>{{ 'resetPassword.form.tooltip.title' | translate }}</strong>
      <div>{{ 'resetPassword.form.tooltip.bullet1' | translate }}</div>
      <div>{{ 'resetPassword.form.tooltip.bullet2' | translate }}</div>
      <div>{{ 'resetPassword.form.tooltip.bullet3' | translate }}</div>
      <div>{{ 'resetPassword.form.tooltip.bullet4' | translate }}</div>
      <div>{{ 'resetPassword.form.tooltip.bullet5' | translate }}</div>
      <div>{{ 'resetPassword.form.tooltip.bullet6' | translate }}</div>
      <div>{{ 'resetPassword.form.tooltip.bullet7' | translate }}</div>
    </ng-template>
    <mat-form-field
      [ngbTooltip]="resetPasswordContent" tooltipClass="ngb-tooltip-class">
      <input matInput required formControlName="password" placeholder="{{ 'resetPassword.form.newPassword' | translate }}" [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix *ngIf="!resetPasswordForm.get('password').errors">{{'check_circle'}}</mat-icon>
      <mat-icon matSuffix class="icon" (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
      <mat-error>
        <div *ngIf="resetPasswordForm.get('password').errors?.required">
          {{ 'resetPassword.form.required' | translate }}
        </div>
        <div *ngIf="resetPasswordForm.get('password').errors?.minlength || resetPasswordForm.get('password').errors?.pattern">
          {{ 'resetPassword.form.required' | translate }}
        </div>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput required formControlName="reenterPassword" placeholder="{{ 'resetPassword.form.reenterPassword' | translate }}" [type]="hide2 ? 'password' : 'text'">
      <mat-icon matSuffix *ngIf="!resetPasswordForm.get('reenterPassword').errors && resetPasswordForm.controls['reenterPassword'].touched">{{'check_circle'}}</mat-icon>
      <mat-icon matSuffix class="icon" (click)="hide2 = !hide2">{{hide2 ? 'visibility' : 'visibility_off'}}</mat-icon>
      <mat-error>
        <div *ngIf="resetPasswordForm.get('reenterPassword').errors?.required">
          {{ 'resetPassword.form.requiredReenter' | translate }}
        </div>
        <div *ngIf="resetPasswordForm.get('reenterPassword').errors?.notEquivalent">
          {{ 'resetPassword.form.requiredReenter' | translate }}
        </div>
      </mat-error>
    </mat-form-field>
    <div class="error-returned" *ngIf="errorReturned">
      {{ 'resetPassword.form.errorReturned' | translate }}
    </div>
    <div class="actions d-flex align-items-center">
      <div><button class="upsc-button" [disabled]="!resetPasswordForm.valid" (click)="resetPassword()">{{ 'loginHelp.userId.submit' | translate }}</button>
      </div>
      <div class="cancel"><a (click)="loginPage()">{{ 'loginHelp.userId.cancel' | translate }}</a></div>
    </div>
  </form>
  <div class="help">
    {{ 'loginHelp.userId.contactUs' | translate }}
    <span class="bold">{{ 'loginHelp.userId.contactUsBold' | translate }}</span>
  </div>
</div>
</div>
