import { Component } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
  selector: 'upsc-plans-features',
  templateUrl: './plans-features.component.html',
  styleUrls: ['./plans-features.component.scss']
})
export class PlansFeaturesComponent {

}
