import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { IClaimsDetails } from 'src/app/shared/models/claim.interface';
import { IPolicies } from 'src/app/shared/models/policies.interface';
import { ClaimService } from 'src/app/services/claim.service';
import { LoginService } from 'src/app/services/login.service';
import { TupssExportService } from 'src/app/services/tupss-export.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { UserService } from 'src/app/services/user.service';
import localMetaData from 'src/assets/full-metadata.json';
import { countries } from 'src/config/metadata-config';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { JpmcErrorDialogComponent } from 'src/app/dialog/jpmc-error-dialog/jpmc-error-dialog.component';
import { environment as ENV } from '../../../../../../../environments/environment';
import { TupssService } from '../../../services/tupss.service';
import { ViewportScroller } from "@angular/common";
import { ConfirmPackageStatusComponent } from '../confirm-package-status/confirm-package-status.component';
import { emptyIEmail } from 'src/app/shared/models/email.interface';
import { DataService } from 'src/app/services/data.service';
import { ExportService } from 'src/app/services/export.service';
import { ALL_SPECIAL_CHARACTERS } from 'src/app/shared/regex-patterns';

@Component({
  selector: 'upsc-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class tupssDetailComponent implements OnInit {

  @ViewChild(ConfirmPackageStatusComponent) packageStatusComponent: ConfirmPackageStatusComponent;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.persistSearch();
  }

  FILENAME: string = this.translateService.instant(this.translateService.instant('claimsDetail.detail'));

  //Interfaces
  claim: IClaimsDetails = {};
  userDetails;
  policy: IPolicies;
  files: any = [];
  listOfFiles = new Array();

  //HTML display helpers
  stateList: any[] = Object.entries(localMetaData.metaData.states).map(([key, value]) => ({ key, value }));
  countryList: any[] = Object.entries(countries).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.country.${key}`) } });//translated countries
  statusDetails = '';
  insured: string;  //Used for opening the email with subject line
  selectedClaimNumber: string;
  sgDocuments = []; //System-generated documents
  uuDocuments = []; //User-uploaded documents
  showFileProgress = false;
  fileTooBig = false;
  fileNameError = false;
  fileTypeError = false;
  systemError = false;
  systemError2 = false;
  claimPaymentRecipientShow: boolean = true;
  isGuestUser = false;
  isUsaUser: boolean;
  currencyType: string;
  isTfcp: boolean;
  deliveryScan: boolean = false;
  packedBy;
  coverageType;
  placeholderPayee;
  placeholderStatus;
  isPackageEditable: boolean = false;
  isPackageEditableContinueSearch: boolean = false;
  iShipDataReady: boolean;
  statusConfirm = false;
  isConfirmationStatus: boolean = false;
  policyNumber: string = '';
  resendSuccess: boolean = false;
  resendError: boolean = false;
  resendLoading: boolean = false;
  flowType: string = this.dataService.flowType;
  isMessageCollapsed: boolean = false;
  canUpdatePayee: boolean = false;
  emailAddress: string;
  email2 : string;


  constructor(
    private claimService: ClaimService,
    private loginService: LoginService,
    private tupssExportService: TupssExportService,
    private route: ActivatedRoute,
    private userService: UserService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private tupssService: TupssService,
    private scroller: ViewportScroller,
    private dataService: DataService,
    private router: Router,
    private excelService: ExportService
  ) { }

  onConfirmationSubmit(event) {
    if (event === '2') {
      this.isPackageEditable = true;
      //this.iShipDataReady = true;
      this.scroller.scrollToAnchor('targetEditable');
    }
    /* else {
      this.isPackageEditable = false;
      this.iShipDataReady = false;
    } */
  }
  onConfirmationSubmitContinueSearch(event) {
    if (event === '1') {
      this.isPackageEditableContinueSearch = true;
      //this.iShipDataReady = true;
      this.scroller.scrollToAnchor('targetEditable');
    }
    /* else {
      this.isPackageEditable = false;
      this.iShipDataReady = false;
    } */
  }
  onClaimSubmit(event) {
    if (event === '0') {
      this.isPackageEditable = false;
      this.statusConfirm = false;
      this.isConfirmationStatus = true;
      this.getClaimDetails();
      this.scroller.scrollToAnchor('targetConfirmationStatus');
      this.packageStatusComponent.unHideUpdateButton();
    }
    else if (event === '1') {
      this.isPackageEditable = false;
      this.statusConfirm = true;
      this.isConfirmationStatus = false;
      this.getClaimDetails();
      this.scroller.scrollToAnchor('targetMainPanel');
    }
    /* else
      this.isPackageEditable = true; */
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ policyDetails }) => { //This decides whats to do with resolver data. If policy details does exist, they should be able to see this page. If not, TBD...
      if (!sessionStorage.getItem('policyDetails')) {
        sessionStorage.setItem('policyDetails', JSON.stringify(policyDetails.data))
      }
    });

    this.userDetails = this.userService.getUserInfo();
    this.policy = this.loginService.getPolicyDetails();
    this.insured = this.policy.insured;
    this.insured = encodeURIComponent(this.insured);
    this.isGuestUser = this.router.url.includes('guest');
    this.isTfcp = this.userService.getUserInfo().type == 'tfcp';
    // if(this.userService.getUserInfo().deliveryScanIndicator == true) {
    //   this.isConfirmationStatus = true;
    // }
    // else { this.deliveryScan = true; }

    //this.isConfirmationStatus = true;
    //this.deliveryScan = true;

    this.route.queryParams.subscribe(params => {
      this.selectedClaimNumber = params.claimNumber;
      this.placeholderPayee = params.payeeID;
      this.placeholderStatus = params.status;
    });

    if (sessionStorage.getItem('claimNumber') && !this.selectedClaimNumber) {
      this.selectedClaimNumber = sessionStorage.getItem('claimNumber');
    }
    if (!this.selectedClaimNumber) {
      if (sessionStorage.getItem('userDetails') && JSON.parse(sessionStorage.getItem('userDetails')).claimNumber) {
        this.selectedClaimNumber = JSON.parse(sessionStorage.getItem('userDetails')).claimNumber;
      }
    }
    if (this.selectedClaimNumber) {
      sessionStorage.setItem('claimNumber', this.selectedClaimNumber);
      if (sessionStorage.getItem('cdLink')) {
        //For users that come through the claim detail link in an email
        let claimNumber = atob(sessionStorage.getItem('cdLink')).split('/')[1];
        if (this.selectedClaimNumber != claimNumber) {
          //Display nothing
        } else {
          /*if (this.userService.getUserInfo().emailDate !== undefined &&
            this.userService.getUserInfo().emailAddress !== undefined) {
            this.isConfirmationStatus = true;
            //window.scrollTo(0, document.body.scrollHeight);
            this.scroller.scrollToAnchor('targetConfirmationStatus');
          } */
          this.getClaimDetails();
        }
      } else {
        /*if (this.userService.getUserInfo().emailDate !== undefined &&
         this.userService.getUserInfo().emailAddress !== undefined) {
         this.isConfirmationStatus = true;
         //window.scrollTo(0, document.body.scrollHeight);
         this.scroller.scrollToAnchor('targetConfirmationStatus');
       } */
        this.getClaimDetails();
      }
    }
    this.emailAddress = sessionStorage.getItem("emailAddress");
    this.email2 = sessionStorage.getItem('email2');

    console.log(this.flowType);

  }

  getClaimDetails() {
    let claimNumber = this.userService.getUserInfo().claimNumber;
    let policyNumber = this.userService.getUserInfo().policyNumber;
    let deliveryScan = this.userService.getUserInfo().deliveryScanIndicator;
    if (!claimNumber) {
      claimNumber = this.selectedClaimNumber ? this.selectedClaimNumber : JSON.parse(sessionStorage.getItem('claimNumber'));
    }
    if (!policyNumber) {
      policyNumber = JSON.parse(sessionStorage.getItem('policyDetails'))?.policyNumber;
    }

    this.claimService.getClaimDetails(claimNumber, policyNumber).subscribe(
      data => {
        sessionStorage.setItem('claimDetails', JSON.stringify(data));
        if (data) {
          this.claimService.setTupssClaimData(data);
          this.claim.claimNumber = this.selectedClaimNumber;
          this.claim.tracking = data.shippingDetailsDTO.trackingNumber ? data.shippingDetailsDTO.trackingNumber : this.translateService.instant('claimsDetail.notProvided');
          this.claim.shipDate = data.shippingDetailsDTO.shipDate ? data.shippingDetailsDTO.shipDate : this.translateService.instant('claimsDetail.notProvided');

          if (data.lossCause == "loss_Ext") {
            this.claim.reason = this.translateService.instant('claimsDetail.loss');
          } else if (data.lossCause == "damage_Ext") {
            this.claim.reason = this.translateService.instant('claimsDetail.damage');
          } else if (data.lossCause == "fallout_Ext") {
            this.claim.reason = this.translateService.instant('claimsDetail.missingContents');
          } else if (data.lossCause == "delay") {
            this.claim.reason = this.translateService.instant('claimsDetail.lateDelivery');
          } else {
            this.claim.reason = this.translateService.instant('claimsDetail.notProvided');
          }
          this.claim.checkNumber = data.checks.length == 0 ? this.translateService.instant('claimsDetail.notProvided') : data.checks[data.checks.length - 1].checkNumber;
          this.claim.checkIssueDate = data.checks.length == 0 ? "null" : data.checks[data.checks.length - 1].issueDate;
          let sliceTrack = data.shippingDetailsDTO.trackingNumber.slice(0, 2);
          if (sliceTrack.toUpperCase() == '1Z') {
            this.claim.upsAccountNumber = data.shippingDetailsDTO.trackingNumber.slice(2, 8).toUpperCase();
          }
          else { this.claim.upsAccountNumber = this.translateService.instant('claimsDetail.notProvided'); }
          this.claim.reference = data.shippingDetailsDTO.referenceNumber ? data.shippingDetailsDTO.referenceNumber : this.translateService.instant('claimsDetail.notProvided');
          this.claim.shipperCompany = data.shippingDetailsDTO.senderDetails?.companyName ? data.shippingDetailsDTO.senderDetails.companyName : this.translateService.instant('claimsDetail.notProvided');
          this.claim.shipperAddress1 = data.shippingDetailsDTO.senderDetails?.address1 ? data.shippingDetailsDTO.senderDetails.address1 : this.translateService.instant('claimsDetail.notProvided');
          // this.claim.shipperAddress1 = data.shippingDetailsDTO.senderDetails?.address1 ? data.shippingDetailsDTO.senderDetails.address1 : this.translateService.instant('claimsDetail.notProvided');
          this.claim.shipperCity = data.shippingDetailsDTO.senderDetails?.city ? `${data.shippingDetailsDTO.senderDetails.city}` : this.translateService.instant('claimsDetail.notProvided');
          this.claim.shipperState = data.shippingDetailsDTO.senderDetails?.state ? ` ${data.shippingDetailsDTO.senderDetails.state}` : "";
          this.claim.shipperCountry = data.shippingDetailsDTO.senderDetails?.country ? data.shippingDetailsDTO.senderDetails.country : this.translateService.instant('claimsDetail.notProvided');
          this.claim.recipientCompany = data.shippingDetailsDTO.receiverDetails?.companyName ? data.shippingDetailsDTO.receiverDetails.companyName : this.translateService.instant('claimsDetail.notProvided');
          this.claim.recipientCity = data.shippingDetailsDTO.receiverDetails?.city ? `${data.shippingDetailsDTO.receiverDetails.city}` : this.translateService.instant('claimsDetail.notProvided');
          this.claim.recipientState = data.shippingDetailsDTO.receiverDetails?.state ? ` ${data.shippingDetailsDTO.receiverDetails.state}` : "";
          this.claim.recipientCountry = data.shippingDetailsDTO.receiverDetails?.country ? data.shippingDetailsDTO.receiverDetails.country : this.translateService.instant('claimsDetail.notProvided');
          this.claim.fName = data.mainContact.firstName ? data.mainContact.firstName : this.translateService.instant('claimsDetail.notProvided');
          this.claim.lName = data.mainContact.lastName ? data.mainContact.lastName : '';
          this.claim.email = data.mainContact.emailAddress1 ? data.mainContact.emailAddress1 : this.translateService.instant('claimsDetail.notProvided');
          this.claim.phoneNumber = data.mainContact.workNumber ? data.mainContact.workNumber : this.translateService.instant('claimsDetail.notProvided');
          this.claim.description = data.shippingDetailsDTO.merchandiseDescription ? data.shippingDetailsDTO.merchandiseDescription : this.translateService.instant('claimsDetail.notProvided');
          this.claim.fileDate = data.reportedDate ? data.reportedDate : this.translateService.instant('claimsDetail.notProvided');
          this.claim.adjusterName = data.adjuster.displayName ? data.adjuster.displayName : this.translateService.instant('claimsDetail.notProvided');
          this.claim.adjusterEmail = data.adjuster.email ? data.adjuster.email : this.translateService.instant('claimsDetail.notProvided');
          this.claim.adjusterPhone = data.adjuster.phoneNumber ? data.adjuster.phoneNumber : this.translateService.instant('claimsDetail.notProvided');
          this.claim.productCategory = data.productCategory_Ext ? data.productCategory_Ext : this.translateService.instant('claimsDetail.notProvided');
          this.claim.claimState = data.claimState ? data.claimState : this.translateService.instant('claimsDetail.notProvided');
          this.claim.claimSubStatus = data.claimSubStatus ? data.claimSubStatus : this.translateService.instant('claimsDetail.notProvided');
          this.claim.customerDisplayStatus = data.customerDisplayStatus == 'Closed' && data.closedOutCome == 'incomplete_documentation_Ext' ? this.translateService.instant('metadata.claimStatusMapping.incomplete_documentation_Ext') : data.customerDisplayStatus ? data.customerDisplayStatus : this.translateService.instant('claimsDetail.notProvided'); //If there is a CLOSED claim with INCOMPLETE_DOCUMENTATION_EXT, then we remap it to DOCUMENTS PENDING
          this.claim.claimedAmount = data.claimAmount_Ext ? data.claimAmount_Ext.replace(' usd', '').replace(' cad', '') : 0.0;
          this.claim.totalPaymentAmount = data.totalPayments.amount ? data.totalPayments.amount : 0;
          this.claim.currency = data.totalPayments.currency ? data.totalPayments.currency : 'usd';
          this.claim.customerNotes = data.customerNotes ? data.customerNotes : this.translateService.instant('claimsDetail.notProvided');
          this.claim.idrdocList = data.idrdocList ? data.idrdocList : null;
          this.claim.closedOutCome = data.closedOutCome;
          this.claim.payeeID = data.payeeID;
          this.claim.quantity = data.quantity;
          this.currencyType = data.claimAmount_Ext.split(' ')[1];
          this.claim.documents = (data.documents && data.documents.length > 0) ? data.documents : this.translateService.instant('claimsDetail.notProvided');
          if (this.claim.documents != 'Not Provided') {
            for (let i = 0; i < Object.keys(this.claim.documents).length; i++) {
              if (this.claim.documents[i].documentType == 'letter_received' || this.claim.documents[i].documentType == 'onl_document_Ext') {
                this.uuDocuments.push(this.claim.documents[i]);
              } else {
                this.sgDocuments.push(this.claim.documents[i]);
              }
            }
          }
          if (this.claim.customerDisplayStatus == 'Denied' || (this.claim.customerDisplayStatus && this.claim.customerDisplayStatus.includes('Closed'))) {
            this.statusDetails = this.translateService.instant(`metadata.claimStatusMapping.${data.closedOutCome}`); //undefined if no status details are found. only need to check for denied or closed claims.
          }
          if (this.claim.customerDisplayStatus == 'Paid' || this.claim.customerDisplayStatus == 'Submitted for Payment' || this.claim.customerDisplayStatus == 'Denied') {
            this.claimPaymentRecipientShow = false;
          }
          //this.claim.claimSubStatus = 'DeliveryConfirmationPending';
          if (this.claim.claimSubStatus == 'DeliveryConfirmationPending' || this.claim.claimSubStatus == 'search_request_unconfirmed') {
            // this.isPackageEditable = true;
            //this.isConfirmationStatus = true;
            this.claim.customerDisplayStatus = "Pending Delivery Confirmation";
            if (deliveryScan == "Yes") {
              this.isConfirmationStatus = true;
              this.scroller.scrollToAnchor('isConfirmationStatus');
            }
            else if (deliveryScan == "No") {
              this.deliveryScan = true;
              this.scroller.scrollToAnchor('isConfirmationStatus22');
              // this.claim.customerDisplayStatus = "Search In Progress";
            }
            this.statusDetails = null;
            sessionStorage.setItem('claimDetails', JSON.stringify(data));
            this.tupssService.setItem("trackingNumber", this.claim.tracking);

            this.claimService.getiShipData({
              transactionSrc: 'Capital_TFCP',
              trackNo: this.claim.tracking
            }).subscribe(
              data => {
                sessionStorage.setItem('persistIShipData', JSON.stringify(data));
                this.iShipDataReady = true;
              }
            );
          }
          else if (this.claim.claimSubStatus == 'pending_search_request') {
            this.claim.customerDisplayStatus = "Search In Progress";
          }
          // for (let i = 0; i < data.claimContacts.length; i++) {
          //   if (data.claimContacts[i].contactRoles[0] === "transportation_carrier_Ext") {
          //     this.claim.carrier = data.claimContacts[i].contactDTO.displayName;
          //   }
          // }
          for (let claimContactsData of data.claimContacts) {
            if (claimContactsData.contactRoles[0] === "transportation_carrier_Ext") {
              this.claim.carrier = claimContactsData.contactDTO.displayName;
            }
            if (claimContactsData.contactRoles[0] === "receiver_Ext") {
              this.claim.recipientZipcode = claimContactsData.contactDTO?.primaryAddress?.postalCode;
            }
            if (claimContactsData.contactRoles[0] === "shipper_Ext") {
              this.claim.shipperZipcode = claimContactsData.contactDTO?.primaryAddress?.postalCode;
            }
            if (claimContactsData.contactRoles.includes("policy_payee_Ext") || claimContactsData.contactRoles.includes("checkpayee")) {
              this.claim.payeeDetails = {
                fullName: claimContactsData.contactDTO.displayName,
                email: claimContactsData.contactDTO.emailAddress1,
                phone: claimContactsData.contactDTO.workNumber,
                addressLine1: claimContactsData.contactDTO.primaryAddress?.addressLine1,
                addressLine2: claimContactsData.contactDTO.primaryAddress?.addressLine2,
                city: claimContactsData.contactDTO.primaryAddress?.city,
                state: this.stateList.find(x => x.key == claimContactsData.contactDTO.primaryAddress?.state)?.value ? this.stateList.find(x => x.key == claimContactsData.contactDTO.primaryAddress?.state).value : claimContactsData.contactDTO.primaryAddress?.state,
                country: this.countryList.find(x => x.key == claimContactsData.contactDTO.primaryAddress?.country)?.value ? this.countryList.find(x => x.key == claimContactsData.contactDTO.primaryAddress?.country).value : claimContactsData.contactDTO.primaryAddress?.country,
                zip: claimContactsData.contactDTO.primaryAddress?.postalCode,
                stateKey: claimContactsData.contactDTO.primaryAddress?.state
              }
            }
          }
          if (this.claim.payeeID) {
            let request;
            if (this.userService.isGuestUser()) {
              request = {
                "policyNumber": this.userService.getUserInfo().policyNumber,
                "emailAddress": sessionStorage.getItem('guestUserEmail') //guest email goes here
              }
            } else {
              request = {
                "policyNumber": this.userService.getUserInfo().policyNumber
              }
            }
            let req = {
              payeeID: this.claim.payeeID,
              policyNumber: this.userService.getUserInfo().policyNumber ? this.userService.getUserInfo().policyNumber : this.userService.getUserInfo().contractId
            }
            this.claimService.getPayeeDetails(req).subscribe(
              data => {
                this.claim.payeeDetails = {
                  fullName: data.data.payeeName,
                  email: data.data.email,
                  phone: data.data.phoneNumber,
                  addressLine1: data.data.addressLine1,
                  addressLine2: data.data.addressLine2,
                  city: data.data.city,
                  state: data.data.state,
                  country: this.countryList.find(x => x.key == data.data.country)?.value ? this.countryList.find(x => x.key == data.data.country).value : data.data.country,
                  zip: data.data.zip
                };
                if (data.data.paymentMethod == "ACH") {
                  this.claim.ach = "**" + data.data.accountNumber;
                } else if (data.data.paymentMethod == "Zelle") {
                  this.claim.ach = this.translateService.instant('payee.table.zelle');
                } else {
                  this.claim.ach = this.translateService.instant('payee.table.check');
                }
                if (this.isConfirmationStatus === true) {
                  //window.scrollTo(0, document.body.scrollHeight);
                  this.scroller.scrollToAnchor('targetConfirmationStatus');
                }
              }, error => {
                this.claim.ach = this.translateService.instant('payee.table.check');
              }
            );
          }
          if (!this.claim.payeeID) {
            this.claim.ach = this.translateService.instant('payee.table.check');
          }
          if (this.claim.carrier) {
            //Do nothing, already good to go
          } else {
            this.claim.carrier = this.translateService.instant('claimsDetail.notProvided');
          }
        }
        this.checkQueryParamsForPrint();
        if (this.isConfirmationStatus === true) {
          //window.scrollTo(0, document.body.scrollHeight);
          this.scroller.scrollToAnchor('targetConfirmationStatus');
        }
      }, error => { }
    );
  }

  //Opens the warning dialog if JPMC is not working
  openWarningDialog() {
    let dialogRef = this.dialog.open(JpmcErrorDialogComponent);
    dialogRef.afterClosed().subscribe();
  }

  updateClaimPaymentRecipient() {
    this.systemError2 = false;
    let request = {
      action: "tupss edit",
      paymentDirection: "Claims",
      country: "US",
      paymentMode: "",
      policyIdentifier: this.policy.policyNumber ? this.policy.policyNumber : "",
      email: this.userDetails.email ? this.userDetails.email : "",
      callBackURL: `${ENV.baseUrl.web}ups-my-choice/detail`,
      scenario: "TUPSS",
      createdByUserId: this.userDetails.userId ? this.userDetails.userId : "",
      guestUserIndicator: "N",
      locale: sessionStorage.getItem('locale'),
      tupssFlow: "mychoice",
      policyData: {
        insured: this.policy.insured
      },
      iShipData: {
        companyName: this.claim.payeeDetails.fullName ? this.claim.payeeDetails.fullName : "",
        email: this.claim.payeeDetails.email ? this.claim.payeeDetails.email : "",
        phone: this.claim.payeeDetails.phone ? this.claim.payeeDetails.phone : "",
        addressLine1: this.claim.payeeDetails.addressLine1 ? this.claim.payeeDetails.addressLine1 : "",
        addressLine2: this.claim.payeeDetails.addressLine2 ? this.claim.payeeDetails.addressLine2 : "",
        city: this.claim.payeeDetails.city ? this.claim.payeeDetails.city : "",
        state: this.claim.payeeDetails.state ? this.claim.payeeDetails.state : "",
        zipCode: this.claim.payeeDetails.zip ? this.claim.payeeDetails.zip : "",
        payeeId: this.claim.payeeID ? this.claim.payeeID : ""
      }
    };

    //Get Call Back URL for CCP Portals
    request.callBackURL = this.dataService.getCallBackURL(ENV.baseUrl.web);
    //get flow type for dpat redirection
    request.tupssFlow = this.dataService.getTupssFlow(); 
    //Dpat redirection
    this.claimService.getRedirect(request).subscribe(
      data => {
        this.systemError2 = false;
        window.location.replace(data.data.url);
      }, error => {
        this.systemError2 = true;
      }
    );
  }

  export(type: string) {
    if (this.claim.tracking && this.isGuestUser) {
      this.excelService.claimDetailToPDF(this.claim, `${this.claim.claimNumber} ${this.FILENAME}`, type);
    } else if (this.claim.tracking) {
      this.tupssExportService.claimDetailToPDF(this.claim, `${this.claim.claimNumber} ${this.FILENAME}`, type);
    }
  }

  //Downloads documents from claim detail
  download(doc) {
    this.claimService.downloadDocument(doc.publicID.split(":")[1], this.policy.policyNumber).subscribe(
      data => {
        let file = new Blob([data], { type: doc.mimeType });
        FileSaver.saveAs(file, doc.name);
      }, error => { }
    );
  }

  persistSearch() {
    if (sessionStorage.getItem('retainState')) {
      sessionStorage.setItem('isPostBack', 'true');
    }
    else {
      sessionStorage.setItem('isPostBack', 'false');
    }
  }

  clearDocumentErrors() {
    this.fileTooBig = false;
    this.fileNameError = false;
    this.fileTypeError = false;
    this.systemError = false;
  }

  uploadFile(event) { //event is the fileList
    let file = event[0];
    let formData: FormData = new FormData();
    // this.fileTooBig = false;

    this.clearDocumentErrors();

    if (file.name.length > 80) {
      this.fileNameError = true;
      return;
    }
    /* let fileNameParts = file.name.split('.');
    if (fileNameParts.length <= 1 || ALLOWED_DOC_TYPES.indexOf(fileNameParts[fileNameParts.length - 1].toLowerCase()) < 0) {
      this.fileTypeError = true;
      return;
    } */
    if (file.size > 7340032) {
      this.fileTooBig = true;
    }
    else {
      this.showFileProgress = true;
      this.listOfFiles.push({
        "name": file.name,
        "processing": true,
        "publicID": "",
        "error": false
      });
      this.claimService.generateUploadToken().subscribe(
        data => {
          let docRequest = {
            "sessionID": data,
            "claimNumber": this.claim.claimNumber,
            "name": this.formatFileName(file),
            "docUID": "001",
            "documentType": "letter_received",
            "securityType": "unrestricted",
            "status": "approved",
            "postFnolDoc": "true",
            "mimeType": ""
          }

          if (file.name.endsWith('.msg')) {
            docRequest.mimeType = "application/vnd.ms-outlook"
          } else {
            docRequest.mimeType = file.type;
          }

          formData.append('documentUploadRequest', JSON.stringify(docRequest));
          formData.append('file', file, this.formatFileName(file));

          this.claimService.uploadDocument(formData).subscribe(
            data1 => {
              if (data1.publicID) {
                let i = this.listOfFiles.findIndex(x => x.name == file.name);
                this.listOfFiles[i].processing = false;
                this.listOfFiles[i].publicID = data1.publicID;
              }
              else {
                this.systemError = true;
                let i = this.listOfFiles.findIndex(x => x.name == file.name);
                this.listOfFiles[i].processing = false;
                this.listOfFiles[i].error = true;
              }
            },
            error => {
              this.systemError = true;
              let i = this.listOfFiles.findIndex(x => x.name == file.name);
              this.listOfFiles[i].processing = false;
              this.listOfFiles[i].error = true;
            }
          );
        },
        err => {
          this.systemError = true;
          let i = this.listOfFiles.findIndex(x => x.name == file.name);
          this.listOfFiles[i].processing = false;
          this.listOfFiles[i].error = true;
        }
      );
    }
  }

  //When user tries to upload a document with special characters, sned it to GW with spaces replacing the special characters
  formatFileName(file) {
    let extension = file.name.substring(file.name.lastIndexOf('.'));
    let fileNameWithoutExtension = file.name.substring(0, file.name.lastIndexOf('.'));

    try {
      fileNameWithoutExtension = fileNameWithoutExtension.replace(/[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/g, ' ');
      fileNameWithoutExtension = fileNameWithoutExtension.replace(ALL_SPECIAL_CHARACTERS, ' ');
      fileNameWithoutExtension = fileNameWithoutExtension.trim();
    } catch(e) { }

    return `${fileNameWithoutExtension}${extension}`;
  }

  remove(file) {
    this.listOfFiles[this.listOfFiles.findIndex(x => x.name == file.name)].processing = true;
    this.claimService.removeDocument(file).subscribe(
      data => {
        this.listOfFiles[this.listOfFiles.findIndex(x => x.name == file.name)].processing = false;
        this.listOfFiles.splice(this.listOfFiles.findIndex(x => x.name == file.name), 1);
      }, error => {
        this.systemError = true;
        let i = this.listOfFiles.findIndex(x => x.name == file.name);
        this.listOfFiles[i].processing = false;
        this.listOfFiles[i].error = true;
      }
    )
  }

  checkQueryParamsForPrint() {
    let queryParams = this.route.snapshot.queryParams;
    if (queryParams.print) {
      this.export('print');
    }
  }

  //Resend the claim submission email. Should only work for TFCP users.
  sendTFCPClaimSubmissionEmail() {
    this.resendLoading = true;
    this.resendError = false;
    this.resendSuccess = false;
    let request = emptyIEmail;
    request = {
      ...request,
      emailFor: 'TFCPClaimSubmission',
      CountryLocale: sessionStorage.getItem('locale'),
      data: {
        email: this.claim.email,
        claimNumber: this.claim.claimNumber,
        claimDetailLink: ENV.baseUrl.web + btoa(`${this.userService.getUserInfo().policyNumber}/${this.claim.claimNumber}/TFCP/${this.claim.email}/${this.claim.tracking}`),
        firstName: this.claim.fName,
        lastName: '',
        companyName: this.claim.shipperCompany,
        city: this.claim.shipperCity,
        zip: this.claim.shipperZipcode,
        state: this.claim.shipperState.trim(),
        streetAddress: this.claim.shipperAddress1
      }
    }

    this.tupssService.sendTFCPClaimSubmissionEmail(request).subscribe(
      data => {
        this.resendLoading = false;
        this.resendSuccess = true;
      },
      error => {
        this.resendLoading = false;
        this.resendError = true;
      }
    );
  }

  determinePayeeUpdateable() {
    if (this.isTfcp || this.router.url.includes('guest')) {
      this.canUpdatePayee = false;
    } else {
      this.canUpdatePayee = true;
    }
  }
}
