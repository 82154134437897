import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { HappyReturnsService } from 'src/app/services/happy-returns.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'upsc-reopen-confirmation',
  templateUrl: './reopen-confirmation.component.html',
  styleUrls: ['./reopen-confirmation.component.scss']
})
export class ReopenConfirmationComponent implements OnInit {


  public isHappyReturns: boolean = false;
  public groupCode: string = '';
  public userDetails: any = {};

  constructor(private dialogRef: MatDialogRef<ReopenConfirmationComponent>,
    private userService: UserService,
    private hpService: HappyReturnsService
  ) {

  }

  ngOnInit(): void {
    this.userDetails = this.userService.getUserInfo();
    try {
      let happyReturnsInfo = this.hpService.getHappyReturnsInfo();
      if (happyReturnsInfo !== null && happyReturnsInfo.partnerName === 'happy-returns') {
        this.isHappyReturns = true;
        this.groupCode = this.hpService.getHappyReturnsInfo().groupCode;
      }
    } catch {
      this.isHappyReturns = false;
    }
  }

  close() {
    this.dialogRef.close("Cancel");
  }

  reOpen() {
    this.dialogRef.close("Reopen");
  }

}
