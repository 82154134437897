import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiEndpoints } from '../../config/api-endpoints';
import { environment } from '../../environments/environment';
import { IClaim, ISummary, IMsgSummary, ClaimIdsModel, ClaimListModel, MessageClaimIdsModel, MessageListModel, MessageIdsModel, ILayoutDetails, ClaimStats, GetUserLayoutDetails, GetUserLayout, removeUserLayout, RedirectDetails } from '../shared/models/claim.interface';
import { DecimalPipe } from '@angular/common';
import { UserService } from './user.service';
import { ReopenClaim } from '../shared/models/reopen-claim.interface';
import { DeleteDraft } from '../shared/models/delete-draft.interface';

@Injectable()
export class ClaimService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private subject = new Subject<any>();
  private setCurrentStep = new Subject<any>();
  private addressValidationData = new BehaviorSubject<any>(null);
  private tupssEnteredAddress = new BehaviorSubject<any>(null);
  private loading = new BehaviorSubject<boolean>(false);
  private iShipData = new BehaviorSubject<any>(null);
  private tupssClaimData = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private number: DecimalPipe,
    private userService: UserService
  ) { }

  public getClaim(id: string): Observable<IClaim> {
    return this.http.get<IClaim>(`${environment.baseUrl.api}${ApiEndpoints.getClaim}?id=${id}`);
  }

  clearFilters() {
    this.subject.next(true);
  }

  setStep() {
    let data = {
      a: event,
      b: 1,
    }
    this.setCurrentStep.next(data);
  }

  getStepValue(): Observable<any> {
    return this.setCurrentStep.asObservable();
  }
  clearStorage() {
    sessionStorage.removeItem('isSearchMade');
    sessionStorage.removeItem('amountSearch');
    sessionStorage.removeItem('claimStatusSearch');
    sessionStorage.removeItem('dateSearch');
    sessionStorage.removeItem('isdefault');
    sessionStorage.removeItem('retainState');
    sessionStorage.removeItem('searchFilters');
    sessionStorage.removeItem('isPostBack');
    sessionStorage.removeItem('defaultType');
    sessionStorage.removeItem('receiverInfo');
    sessionStorage.removeItem('accountInfo');
  }
  getResetValue(): Observable<any> {
    return this.subject.asObservable();
  }
  public saveClaim(claim: IClaim): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.saveClaim}`, claim, this.httpOptions);
  }

  public createClaim(claim: IClaim): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.createClaim}`, claim, this.httpOptions);
  }

  public submitClaim(claim: IClaim): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.submitClaim}`, claim, this.httpOptions);
  }

  public withdrawClaim(withdrawnClaim): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.withdrawClaim}`, { "policyNumber": withdrawnClaim.userPolicyNumber, "claimNumber": withdrawnClaim.userClaimNumber }, this.httpOptions);
  }

  public reopenClaim(claim: ReopenClaim): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.reopenClaim}`, claim, this.httpOptions);
  }

  public deleteDraft(draftDelete: DeleteDraft): Observable<any> {
    const httpDelOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: draftDelete
    };

    return this.http.delete<any>(`${environment.baseUrl.api}${ApiEndpoints.deleteDraft}`, httpDelOptions);
  }

  public getMetaData(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl.api}${ApiEndpoints.getMetaData}`, this.httpOptions);
  }

  public uploadDocument(file: FormData): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.uploadDocument}`, file);
  }

  public generateUploadToken(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl.api}${ApiEndpoints.generateUploadToken}`, this.httpOptions);
  }

  public formatAmount(event, backspace: boolean) {
    var plainNumber = event.replace(/[^-+0-9]/g, '');
    plainNumber = this.number.transform(plainNumber / 100, '1.2-2').replace(/,/g, '');
    return plainNumber;
  }

  public removeDocument(file): Observable<any> {
    //Include the body in options for delete requests
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        publicID: file.publicID
      }
    };
    return this.http.delete<any>(`${environment.baseUrl.api}${ApiEndpoints.removeDocument}`, httpOptions);
  }

  public getShipmentDetails(id: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl.api}${ApiEndpoints.getShipmentDetails}${id}`, this.httpOptions);
  }

 public getUserLayout(layout: GetUserLayout): Observable<any> {
  return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.getUserLayouts}`, layout, this.httpOptions);
  }

  public removeUserLayout(layout: removeUserLayout): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.removeUserLayouts}`, layout, this.httpOptions);
  }

  public getUserLayoutDetails(layout: GetUserLayoutDetails): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.getUserLayoutcolumns}`, layout, this.httpOptions);
  }

  public submitUserLayoutDetails(layout: ILayoutDetails): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.submitUserLayoutcolumns}`, layout, this.httpOptions);
  }

  public getRedirect(details: RedirectDetails): Observable<any> {
    if(this.userService.isDDClaimsFlow()) {
      details = {...details, tupssFlow: 'DD', scenario: 'tupss'};
    }
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.getRedirect}`, details, this.httpOptions);
  }

  public getPayeeDetails(req): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.getPayeeDetails}`, req, this.httpOptions);
  }

  public checkDuplicateClaims(id: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl.api}${ApiEndpoints.CHECK_CLAIMS}${id}`, this.httpOptions);
  }

  public getAllClaimIds(summery: ISummary): Observable<ClaimIdsModel> {
    let shopifyUID = this.userService.getshopifyUserObjectUID();
    if (shopifyUID !== null) {
      summery.objectUID = shopifyUID;
    }
    return this.http.post<ClaimIdsModel>(`${environment.baseUrl.api}${ApiEndpoints.getAllClaimIds}`, summery, this.httpOptions);
  }

  public getAllMessageClaimIDs(summary: ISummary): Observable<MessageClaimIdsModel> {
    let shopifyUID = this.userService.getshopifyUserObjectUID();
    if (shopifyUID !== null) {
      summary.objectUID = shopifyUID;
    }
    return this.http.post<MessageClaimIdsModel>(`${environment.baseUrl.api}${ApiEndpoints.getAllMessageClaimIds}`, summary, this.httpOptions);
  }

  public getAllMessageIDs(summary: IMsgSummary): Observable<MessageIdsModel> {
    return this.http.post<MessageIdsModel>(`${environment.baseUrl.api}${ApiEndpoints.getAllMessageIds}`, summary, this.httpOptions);
  }

  public getClaimByIds(policyNumber: string, claimIds: string[], isParentPolicy?: boolean): Observable<ClaimListModel> {
    const endpoint = `${environment.baseUrl.api}${ApiEndpoints.getClaimSummary}`;
    let shopifyUID = this.userService.getshopifyUserObjectUID();
    if (shopifyUID !== null) {
      return this.http.post<ClaimListModel>(endpoint, { policyNumber: policyNumber, claimNumbers: claimIds, objectUID: shopifyUID }, this.httpOptions);
    }
    else {
      return this.http.post<ClaimListModel>(endpoint, { policyNumber: policyNumber, claimNumbers: claimIds, isParentPolicy: isParentPolicy }, this.httpOptions);
    }

  }

  public getMessageSummaryByIds(policyNumber: string, claimIds: string[], isParentPolicy?: boolean): Observable<MessageListModel> {
    const endpoint = `${environment.baseUrl.api}${ApiEndpoints.getMessageSummary}`;
    let shopifyUID = this.userService.getshopifyUserObjectUID();
    if (shopifyUID !== null) {
      return this.http.post<MessageListModel>(endpoint, { policyNumber: policyNumber, claimNumbers: claimIds, objectUID: shopifyUID }, this.httpOptions);
    }
    else {
      return this.http.post<MessageListModel>(endpoint, { policyNumber: policyNumber, claimNumbers: claimIds, isParentPolicy: isParentPolicy }, this.httpOptions);
    }
  }

  public getMsgDetailsByIds(policyNumber: string, message: string, claimNumber: string, messageIds: string[]): Observable<MessageListModel> {
    return this.http.post<MessageListModel>(`${environment.baseUrl.api}${ApiEndpoints.getMessageDetails}`, {
      policyNumber: policyNumber, message: message, claimNumber: claimNumber,
      messageIds: messageIds
    }, this.httpOptions);

  }

  public getShipmentDetailsAutofill(id: string, subpath?: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl.api}${ApiEndpoints.getShipmentDetailsAutofill}${id}${subpath ? `/${subpath}` : ''}`);
  }

  public getClaimDetails(claimNumber: string, policyNumber: string): Observable<any> {
    const endpoint = `${environment.baseUrl.api}${ApiEndpoints.getClaimsDetails}`;
    let shopifyUID = this.userService.getshopifyUserObjectUID();
    if (shopifyUID !== null) {
      return this.http.get<any>(endpoint + "/claimNum/" + claimNumber + "/policyNum/" + policyNumber + "?" + "objectUID=" + shopifyUID, this.httpOptions);
    }
    else {
      return this.http.get<any>(endpoint + "/claimNum/" + claimNumber + "/policyNum/" + policyNumber, this.httpOptions);
    }
  }

  public getClaims(claimNumber: string,) {
    return this.http.get<any>(`${environment.baseUrl.api}${ApiEndpoints.getClaims}${claimNumber}`, this.httpOptions);
  }

  downloadDocument(id: string, policyNumber: string): Observable<any> {
    let options = { 'responseType': 'arraybuffer' as 'json' }
    let shopifyUID = this.userService.getshopifyUserObjectUID();
    if (shopifyUID !== null) {
      return this.http.get<any>(`${environment.baseUrl.api}${ApiEndpoints.downloadDocument}/` + id + "/policyNum/" + policyNumber + "?" + "objectUID=" + shopifyUID, options);
    }
    else {
      return this.http.get<any>(`${environment.baseUrl.api}${ApiEndpoints.downloadDocument}/` + id + "/policyNum/" + policyNumber, options);
    }
  }

  addressValidation(body) {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.ADDRESS_VALIDATION}`, body, this.httpOptions);
  }

  setAddValData(data) {
    this.addressValidationData.next(data);
  }

  getAddValData() {
    return this.addressValidationData;
  }

  setTupssAddress(data) {
    this.tupssEnteredAddress.next(data);
  }

  getTupssAddress() {
    return this.tupssEnteredAddress;
  }

  setLoading(bool) {
    this.loading.next(bool);
  }

  getLoading() {
    return this.loading;
  }

  getiShipData(payload) {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.ISHIP}`, payload, this.httpOptions).pipe(
      map((res) => {
        let zipCode = res.data?.response?.cdsresponse?.shipmentInfo?.fromAddress?.postal;
        if (zipCode) {
          if (zipCode.length > 5) {
            zipCode = `${zipCode.substring(0, 5)}-${zipCode.substring(5)}`;
          }
          res.data.response.cdsresponse.shipmentInfo.fromAddress.postal = zipCode;
        }
        let toAddressZip = res.data?.response?.cdsresponse?.shipmentInfo?.toAddress?.postal;
        if (toAddressZip) {
          if (toAddressZip.length > 5) {
            toAddressZip = `${toAddressZip.substring(0, 5)}-${toAddressZip.substring(5)}`;
          }
          res.data.response.cdsresponse.shipmentInfo.toAddress.postal = toAddressZip;
        }
        return res;
      })
    );
  }

  getClaimStats(policyNumber: string, groupCode: string) {
    //Building the request here
    //Default / child:
    let request;

    if (groupCode.length > 0) {
      request = {
        policyNumber: policyNumber,
        groupCode: groupCode
      }
    } else {
      request = {
        policyNumber: policyNumber
      }
    }   

    //Parent:
    if (this.userService.isParentPolicy()) {
      request = { ...request, childParentPolicies: JSON.parse(sessionStorage.getItem('childPolicies'))}
    }

    //Shipper:
    if (this.userService.getUserInfo().groupID) {
      request = { ...request, groupID: this.userService.getUserInfo().groupID }
    }
    
    return this.http.post<ClaimStats>(`${environment.baseUrl.api}${ApiEndpoints.CLAIM_STATS}`, request);
  }

  setLocaliShipData(data) {
    this.iShipData.next(data);
  }

  getLocaliShipData() {
    return this.iShipData;
  }

  setTupssClaimData(data) {
    //Set the pre vs post to POST
    sessionStorage.setItem('userDetails', JSON.stringify({ ...JSON.parse(sessionStorage.getItem('userDetails')), stage: 'post' }));
    this.tupssClaimData.next(data);
  }

  getTupssClaimData() {
    return this.tupssClaimData;
  }

  postMessage(request) {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.POST_MESSAGE}`, request, this.httpOptions);
  }

  setDeliveryStatus(request) {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.DELIVERY_STATS}`, request, this.httpOptions);
  }

  sendMyChoiceClaimSubmissionEmail(request) {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.MYCHOICE_CLAIM_SUBMISSION_EMAIL}`, request, this.httpOptions);
  }
  sendMyChoicePaymentReceiptEmail(request) {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.MYCHOICE_PAYMENT_RECEIPT_EMAIL}`, request, this.httpOptions);
  }

  myChoiceZipValidate(request) {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.MYCHOICE_ZIP_VALIDATION}`, request, this.httpOptions);
  }

  sendCCPClaimSubmissionEmail(request) {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.CCP_CLAIM_SUBMISSION_EMAIL}`, request, this.httpOptions);
  }

  sendClaimSubmissionEmail(request) {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.CCP_CLAIM_SUBMISSION_EMAIL}`, request, this.httpOptions);
  }

  sendCCPVerificationCodeEmail(request) {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.CCP_VERIFICATION_CODE_EMAIL}`, request, this.httpOptions);
  }

  checkCCPPolicy(request) {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.CHECK_CCP_POLICY}`, request, this.httpOptions);
  }
}
