import { Component } from '@angular/core';
import { DDOnBoardingService } from 'src/app/services/dd-onboarding.service';
import { UserService } from 'src/app/services/user.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { VisibilityPopupComponent } from '../dialog/visibility-popup/visibility-popup.component';

@Component({
  selector: 'upsc-change-plan',
  templateUrl: './change-plan.component.html',
  styleUrls: ['./change-plan.component.scss']
})
export class ChangePlanComponent {


  public isShowFeatures: boolean = false;
  public closeShowFeatures: boolean = false;
  public currentPlan: string = '';
  userId: string = '';
  public pageLoading: boolean = false;
  public systemError: boolean = false;
  userPolicy: string = '';
  users: any = [];
  contaxId: string = 'silcustomer';
  public contaxOnBoarding: any = {};
  policy: any = {};
  visibilitySettings: any = {};
  constructor(private userService: UserService,
    private ddOnBoardingService: DDOnBoardingService,
    public dialog: MatDialog,
  ) {

  }

  ngOnInit() {
    let details = JSON.parse(sessionStorage.getItem('existingPlanDetails'));
    this.currentPlan = details?.subscription;
    this.userId = JSON.parse(sessionStorage.getItem('userDetails'))?.userId;
    this.userPolicy = this.userService.getUserInfo()?.policyNumber;
    this.policy = JSON.parse(sessionStorage.getItem('policyDetails'));
    this.contaxOnBoarding.name = this.policy.insured;
    this.getUserInfo();
  }

  getUserInfo() {
    this.pageLoading = true;
    this.systemError = false;
    let payload = {
      policyNumber: this.userPolicy,
      userIDs: [this.userId]
    }
    this.users = [];
    let user: any = {};
    this.userService.getUserInfoListForPolicy(payload).subscribe(
      data => {
        if (data !== null && data && data.data && data.data.users.length > 0) {
          data.data.users.forEach(element => {
            user.email = element.emailAddress;
            try {
              var name = element.contactName.split(" ");
              if (name) {
                user.firstName = name[0];
                user.lastName = name[1];
              }
            } catch {
              user.firstName = element.contactName;
            }
            this.users.push(user);
          });
          this.contaxOnBoarding.users = this.users;
          this.contaxOnBoarding.id = this.contaxId;
          this.pageLoading = false;
        } else {
          //check for not allowed
          this.pageLoading = false;
          this.systemError = true;
          this.contaxOnBoarding.id = this.contaxId;
          this.contaxOnBoarding.users = [];
        }
      },
      error => {
        this.pageLoading = false;
        this.systemError = true;
      }
    );
  }


  showFeatures() {
    if (this.isShowFeatures) {
      this.isShowFeatures = false;
    } else {
      this.isShowFeatures = true;
    }
  }

  closeFeatures() {
    if (this.isShowFeatures) {
      this.closeShowFeatures = true;
    } else {
      this.closeShowFeatures = false;
    }
  }
  public changeCancelPlan(subscriptionPlan: string) {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.width = '520px';
    this.visibilitySettings.action = "changePlan";
    this.visibilitySettings.subscription = subscriptionPlan;
    sessionStorage.setItem('visibilitySettings', JSON.stringify(this.visibilitySettings));
    let dialogRef = this.dialog.open(VisibilityPopupComponent, config);
    dialogRef.afterClosed().subscribe(
      closed => {
        // localStorage.removeItem('selectedRecentInvoices');
        // localStorage.removeItem('selectedpaymenthistory');
      }
    );
  }

  showUp() {
    window.scroll(0, 0);
  }
}
