<!DOCTYPE html>
<html lang="en">

<head>
	<meta charset="utf-8">
	<base href="/">
	<meta name="viewport" content="width=device-width, initial-scale=1">
	<meta name="DCSext.pCC" content="US" />
	<meta name="DCSext.pLL" content="en" />
	<meta name="DCSext.pPID" content="" />
	<link rel="shortcut icon" type="image/x-icon" href="https://online-vulture.upscapital.com/favicon.ico">
	<!-- Latest compiled and minified CSS -->
	<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css">
	<link href="https://ajax.aspnetcdn.com/ajax/bootstrap/3.3.5/css/bootstrap.min.css" rel="stylesheet" type="text/css"/>
	<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css">
  <script async src="https://tags.tiqcdn.com/utag/ups/ups-capital/qa/utag.js"></script>
  <title>UPS Capital Online Account Login</title>
	<style>
		.no-padding {
			padding: 0;
		}

		img#upsc-logo {
			width: 150px;
			height: auto;
			@media screen and (min-width: 576px) {
				width: 200px;
			}
		}

		.footer {
			width: 100%;
			background-color: #4A4A4C;
			padding-top: 20px;
			padding-bottom: 20px;
		}

		#divFooter {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			font-family: Tahoma, 'Times New Roman', Times, serif;
			font-size: 14px;
			background-color: #4A4A4C;
			color: #D8D7DF;
		}

		.footer-wrap-left {
			text-align: center;
			width: 100%;
			color: #D8D7DF;
		}

		.footer-align {
			display: flex;
			flex-wrap: wrap !important;
			width: 80%;
			max-width: 1448px;
			margin-left: auto;
			margin-right: auto;
			font-size: 14px;
		}

		.break {
			clear: both;
			display: block;
			border-top: 1px solid #D8D7DF;
			padding-top: 20px;
			width: 100%;
			max-width: 1448px;
			margin-left: auto;
			margin-right: auto;
			padding-left: 0px;
		}

    .footer-link {
      display: flex;
      justify-content: center;
    }

		.footer-link a {
			color: #D8D7DF;
			text-decoration: none;
			display: flex;
			align-items: left;
			cursor: pointer;
			font-family: Tahoma, "Times New Roman", Times, serif;
		}

		.vertical-border {
			border-left: 2px solid;
			height: 18px;
			display: flex;
			align-items: center;
			margin: 3px 8px 0px;
			opacity: 0.7;
		}

		.copyright-left {
			display: flex;
			line-height: 1;
			margin-top: 1rem;
			font-family: Tahoma, "Times New Roman", Times, serif;
			color: #D8D7DF;
			/* padding: 0px 5px 0px 5px; */
			width: 80%;
			max-width: 1448px;
			margin-left: auto;
			margin-right: auto;
			font-size: 14px;
			display: flex;
		}

		.header {
			padding-bottom: 20px;
			padding-top: 20px;
			width: 100%;
			border-bottom: solid 1px #e8e8e6;
			padding: 30px 40px 20px 0px;
			box-shadow: -2px 5px 5px -2px #e8e8e6;
		}

		.navigation-wrap {
			display: flex;
			flex-wrap: wrap !important;
			width: 90%;
			max-width: 1448px;
			margin-left: auto;
			margin-right: auto;
		}

		.navigation-wrap .nav-welcome-desktop {
			margin-left: 15px;
			margin-right: 0px;
			background: transparent;
			padding-top: 10px;
			display: flex;
			position: absolute;
			top: 30px;
			right: 150px;
			display: flex;
			position: absolute;
			top: 30px;
			right: 80px;
		}

		.help {
			/* padding-left: 10px; */
			display: flex;
			align-items: center;
			font-family: "Roboto-Bold";
			display: flex;
			margin: 1rem 0 0.5rem;
		}

		.forgot {
			color: #0662BB;
			font-size: 18px;
			font-weight: 550;
			cursor: pointer;
		}

		.register {
			font-weight: normal;
			font-family: "Roboto", sans-serif;
			font-size: 18px;
		}

		.create-account {
			color: #0662BB;
			text-decoration: underline;
			margin-left: 0.5rem;
		}

		.open-icon {
			font-family: "Material Icons";
			font-weight: normal;
			font-style: normal;
			font-size: 24px;
			line-height: 1;
			text-transform: none;
			letter-spacing: normal;
			word-wrap: normal;
			white-space: nowrap;
			direction: ltr;
			text-rendering: optimizeLegibility;
			-moz-osx-font-smoothing: grayscale;
			font-feature-settings: "liga";
			overflow: unset !important;
			user-select: none;
			background-repeat: no-repeat;
			display: inline-block;
			fill: currentColor;
			width: 24px;
			height: 25px;
			color: #D8D7DF;
			margin-top: -5px;
			margin-left: 1px;
			transform: scale(0.7);
		}

		i.bi.bi-box-arrow-up-right {
			padding-left: 5px;
			line-height: 1.5;
			font-size: 12px;
			font-weight: 600;
		}

    i.bi.bi-chevron-right {
      font-size: small;
      font-weight: 900;
    }

		.bi::before {
			font-weight: 600 !important;
		}

    .messageBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid steelblue;
      border-radius: 20px;
      padding: 10px;
    }

    .messageBox .error {
      width: 80%;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }
    .messageBox .error .message {
      width: 80%;
      text-align: center;
      font-size: 14px;
    }


    @media screen and (max-width: 768px) {
      .help {
          font-size: 14px;
      }
      .register {
          font-size: 16px;
      }
      .footer-align {
          flex-direction: column;
      }
      .break {
        display: none;
      }
      .vertical-border {
        display: none;
      }
      .footer-link {
        justify-content: start;
        padding: 5px 0;
        border-bottom: solid thin #c4c4c4;
      }
    }

    @media screen and (min-width: 768px) {
      .messageBox {
        padding: 30px 20px;
      }
      .messageBox .error {
        font-size: 20px;
      }
      .messageBox .error .message {
          font-size: 16px;
      }
    }

    .panel {
      border: 0;
    }
	</style>
	<style>
		.unified_container .row .panel-default {
                padding: 40px 30px;
                margin-top: auto;
                margin-bottom: auto;
                height: 100%;
                background-color: #fff
            }

            .unified_container .row .panel-default .panel-body {
                max-width: 380px;
                width: auto;
				margin: 1.5rem auto;
                padding: 0
            }

            .unified_container .row .panel-default .panel-body .companyName {
                font-size: 1.5em;
                margin-bottom: 45px
            }

            .unified_container .row .panel-default .panel-body .companyLogo {
                display: block
            }

            .unified_container .row .panel-default .panel-body img {
                max-width: 100%;
                width: auto;
                height: 70px;
                margin: 0 auto;
                display: block
            }

            .unified_container .row .panel-default .panel-body h3 {
                font-size: 1.5em;
                display: none
            }

            .unified_container .row .panel-default #api {
                display: table;
                margin: 0;
                width: 100%
            }

            .unified_container .row .panel-default #api .intro {
                display: block;
                text-align: left
            }

            .unified_container .row .panel-default #api .intro h2 {
                font-size: 20px
            }

            .unified_container .row .panel-default #api .divider {
                display: none
            }

            .unified_container .row .panel-default #api .localAccount {
                top: 10px;
                width: 100%
            }

            .unified_container .row .panel-default #api .localAccount .intro h2 {
                font-size: 20px
            }

            .unified_container .row .panel-default #api .localAccount .error {
                color: #b00;
                text-align: left
            }

            .unified_container .row .panel-default #api .localAccount .entry .buttons {
                margin: 0 0 23px 0;
                text-align: left
            }

            .unified_container .row .panel-default #api .localAccount .entry .buttons button {
                background-image: none;
                /* background-color: #296ec6; */
                background-color: #00857d;
                border: none;
                border-radius: .5rem;
                cursor: pointer;
                display: inline-block;
                font-size: 1em;
                font-weight: 400;
                height: inherit;
                line-height: 1.3333333;
                margin-top: 2rem;
                margin-right: 0;
                margin-bottom: 1.2rem;
                margin-left: 0;
                padding: 10px 16px;
                text-align: center;
                touch-action: manipulation;
                user-select: none;
                vertical-align: middle;
                white-space: nowrap;
                width: inherit;
                -moz-user-select: none;
                -ms-touch-action: manipulation;
                -ms-user-select: none;
                -webkit-user-select: none;
                color: #fff;
                width: 63%;
                box-shadow: 0 0 30px 0 #dae1f7
            }

            @media screen and (-ms-high-contrast:active) {
                .unified_container .row .panel-default #api .accountButton,.unified_container .row .panel-default #api .localAccount .entry .buttons button {
                    border: 1px dashed #fff
                }

                .unified_container .row .panel-default #api .accountButton:hover,.unified_container .row .panel-default #api .localAccount .entry .buttons button:hover {
                    border: 1px solid #fff
                }
            }

            .unified_container .row .panel-default #api .localAccount .entry .buttons button:focus,.unified_container .row .panel-default #api .localAccount .entry .buttons button:hover {
                -moz-box-shadow: none;
                -webkit-box-shadow: none;
                box-shadow: none;
                background-color: #1662c7;
                color: #fff
            }

            .unified_container .row .panel-default #api .localAccount .entry .entry-item {
                position: relative;
                width: 100%;
                padding-top: 6px;
                padding-bottom: 6px;
                display: block
            }

            .unified_container .row .panel-default #api .localAccount .entry .entry-item label {
                display: none
            }

            .unified_container .row .panel-default #api .localAccount .entry .entry-item input:focus {
                border: 2px solid #000
            }

            .unified_container .row .panel-default #api .localAccount .entry .entry-item input {
                outline: 0;
                background-color: #fff!important;
                background-image: none!important;
                border-radius: .5rem!important;
                color: #555;
                display: inline-block;
                font-size: 1em;
                height: 50px;
                line-height: 1.42857143;
                padding: 6px 20px;
                transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s!important;
                width: 100%!important;
                -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s!important;
                -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s!important
            }

            .unified_container .row .panel-default #api .localAccount .entry .entry-item #password {
                margin-bottom: 20px
            }

            .unified_container .row .panel-default #api .localAccount .entry .entry-item .password-label {
                text-align: left;
                height: 0;
                left: 0;
                width: 100%
            }

            .forgot-password {
                text-align: left
            }

            .forgot-password.center-height {
                position: absolute;
                bottom: 0;
                clear: right;
                cursor: pointer;
                text-align: right
            }

            @keyframes slideInFromRight {
                0% {
                    opacity: 0;
                    transform: translateX(50%)
                }

                100% {
                    opacity: 1;
                    transform: translateX(0)
                }
            }

            .unified_container .row .panel-default #api .localAccount .entry .entry-item .password-label #forgotPassword {
                position: absolute;
                bottom: 0;
                clear: right;
                cursor: pointer;
                text-align: right
            }

            .unified_container .row .panel-default #api .localAccount .rememberMe {
                margin-top: -2rem;
                text-align: right;
                margin-left: 59%;
                position: relative;
                width: 15rem
            }

            .unified_container .row .panel-default #api .localAccount .rememberMe.align-to-left {
                margin-top: .5rem;
                text-align: left;
                margin-left: 0;
                width: 100%
            }

            .working {
                display: none
            }

            .unified_container .row .panel-default #api .claims-provider-list-text-links p,.unified_container .row .panel-default #api .localAccount .create p {
                text-align: left;
                margin-top: -3rem;
                margin-bottom: 3rem
            }

            .unified_container .row .panel-default #api .localAccount .create #createAccount {
                border-radius: .5rem;
                display: inline-block;
                font-size: 1em;
                font-weight: 400;
                height: inherit;
                line-height: 1.3333333;
                padding: 10px 16px;
                text-align: center;
                touch-action: manipulation;
                user-select: none;
                white-space: nowrap;
                -moz-user-select: none;
                -ms-touch-action: manipulation;
                -ms-user-select: none;
                -webkit-user-select: none;
                width: auto;
                margin-top: -3rem;
                cursor: pointer;
                animation: .5s ease-out 0s 1 slideInFromRight
            }

            .unified_container .row .panel-default #api .social {
                display: table-footer-group;
                position: relative;
                top: 1px;
                width: auto
            }

            .unified_container .row .panel-default #api .accountButton {
                background-size: 10% 70%;
                background-position-x: 3%;
                background-position-y: 50%;
                background-repeat: no-repeat;
                display: block;
                font-size: 16px;
                height: 45px;
                width: 100%;
                margin-left: auto;
                overflow: hidden;
                padding: 0 50px;
                max-width: 380px;
                border: 1px solid #605e5c;
                background-color: #fff;
                -moz-border-radius: .5rem;
                -webkit-border-radius: .5rem;
                border-radius: .5rem;
                margin-top: 10px
            }

            .unified_container .row .panel-default #api .accountButton:hover {
                color: #fff;
                background-color: #1662c7
            }

            .claims-provider-list-text-links-bottom-under-form {
                text-align: left
            }

            @media (max-width: 100%) {
                .unified_container .row .panel-default {
                    margin-top:auto;
                    margin-bottom: auto;
                    height: 550px
                }

                .unified_container .row .panel-default .panel-body {
                    padding: 5rem 3rem;
                    margin-top: 20px
                }

                .unified_container .row .panel-default .panel-body .companyName {
                    display: none
                }

                .unified_container .row .panel-default .panel-body .companyLogo {
                    display: block
                }

                .unified_container .row .panel-default .panel-body h3 {
                    display: block
                }

                .unified_container .row .panel-default .panel-body #api {
                    width: auto
                }

                .unified_container .row .panel-default .panel-body #api .social {
                    top: 370px;
                    width: auto
                }

                .unified_container .row .panel-default .panel-body #api .social .options .accountButton {
                    height: 40px
                }

                .unified_container .row .panel-default .panel-body #api .social .options #FacebookExchange {
                    background-size: 12px;
                    background-position: 50% 75%;
                    margin-bottom: 10px
                }

                .unified_container .row .panel-default .panel-body #api .social .options #GoogleExchange {
                    background-size: 20px;
                    background-position: 50% 75%;
                    margin-bottom: 10px
                }

                .unified_container .row .panel-default .panel-body #api .social .options #TwitterExchange {
                    background-size: 20px;
                    background-position-x: 56px;
                    background-position-y: 14px;
                    margin-bottom: 10px
                }

                .unified_container .row .panel-default .panel-body #api .localAccount {
                    top: 20px
                }

                .unified_container .row .panel-default .panel-body #api .localAccount .entry .entry-item {
                    display: block;
                    width: 100%;
                    margin-bottom: 10px
                }

                .unified_container .row .panel-default .panel-body #api .localAccount .entry .entry-item .password-label {
                    top: 145px
                }

                .unified_container .row .panel-default .panel-body #api .localAccount .entry .entry-item #logonIdentifier {
                    height: 40px
                }

                .unified_container .row .panel-default .panel-body #api .localAccount .entry .entry-item #password {
                    height: 40px
                }

                .unified_container .row .panel-default .panel-body #api .localAccount .entry .entry-item:nth-child(2) {
                    width: auto;
                    float: left
                }

                .unified_container .row .panel-default .panel-body #api .localAccount .entry .buttons #next {
                    width: auto;
                    text-indent: -4px;
                    height: 40px;
                    line-height: 0;
                    position: relative;
                    bottom: 5px;
                    left: 8px
                }
            }

            @media screen and (max-width: 320px) {
                .unified_container .row .panel-default #api .localAccount .rememberMe {
                    margin-top:1.75rem;
                    text-align: left;
                    margin-left: 0;
                    width: 100%
                }

                .unified_container .row .panel-default #api .localAccount .entry .entry-item .password-label #forgotPassword {
                    bottom: -1rem
                }

                .forgot-password.center-height {
                    top: 0;
                    right: 12rem;
                    position: relative
                }
            }
	</style>
</head>

<body>
	<div class="container-fluid unified_container" role="presentation">
		<div class="row">
			<div class="col-md-12 no-padding">
				<div class="header">
					<div class="navigation-wrap">
						<a href="https://upscapital.com/" target="_blank" rel="noopener noreferrer" class="ng-star-inserted">
              <img alt="UPS Capital" id="upsc-logo" src="https://online.upscapital.com/assets/images/logos/ca_us/InsureShield_Logo_226x79_larger_tagline-01.png">
						</a>
					</div>
				</div>
        <!-- block 1: comment out for b2c template; uncomment for component template -->
				<div class="panel panel-default" style="min-height: 42vh;">
          <div class="d-flex justify-content-center align-items-center h-100">
            <div *ngIf="!errorOccurred"  class="w-75 align-items-center">
              <mat-progress-bar
                    [mode]="'indeterminate'">
              </mat-progress-bar>
            </div>
            <div *ngIf="errorOccurred" class="messageBox d-flex flex-column align-item-center">
              <p class="error">{{'autoAuth.error' | translate}}</p>
              <p class="message">
                <span>{{'autoAuth.tryAgain1' | translate}}</span>
                <a *ngIf="workflow!='DD'" href="mailto:InsureShieldsupport@ups.com?subject={{ 'autoAuth.subject' | translate }}" class=""><u> {{'autoAuth.email' | translate}} </u></a>
                <a *ngIf="workflow=='DD'" href="mailto:deliverydefensecustomerservice@ups.com?subject=DeliveryDefense Authentication" class=""><u> {{'autoAuth.email' | translate}} </u></a>
                <span>{{'autoAuth.tryAgain2' | translate}}</span></p>
              <div class="message">{{'autoAuth.hours' | translate}}</div>
              <div class="message">{{'autoAuth.days' | translate}}</div>
              <div class="message">{{'support.time' | translate}}</div>
            </div>
          </div>
        </div>
        <!-- end of block 1 -->
        <!-- block 2: uncomment for b2c template; comment out for component template -->
        <!-- <div class="panel panel-default" style="max-height: 71vh;">
					<div class="panel-body">
						<div id="api" role="main"></div>
					</div>
					<div class="panel-body">
						<div class="help"><a class="forgot" href="https://online-vulture.upscapital.com/login-help">Forgot
								Username/Password&nbsp;<i class="bi bi-chevron-right"></i></a>
						</div>
						<div class="register"> Not yet registered? <a class="create-account"
								href="https://online-vulture.upscapital.com/create-account">Create Account</a>
						</div>
					</div>
				</div> -->
        <!-- end of block 2 -->
				<div class="footer">
					<div class="d-flex flex-column-reverse mb-3 mb-lg-4">
						<div id="divFooter">
							<div class="footer-wrap-left mt-3">
								<div class="footer-align">
									<div class="break"></div>
									<div class="footer-link"><a
											href="http://www.ups.com/content/us/en/resources/ship/terms/use.html" target="_blank"
											rel="noopener noreferrer"> Website Terms of Use<i class="bi bi-box-arrow-up-right"></i></a>
                      <span class="vertical-border"></span>
									</div>
									<div class="footer-link"><a
											href="https://www.insureshield.com/us/en/legal/technology-agreement.html" target="_blank">
											Technology Agreement<i class="bi bi-box-arrow-up-right"></i></a>
                      <span class="vertical-border"></span>
									</div>
									<div class="footer-link"><a
											href=" https://www.insureshield.com/us/en/legal/product-disclosure.html" target="_blank"> Product	Disclosures<i class="bi bi-box-arrow-up-right"></i></a>
                      <span class="vertical-border"></span>
									</div>
									<div class="footer-link"><a
											href="http://www.ups.com/content/us/en/resources/ship/terms/california_privacy.html"
											target="_blank" rel="noopener noreferrer"> Your California Privacy Rights<i class="bi bi-box-arrow-up-right"></i></a>
                      <span class="vertical-border"></span>
									</div>
									<div class="footer-link"><a
											href="https://www.insureshield.com/us/en/legal/privacy-notice.html" target="_blank"
											rel="noopener noreferrer"> Privacy Notice<i class="bi bi-box-arrow-up-right"></i></a>
                      <span class="vertical-border"></span>
									</div>
                  <!-- block 3: comment out for b2c template; uncomment for component template -->
									<div class="footer-link"><a
											href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
											Cookie Settings </a>
                      <span class="vertical-border"></span>
                  </div>
                  <!-- end of block 3 -->
									<div class="footer-link"><a href="https://upscapital.com/opt-out/" target="_blank"
											rel="noopener noreferrer"> Do Not Sell or Share My
											Personal Information<i class="bi bi-box-arrow-up-right"></i></a>
									</div>
								</div>

								<div class="copyright-left">Copyright © 2022 - 2024 United Parcel Service of America, Inc. All rights
									reserved. </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</body>
</html>
