<div class="header-container">
    <div class="header-wrapper">
        <div class="ddvtitle">
            {{'visibility.changePlan' | translate}}
        </div>
        <div class="green-bar">
            <div class="login-bar"></div>
        </div>
        <div class="title-sub">
            {{'visibility.planDescription' | translate}}
        </div>
    </div>
</div>

<div class="col-md-12 loading-container" *ngIf="pageLoading">
    <div class="loading">
        <div class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
    </div>
</div>

<!-- <!the below table taken from insights comp--  -->

<div class="plan-container" *ngIf="!pageLoading">
    <div class="plan-wrapper">
        <div class="table-header-container">
            <!-- <table class="tbl-header"> -->
                    <div>
                        <div class="basic">
                            <span class="slanting-basic">
                                <span class="header">Basic</span>
                                <span class="header-description">For our InsureShield Connect customers looking for a
                                    value add.</span>
                            </span>
                            <span class="slanting-basic-image">
                                <img src="../../../../assets/images/basic.svg" class="imgFull">
                            </span>

                            <span class="curved-frame">
                                <span class="dollar">$0/mo</span>
                                <span class="dollar-byline">Upgrade to any plan at any time.</span>

                                <span class="dd-button-container">
                                    <span class="plan-current" *ngIf="currentPlan==='basic'">Current Plan</span>
                                    <button class="dd-upsc-button1" *ngIf="currentPlan!=='basic'"
                                        (click)="changeCancelPlan('basic')">
                                        <div class="btn-text-icon">
                                            <div class="text">Select Basic</div><mat-icon>chevron_right</mat-icon>
                                        </div>
                                    </button>
                                </span>

                                <span class="check-circle-list">
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">USPS, DHL, and FedEx Tracking Data</span>
                                    </span>
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">2 Users</span>
                                    </span>
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">4 Dashboards</span>
                                    </span>
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">20K Data Export Limit</span>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div>
                        <div class="starter">
                            <span class="slanting-starter">
                                <span class="header">Starter</span>
                                <span class="header-description">For small businesses looking to get a leg up on their
                                    tracking
                                    needs.</span>
                            </span>
                            <span class="slanting-starter-image">
                                <img src="../../../../assets/images/starter.svg" class="imgFull">
                            </span>
                            <span class="curved-frame">
                                <span class="dollar">$249/mo</span>
                                <span class="dollar-byline">For up to 5,000 shipments per month.</span>

                                <span class="dd-button-container">
                                    <span class="plan-current" *ngIf="currentPlan==='starter'">Current Plan</span>
                                    <button class="dd-upsc-button2" *ngIf="currentPlan!=='starter'"
                                        (click)="changeCancelPlan('starter')">
                                        <div class="btn-text-icon">
                                            <div class="text">Select Starter</div><mat-icon>chevron_right</mat-icon>
                                        </div>
                                    </button>
                                </span>

                                <span class="check-circle-list">
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">Small Package Carriers Tracking Data</span>
                                    </span>
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">5 Users</span>
                                    </span>
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">9 Dashboards</span>
                                    </span>
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">100K Data Export Limit</span>
                                    </span>
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">3 Years of Retention Proof of Delivery</span>
                                    </span>
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">Email Export of Scheduled Data</span>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div>
                        <div class="pro">
                            <span class="slanting-pro">
                                <span class="header">Pro</span>
                                <span class="header-description">For medium businesses looking to scale through tracking
                                    insights.</span>
                            </span>
                            <span class="slanting-pro-image">
                                <img src="../../../../assets/images/pro.svg" class="imgFull">
                            </span>
                            <span class="curved-frame">
                                <span class="dollar">$399/mo</span>
                                <span class="dollar-byline">For over 5,000 shipments per month.</span>

                                <span class="dd-button-container">
                                    <span class="plan-current" *ngIf="currentPlan==='pro'">Current Plan</span>
                                    <button class="dd-upsc-button3" *ngIf="currentPlan!=='pro'"
                                        (click)="changeCancelPlan('pro')">
                                        <div class="btn-text-icon">
                                            <div class="text">Select Pro</div><mat-icon>chevron_right</mat-icon>
                                        </div>
                                    </button>
                                </span>

                                <span class="check-circle-list">
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">Small Package Carriers Tracking Data</span>
                                    </span>
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">10 Users</span>
                                    </span>
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">9 Dashboards</span>
                                    </span>
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">100K Data Export Limit</span>
                                    </span>
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">3 Years of Retention Proof of Delivery</span>
                                    </span>
                                    <span class="plan-features">
                                        <img src="../../../assets/images/check_circleImg.png" class="circle-checkimg">
                                        <span class="pointerText">Email Export of Scheduled Data</span>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <!-- <th>
                      <div class="premium">
                          <span class="slanting-premium">
                              <span class="header">Premium</span>
                              <span class="header-description">For large businesses looking to keep their growth on
                                  track and an eye
                                  on
                                  their billing.</span>
                          </span>
                          <span class="slanting-premium-image">
                              <img src="../../../../assets/images/premium.svg" class="imgFull">
                          </span>
                          <span class="curved-frame">
                              <span class="dollar">Custom</span>
                              <span class="dollar-byline">Reach out and we’ll help create the best plan.</span>

                              <span class="dd-button-container">
                                  <span class="plan-current" *ngIf="currentPlan==='premium'">Current Plan</span>
                                  <button class="upsc-button" *ngIf="currentPlan!=='premium'">
                                      <div class="btn-text-icon">
                                          <div class="text">Contact Us</div><mat-icon>chevron_right</mat-icon>
                                      </div>
                                  </button>
                              </span>

                              <span class="check-circle-list">
                                  <span class="plan-features">
                                      <span class="icon"><mat-icon>check_circle</mat-icon></span>
                                      <span class="text">Multi-Modal Tracking Data</span>
                                  </span>
                                  <span class="plan-features">
                                      <span class="icon"><mat-icon>check_circle</mat-icon></span>
                                      <span class="text">Carrier Billing Data</span>
                                  </span>
                                  <span class="plan-features">
                                      <span class="icon"><mat-icon>check_circle</mat-icon></span>
                                      <span class="text">Unlimited Users</span>
                                  </span>
                                  <span class="plan-features">
                                      <span class="icon"><mat-icon>check_circle</mat-icon></span>
                                      <span class="text">14 Dashboards</span>
                                  </span>
                                  <span class="plan-features">
                                      <span class="icon"><mat-icon>check_circle</mat-icon></span>
                                      <span class="text">250K Data Export Limit</span>
                                  </span>
                                  <span class="plan-features">
                                      <span class="icon"><mat-icon>check_circle</mat-icon></span>
                                      <span class="text">No Limit for Retention Proof of Delivery</span>
                                  </span>
                                  <span class="plan-features">
                                      <span class="icon"><mat-icon>check_circle</mat-icon></span>
                                      <span class="text">Email or Cloud Export of Scheduled Data</span>
                                  </span>
                                  <span class="plan-features">
                                      <span class="icon"><mat-icon>check_circle</mat-icon></span>
                                      <span class="text">Storage of Document Scans</span>
                                  </span>
                                  <span class="plan-features">
                                      <span class="icon"><mat-icon>check_circle</mat-icon></span>
                                      <span class="text">UPS Delivery Intercept API Built In</span>
                                  </span>
                              </span>
                          </span>
                      </div>
                  </th> -->
                <!-- </div> -->
            <!-- </table> -->
        </div>

        <div class="table-header-container-mobile">
            <div class="basic">
                <span class="slanting-basic">
                    <span class="header">Basic</span>
                    <span class="header-description">For our InsureShield Connect customers looking for a
                        value add.</span>
                </span>
                <span class="curved-frame">
                    <span class="dollar">$0/mo</span>
                    <span class="dollar-byline">Upgrade to any plan at any time.</span>

                    <span class="button-container">
                        <button class="upsc-button" *ngIf="currentPlan!=='basic'" (click)="changeCancelPlan('basic')">
                            <div class="btn-text-icon">
                                <div class="text">Select Basic</div><mat-icon>chevron_right</mat-icon>
                            </div>
                        </button>
                    </span>

                    <span class="check-circle-list">
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">USPS, DHL, and FedEx Tracking Data</span>
                        </span>
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">2 Users</span>
                        </span>
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">4 Dashboards</span>
                        </span>
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">20K Data Export Limit</span>
                        </span>
                    </span>
                </span>
            </div>

            <div class="starter">
                <span class="slanting-starter">
                    <span class="header">Starter</span>
                    <span class="header-description">For small businesses looking to get a leg up on their
                        tracking
                        needs.</span>
                </span>
                <span class="curved-frame">
                    <span class="dollar">$249/mo</span>
                    <span class="dollar-byline">For up to 5,000 shipments per month.</span>

                    <span class="button-container">
                        <button class="upsc-button" *ngIf="currentPlan!=='starter'"
                            (click)="changeCancelPlan('starter')">
                            <div class="btn-text-icon">
                                <div class="text">Select Starter</div><mat-icon>chevron_right</mat-icon>
                            </div>
                        </button>
                    </span>

                    <span class="check-circle-list">
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">Small Package Carriers Tracking Data</span>
                        </span>
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">5 Users</span>
                        </span>
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">9 Dashboards</span>
                        </span>
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">100K Data Export Limit</span>
                        </span>
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">3 Years of Retention Proof of Delivery</span>
                        </span>
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">Email Export of Scheduled Data</span>
                        </span>
                    </span>
                </span>
            </div>

            <div class="pro">
                <span class="slanting-pro">
                    <span class="header">Pro</span>
                    <span class="header-description">For medium businesses looking to scale through tracking
                        insights.</span>
                </span>
                <span class="curved-frame">
                    <span class="dollar">$399/mo</span>
                    <span class="dollar-byline">For over 5,000 shipments per month.</span>

                    <span class="button-container">
                        <button class="upsc-button" (click)="changeCancelPlan('pro')">
                            <div class="btn-text-icon">
                                <div class="text">Select Pro</div><mat-icon>chevron_right</mat-icon>
                            </div>
                        </button>
                    </span>

                    <span class="check-circle-list">
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">Small Package Carriers Tracking Data</span>
                        </span>
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">10 Users</span>
                        </span>
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">9 Dashboards</span>
                        </span>
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">100K Data Export Limit</span>
                        </span>
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">3 Years of Retention Proof of Delivery</span>
                        </span>
                        <span class="plan-features">
                            <span class="icon"><mat-icon>check_circle</mat-icon></span>
                            <span class="text">Email Export of Scheduled Data</span>
                        </span>
                    </span>
                </span>
            </div>

            <!-- <div class="premium">
              <span class="slanting-premium">
                  <span class="header">Premium</span>
                  <span class="header-description">For large businesses looking to keep their growth on
                      track and an eye
                      on
                      their billing.</span>
              </span>
              <span class="curved-frame">
                  <span class="dollar">Custom</span>
                  <span class="dollar-byline">Reach out and we’ll help create the best plan.</span>

                  <span class="button-container">
                      <span class="plan-current" *ngIf="currentPlan==='premium'">Current Plan</span>
                      <button class="upsc-button" *ngIf="currentPlan!=='premium'">
                          <div class="btn-text-icon">
                              <div class="text">Contact Us</div><mat-icon>chevron_right</mat-icon>
                          </div>
                      </button>
                  </span>

                  <span class="check-circle-list">
                      <span class="plan-features">
                          <span class="icon"><mat-icon>check_circle</mat-icon></span>
                          <span class="text">Multi-Modal Tracking Data</span>
                      </span>
                      <span class="plan-features">
                          <span class="icon"><mat-icon>check_circle</mat-icon></span>
                          <span class="text">Carrier Billing Data</span>
                      </span>
                      <span class="plan-features">
                          <span class="icon"><mat-icon>check_circle</mat-icon></span>
                          <span class="text">Unlimited Users</span>
                      </span>
                      <span class="plan-features">
                          <span class="icon"><mat-icon>check_circle</mat-icon></span>
                          <span class="text">14 Dashboards</span>
                      </span>
                      <span class="plan-features">
                          <span class="icon"><mat-icon>check_circle</mat-icon></span>
                          <span class="text">250K Data Export Limit</span>
                      </span>
                      <span class="plan-features">
                          <span class="icon"><mat-icon>check_circle</mat-icon></span>
                          <span class="text">No Limit for Retention Proof of Delivery</span>
                      </span>
                      <span class="plan-features">
                          <span class="icon"><mat-icon>check_circle</mat-icon></span>
                          <span class="text">Email or Cloud Export of Scheduled Data</span>
                      </span>
                      <span class="plan-features">
                          <span class="icon"><mat-icon>check_circle</mat-icon></span>
                          <span class="text">Storage of Document Scans</span>
                      </span>
                      <span class="plan-features">
                          <span class="icon"><mat-icon>check_circle</mat-icon></span>
                          <span class="text">UPS Delivery Intercept API Built In</span>
                      </span>
                  </span>
              </span>
          </div> -->
        </div>
    </div>
</div>






















<div class="feature-display-link" *ngIf="!pageLoading">
    <div class="feature-link" [ngClass]="{'rotate-icon':isShowFeatures}">
        <div class="text" (click)="showFeatures()">See Full Features of Plan</div><mat-icon
            (click)="showFeatures()">expand_more</mat-icon>
    </div>
</div>

<upsc-plans-features *ngIf="isShowFeatures && !pageLoading"></upsc-plans-features>
<!-- 
<div class="feature-display-link" *ngIf="!pageLoading && isShowFeatures">
    <div class="feature-link" [ngClass]="{'close-rotate-icon':closeShowFeatures}">
        <div class="text" (click)="closeFeatures()">Close Full Features of Plans</div><mat-icon
            (click)="closeFeatures()">expand_less</mat-icon>
    </div>
</div> -->

<div class="plan-container-button">
    <button class="ddupsc-white-button" (click)="showUp()">
        <mat-icon>chevron_left</mat-icon> Back
    </button>
</div>