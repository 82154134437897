import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EMPTY, interval, Observable, timer } from 'rxjs';
import { concatMapTo, distinctUntilChanged, retry, take } from 'rxjs/operators';
import { AccountInfo } from '../shared/models/account-info.interface';
import { IPolicies } from '../shared/models/policies.interface';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { ErrordialogComponent } from '../shared/components/errordialog/errordialog.component';

@Injectable()
export class LoginService {
  private userDetail: AccountInfo;
  private policyDetail: IPolicies;
  private dwDetail;
  private token = {
    accessToken: ""
  }
  private refreshToken = {
    refreshToken: ""
  }
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  constructor(private http: HttpClient, public dialog: MatDialog) { }

  //API call to login user
  public userLogin(login): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.login}`, login, this.httpOptions);
  }

  //Should theoretically only be called once (during user login)
  //Stored in sessionStorage.getItem('userDetails')
  public userDetails(accesstoken: string): Observable<any> {
    this.token.accessToken = accesstoken;
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.decodeToken}`, this.token, this.httpOptions);
  }

  public userDetails2(token: string): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.decodeToken2}`, {'idToken': token}, this.httpOptions);
  }

  // Update the sessionstorage when a user tries to update their profile information and user preferences
  public updateUserDetails(userObj: AccountInfo): AccountInfo {
    this.userDetail = <AccountInfo>JSON.parse(sessionStorage.getItem('userDetails'));

    if (userObj != null) {
      if (userObj.contactPhone != null) {
        this.userDetail.contactPhone = userObj.contactPhone;
      }
      if (userObj.contactName != null) {
        this.userDetail.contactName = userObj.contactName;
      }
      if (userObj.firstName != null) {
        this.userDetail.firstName = userObj.firstName;
      }
      if (userObj.lastName != null) {
        this.userDetail.lastName = userObj.lastName;
      }
      if (userObj.policyNumber != null) {
        this.userDetail.policyNumber = userObj.policyNumber;
      }
      if (userObj.zipCode != null) {
        this.userDetail.zipCode = userObj.zipCode;
      }

      //Updating autofill preferences portion
      this.userDetail.userPreference.address.addressLine1 = userObj.userPreference.address.addressLine1 ? userObj.userPreference.address.addressLine1 : null;
      this.userDetail.userPreference.address.addressLine2 = userObj.userPreference.address.addressLine2 ? userObj.userPreference.address.addressLine2 : null;
      this.userDetail.userPreference.address.addressLine3 = userObj.userPreference.address.addressLine3 ? userObj.userPreference.address.addressLine3 : null;
      this.userDetail.userPreference.address.city = userObj.userPreference.address.city ? userObj.userPreference.address.city : null;
      this.userDetail.userPreference.address.country = userObj.userPreference.address.country ? userObj.userPreference.address.country : null;
      this.userDetail.userPreference.address.state = userObj.userPreference.address.state ? userObj.userPreference.address.state : null;
      this.userDetail.userPreference.address.zip = userObj.userPreference.address.zip ? userObj.userPreference.address.zip : null;
      this.userDetail.userPreference.carrier = userObj.userPreference.carrier ? userObj.userPreference.carrier : null;
      this.userDetail.userPreference.preferenceName = userObj.userPreference.preferenceName ? userObj.userPreference.preferenceName : null;
      this.userDetail.userPreference.productCategory = userObj.userPreference.productCategory ? userObj.userPreference.productCategory : null;
      this.userDetail.userPreference.userPreferenceID = userObj.userPreference.userPreferenceID ? userObj.userPreference.userPreferenceID : null;

      // update the session variable with the latest changes
      sessionStorage.setItem('userDetails', JSON.stringify(userObj));
      return this.userDetail;
    }
  }

  //Returns the sessionStorage policy details in the IPolicies format
  public getPolicyDetails(): IPolicies {
    this.policyDetail = <IPolicies>JSON.parse(sessionStorage.getItem('policyDetails'));
    return this.policyDetail;
  }

  public getDWDetails() {
    this.dwDetail = JSON.parse(sessionStorage.getItem('dwDetails'));
    return this.dwDetail;
  }

  //CLEAR the local and session storage. Also resets the userDetail variable
  public removeLocalStorage(userName?: string, ObjectId?: string): Observable<any> {
    const endpoint = `${environment.baseUrl.api}${ApiEndpoints.logouts}`;

    //Maintain locale even if they logout
    let locale = sessionStorage.getItem('locale');
    localStorage.clear();
    sessionStorage.clear();
    this.userDetail = null;
    sessionStorage.setItem('locale', locale);
    if(userName && ObjectId) {
      return this.http.post<any>(endpoint, { userName, ObjectId });
    }
    else {
      return EMPTY;
    }
  }

  public getFreshToken(refreshToken: string): Observable<any> {
    this.refreshToken.refreshToken = refreshToken;
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.getFreshToken}`, this.refreshToken, this.httpOptions).pipe(retry(3));
  }

  errorDialog() {
    if (this.dialog.openDialogs.length == 0) {
      let dialogRef = this.dialog.open(ErrordialogComponent);
      dialogRef.afterClosed().subscribe(result => { });
    }
  }

  getGuestAccessToken(urlToken) {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.GUEST_ACCESS}`, { "GuestUserAccessURL": urlToken }, this.httpOptions);
  }

  setUpPowerBITokenTimer(policyDetails) {
    let tokenTime = new Date(policyDetails.dashboardInfo.embedToken.expiration);
    let currentTime = new Date();
    let keepFetching = true;
    //triggers 5 minutes before expiration (5 * 60 * 1000)
    timer((tokenTime.getTime() - 56 * 60 * 1000) - currentTime.getTime()).pipe(
      concatMapTo(interval(5000)), //check every one minute 1 * 60 * 1000
      distinctUntilChanged(),
      take(5)
    ).subscribe(
      data => {
        this.http.post<{embedToken: {expiration: string, token: string}, embedUrl: string}>(`${environment.baseUrl.api}${ApiEndpoints.REFRESH_POWERBI_TOKEN}`,
          {
            policyNumber: policyDetails.policyNumber
          },
          this.httpOptions).subscribe(
          data => {
            if(data.embedToken.expiration) {
              if(new Date(data.embedToken.expiration).getTime() > tokenTime.getTime()) {
                // console.log('refreshed token');
                keepFetching = false;
                this.policyDetail = {...this.policyDetail, ...{
                  dashboardInfo: {
                    embedToken: {
                      token: data.embedToken.token
                    },
                    embedUrl: data.embedUrl
                  }
                }};
                sessionStorage.setItem("policyDetails", JSON.stringify(this.policyDetail));
                this.setUpPowerBITokenTimer(this.policyDetail);
              }
              else {
                // console.log('same token');
              }
            }
          }
        );
      }
    )
  }

  recaptchaCheck(request) {
    return this.http.post<any>(`${environment.baseUrl.api}${ApiEndpoints.RECAPTCHA_CHECK}`, request, this.httpOptions);
  }
}

