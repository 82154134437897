import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimService } from 'src/app/services/claim.service';
import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';
import { ClaimStats } from 'src/app/shared/models/claim.interface';
import { UserPermission } from 'src/app/shared/models/user-permission';

@Component({
  selector: 'upsc-hr-claims',
  templateUrl: './hr-claims.component.html',
  styleUrls: ['./hr-claims.component.scss']
})
export class HrClaimsComponent {

  showStats: boolean;
  public userPermission: UserPermission = new UserPermission();
  claimStats: ClaimStats;
  tabData: any = {};


  constructor(private userService: UserService,
    private claimService: ClaimService, private loginService: LoginService,
    private router: Router) {
    this.userPermission = this.userService.getUserPermissions();
    this.claimService.getClaimStats(this.loginService.getPolicyDetails().policyNumber, '').subscribe(
      data => {
        this.claimStats = data;
        this.showStats = this.claimStats.newMessagesCnt > 0;
      }
    );

    this.tabData = {
      claims: false,
      fileClaim: false,
      messages: false,
      faq: false
    }


    if (this.router.url.endsWith('/help')) {
      this.setHighlghter('faq')
    } else if (this.router.url.endsWith('/claims')) {
      this.setHighlghter('claims')
    } else if (this.router.url.endsWith('/message-list')) {
      this.setHighlghter('messages')
    } else {
      this.setHighlghter('fileClaim')
    }
  }


  setHighlghter(tabName: string) {
    if (tabName === 'faq') {
      this.tabData.faq = true;
      this.tabData.claims = false;
      this.tabData.messages = false;
      this.tabData.fileClaim = false;
    } else if (tabName === 'claims') {
      this.tabData.faq = false;
      this.tabData.claims = true;
      this.tabData.messages = false;
      this.tabData.fileClaim = false;
    } else if (tabName === 'messages') {
      this.tabData.faq = false;
      this.tabData.claims = false;
      this.tabData.messages = true;
      this.tabData.fileClaim = false;
    } else {
      this.tabData.faq = false;
      this.tabData.claims = false;
      this.tabData.messages = false;
      this.tabData.fileClaim = true;
    }
  }


  gotoMessages(event: any) {
    this.router.navigate(['/happy-returns/claims/message-list/']);
  }


  // for sub-header horizontal scroll
  mouseDown = false;
  startX: any;
  scrollLeft: any;
  @ViewChild('parent') slider: ElementRef;
  startDragging(e, flag) {
    this.mouseDown = true;
    this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;
  }
  stopDragging(e, flag) {
    this.mouseDown = false;
  }
  moveEvent(e) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - this.slider.nativeElement.offsetLeft;
    const scroll = x - this.startX;
    this.slider.nativeElement.scrollLeft = this.scrollLeft - scroll;
  }

}
