import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { BillingService } from 'src/app/services/billing.service';
import { LoginService } from 'src/app/services/login.service';
import { PolicyService } from 'src/app/services/policy.service';
import { ScriptService } from 'src/app/services/script.service';
import { UserService } from 'src/app/services/user.service';
import { AccessPendingComponent } from 'src/app/shared/dialogs/access-pending/access-pending.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'upsc-authorize',
  templateUrl: './unified.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthorizeComponent implements OnInit
  {
  tokenError: boolean;
  errorOccurred: boolean;
  idData: boolean = false;
  workflow: string;
  navEvent: any;

  constructor(
    private router: Router,
    private userService: UserService,
    private loginService: LoginService,
    private policyService: PolicyService,
    private billingService: BillingService,
    private scriptService: ScriptService,
    private dialog: MatDialog,
    private msalService: MsalService
  ) {}

  ngOnInit() {
    let token = sessionStorage.getItem('access_token')
    let returnUrl = sessionStorage.getItem('returnUrl') || '/dashboard';
    this.loginService.userDetails2(token).subscribe(
      data => {
        //deny access if user data is missing
        if(!data.policyNumber || !data.approved || !data.active || !data.permissions || data.permissions.length <= 0) {
          if (this.dialog.openDialogs.length == 0) {
            let dialogRef = this.dialog.open(AccessPendingComponent);
            dialogRef.afterClosed().subscribe(
              d => {
                this.onErrors();
              }
            );
          }
        }
        else {
          if (data.policyNumber.search(/^\d+-p\d+$/) == 0) {
            data.policyNumber = data.policyNumber.toUpperCase();
          }
          this.userService.setUserInfo(data);
          sessionStorage.setItem('userid', data.userId);

          this.userService.setUserPermissions(data.permissions);

          this.userService.getPolicySearch(data.policyNumber).subscribe(
            policydata => {
              if(!policydata?.data) {
                this.errorOccurred = true;
              }
              else {
                sessionStorage.setItem('policyDetails', JSON.stringify(policydata.data));
                if (this.userService.isUserAdjuster()) {
                  this.router.navigateByUrl('/adjusterDashboard');
                }
                else if (policydata.data.policyType.key == 'Flex' && policydata.data.policyType.value == 'Flexible Parcel Insurance' && policydata.data.policySource == 'ISHIPFLEXMC') { // Tupss flex USPS policies check
                  this.router.navigateByUrl('/billing');
                }
                else if (policydata.data.ref1 == 'HK') {
                  let smartUploadURL = environment.smartUpload.url;
                  this.policyService.getSmartUploadUrl({
                    policyno: policydata.data.policyNumber,
                    username: this.userService.getUserInfo().firstName
                  }).subscribe(
                    data => {
                      if (data?.url && data.url != '') {
                        smartUploadURL = data.url;
                      }
                      window.location.href = smartUploadURL;
                    },
                    error => {
                      window.location.href = smartUploadURL;
                    }
                  );

                }
                else {
                  this.scriptService.pendoInit();

                  let dwRequest = {
                    productSourceSystem: "gw",
                    sourceSystemIdentifierName: "PolicyNumber",
                    sourceSystemIdentifier: policydata?.data?.policyNumber,
                    country: "US",
                    roleType: "string",
                    productType: "string"
                  }
                  this.billingService.DWgetSummary(dwRequest).subscribe(
                    data => {
                      if (data?.data?.wallet) {
                        sessionStorage.setItem('dwDetails', JSON.stringify(data.data.wallet))
                      }
                      this.router.navigateByUrl(returnUrl);
                    },
                    error => {
                      this.router.navigateByUrl(returnUrl);
                    }
                  );

                  // this.router.navigateByUrl(returnUrl);
                }
              }
            },
            error => this.errorOccurred = true
          )
        }
      },
      error => {
        this.errorOccurred = true;
      }
    )
  }

  onErrors() {
    this.msalService.instance.clearCache();
    sessionStorage.clear();
    this.errorOccurred = true;
  }
}

