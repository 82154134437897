import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { UtilityService } from '../../../services/utility.service';
import { UserService } from 'src/app/services/user.service';
import { HappyReturnsService } from 'src/app/services/happy-returns.service';

@Component({
  selector: 'upsc-collapsible-panel-white',
  templateUrl: './collapsible-panel-white.component.html',
  styleUrls: ['./collapsible-panel-white.component.scss']
})
export class CollapsiblePanelWhiteComponent {
  @HostBinding('class') public hostClass = 'collapsible-panel-container';

  @Input() public isCollapsed = false;
  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public showInfo: boolean;
  @Input() public showRed: boolean;
  @Input() public showWarning: boolean;
  @Input() edit: boolean;
  @Output() updatePayee = new EventEmitter<boolean>(false);
  @Output() collapsedEvent = new EventEmitter<boolean>(false);
  public tooltipPosition$: any;
  public isHappyReturns: boolean = false;
  public groupCode: string = '';
  userDetails: any = {};

  constructor(private utilService: UtilityService, private userService: UserService,
    private hpService: HappyReturnsService) {
    this.tooltipPosition$ = this.utilService.tooltipPositionWatch();
  }

  ngOnInit() {

    this.userDetails = this.userService.getUserInfo();

    try {
      let happyReturnsInfo = this.hpService.getHappyReturnsInfo();
      if (happyReturnsInfo !== null && happyReturnsInfo.partnerName === 'happy-returns') {
        this.isHappyReturns = true;
        this.groupCode = this.hpService.getHappyReturnsInfo().groupCode;
      }
    } catch {
      this.isHappyReturns = false;
    }
  }

  editPayee() {
    this.updatePayee.emit(true);
  }

  unCollapsed() {
    this.collapsedEvent.emit(true);
  }

  collapsed() {
    this.collapsedEvent.emit(false);
  }

  topCollapsed(isCollapsed) {
    this.collapsedEvent.emit(!isCollapsed);
  }

}
